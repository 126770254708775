import { z } from 'zod';
import Logger from '../utils/logger';
import { getRequestQueryWithAuth, isHttpSuccess } from './requests';
import { type Expand } from 'common/src/utils/types';
import { nullToUndefined } from 'common/src/utils/zod';
import { useCallback, useRef, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { deduplicate } from 'common/src/utils/collections';

const ITEMS_PER_PAGE = 32;

/** Schema for a Student Quiz, according to the API. */
const studentQuizApiSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  shared: z.boolean(),
  published: z.boolean(),
  tag: z.string(),
  year: z.string().nullish().transform(nullToUndefined),
  numberOfQuestions: z.number().int().min(0),
  randomiseQuestionParameters: z.boolean(),
  totalStars: z.number().int().min(0).nullish().transform(nullToUndefined).default(0),
  totalQuestionsAnswered: z.number().int().min(0).nullish().transform(nullToUndefined).default(0),
  quizInstanceAssignmentId: z.number().nullish().transform(nullToUndefined),
  quizSessionUuid: z.string().nullish().transform(nullToUndefined)
});

/** Schema for a list of Student Quizzes, according to the API. */
const studentQuizzesApiSchema = z.object({
  'hydra:member': studentQuizApiSchema.array(),
  'hydra:totalItems': z.number().int().min(0)
});

/** A quiz assigned to a student. */
export type StudentQuizApiEntity = Expand<z.infer<typeof studentQuizApiSchema>>;

/**
 * Hook for getting the latest student quizzes when the screen is focused. Returns an object with different properties
 * in depending on the status (loading, success, error).
 *
 * This hook also manages pagination, once the initial load is successful: simply call `loadNextPage` from the returned
 * object to load the next page, and the `loadingNextPage` boolean will indicate when this request is ongoing.
 *
 * This hook additionally deduplicates the student quizzes, in the edge case that the quizzes contain duplicates.
 * If a page with duplicates loads it will appear to have fewer than 32 entries.
 */
export function useStudentQuizzes(
  /**
   * Force requests to have a minimum duration of this long (to give time for loading spinners to look good). Affects
   * initial load only.
   */
  minDuration = 1000
):
  | {
      status: 'loading';
      /** When loading new data, the old data might be here. */
      studentQuizzes?: { pages: StudentQuizApiEntity[][]; totalItems: number };
      errorString?: undefined;
      loadNextPage?: undefined;
      loadingNextPage?: undefined;
    }
  | {
      status: 'error';
      studentQuizzes?: undefined;
      errorString: 'network error' | 'http error' | 'unknown error' | 'invalid response';
      loadNextPage?: undefined;
      loadingNextPage?: undefined;
    }
  | {
      status: 'success';
      studentQuizzes: { pages: StudentQuizApiEntity[][]; totalItems: number };
      errorString?: undefined;
      loadNextPage: (minDuration?: number) => Promise<void>;
      loadingNextPage: boolean;
    } {
  const [error, setError] = useState<
    'network error' | 'http error' | 'unknown error' | 'invalid response' | undefined
  >(undefined);
  const [data, setData] = useState<
    { pages: StudentQuizApiEntity[][]; ids: Set<number>; totalItems: number } | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const loadingNextPageRef = useRef(false);

  // Refresh the whole view whenever we regain focus
  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (loadingRef.current) {
          // Already loading, just abort silently.
          // Note: we use a ref for this, because this callback must not depend on the reactive variable
          // `loading`, or else it would be called in an infinite loop.
          return;
        }
        setLoading(true);
        loadingRef.current = true;
        const minDurationTimer = new Promise(r => setTimeout(r, minDuration));
        const result = await getStudentQuizzes(1);
        await minDurationTimer;
        setLoading(false);
        loadingRef.current = false;

        if (typeof result === 'string') {
          setError(result);
          setData(undefined);
        } else {
          setError(undefined);
          const ids = new Set<number>();
          const page = deduplicate(result.quizzesInPage, q => q.id, ids);
          setData({ pages: [page], ids, totalItems: result.totalQuizzes });
        }
      })();
    }, [minDuration])
  );

  /** Method for loading additional pages */
  const loadNextPage = useCallback(
    async (minDuration = 1000) => {
      if (data === undefined || Math.ceil(data.totalItems / ITEMS_PER_PAGE) === data.pages.length) {
        return;
      }

      const pageIndex = data.pages.length;

      if (loadingNextPageRef.current) {
        // Already loading, just abort silently
        // Note: we use a ref for this, because this callback must not depend on the reactive variable
        // `loadingNextPage`, or else it would be called in an infinite loop.
        return;
      }
      setLoadingNextPage(true);
      loadingNextPageRef.current = true;
      const minDurationTimer = new Promise(r => setTimeout(r, minDuration));
      const result = await getStudentQuizzes(pageIndex + 1);
      await minDurationTimer;
      setLoadingNextPage(false);
      loadingNextPageRef.current = false;

      if (typeof result === 'string') {
        setError(result);
        setData(undefined);
      } else {
        setError(undefined);
        setData(old => {
          if (old === undefined) {
            return old;
          }

          const ids = new Set(old.ids);
          const page = deduplicate(result.quizzesInPage, q => q.id, ids);
          const pages = [...old.pages];
          pages[pageIndex] = page;
          return { pages, ids, totalItems: result.totalQuizzes };
        });
      }
    },
    [data]
  );

  if (loading) {
    return {
      status: 'loading',
      studentQuizzes: data
    };
  } else if (error) {
    return { status: 'error', errorString: error };
  } else if (data) {
    return { status: 'success', studentQuizzes: data, loadNextPage, loadingNextPage };
  } else {
    // Shouldn't happen, but pretend we're loading
    return { status: 'loading' };
  }
}

/**
 * Get the student quizzes for a page (page size is {@link ITEMS_PER_PAGE}).
 * The response data also tells you how many quizzes there are in total.
 *
 * Note: for an easy-to-use hook, see {@link useStudentQuizzes}.
 */
export const getStudentQuizzes = async (
  pageNum: number
): Promise<
  | { quizzesInPage: StudentQuizApiEntity[]; totalQuizzes: number }
  | 'network error'
  | 'http error'
  | 'unknown error'
  | 'invalid response'
> => {
  const logTag = 'getStudentQuizzes' as const;
  const studentQuizzesEndpoint = `/web/infinity/student-quizzes?_page=${pageNum}&_itemsPerPage=${ITEMS_PER_PAGE}`;
  const result = await getRequestQueryWithAuth(studentQuizzesEndpoint, 'json');

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', logTag, 'NETWORK_ERROR', { eventData: result });
        return 'network error';
      case 'http':
      case 'loggedOut':
        Logger.captureEvent('error', logTag, 'HTTP_ERROR', { eventData: result });
        return 'http error';
      case 'unknown':
        Logger.captureEvent('error', logTag, 'UNKNOWN_ERROR', { eventData: result });
        return 'unknown error';
      default:
        Logger.captureEvent('fatal', logTag, 'UNKNOWN_ERROR', {
          additionalMsg: `Logic error: Unreachable (${result satisfies never})`
        });
        // Produces TS error and throws runtime error if we missed a case
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const data = result.response.data;

  // Success - Validate the response
  const parseResults = studentQuizzesApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    console.error(parseResults);
    Logger.captureEvent('error', logTag, 'PARSE_ERROR', { eventData: parseResults });
    return 'invalid response';
  }

  // Validation success
  return {
    quizzesInPage: parseResults.data['hydra:member'],
    totalQuizzes: parseResults.data['hydra:totalItems']
  };
};
