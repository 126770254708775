import { useCallback } from 'react';
import useScreenDimensions from './useScreenDimensions';
import { Platform } from 'react-native';

const devices = ['mobile', 'tablet'] as const;
type Device = 'mobile' | 'tablet';
type ChooseFunction = <T>(options: [T, T] | { mobile: T; tablet: T }) => T;

export default function useBreakpoints(): { type: Device; choose: ChooseFunction; resize: number } {
  const screenDimensions = useScreenDimensions();
  // For some reason on native sometimes the width and height get reversed. Since we lock to landscape, we just care about the smaller dimension.
  const screenHeight =
    Platform.OS === 'web'
      ? screenDimensions.height
      : Math.min(screenDimensions.height, screenDimensions.width);

  let device: Device;
  if (screenHeight <= 480) {
    device = 'mobile';
  } else {
    device = 'tablet';
  }

  const choose = useCallback<ChooseFunction>(
    options => (Array.isArray(options) ? options[devices.indexOf(device)] : options[device]),
    [device]
  );

  const resize = choose({ mobile: 0.7, tablet: 1 });

  return { type: device, choose, resize };
}
