export default {
  after: 'after',
  before: 'before',
  counter: 'counter',
  counters: 'counters',
  equalGroups: 'equal groups',
  EvenNumbers: 'Even numbers',
  equalTo: 'equal to',
  greaterThan: 'greater than',
  groupX: 'Group {0}',
  'In both the 5 and 10 times-tables': 'In both the 5 and 10 times-tables',
  lessThan: 'less than',
  Marbles: 'Marbles',
  more: 'more',
  OddNumbers: 'Odd numbers',
  'Only in the 5 times-table': 'Only in the 5 times-table',
  'Only in the 10 times-table': 'Only in the 10 times-table',
  fewer: 'fewer',
  unequalGroups: 'unequal groups',
  tens: 'Tens',
  ones: 'Ones',

  directions: {
    full: 'full',
    fullTurn: 'full turn',
    half: 'half',
    halfTurn: 'half turn',
    left: 'left',
    quarter: 'quarter',
    quarterTurn: 'quarter turn',
    right: 'right',
    threeQuarter: 'three-quarter',
    threeQuarterTurn: 'three-quarter turn'
  }
};
