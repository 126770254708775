import { SvgName } from '../assets/svg';
import { TranslationFunctions } from '../i18n/i18n-types';
import { getRandomFromArray, getRandomSubArrayFromArray, seededRandom } from './random';
import z from 'zod';

const threeDShapeArray = [
  'cuboids',
  'hexagonalPrisms',
  'pentagonalPrisms',
  'squareBasedPyramids',
  'triangularBasedPyramids',
  'triangularPrisms',
  'cubes',
  'cones',
  'cylinders'
] as const;

export const threeDShapesSchema = z.enum(threeDShapeArray);
export type ThreeDShape = z.infer<typeof threeDShapesSchema>;

export const shapes3DAsWord = (
  object: ThreeDShape,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'cubes':
      return translate.shapes.cubes(amount);
    case 'squareBasedPyramids':
      return translate.shapes.squareBasedPyramids(amount);
    case 'cylinders':
      return translate.shapes.cylinders(amount);
    case 'cones':
      return translate.shapes.cones(amount);
    case 'cuboids':
      return translate.shapes.cuboids(amount);
    case 'hexagonalPrisms':
      return translate.shapes.hexagonalPrisms(amount);
    case 'pentagonalPrisms':
      return translate.shapes.pentagonalPrisms(amount);
    case 'triangularPrisms':
      return translate.shapes.triangularPrisms(amount);
    case 'triangularBasedPyramids':
      return translate.shapes.triangularBasedPyramids(amount);
  }
};

const marshmallowShapeSVGName: {
  svgName: SvgName;
  vertices: number;
  edges: number;
  /** used for translation so need to match whats in the index file **/
  shape: ThreeDShape;
}[] = [
  {
    svgName: '3D_shapes_straws_marshmallows/Cuboid_shapes_straws_marshmallows',
    vertices: 8,
    edges: 12,
    shape: 'cuboids'
  },
  {
    svgName: '3D_shapes_straws_marshmallows/Hexagonal_prism_straws_marshmallows',
    vertices: 12,
    edges: 18,
    shape: 'hexagonalPrisms'
  },
  {
    svgName: '3D_shapes_straws_marshmallows/Pentagonal_prism_straws_marshmallows',
    vertices: 10,
    edges: 15,
    shape: 'pentagonalPrisms'
  },
  {
    svgName: '3D_shapes_straws_marshmallows/Square_based_pyramid_straws_marshmallows',
    vertices: 5,
    edges: 8,
    shape: 'squareBasedPyramids'
  },
  {
    svgName: '3D_shapes_straws_marshmallows/Tetrahedron_shapes_straws_marshmallows',
    vertices: 4,
    edges: 6,
    shape: 'triangularBasedPyramids'
  },
  {
    svgName: '3D_shapes_straws_marshmallows/Triangular_prism_straws_marshmallows',
    vertices: 6,
    edges: 9,
    shape: 'triangularPrisms'
  },
  {
    svgName: '3D_shapes_straws_marshmallows/cube_straws_marshmallows',
    vertices: 8,
    edges: 12,
    shape: 'cubes'
  }
];

export function getRandomShapeStrawMarshmallow() {
  return getRandomSubArrayFromArray([...marshmallowShapeSVGName] as const, 1)[0];
}

export const netsSVGName: {
  svgName: SvgName;
  /** used for translation so need to match whats in the index file **/
  shape: ThreeDShape;
}[] = [
  {
    svgName: 'Shape_nets/Cone_net',
    shape: 'cones'
  },
  {
    svgName: 'Shape_nets/Cube_net',
    shape: 'cubes'
  },
  {
    svgName: 'Shape_nets/Cuboid_net',
    shape: 'cuboids'
  },
  {
    svgName: 'Shape_nets/Cylinder_net',
    shape: 'cylinders'
  },
  {
    svgName: 'Shape_nets/Hexagonal_prism_net',
    shape: 'hexagonalPrisms'
  },
  {
    svgName: 'Shape_nets/Pentagonal_prism_net',
    shape: 'pentagonalPrisms'
  },
  {
    svgName: 'Shape_nets/Squared_based_pyramid_net',
    shape: 'squareBasedPyramids'
  },
  {
    svgName: 'Shape_nets/Tetrahedron_net',
    shape: 'triangularBasedPyramids'
  },
  {
    svgName: 'Shape_nets/Triangular_prism_net',
    shape: 'triangularPrisms'
  }
];

export const netsSVGNameNoFill: {
  svgName: SvgName;
  /** used for translation so need to match whats in the index file **/
  shape: ThreeDShape;
}[] = [
  {
    svgName: 'Shape_nets/Cone_net_noFill',
    shape: 'cones'
  },
  {
    svgName: 'Shape_nets/Cube_net_noFill',
    shape: 'cubes'
  },
  {
    svgName: 'Shape_nets/Cuboid_net_noFill',
    shape: 'cuboids'
  },
  {
    svgName: 'Shape_nets/Cylinder_net_noFill',
    shape: 'cylinders'
  },
  {
    svgName: 'Shape_nets/Hexagonal_prism_net_noFill',
    shape: 'hexagonalPrisms'
  },
  {
    svgName: 'Shape_nets/Pentagonal_prism_net_noFill',
    shape: 'pentagonalPrisms'
  },
  {
    svgName: 'Shape_nets/Squared_based_pyramid_net_noFill',
    shape: 'squareBasedPyramids'
  },
  {
    svgName: 'Shape_nets/Tetrahedron_net_noFill',
    shape: 'triangularBasedPyramids'
  },
  {
    svgName: 'Shape_nets/Triangular_prism_net_noFill',
    shape: 'triangularPrisms'
  }
];

export function getRandomUniqueNets(quantity: number) {
  return getRandomSubArrayFromArray([...netsSVGName] as const, quantity);
}

export const cubeNetsSvgName: {
  svgName: SvgName;
  correct: boolean;
}[] = [
  {
    svgName: 'Shape_nets/correctnetsofcubes1',
    correct: true
  },
  {
    svgName: 'Shape_nets/correctnetsofcubes2',
    correct: true
  },
  {
    svgName: 'Shape_nets/correctnetsofcubes3',
    correct: true
  },
  {
    svgName: 'Shape_nets/correctnetsofcubes4',
    correct: true
  },
  {
    svgName: 'Shape_nets/correctnetsofcubes5',
    correct: true
  },
  {
    svgName: 'Shape_nets/correctnetsofcubes6',
    correct: true
  },
  {
    svgName: 'Shape_nets/correctnetsofcubes7',
    correct: true
  },
  {
    svgName: 'Shape_nets/correctnetsofcubes8',
    correct: true
  },
  {
    svgName: 'Shape_nets/incorrectnetsofcubes1',
    correct: false
  },
  {
    svgName: 'Shape_nets/incorrectnetsofcubes2',
    correct: false
  },
  {
    svgName: 'Shape_nets/incorrectnetsofcubes3',
    correct: false
  },
  {
    svgName: 'Shape_nets/incorrectnetsofcubes4',
    correct: false
  }
];

export function getRandomCubeNets(quantity: number, isCorrect?: boolean) {
  const array =
    isCorrect !== undefined
      ? cubeNetsSvgName.filter(val => val.correct === isCorrect)
      : cubeNetsSvgName;
  return getRandomSubArrayFromArray([...array] as const, quantity);
}

export const shapeProperties: {
  vertices: 0 | 1 | 4 | 5 | 6 | 8 | 10 | 12;
  edges: 1 | 2 | 6 | 8 | 9 | 12 | 15 | 18;
  faces: 2 | 3 | 4 | 5 | 6 | 7 | 8;
  shape: ThreeDShape;
  svg: string;
  stampImages: StampShape[];
  stampCount?: number[];
}[] = [
  {
    vertices: 8,
    edges: 12,
    faces: 6,
    shape: 'cuboids',
    svg: get3DShapeSvgName('cuboids'),
    stampImages: ['rectangles', 'squares'],
    stampCount: [4, 2]
  },
  {
    vertices: 12,
    edges: 18,
    faces: 8,
    shape: 'hexagonalPrisms',
    svg: get3DShapeSvgName('hexagonalPrisms'),
    stampImages: ['hexagons', 'rectangles'],
    stampCount: [2, 6]
  },
  {
    vertices: 10,
    edges: 15,
    faces: 7,
    shape: 'pentagonalPrisms',
    svg: get3DShapeSvgName('pentagonalPrisms'),
    stampImages: ['pentagons', 'rectangles'],
    stampCount: [2, 5]
  },
  {
    vertices: 5,
    edges: 8,
    faces: 5,
    shape: 'squareBasedPyramids',
    svg: get3DShapeSvgName('squareBasedPyramids'),
    stampImages: ['squares', 'triangles'],
    stampCount: [1, 4]
  },
  {
    vertices: 4,
    edges: 6,
    faces: 4,
    shape: 'triangularBasedPyramids',
    svg: get3DShapeSvgName('triangularBasedPyramids'),
    stampImages: ['triangles'],
    stampCount: [4]
  },
  {
    vertices: 6,
    edges: 9,
    faces: 5,
    shape: 'triangularPrisms',
    svg: get3DShapeSvgName('triangularPrisms'),
    stampImages: ['triangles', 'rectangles'],
    stampCount: [2, 3]
  },
  {
    vertices: 8,
    edges: 12,
    faces: 6,
    shape: 'cubes',
    svg: get3DShapeSvgName('cubes'),
    stampImages: ['squares'],
    stampCount: [4]
  },
  {
    shape: 'cylinders',
    edges: 2,
    vertices: 0,
    faces: 3,
    svg: get3DShapeSvgName('cylinders'),
    stampImages: ['circles']
  },
  {
    shape: 'cones',
    edges: 1,
    vertices: 1,
    faces: 2,
    svg: get3DShapeSvgName('cones'),
    stampImages: ['circles']
  }
];

const threeDShapeSvgArray = [
  'Cone_dotted_lines',
  'Other_shapes/cube2',
  'Other_shapes/Cube_dottedlines1',
  'Other_shapes/Cube_dottedlines2',
  'Other_shapes/squarebasedpyramid1',
  'Other_shapes/squarebasedpyramid2',
  'Other_shapes/squarebasedpyramid3',
  'Other_shapes/tetrahedron2',
  'Other_shapes/tetrahedron3',
  'Other_shapes/Tetrehedron1_dotted_lines',
  'Other_shapes/Tetrehedron2_dotted_lines',
  'Other_shapes/Tetrehedron3_dotted_lines',
  'Other_shapes/cylinder2',
  'Other_shapes/Cylinder_dotted_lines1',
  'Other_shapes/Cylinder_dotted_lines2',
  'Other_shapes/cuboid3',
  'Other_shapes/cuboid2',
  'Other_shapes/Cuboid_dottedlines1',
  'Other_shapes/Cuboid_dottedlines2',
  'Other_shapes/Cuboid_dottedlines3',
  'Other_shapes/hexagonalprism2',
  'Other_shapes/Hexagonalprism_dottedlines1',
  'Other_shapes/Hexagonalprism_dottedlines2',
  'Other_shapes/pentagonalprism2',
  'Other_shapes/Pentagonalprism_dottedlines1',
  'Other_shapes/Pentagonalprism_dottedlines2',
  'Other_shapes/Triangularprism_dottedlines1',
  'Other_shapes/triangularprism2',
  'Other_shapes/triangularprism3',
  'Other_shapes/Triangularprism_dottedlines2',
  'Other_shapes/Triangularprism_dottedlines3'
] as const;

export const ThreeDShapeSvgSchema = z.enum(threeDShapeSvgArray);

export type ThreeDShapeSvgs = (typeof threeDShapeSvgArray)[number];

export function get3DShapeSvgName(
  object: ThreeDShape,
  seed?: string | number | object
): ThreeDShapeSvgs {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;
  switch (object) {
    case 'cubes':
      return getRandomFromArray(
        [
          'Other_shapes/cube2',
          'Other_shapes/Cube_dottedlines1',
          'Other_shapes/Cube_dottedlines2'
        ] as const,
        seeded
      );
    case 'squareBasedPyramids':
      return getRandomFromArray(
        [
          'Other_shapes/squarebasedpyramid1',
          'Other_shapes/squarebasedpyramid2',
          'Other_shapes/squarebasedpyramid3'
        ] as const,
        seeded
      );
    case 'triangularBasedPyramids':
      return getRandomFromArray(
        [
          'Other_shapes/tetrahedron2',
          'Other_shapes/tetrahedron3',
          'Other_shapes/Tetrehedron1_dotted_lines',
          'Other_shapes/Tetrehedron2_dotted_lines',
          'Other_shapes/Tetrehedron3_dotted_lines'
        ] as const,
        seeded
      );
    case 'cylinders':
      return getRandomFromArray(
        [
          'Other_shapes/cylinder2',
          'Other_shapes/Cylinder_dotted_lines1',
          'Other_shapes/Cylinder_dotted_lines2'
        ] as const,
        seeded
      );
    case 'cones':
      return 'Cone_dotted_lines';
    case 'cuboids':
      return getRandomFromArray(
        [
          'Other_shapes/cuboid3',
          'Other_shapes/cuboid2',
          'Other_shapes/Cuboid_dottedlines1',
          'Other_shapes/Cuboid_dottedlines2',
          'Other_shapes/Cuboid_dottedlines3'
        ] as const,
        seeded
      );
    case 'hexagonalPrisms':
      return getRandomFromArray(
        [
          'Other_shapes/hexagonalprism2',
          'Other_shapes/Hexagonalprism_dottedlines1',
          'Other_shapes/Hexagonalprism_dottedlines2'
        ] as const,
        seeded
      );
    case 'pentagonalPrisms':
      return getRandomFromArray(
        [
          'Other_shapes/pentagonalprism2',
          'Other_shapes/Pentagonalprism_dottedlines1',
          'Other_shapes/Pentagonalprism_dottedlines2'
        ] as const,
        seeded
      );
    case 'triangularPrisms':
      return getRandomFromArray(
        [
          'Other_shapes/Triangularprism_dottedlines1',
          'Other_shapes/triangularprism2',
          'Other_shapes/triangularprism3',
          'Other_shapes/Triangularprism_dottedlines2',
          'Other_shapes/Triangularprism_dottedlines3'
        ] as const,
        seeded
      );
  }
}

export function getRandom3DShape() {
  return getRandomFromArray(threeDShapeArray);
}

export function getRandomUnique3DShapeNames(numberOf3DShapesNames: number) {
  return getRandomSubArrayFromArray([...threeDShapeArray] as const, numberOf3DShapesNames);
}

const realLifeObjectOf3DShapesSvgNames: SvgName[] = [
  '3D_shapes_in_real_life_objects/Cone_objects/Chips_cone',
  '3D_shapes_in_real_life_objects/Cone_objects/Megaphone',
  '3D_shapes_in_real_life_objects/Cone_objects/Traffic_cone',
  '3D_shapes_in_real_life_objects/Cone_objects/Waffle_cone',
  '3D_shapes_in_real_life_objects/Cube_objects/Brick',
  '3D_shapes_in_real_life_objects/Cube_objects/Dice',
  '3D_shapes_in_real_life_objects/Cube_objects/Ice_cube',
  '3D_shapes_in_real_life_objects/Cube_objects/Open_cardboard_box',
  '3D_shapes_in_real_life_objects/Cube_objects/Present',
  '3D_shapes_in_real_life_objects/Cube_objects/Rubix_cube',
  '3D_shapes_in_real_life_objects/Cube_objects/Stacking_crate',
  '3D_shapes_in_real_life_objects/Cube_objects/White_sugar_cube',
  '3D_shapes_in_real_life_objects/Cube_objects/Wooden_letter_block',
  '3D_shapes_in_real_life_objects/Cuboid_objects/Brick',
  '3D_shapes_in_real_life_objects/Cuboid_objects/Cardboard_box_cuboid',
  '3D_shapes_in_real_life_objects/Cuboid_objects/Cereal_box',
  '3D_shapes_in_real_life_objects/Cuboid_objects/Chest_cuboid',
  '3D_shapes_in_real_life_objects/Cuboid_objects/Chocolate_cuboid',
  '3D_shapes_in_real_life_objects/Cuboid_objects/Domino',
  '3D_shapes_in_real_life_objects/Cuboid_objects/Tissue_box',
  '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Communication_tower',
  '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Egyptian_pyramid',
  '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Louvre_pyramid',
  '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Mayan_pyramid',
  '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Safety_sign',
  '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Tower',
  '3D_shapes_in_real_life_objects/Triangular_prism_objects/Cheese',
  '3D_shapes_in_real_life_objects/Triangular_prism_objects/Chocolate_triangular_prism',
  '3D_shapes_in_real_life_objects/Triangular_prism_objects/Tent',
  '3D_shapes_in_real_life_objects/Triangular_prism_objects/Wood_triangular_prism'
];

export function getRandomUniqueRealLifeObjectOf3DShapesSvgNames(numberOf3DShapesNames: number) {
  return getRandomSubArrayFromArray(
    [...realLifeObjectOf3DShapesSvgNames] as const,
    numberOf3DShapesNames
  );
}

const stampArray = [
  'triangles',
  'squares',
  'circles',
  'rectangles',
  'pentagons',
  'hexagons'
] as const;

export const stampsSchema = z.enum(stampArray);
export type StampShape = z.infer<typeof stampsSchema>;

export function getStampSVGName(shape: StampShape) {
  switch (shape) {
    case 'triangles':
      return 'Other_shapes/stamp3dshape3';
    case 'squares':
      return 'Other_shapes/stamp3dshape1';
    case 'circles':
      return 'Other_shapes/stamp3dshape2';
    case 'rectangles':
      return 'Other_shapes/stamp3dshape4';
    case 'pentagons':
      return 'Other_shapes/stamp3dshape5';
    case 'hexagons':
      return 'Other_shapes/stamp3dshape6';
  }
}

export function getThreeDShapeOnIsometricPaper(shape: ThreeDShape): SvgName {
  switch (shape) {
    case 'cones':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_shape_cone1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_cone2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_cone3_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_cone4_isometric_paper'
      ] as const);
    case 'cubes':
      return getRandomFromArray([
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape2_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape10_isometric_paper'
      ] as const);
    case 'cuboids':
      return getRandomFromArray([
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape1_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape3_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape4_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape5_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape6_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape7_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape8_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape9_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape11_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape12_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape13_isometric_paper'
      ] as const);
    case 'cylinders':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_shape_cylinder1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_cylinder2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_cylinder3_isometric_paper'
      ] as const);
    case 'hexagonalPrisms':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism3_isometric_paper'
      ] as const);
    case 'pentagonalPrisms':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism3_isometric_paper'
      ] as const);
    case 'squareBasedPyramids':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid3_isometric_paper'
      ] as const);
    case 'triangularBasedPyramids':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid3_isometric_paper'
      ] as const);
    default:
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism3_isometric_paper'
      ] as const);
  }
}

export function getThreeDOutlineShapeOnIsometricPaper(shape: ThreeDShape): SvgName {
  switch (shape) {
    case 'cones':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_outline_shape_cone1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_cone2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_cone3_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_cone4_isometric_paper'
      ] as const);
    case 'cubes':
      return getRandomFromArray([
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape2_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape10_isometric_paper'
      ] as const);
    case 'cuboids':
      return getRandomFromArray([
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape1_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape3_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape4_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape5_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape6_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape7_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape8_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape9_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape11_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape12_isometric_paper',
        '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape13_isometric_paper'
      ] as const);
    case 'cylinders':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder3_isometric_paper'
      ] as const);
    case 'hexagonalPrisms':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism3_isometric_paper'
      ] as const);
    case 'pentagonalPrisms':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism3_isometric_paper'
      ] as const);
    case 'squareBasedPyramids':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid3_isometric_paper'
      ] as const);
    case 'triangularBasedPyramids':
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid3_isometric_paper'
      ] as const);
    default:
      return getRandomFromArray([
        '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism1_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism2_isometric_paper',
        '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism3_isometric_paper'
      ] as const);
  }
}

export function getRandomThreeDCompoundShapeOnIsometricPaperData() {
  return getRandomFromArray([
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape1', faces: 11 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape2', faces: 11 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape3', faces: 11 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape4', faces: 9 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape5', faces: 10 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape6', faces: 10 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape7', faces: 9 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape8', faces: 10 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape9', faces: 9 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape10', faces: 10 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape11', faces: 10 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape12', faces: 11 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape13', faces: 11 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape14', faces: 11 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape15', faces: 11 },
    { svgName: '3D_compound_shapes_on_isometric_paper/3D_compound_shape16', faces: 11 }
  ]);
}

export const get3DShapeFullColorsSVGPath = (
  color: 'blue' | 'green' | 'orange' | 'pink' | 'purple' | 'red' | 'yellow',
  shape: 'Cone' | 'Cube' | 'Cuboid' | 'Cylinder' | 'Sphere' | 'Square_pyramid'
) => {
  const svgNames = {
    Cone: `3D_shapes_full_colors/Cones/Cone_${color}` as const,
    Cuboid: `3D_shapes_full_colors/Cuboids/Cuboid_${color}` as const,
    Cube: `3D_shapes_full_colors/Cubes/Cube_${color}` as const,
    Cylinder: `3D_shapes_full_colors/Cylinders/Cylinder_${color}` as const,
    Sphere: `3D_shapes_full_colors/Spheres/Sphere_${color}` as const,
    Square_pyramid: `3D_shapes_full_colors/Square_pyramids/Square_pyramid_${color}` as const
  };
  return svgNames[shape];
};

export const threeDShapeToString = (
  translate: TranslationFunctions,
  shape: 'Cone' | 'Cube' | 'Cuboid' | 'Cylinder' | 'Sphere' | 'Square_pyramid',
  number: number
) => {
  switch (shape) {
    case 'Cone':
      return translate.shapes.cones(number);
    case 'Cube':
      return translate.shapes.cubes(number);
    case 'Cuboid':
      return translate.shapes.cuboids(number);
    case 'Cylinder':
      return translate.shapes.cylinders(number);
    case 'Sphere':
      return translate.shapes.spheres(number);
    case 'Square_pyramid':
      return translate.shapes.pyramids(number);
  }
};
