import { PropsWithChildren } from 'react';
import { View } from 'react-native';
import {
  useWindowLayout,
  containAspectRatio,
  QUESTION_WIDTH,
  MINIMUM_QUESTION_HEIGHT,
  ScaleContent
} from 'common/src/theme/scaling';

/**
 * Provides a basis for a screen which is scaled using a scaling that will fit a 1280x720 rectangle within its safe
 * area. Its children will be centered within the safe area.
 *
 * This also provides a Portal Host which has been scaled, but is not constrained to the safe area, because there are
 * many uses of portals which go over the unsafe area. If providing insets manually, bear in mind that you'll need to
 * divide them by the scale factor from {@link ScaleFactorContext}.
 */
export default function BaseScreen({ children }: PropsWithChildren) {
  const { windowDimensions, insets } = useWindowLayout();

  const questionSafeWidth = windowDimensions.width - insets.left - insets.right;
  const questionSafeHeight = windowDimensions.height - insets.top - insets.bottom;

  const questionActualDimens = containAspectRatio(
    { width: questionSafeWidth, height: questionSafeHeight },
    QUESTION_WIDTH / MINIMUM_QUESTION_HEIGHT
  );
  const scaleFactor = questionActualDimens.width / QUESTION_WIDTH;

  return (
    <ScaleContent dimens={windowDimensions} scaleFactor={scaleFactor}>
      <View
        style={{
          flex: 1,
          marginLeft: insets.left / scaleFactor,
          marginBottom: insets.bottom / scaleFactor,
          marginRight: insets.right / scaleFactor,
          marginTop: insets.top / scaleFactor,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {children}
      </View>
    </ScaleContent>
  );
}
