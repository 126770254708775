import { StatusBar } from 'expo-status-bar';
import { RootStackProps } from '../navigation/types';
import { showErrorToast, showInfoToast } from '../components/Toast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageBackground } from 'react-native';
import QuizAndResultsScreen from 'common/src/components/screens/QuizAndResultsScreen';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import { updateQuizSession } from '../network/quizSession';
import useQuestionQueueStore, {
  useSubmitAllQueuedQuestions
} from '../storage/useQuestionQueueStore';
import { QuestionToken } from 'common/src/SchemeOfLearning';
import { useI18nContext } from '../i18n/i18n-react';
import useBreakpoints from '../hooks/useBreakpoints';
import DebugModeContext from 'common/src/contexts/DebugModeContext';
import useLoginStore from '../storage/useLoginStore';
import { useNetworkStatus } from '../hooks/useNetworkStatus';
import Toast from 'react-native-root-toast';
import { popToTop } from '../navigation/actions';
import ENV from '../ENV';
import {
  combineResumeStatusWithLocalSessionCache,
  getResumeStatusFromQuizSession
} from '../utils/resumeQuizHelpers';
import Logger from '../utils/logger';

/** Combined Quiz and Results screen. */
export default function QuizScreen({ navigation }: RootStackProps<'Quiz'>) {
  const translate = useI18nContext().LL;

  const [quizKey, setQuizKey] = useState(0);
  const [error, setError] = useState('');

  const quizSession = useQuizSessionStore(state => state.quizSession);
  const updateQueueItem = useQuestionQueueStore(state => state.updateQueueItem);
  const deleteQueueItem = useQuestionQueueStore(state => state.deleteQueueItem);
  const submitAllQueuedQuestions = useSubmitAllQueuedQuestions(updateQuizSession);
  const school = useLoginStore(state => state.school);

  const hasInfinityPlus = school?.hasInfinityPlus;

  const { resize } = useBreakpoints();

  const { isConnected } = useNetworkStatus();

  // Just throw if the quizSession isn't defined. This should be impossible since we only navigate to this screen
  // immediately after setting the quizSession.
  if (quizSession === undefined) {
    throw new Error('Quiz Session is undefined, this should not be possible');
  }

  const sessionCache = useQuizSessionStore(state => state.cache[quizSession?.id]);
  const updateCacheEntry = useQuizSessionStore(state => state.updateCacheEntry);

  /** Tokens for the quiz. These may be missing question parameters, in which case they will be generated locally. */
  const tokens = useMemo(
    () =>
      quizSession.questions.map(
        (question): QuestionToken =>
          quizSession.randomiseQuestionParameters || question.parameters === undefined
            ? question.uid
            : // question.parameters is stringified question parameters, as obtained from the server.
              // All question params are plain old javascript objects.
              [question.uid, JSON.parse(question.parameters) as Record<string, unknown>]
      ),
    [quizSession.questions, quizSession.randomiseQuestionParameters]
  );

  /** The state to resume the quiz from, based on the question results in the quiz session. */
  const resumeFrom: Parameters<typeof QuizAndResultsScreen>[0]['resumeFrom'] = useMemo(() => {
    const resumeStatus = getResumeStatusFromQuizSession(quizSession);
    if (resumeStatus && sessionCache) {
      return combineResumeStatusWithLocalSessionCache(resumeStatus, sessionCache);
    } else {
      return resumeStatus;
    }
  }, [quizSession, sessionCache]);

  if (error !== '') {
    showErrorToast({
      title: error,
      message: translate.errorModals.cannotLoadQuizQuestions(),
      extraDuration: 1000,
      resize: resize
    });
    setError('');
    navigation.dispatch(popToTop());
  }

  const [toastInstance, setToastInstance] = useState<null | React.JSX.Element>(null);

  const removeToasts = useCallback(() => {
    if (toastInstance) {
      Toast.hide(toastInstance);
      setToastInstance(null);
    }
  }, [toastInstance]);

  useEffect(() => {
    if (isConnected && toastInstance) {
      Toast.hide(toastInstance);
      setToastInstance(null);
    }
  }, [isConnected, toastInstance]);

  const setAndShowToastInstance = useCallback(() => {
    // SetToast and save instance for removal
    const newToastInstance = showErrorToast({
      title: translate.errorModals.internetConnectionLost(),
      message: translate.errorModals.resultsFailedToSendEnsureAppIsConnectedToInternet(),
      persist: true
    });

    if (toastInstance) {
      // Remove last toast
      setTimeout(() => {
        Toast.hide(toastInstance);
      }, 500);
    }

    setToastInstance(newToastInstance);
  }, [toastInstance, translate.errorModals]);

  const onTokensInvalid = useCallback(
    (invalidTokens: QuestionToken[]) => {
      if (invalidTokens.length === tokens.length) {
        // All the questions were invalid! Show a toast and navigate back to home.
        showErrorToast({
          title: translate.errorModals.appNeedsToBeUpdated(),
          message: translate.errorModals.cannotLoadQuizQuestions(),
          extraDuration: 1000,
          resize: resize
        });

        navigation.dispatch(popToTop());
      } else {
        // Some, but not all of the questions were invalid. The QuizAndResultsScreen is just using the valid ones, so
        // we don't need to do anything other than show a toast.
        showInfoToast({
          title: translate.errorModals.appNeedsToBeUpdated(),
          message: translate.errorModals.xQuestionsCouldNotBeLoaded(invalidTokens.length),
          extraDuration: 1000,
          delay: 300,
          resize: resize
        });
      }
    },
    [navigation, resize, tokens.length, translate.errorModals]
  );

  /** Callback for when a question is answered by the user, correctly or incorrectly. */
  const onAnswer = useCallback(
    async (
      questionIndex: number,
      answer: string,
      isCorrect: boolean,
      timeTaken: number,
      attemptNumber: number,
      parameters: Record<string, unknown>
    ) => {
      // Skip over dev QR Codes
      if (!quizSession || quizSession.retryInfo.type === 'legacy') {
        return;
      }

      // Work out if this answer completes the quiz
      const isComplete =
        questionIndex === quizSession.questions.length - 1 && (isCorrect || attemptNumber === 3);

      // Send question results.
      // First, try to send results for this session on its own.
      const questionId = quizSession.questions[questionIndex].id;
      const questionResult = {
        question: questionId,
        answer,
        isCorrect,
        timeTaken,
        attemptNumber,
        parameters
      };
      const queueItem = updateQueueItem(quizSession.id, questionResult, isComplete || undefined);

      const result = await updateQuizSession(quizSession.id, {
        questionResults: queueItem.questionAttempts,
        isComplete: queueItem.isComplete
      });

      if (typeof result !== 'string') {
        // Success
        deleteQueueItem(quizSession.id);
        removeToasts();

        // Successfully sent results for this quiz, so try sending all unsent queued attempts.
        // Don't show error toast if these fail.
        submitAllQueuedQuestions();
      } else {
        // Failure
        if (hasInfinityPlus && !toastInstance) {
          setAndShowToastInstance();
        }
      }
    },
    [
      deleteQueueItem,
      hasInfinityPlus,
      quizSession,
      removeToasts,
      setAndShowToastInstance,
      submitAllQueuedQuestions,
      toastInstance,
      updateQueueItem
    ]
  );

  /** Callback for when the quiz ends. */
  const onQuizEnd = useCallback(() => {
    if (toastInstance) {
      // We were showing an error toast. Show it again if they dismissed it.
      setAndShowToastInstance();
    }
  }, [setAndShowToastInstance, toastInstance]);

  return (
    <>
      <StatusBar translucent style="light" />
      <ImageBackground
        source={require('pupil-app/assets/images/SpaceBackground.png')}
        resizeMode="cover"
        fadeDuration={0}
        style={{
          flex: 1
        }}
        testID="QUIZ_SCREEN"
      >
        <DebugModeContext.Provider value={ENV.IS_E2E}>
          <QuizAndResultsScreen
            key={quizKey}
            quizName={quizSession.name}
            tokens={tokens}
            resumeFrom={resumeFrom}
            loadingTextColor="white"
            onTokensInvalid={onTokensInvalid}
            onAnswer={onAnswer}
            onQuizEnd={onQuizEnd}
            onExitQuiz={currentState => {
              if (currentState) {
                updateCacheEntry(quizSession.id, currentState);
              }

              navigation.goBack();
              removeToasts();
            }}
            onRetryQuiz={() => {
              setQuizKey(old => old + 1);
              navigation.navigate('QuizLoading');
            }}
            onReturnToHome={() => {
              navigation.goBack();
              removeToasts();
            }}
            onQuestionError={([uid, params], userAnswer, error) => {
              Logger.captureEvent('error', 'quizScreen', 'RENDER_ERROR', {
                additionalMsg: uid,
                eventData: {
                  quizSessionId: quizSession.id,
                  quizName: quizSession.name,
                  questionParams: JSON.stringify(params),
                  userAnswer: JSON.stringify(userAnswer),
                  errorMessage: error.message
                }
              });
            }}
          />
        </DebugModeContext.Provider>
      </ImageBackground>
    </>
  );
}
