import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { all, create, number } from 'mathjs';
import {
  moreShapesAsWord,
  moreShapesName,
  moreShapesNames,
  moreShapesSchema
} from '../../../../utils/shapes';
import {
  getRegularShapeWithAllSidesMarkedSvgName,
  getShapeSvgName
} from '../../../../utils/shapeImages/shapes';
import { getRandomIrregularShapeSvgNames } from '../../../../utils/shapeImages/irregular';
import {
  getQuadrilateralSvgName,
  quadrilateralColors
} from '../../../../utils/quadrilateralImages';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Text from 'common/src/components/typography/Text';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { get3DShapeSvgName, getRandom3DShape } from '../../../../utils/threeDShapes';
import {
  brokenAndUnbrokenShapeToAssetPath,
  getRandomBrokenShape
} from '../../../../utils/brokenAndUnbrokenShapes';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { LabelledShapesWithMarkedSides } from '../../../../components/question/representations/LabelledShapesWithMarkedSides';
import { getRandomPolygonShapeNames, pentagonHouses } from '../../../../utils/shapeImages/polygons';
import { getRandomUniqueTriangleNames } from '../../../../utils/shapeImages/triangles';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ayE',
  description: 'ayE',
  keywords: ['Polygons'],
  schema: z.object({
    shapeSvgs: z.array(z.string()).length(4)
  }),
  simpleGenerator: () => {
    const { polygonShapeSvgA, polygonShapeSvgB, triangle } = rejectionSample(
      () => {
        const [polygonShapeA, polygonShapeB] = getRandomPolygonShapeNames(2);

        const polygonShapeSvgA = getShapeSvgName(polygonShapeA);
        const polygonShapeSvgB = getShapeSvgName(polygonShapeB);

        const [triangle] = getRandomUniqueTriangleNames(1);

        return { polygonShapeSvgA, polygonShapeSvgB, triangle };
      },
      // Only permit if array contains no duplicates.
      ({ polygonShapeSvgA, polygonShapeSvgB, triangle }) =>
        arrayHasNoDuplicates([polygonShapeSvgA, polygonShapeSvgB, triangle])
    );

    const threeDShape = getRandom3DShape();
    const pentagonHouse = getRandomFromArray(pentagonHouses);
    const threeDShapeSvg = get3DShapeSvgName(threeDShape);
    const brokenShape = brokenAndUnbrokenShapeToAssetPath[getRandomBrokenShape()];

    const [polygonSvgNameA, polygonSvgNameB] = getRandomSubArrayFromArray(
      [polygonShapeSvgA, polygonShapeSvgB, triangle, pentagonHouse],
      2
    );

    const [nonPolygonSvgNameA, nonPolygonSvgNameB] = getRandomSubArrayFromArray(
      [threeDShapeSvg, brokenShape],
      2
    );

    const shapeSvgs = [polygonSvgNameA, polygonSvgNameB, nonPolygonSvgNameA, nonPolygonSvgNameB];

    return { shapeSvgs };
  },
  Component: ({ translate, question: { shapeSvgs } }) => {
    const shuffledItems = shuffle(shapeSvgs, { random: seededRandom({ shapeSvgs }) });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortTheShapes()}
        pdfTitle={translate.instructions.useCardsToSortTheShapes()}
        zoneNames={[translate.keywords.Polygons(), translate.tableHeaders.notAPolygon()]}
        items={shuffledItems.map(svg => ({
          value: svg,
          component: <AssetSvg name={svg as SvgName} height={75} width={75} />
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        testCorrect={[
          [shapeSvgs[0], shapeSvgs[1]],
          [shapeSvgs[2], shapeSvgs[3]]
        ]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'ayF',
  description: 'ayF',
  keywords: ['Polygons'],
  schema: z.object({
    questionIndex: numberEnum([0, 1, 2, 3])
  }),
  simpleGenerator: () => {
    const questionIndex = getRandomFromArray([0, 1, 2, 3] as const);

    return { questionIndex };
  },
  Component: props => {
    const {
      question: { questionIndex },
      translate
    } = props;

    const answerOptions = [
      'angle',
      'open',
      'closed',
      'curved',
      'straight',
      'twoD',
      'ThreeD'
    ] as const;
    type AnswerOption = (typeof answerOptions)[number];

    const sentences = [
      {
        sentence: translate.answerSentences.polygonsAreAnsShapes(),
        answer: ['twoD']
      },
      {
        sentence: translate.answerSentences.polygonsAreMadeUpOfAnsSides(),
        answer: ['straight']
      },
      {
        sentence: translate.answerSentences.whereThePolygonsAnsLinesMeetTheyFormAnAns(),
        answer: ['straight', 'angle']
      },
      {
        sentence:
          translate.answerSentences.polygonsAreAnsShapesWhichMeansThereAreNoGapsInTheirSides(),
        answer: ['closed']
      }
    ] as { sentence: string; answer: AnswerOption[] }[];

    const sentence = sentences[questionIndex];

    const remainingOptions = answerOptions.filter(option => !sentence.answer.includes(option));

    const items =
      sentence.answer.length > 1
        ? [
            ...sentence.answer,
            ...getRandomSubArrayFromArray(remainingOptions, 2, {
              random: seededRandom(props.question)
            })
          ]
        : [
            ...sentence.answer,
            ...getRandomSubArrayFromArray(remainingOptions, 3, {
              random: seededRandom(props.question)
            })
          ];

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={shuffle(items, { random: seededRandom(props.question) }).map(item => ({
          value: item,
          component: translate.misc[item]()
        }))}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        pdfItemVariant="tallRectangle"
        sentence={sentence.sentence}
        testCorrect={sentence.answer}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ayG',
  description: 'ayG',
  keywords: ['Polygons'],
  schema: z.object({
    answerSvg: z.string(),
    answerShapeName: moreShapesSchema,
    incorrectShapeNames: z.array(moreShapesSchema).length(3)
  }),
  simpleGenerator: () => {
    const answerShapeName = getRandomFromArray(
      moreShapesNames.filter(val => val !== 'circles')
    ) as moreShapesName;
    const isQuadrilateral =
      answerShapeName === 'quadrilaterals' ||
      answerShapeName === 'rectangles' ||
      answerShapeName === 'squares';

    const remainingShapes = moreShapesNames.filter(name => name !== answerShapeName);

    const incorrectShapeNames = isQuadrilateral
      ? getRandomSubArrayFromArray(
          remainingShapes.filter(
            name => name !== 'rectangles' && name !== 'squares' && name !== 'quadrilaterals'
          ),
          3
        )
      : (getRandomSubArrayFromArray(remainingShapes, 3) as moreShapesName[]);

    const answerSvg =
      answerShapeName === 'quadrilaterals'
        ? getQuadrilateralSvgName(
            getRandomFromArray([
              'Parallelogram',
              'Rectangle',
              'Rhombus',
              'Square',
              'Trapezium',
              'Kite'
            ] as const),
            getRandomFromArray(quadrilateralColors)
          )
        : getShapeSvgName(answerShapeName);

    return { answerSvg, incorrectShapeNames, answerShapeName };
  },
  Component: props => {
    const {
      question: { answerSvg, incorrectShapeNames, answerShapeName },
      translate
    } = props;

    const items = shuffle([answerShapeName, ...incorrectShapeNames], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectNameOfPolygon()}
        pdfTitle={translate.instructions.circleNameOfPolygon()}
        testCorrect={[answerShapeName]}
        numItems={4}
        Content={
          <View style={{ top: 20 }}>
            <AssetSvg name={answerSvg as SvgName} height={200} width={200} />
          </View>
        }
        renderItems={items.map(shapeName => ({
          value: shapeName,
          component: (
            <Text style={{ textAlign: 'center' }} variant="WRN700">
              {moreShapesAsWord(shapeName, translate, 1)}
            </Text>
          )
        }))}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ayH',
  description: 'ayH',
  keywords: ['Polygons', 'Regular'],
  schema: z.object({
    regularPolygonSvgNameA: z.string(),
    regularPolygonSvgNameB: z.string(),
    irregularPolygonSvgNameA: z.string(),
    irregularPolygonSvgNameB: z.string()
  }),
  simpleGenerator: () => {
    const regularShapeName = getRandomSubArrayFromArray(
      [
        'kites',
        'triangles',
        'squares',
        'hexagons',
        'pentagons',
        'heptagons',
        'nonagons',
        'octagons',
        'decagons'
      ] as const,
      2
    );
    const [irregularPolygonSvgNameA, irregularPolygonSvgNameB] = getRandomIrregularShapeSvgNames(2);
    const [regularPolygonSvgNameA, regularPolygonSvgNameB] = regularShapeName.map(name =>
      getShapeSvgName(name)
    );

    return {
      regularPolygonSvgNameA,
      regularPolygonSvgNameB,
      irregularPolygonSvgNameA,
      irregularPolygonSvgNameB
    };
  },
  Component: props => {
    const {
      question: {
        regularPolygonSvgNameA,
        regularPolygonSvgNameB,
        irregularPolygonSvgNameA,
        irregularPolygonSvgNameB
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          component: <AssetSvg name={regularPolygonSvgNameA as SvgName} width={150} height={150} />
        },
        {
          value: 'B',
          component: <AssetSvg name={regularPolygonSvgNameB as SvgName} width={150} height={150} />
        },
        {
          value: 'C',
          component: (
            <AssetSvg name={irregularPolygonSvgNameA as SvgName} width={150} height={150} />
          )
        },
        {
          value: 'D',
          component: (
            <AssetSvg name={irregularPolygonSvgNameB as SvgName} width={150} height={150} />
          )
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectAllRegularPolygons()}
        pdfTitle={translate.instructions.circleAllRegularPolygons()}
        testCorrect={['A', 'B']}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ayI',
  description: 'ayI',
  keywords: ['Polygons', 'Perimeter'],
  schema: z.object({
    shapeName: z.enum(['pentagons', 'hexagons', 'heptagons', 'octagons', 'nonagons', 'decagons']),
    lengthOfSides: z.number().int().min(3).max(9),
    shapeSvgName: z.string()
  }),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray([
      'pentagons',
      'hexagons',
      'heptagons',
      'octagons',
      'nonagons',
      'decagons'
    ] as const);

    const lengthOfSides = randomIntegerInclusive(3, 9);

    const shapeSvgName = getRegularShapeWithAllSidesMarkedSvgName(shapeName);

    return {
      lengthOfSides,
      shapeName,
      shapeSvgName
    };
  },
  Component: ({ question: { lengthOfSides, shapeName, shapeSvgName }, translate, displayMode }) => {
    const answer = (() => {
      switch (shapeName) {
        case 'pentagons':
          return number(math.evaluate(`${lengthOfSides} * ${5}`));
        case 'hexagons':
          return number(math.evaluate(`${lengthOfSides} * ${6}`));
        case 'heptagons':
          return number(math.evaluate(`${lengthOfSides} * ${7}`));
        case 'octagons':
          return number(math.evaluate(`${lengthOfSides} * ${8}`));
        case 'nonagons':
          return number(math.evaluate(`${lengthOfSides} * ${9}`));
        case 'decagons':
          return number(math.evaluate(`${lengthOfSides} * ${10}`));
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsARegularXWhatIsThePerimeterOfTheX(
          translate.shapes[shapeName](1)
        )}
        Content={({ dimens }) => (
          <View style={{ top: 50 }}>
            {LabelledShapesWithMarkedSides({
              dimens,
              displayMode,
              labelText: translate.units.numberOfCm(lengthOfSides),
              shapeName,
              shapeSvgName
            })}
          </View>
        )}
        sentence={translate.answerSentences.ansCm()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ayJ',
  description: 'ayJ',
  keywords: ['Polygons'],
  schema: z
    .object({
      numberOfSides: z.number().int().min(5).max(10),
      perimeter: z.number().int().min(50).max(100)
    })
    .refine(
      val => val.perimeter % val.numberOfSides === 0,
      'perimeter needs to be a multiple of numberOfSides'
    ),
  simpleGenerator: () => {
    const numberOfSides = randomIntegerInclusive(5, 10);

    const perimeter = randomIntegerInclusive(50, 100, { constraint: x => x % numberOfSides === 0 });

    return { numberOfSides, perimeter };
  },
  Component: props => {
    const {
      question: { numberOfSides, perimeter },
      translate
    } = props;

    const answer = number(math.evaluate(`${perimeter} / ${numberOfSides}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aRegularPolygonHasXSidesItsPerimeterIsYmmWhatIsTheLengthOfEachSideOfThePolygon(
          translate.numbersAsWords[numberOfSides as 5 | 6 | 7 | 8 | 9 | 10](),
          perimeter
        )}
        sentence={translate.answerSentences.ansMm()}
        testCorrect={[answer.toString()]}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Polygons',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [Question4]
});
export default SmallStep;
