import { useMemo, memo } from 'react';
import {
  getSvgInfo,
  type SvgName,
  type SvgNameCustomizable,
  type SvgWithNaturalDimensions,
  type SvgNameFixed
} from './_generatedSvgInfo';
import SvgImage from 'common/src/utils/svgImage';
import { type SvgImageProps } from 'common/src/utils/svgImageTypes';
import { type SvgProps } from 'react-native-svg';

/**
 * React component of an SVG obtained from assets. Uses the `name` prop to load a specific SVG.
 *
 * -   If you provide width and height, then the image will use those dimensions.
 * -   If you provide exactly one of width or height, then the image will calculate the other dimension based on the
 *     viewBox's aspect ratio.
 * -   If you provide neither width nor height, then:
 *     -   If the .svg file has a width/height defined, it will use that.
 *     -   Otherwise, the image with fill its parent.
 * -   If the SVG is one of {@link SvgNameCustomizable}, then you can additionally provide SVG props
 */
const AssetSvg = memo(function ({
  name,
  width,
  height,
  style,
  ...props
}:
  | {
      name: SvgNameCustomizable;
      width?: number;
      height?: number;
      style?: SvgProps['style'];
      /** Svg props. Only used if `name` is one of {@link SvgNameCustomizable}, otherwise has no effect. */
      svgProps?: Omit<SvgProps, 'width' | 'height' | 'style'>;
    }
  | {
      name: SvgNameFixed;
      width?: number;
      height?: number;
      style?: SvgImageProps['style'];
    }
  | {
      name: SvgName;
      width?: number;
      height?: number;
      style?: SvgProps['style'] & SvgImageProps['style'];
    }): JSX.Element {
  const svgInfo = useMemo(() => getSvgInfo(name), [name]);
  const { defaultWidth, defaultHeight, aspectRatio } = svgInfo;

  if ('asset' in svgInfo) {
    ////
    // SVG was a .svg file so name was a FixedAssetSvg.
    // Use expo-image or next-image to render
    ////
    const asset = svgInfo.asset;
    if ('svgProps' in props && props.svgProps !== undefined) {
      console.warn('svgProps provided but name was a FixedSvgName');
    }

    if (width === undefined && height === undefined) {
      // Neither width nor height are provided.
      if (defaultHeight !== undefined && defaultWidth !== undefined) {
        // Show image using the width/height defined in the SVG file.
        return (
          <SvgImage
            source={asset}
            width={defaultWidth}
            height={defaultHeight}
            style={style as SvgImageProps['style']}
          />
        );
      } else {
        // Allow image to fill its container
        return <SvgImage source={asset} fill={true} style={style as SvgImageProps['style']} />;
      }
    } else {
      // At least one of width/height are given. If one is unset, calculate it based on the aspect ratio.
      if (width === undefined && height !== undefined) {
        // Height is given but width isn't - set width
        width = height * aspectRatio;
      } else if (width !== undefined && height === undefined) {
        // Width is given but height isn't - set height
        height = width / aspectRatio;
      }

      return (
        <SvgImage
          source={asset}
          width={width}
          height={height}
          style={style as SvgImageProps['style']}
        />
      );
    }
  } else {
    ////
    // SVG was a .tsx file with react-native-svg, so name was a CustomizableSvgName.
    // Render as is, after applying the custom width/height.
    ////
    const svgProps = (props as { svgProps?: Omit<SvgProps, 'width' | 'height' | 'style'> })
      .svgProps;
    const Svg = svgInfo.Svg;
    if ('imageProps' in props && props.imageProps !== undefined) {
      console.warn('imageProps provided but name was a CustomizableSvgName');
    }

    if (width === undefined && height === undefined) {
      // Neither width nor height are provided.
      if (defaultHeight !== undefined && defaultWidth !== undefined) {
        // Show image using the width/height defined in the SVG file.
        return <Svg width={defaultWidth} height={defaultHeight} style={style} {...svgProps} />;
      } else {
        // Allow image to fill its container
        return <Svg width={undefined} height={undefined} style={style} {...svgProps} />;
      }
    } else {
      // At least one of width/height are given. If one is unset, calculate it based on the aspect ratio.
      if (width === undefined && height !== undefined) {
        // Height is given but width isn't - set width
        width = height * aspectRatio;
      } else if (width !== undefined && height === undefined) {
        // Width is given but height isn't - set height
        height = width / aspectRatio;
      }
    }

    return <Svg width={width} height={height} style={style} {...svgProps} />;
  }
});

export {
  AssetSvg,
  getSvgInfo,
  SvgName,
  SvgNameFixed,
  SvgNameCustomizable,
  SvgWithNaturalDimensions
};
