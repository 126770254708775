import { GREATER_THAN, LESS_THAN, MULT } from '../../constants';

export default {
  canEachChildHave1AppleTickYourAnswer: 'Can each child have 1 apple?<br/>Tick your answer.',
  canEachChildHave1CakeTickYourAnswer: 'Can each child have 1 cake?<br/>Tick your answer.',
  canEachChildHave1CookieTickYourAnswer: 'Can each child have 1 cookie?<br/>Tick your answer.',
  canEachChildHave1PieceOfFruitTickYourAnswer:
    'Can each child have 1 piece of fruit?<br/>Tick your answer.',
  canEachDogHave1BallTickYourAnswer: 'Can each dog have 1 ball?<br/>Tick your answer.',
  canEachDogHave1BoneTickYourAnswer: 'Can each dog have 1 bone?<br/>Tick your answer.',
  canEachHorseHave1BaleOfHayTickYourAnswer:
    'Can each horse have 1 bale of hay?<br/>Tick your answer.',
  canEachHorseHave1CarrotTickYourAnswer: 'Can each horse have 1 carrot?<br/>Tick your answer.',
  canEachSheepHave1BaleOfHayTickYourAnswer:
    'Can each sheep have 1 bale of hay?<br/>Tick your answer.',
  characterIsCountingBackwardsFromXToYTickTheNumbersCharacterWillSay:
    '{character} is counting backwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Tick the numbers {character} will say.',
  characterIsCountingForwardsFromXToYTickTheNumbersCharacterWillSay:
    '{character} is counting forwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Tick the numbers {character} will say.',
  characterIsCountingForwardsInXFromYTickNumbers:
    '{0} is counting forwards in {1}s from {2}<br/>Tick the numbers that {0} will say.',
  characterIsCountingBackwardsInXFromYTickNumbers:
    '{0} is counting backwards in {1}s from {2}<br/>Tick the numbers that {0} will say.',
  circleAllTheEvenNumbers: 'Circle all the even numbers.',
  circleAllTheOddNumbers: 'Circle all the odd numbers.',
  circleTheDiagramsThatShowAHalf: 'Circle the diagram{{s}} that show a half.',
  circleTheDiagramsThatShowAQuarter: 'Circle the diagram{{s}} that show a quarter.',
  circleGreaterThanNumberAsWord: 'Circle a number that is greater than {0: string|lower}.',
  circleGreaterThanNumberAsNumber: 'Circle a number that is greater than {0: number}',
  circleLessThanNumberAsWord: 'Circle a number that is less than {0: string|lower}.',
  circleLessThanNumberAsNumber: 'Circle a number that is less than {0: number}',
  circleNumberAsWord: 'Circle {0: string|lower}.',
  circleOneLessThanNumberAsWord: 'Circle one less than {0: string|lower}.',
  circle1LessThanNumberAsNumber: 'Circle 1 less than {0: number}',
  circleOneMoreThanNumberAsWord: 'Circle one more than {0: string|lower}.',
  circle1MoreThanNumberAsNumber: 'Circle 1 more than {0: number}',
  circleTheGroupThatHasAXShaded: 'Circle the group that has a {0:string|lower} shaded.',
  circleTheGroupsThatHaveAXShaded: 'Circle the groups that have a {0:string|lower} shaded.',
  circleTheGroupThatShowsX: 'Circle the group that shows {0:string|lower}.',
  circleTheGroupsThatShowsX: 'Circle the groups that shows {0:string|lower}.',
  circleTheNumber1LessThanNum: 'Circle the number 1 less than {numberA:number|numberFormat}',
  circleTheNumber1MoreThanNum: 'Circle the number 1 more than {numberA:number|numberFormat}',
  circleTheNumberNum: 'Circle the number {numberA:number|numberFormat}',
  circleTheXThatAreCutInHalf: 'Circle the {0:string|lower} that are cut in half.',
  circleTheXThatIsCutInHalf: 'Circle the {0:string|lower} that is cut in half.',
  circleTheXThatAreCutIntoYEqualParts:
    'Circle the {0:string|lower} that are cut into {1:number|numberFormat} equal parts.',
  circleTheXThatIsCutIntoYEqualParts:
    'Circle the {0:string|lower} that is cut into {1:number|numberFormat} equal parts.',
  circleXLessThanY: 'Circle {0:number|numberFormat} less than {1:number|numberFormat}',
  circleXMoreThanY: 'Circle {0:number|numberFormat} more than {1:number|numberFormat}',
  completeTheNumberLine: 'Complete the number line.',
  draw1AppleForEachChildIntoTheBox: 'Draw 1 apple for each child into the box.',
  draw1MoreThanNum: 'Draw 1 more than {0:number|numberFormat}',
  draw1LessThanNum: 'Draw 1 less than {0:number|numberFormat}',
  drawAnArrayToShowXMultY: `Draw an array to show {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  drawAnArrowToShowWhereNumBelongsOnTheNumberLine:
    'Draw an arrow to show where {0:number|numberFormat} belongs on the number line.',
  drawArrowEstimateNumberIs: 'Draw an arrow to estimate where {0} is.',
  drawArrowEstimateNumberIsOnNumberLine:
    'Draw an arrow to estimate where {0} is on the number line.',
  drawAnArrowToEstimateWhereNumBelongsOnTheNumberLine:
    'Draw an arrow to estimate where {0:number|numberFormat} belongs on the number line.',
  drawBase10ToShowX: 'Draw base 10 to {0}',
  drawCountersToAddXMore: 'Draw counters to add {0:number|numberFormat} more.',
  drawCountersToMakeXEqualGroupsOfY:
    'Draw counters to make {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  drawCountersToShowX: 'Draw counters to show {0}',
  drawFourCrossesToSplitTheShapeIntoQuarters: 'Draw 4 crosses to split the shape into quarters.',
  drawTwoCrossesToSplitTheShapeInHalf: 'Draw 2 crosses to split the shape in half.',
  drawBase10ToMakeLess:
    'Draw base 10 to make {0:number|numberFormat} less than {1:number|numberFormat}',
  drawBase10ToMakeMore:
    'Draw base 10 to make {0:number|numberFormat} more than {1:number|numberFormat}',
  drawHandsOnClockToShowX: 'Draw hands on the clock to show {0}',
  drawLinesToSortTheObjectsIntoTwoGroups: 'Draw lines to sort the objects into two groups.',
  drawNumCounters: 'Draw {0:string|lower} counter{{1:s}}.',
  estimateTheNumberArrowPointingToTickAnswer:
    'Estimate the number the arrow is pointing to.<br/>Tick your answer.',
  hereAreSomeNumberBondsToX: 'Here are some number bonds to {0:number|numberFormat}',
  howManyXAreThereTickYourAnswer: 'How many {0} are there?<br/>Tick your answer.',
  matchTheAdditionsToMultiplications: 'Match the additions to the multiplications.',
  matchTheCoinsToTheCorrectValues: 'Match the coins to the correct values.',
  matchTheDoublesToAdditions: 'Match the doubles to the additions.',
  matchTheNotesToTheCorrectValues: 'Match the notes to the correct values.',
  matchTheNumbersToTheBaseTen: 'Match the numbers to the base 10',
  matchTheNumeralsToTheWords: 'Match the numerals to the words',
  matchThePicturesToTheStatements: 'Match the pictures to the statements.',
  matchTheShapesToTheTurn: 'Match the shapes to the turn.',
  matchTheTenFramesToTheNumberBonds: 'Match the ten frames to the number bonds.',
  matchTheTenFramesToTheSubtractions: 'Match the ten frames to the subtractions.',
  matchTheTimesToTheClocks: 'Match the times to the clocks.',
  name1HasNumCountersName2HasFewerCountersThanName1DrawCountersToShowHowManyCountersName1CouldHave:
    '{name1} has {numberA:number|numberFormat} counters.<br/>{name2} has fewer counters than {name1}.</br>Draw counters to show how many counters {name2} could have.',
  orderGlassesStartWithTheGreatestAmountVolume:
    'Order the glasses.<br/>Start with the greatest volume.',
  orderGlassesStartWithTheSmallestAmountVolume:
    'Order the glasses.<br/>Start with the smallest volume.',
  orderObjectsStartWithGreatestCapacity: 'Order the objects.<br/>Start with the greatest capacity.',
  orderObjectsStartWithSmallestCapacity: 'Order the objects.<br/>Start with the smallest capacity.',
  orderTheNumbersFromSmallestToGreatest: 'Order the numbers from smallest to greatest.',
  orderTheNumbersFromGreatestToSmallest: 'Order the numbers from greatest to smallest.',
  nameAHasNCountersNameBHasFewerCountersThanNameADrawCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has fewer counters than {nameA}.<br/>Draw counters to show how many counters {nameB} <b>could</b> have.',
  nameAHasNCountersNameBHasMoreCountersThanNameADrawCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has more counters than {nameA}.<br/>Draw counters to show how many counters {nameB} <b>could</b> have.',

  shadeTheCirclesToMakeArrayOfXRowsAndYColumns:
    'Shade the circles to make an array with {0:number|numberFormat} rows and {1:number|numberFormat} columns.',
  shareNumCountersEquallyIntoNumBoxes:
    'Share {0:number|numberFormat} counters equally into {1:number|numberFormat} boxes.',
  sortTheActivitiesIntoTheTable: 'Sort the activities into the table.',
  sortTheFruitIntoGroups: 'Sort the fruit into groups.',
  sortTheNumbersIntoTheTable: 'Sort the numbers into the table.',
  sortTheObjectsIntoGroups: 'Sort the objects into groups.',
  sortTheObjectsIntoTwoGroups: 'Sort the objects into two groups.',
  theCapacityOfOneJugIsXCupsOfSandTickTheCapacityOfTwoJugs:
    'The capacity of 1 jug is {0:number|numberFormat} cups of sand. Tick the capacity of 2 jugs.',
  theDaysAreInOrderTickTheMissingDayOfTheWeek:
    'The days are in order.<br/>Tick the missing day of the week.',
  theMassOfTheXObjectIsYAmountThanZCubesTickThePictureThatShowsThis:
    'The mass of the {0:string|lower} is {1:string|lower} {2:number|numberFormat} cube{{s}}.<br/>Tick the picture that shows this.',
  theNumbersAreAllInXTimesTableTickNumbersAlsoInYTimesTable:
    'The numbers are all in the {0:number|numberFormat} times-table.<br/>Tick the numbers that are also in the {1:number|numberFormat} times-table.',
  theToyCarIsHeavierThenXCubesButLighterThanYCubesTickAllTheMassesThatTheCarCouldBe:
    'The toy car is heavier than {0:number|numberFormat} cubes, but lighter than {1:number|numberFormat} cubes.<br/>Tick all the masses that the car could be.',
  theTimeIsNumOClockDrawHandsOnTheClockToShowTimeInNumHours:
    "The time is {0} o'clock.<br/>Draw hands on the clock to show what time it will be in {1:number|numberFormat} hour{{s}}.",
  theXContainerIsYAmountDrawHowMuchJuiceCouldBeInTheXContainer:
    'The {0:string|lower} is {1:string|lower}.<br/>Draw how much juice could be in the {0:string|lower}.',
  theXObjectIsYWeightThanTheZObjectTickThePicture:
    'The {0:string|lower} is {1:string|lower} than the {2:string|lower}.<br/>Tick the picture that shows this.',
  threeChildrenRunARaceTickTheChildWhoComesFirst:
    'Three children run a race.<br/>Tick the child who comes first.',
  tickAllTheNumberBondsToNum: 'Tick <b>all</b> the number bonds to {0:number|numberFormat}',
  tickTheAdditionsThatAreEqualToNum: 'Tick the additions that are equal to {0:number|numberFormat}',
  tickTheAdditionThatMatchTheBarModel: 'Tick the addition that match the bar model.',
  tickTheAdditionThatMatchTheCounters: 'Tick the addition that match the counters.',
  tickTheAdditionsThatMatchTheBarModel: 'Tick the additions that match the bar model.',
  tickTheAdditionsThatMatchTheCounters: 'Tick the additions that match the counters.',
  tickTheBase10ThatShowsX: 'Tick the base 10 that shows {0}',
  tickTheContainerWithTheGreatestCapacity: 'Tick the container with the greatest capacity.',
  tickTheContainerWithTheSmallestCapacity: 'Tick the container with the smallest capacity.',
  tickTheCorrectNumberSentence: 'Tick the correct number sentence.',
  tickTheCorrectNumberSentences: 'Tick the correct number sentences.',
  tickTheGroupThatMatchesTheNumberOfChildren: 'Tick the group that matches the number of children.',
  tickTheGroupWithFewerObject: 'Tick the group with fewer {0:string|lower}.',
  tickTheGroupWithMoreObject: 'Tick the group with more {0:string|lower}.',
  tickTheMissingNumberBondToX: 'Tick the <b>missing</b> number bond to {0:number|numberFormat}',
  tickTheNumberBondsToNum: 'Tick the number bonds to {0:number|numberFormat}',
  tickThePictureThatShowsEqualGroups: 'Tick the picture that shows <b>equal</b> groups.',
  tickThePictureThatShowsUnequalGroups: 'Tick the picture that shows <b>unequal</b> groups.',
  tickTheNumberSentenceThatMatchesPicture: 'Tick the number sentence that matches the picture.',
  tickThePictureThatShowsXEqualGroupsOfY:
    'Tick the picture that shows {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  tickTheXWeightObject: 'Tick the {0:string|lower} object.',
  useCardsCompleteMultiplication: 'Use the cards to complete the multiplication.',
  useCardsCompleteSentence: 'Use the cards to complete the sentence.',
  useCardsToCompleteTheCalculations: 'Use the cards to complete the calculations.',
  tickTheMonthsThatHaveExactlyXDays:
    'Tick the months that have exactly {0:number|numberFormat} days.',
  tickTheTimeShown: 'Tick the time shown.',
  tickWhatDayOfTheWeekTheXDateIsOn: 'Tick what day of the week the {0:string|lower} is on.',
  tickWhatTheShapeWillLookLikeAfterAFullTurn:
    'Tick what the shape will look like after a <b>full</b> turn.',
  tickWhatTheShapeWillLookLikeAfterAHalfTurn:
    'Tick what the shape will look like after a <b>half</b> turn.',
  tickWhatTheShapeWillLookLikeAfterAQuarterTurn:
    'Tick what the shape will look like after a <b>quarter</b> turn.',
  tickWhatTheShapeWillLookLikeAfterAThreeQuarterTurn:
    'Tick what the shape will look like after a <b>three-quarter</b> turn.',
  tickYourAnswer: 'Tick your answer.',
  useACardToCompleteTheNumberSentence: 'Use a card to complete the number sentence.',
  useACardToCompleteSentence: 'Use a card to complete the sentence.',
  useTheCardsToCompleteTheCount: 'Use the cards to complete the count.',
  useTheCardsToCompleteTheMultiplication: 'Use the cards to complete the multiplication.',
  useTheCardsToCompleteTheNumberSentence: 'Use the cards to complete the number sentence.',
  useTheCardsToCompleteTheSentence: 'Use the cards to complete the sentence.',
  useTheCardsToCompleteTheSentences: 'Use the cards to complete the sentences.',
  useTheCardsToMakeA2DigitEvenNumber: 'Use the cards to make a 2-digit <b>even</b> number.',
  useTheCardsToMakeA2DigitOddNumber: 'Use the cards to make a 2-digit <b>odd</b> number.',
  useTheCardsToOrderTheNumbers: 'Use the cards to order the numbers.',
  useTheCardsToPutThemInOrder: 'Use the cards to put them in order.',
  useTheCardsToMakeTheAdditionCorrect: 'Use the cards to make the addition correct.',
  useTheWordsToCompleteTheSentence: 'Use the words to complete the sentence.',
  whatIsTheNumberAsANumeralTickYourAnswer: 'What is the word as a numeral?<br/>Tick your answer.',
  whatIsTheNumeralAsANumberTickYourAnswer: 'What is the numeral as a word?<br/>Tick your answer.',
  whatIstheWholeWriteTheNumber: 'What is the whole?<br/>Write the number.',
  whatSubtractionIsShownOnTheNumberLineUseTheCardsToCompleteTheNumberSentence:
    'What subtraction is shown on the number line?<br/>Use the cards to complete the number sentence.',
  whichTenFrameMatchesThePictureSelectYourAnswer:
    'Which ten frame matches the picture?<br/>Tick your answer.',
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheCapacityOfTheContainers: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the capacity of the containers.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompare: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the amounts.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the numbers.`,
  writeMissingNumber: 'Write the missing number.',
  writeTheMissingPhrase: 'Write the missing phrase.',
  writeTheNameOfTheShape: 'Write the name of the shape.',
  writeTheNameOfTheShapeToCompleteTheSentence:
    'Write the name of the shape to complete the sentence.',
  writeNumbersInOrder: 'Write the numbers in order.',
  writeTwoDifferentMultiplicationsToMatchArray:
    'Write two different multiplications to match the array.',
  writeWhetherTheGroupsAreEqualOrUnequal: 'Write whether the groups are equal or unequal.',
  xContainerHasYMoreOrLessJuiceThanZContainerDrawHowMuchJuiceCouldBeInXContainer:
    '{0:string} {1:string} has {2:string|lower} juice than {0:string|lower} {3:string}.<br/>Draw how much juice could be in {0:string|lower} {3:string}.'
};
