import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import WoodenBalanceScale from '../../../../components/question/representations/WoodenBalanceScale';
import {
  getRandomUniqueScaleObjectNames,
  ScaleObjectName,
  scaleObjects,
  scaleObjectsSchema
} from '../../../../utils/objects';
import { AssetSvg } from '../../../../assets/svg';
import {
  getRandomBoolean,
  getRandomFromArray,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdy',
  description: 'bdy',
  keywords: ['Heavier', 'Lighter', 'Balance Scales', 'Mass'],
  schema: z.object({
    scaleObjectNames: z.array(scaleObjectsSchema).length(2),
    lighterOrHeavier: z.enum(['Lighter', 'Heavier']),
    scaleDirection: z.enum(['Left', 'Right'])
  }),
  simpleGenerator: () => {
    const lighterOrHeavier = getRandomFromArray(['Lighter', 'Heavier'] as const);
    const scaleDirection = getRandomFromArray(['Left', 'Right'] as const);
    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val => val.filter(name => name.toLowerCase().includes('cube')).length === 0
    );

    return { scaleObjectNames, lighterOrHeavier, scaleDirection };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, lighterOrHeavier, scaleDirection },
      translate
    } = props;

    const titleWeight =
      lighterOrHeavier === 'Lighter' ? translate.keywords.Lighter() : translate.keywords.Heavier();

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const [heavierItemName, lighterItemName] = [scaleObjectNameA, scaleObjectNameB].sort(
      (a, b) => scaleObjects[b].weight - scaleObjects[a].weight
    );

    // Items order matches the scaleDirection
    const items: [ScaleObjectName, ScaleObjectName] =
      scaleDirection === 'Left'
        ? [heavierItemName, lighterItemName]
        : [lighterItemName, heavierItemName];

    // Correct answer depends on the weight
    const correctAnswer = lighterOrHeavier === 'Lighter' ? lighterItemName : heavierItemName;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheXWeightObject(titleWeight)}
        pdfTitle={translate.ks1PDFInstructions.tickTheXWeightObject(titleWeight)}
        testCorrect={[correctAnswer]}
        numItems={2}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={items}
            dimens={dimens}
            containerStyle={{ marginTop: 50 }}
            direction={scaleDirection === 'Left' ? 'left' : 'right'}
          />
        )}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item,
            component: (
              <AssetSvg
                name={scaleObjects[item].name}
                height={dimens.height * 0.3}
                width={dimens.width * 0.3}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdz',
  description: 'bdz',
  keywords: ['Heavier', 'Lighter', 'Balance Scales', 'Mass'],
  schema: z.object({
    scaleObjectNames: z.array(scaleObjectsSchema).length(2),
    scaleDirection: z.enum(['Left', 'Right']),
    lighterOrHeavier: z.enum(['Lighter', 'Heavier'])
  }),
  simpleGenerator: () => {
    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val => val.filter(name => name.toLowerCase().includes('cube')).length === 0
    );
    const lighterOrHeavier = getRandomFromArray(['Lighter', 'Heavier'] as const);
    const scaleDirection = getRandomFromArray(['Left', 'Right'] as const);

    return { scaleObjectNames, scaleDirection, lighterOrHeavier };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, scaleDirection, lighterOrHeavier },
      translate
    } = props;

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const [heavierItemName, lighterItemName] = [scaleObjectNameA, scaleObjectNameB].sort(
      (a, b) => scaleObjects[b].weight - scaleObjects[a].weight
    );

    // Items order matches the scaleDirection
    const items: [ScaleObjectName, ScaleObjectName] =
      scaleDirection === 'Left'
        ? [heavierItemName, lighterItemName]
        : [lighterItemName, heavierItemName];

    const [scaleObjectA, scaleObjectB] = scaleObjectNames;

    const titleWeight =
      lighterOrHeavier === 'Lighter' ? translate.keywords.Lighter() : translate.keywords.Heavier();

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.theXObjectIsYWeightThanTheZObjectSelectThePicture(
          lighterOrHeavier === 'Lighter' ? lighterItemName : heavierItemName,
          titleWeight,
          lighterOrHeavier === 'Lighter' ? heavierItemName : lighterItemName
        )}
        pdfTitle={translate.ks1PDFInstructions.theXObjectIsYWeightThanTheZObjectTickThePicture(
          scaleObjectA,
          titleWeight,
          scaleObjectB
        )}
        testCorrect={[0]}
        numItems={2}
        renderItems={({ dimens }) => {
          return shuffle(
            items.map((_item, idx) => ({
              value: idx,
              component: (
                <WoodenBalanceScale
                  items={items}
                  dimens={dimens}
                  containerStyle={{ marginTop: 90 }}
                  direction={
                    idx === 0
                      ? scaleDirection === 'Left'
                        ? 'left'
                        : 'right'
                      : scaleDirection === 'Left'
                      ? 'right'
                      : 'left'
                  }
                  iconWidth={50}
                  widthScaling
                />
              )
            })),
            {
              random: seededRandom(props.question)
            }
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdA',
  description: 'bdA',
  keywords: [
    'Greater than',
    'Less than',
    'The same as',
    'Heavier',
    'Lighter',
    'Balance Scales',
    'Mass'
  ],
  schema: z.object({
    scaleObjectNames: z.array(scaleObjectsSchema).length(2),
    scaleDirection: z.enum(['Left', 'Right', 'Equal']),
    variation: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val => val.filter(name => name.toLowerCase().includes('cube')).length === 0
    );

    const scaleDirection = getRandomFromArray(['Left', 'Right', 'Equal'] as const);

    const variation = getRandomBoolean();

    return { scaleObjectNames, scaleDirection, variation };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, scaleDirection, variation },
      translate
    } = props;

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const heavierThan = translate.operations.heavierThan();
    const lighterThan = translate.operations.lighterThan();
    const theSameMassAs = translate.misc.theSameMassAs();

    const correctAnswer = variation
      ? scaleDirection === 'Left'
        ? heavierThan
        : scaleDirection === 'Right'
        ? lighterThan
        : theSameMassAs
      : scaleDirection === 'Left'
      ? heavierThan
      : lighterThan;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragCardCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        sentence={
          variation
            ? translate.ks1AnswerSentences.theMassOfTheXShapeIsAnsMassOfTheYShape(
                scaleObjectNameA,
                scaleObjectNameB
              )
            : translate.ks1AnswerSentences.theXShapeIsAnsTheYShape(
                scaleObjectNameA,
                scaleObjectNameB
              )
        }
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[scaleObjectNameA, scaleObjectNameB]}
            dimens={dimens}
            containerStyle={{ marginTop: 50 }}
            iconWidth={50}
            direction={
              variation
                ? scaleDirection === 'Left'
                  ? 'left'
                  : scaleDirection === 'Right'
                  ? 'right'
                  : undefined
                : scaleDirection === 'Left'
                ? 'left'
                : 'right'
            }
          />
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        actionPanelVariant="endWide"
        questionHeight={1000}
        items={shuffle([heavierThan, lighterThan, theSameMassAs], {
          random: seededRandom(props.question)
        })}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HeavierAndLighter',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
