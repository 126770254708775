import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { countRange } from '../../../../utils/collections';
import { ADD } from '../../../../constants';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { isInRange } from '../../../../utils/matchers';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'ba9',
  description: 'ba9',
  keywords: ['Number bonds', 'Add', 'Systematic'],
  questionHeight: 900,
  schema: z.object({
    total: z.number().int().min(3).max(9),
    missingBond: z.number().int().min(0).max(9),
    incorrectBonds: z
      .array(z.tuple([z.number().int().min(0).max(10), z.number().int().min(0).max(10)]))
      .min(2)
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(3, 9);

    const missingBond = randomIntegerInclusive(0, total, {
      // The missing bond should not be the same numbers i.e if the total has a middle
      constraint: x => total / 2 !== x
    });

    const incorrectBonds = [total - 1, total + 1].map(tot => {
      const num1 = randomIntegerInclusive(0, tot);
      const num2 = randomIntegerInclusive(0, 9, {
        constraint: x => num1 + x !== total && num1 + x <= 10
      });
      return [num1, num2] as [number, number];
    });

    return {
      total,
      missingBond,
      incorrectBonds
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { incorrectBonds, total, missingBond } = question;

    const contexBoxDimens =
      displayMode === 'digital' ? { width: 150, height: 100 } : { width: 200, height: 125 };

    const content = countRange(total + 1).map(i => {
      if (i !== missingBond) {
        return (
          <ContentBox key={i} containerStyle={contexBoxDimens}>
            <Text variant="WRN400">{`${i.toLocaleString()} ${ADD} ${(
              total - i
            ).toLocaleString()}`}</Text>
          </ContentBox>
        );
      }
    });

    const items = shuffle(
      [
        [missingBond, total - missingBond],
        [total - missingBond, missingBond],
        ...incorrectBonds
      ].map(([num1, num2], i) => [
        ['A', 'B', 'C', 'D'][i],
        `${num1.toLocaleString()} ${ADD} ${num2.toLocaleString()}`
      ]),
      { random: seededRandom(question) }
    );

    const numberBondCards = (
      <MeasureView>
        {dimens => (
          <View
            style={{
              columnGap: 36,
              rowGap: 36,
              height:
                total > 6
                  ? contexBoxDimens.height * 3 + 72
                  : total > 3
                  ? contexBoxDimens.height * 2 + 36
                  : contexBoxDimens.height,
              width: dimens.width * 0.8,
              flexDirection: 'column',
              flexWrap: 'wrap'
            }}
          >
            {content}
          </View>
        )}
      </MeasureView>
    );
    return (
      <QF39ContentWithSelectablesOnRight
        questionHeight={900}
        title={translate.ks1Instructions.selectTheMissingNumberBondToX(total)}
        pdfTitle={translate.ks1PDFInstructions.hereAreSomeNumberBondsToX(total)}
        leftContent={displayMode === 'digital' ? numberBondCards : <></>}
        topContent={
          displayMode !== 'digital' ? (
            <>
              {numberBondCards}{' '}
              <TextStructure
                style={{ paddingTop: 64 }}
                sentence={translate.ks1PDFInstructions.tickTheMissingNumberBondToX(total)}
              />
            </>
          ) : (
            <></>
          )
        }
        selectables={Object.fromEntries([...items])}
        selectableStyle={displayMode === 'digital' ? {} : { flexDirection: 'row', gap: 200 }}
        selectableVariant={displayMode === 'digital' ? 'oval' : 'circle'}
        correctAnswer={['A']}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bba',
  description: 'bba',
  keywords: ['Number bonds', 'Add', 'Systematic'],
  questionHeight: 1000,
  schema: z
    .object({
      total: z.number().int().min(3).max(9),
      bonds: z
        .array(z.tuple([z.number().int().min(0).max(10), z.number().int().min(0).max(10)]))
        .min(9)
        .max(12)
    })
    .refine(
      ({ bonds }) => bonds.every(([num1, num2]) => num1 + num2 <= 10),
      'totals should be less than 10'
    ),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(3, 9);
    const numCorrect = total + 1;
    const numSelectables = total < 7 ? 9 : 12;

    const correct1numbers = countRange(numCorrect);
    const correctBonds = correct1numbers.map(i => [total - i, i] as [number, number]);

    const remainingNum1 = randomUniqueIntegersInclusive(0, total + 1, numSelectables - numCorrect);
    const remainingBonds = remainingNum1.map(num1 => {
      const num2 = randomIntegerInclusive(0, 9, {
        constraint: x =>
          num1 + x !== total && num1 + x <= 10 && isInRange(total - 1, total + 2)(num1 + x)
      });
      return [num1, num2] as [number, number];
    });

    return {
      total,
      bonds: shuffle([...correctBonds, ...remainingBonds])
    };
  },
  Component: ({ question, translate }) => {
    const { bonds, total } = question;

    const items = bonds.map(([num1, num2], i) => ({
      component: `${num1.toLocaleString()} ${ADD} ${num2.toLocaleString()}`,
      value: i,
      isCorrect: num1 + num2 === total
    }));

    return (
      <QF10SelectNumbers
        questionHeight={1000}
        title={translate.ks1Instructions.selectAllTheNumberBondsToNum(total)}
        pdfTitle={translate.ks1PDFInstructions.tickAllTheNumberBondsToNum(total)}
        items={items}
        multiSelect
        testCorrect={items.filter(it => it.isCorrect).map(it => it.value)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SystematicNumberBondsWithin10',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
