import { Button } from 'react-native-paper';
import { resolveFont } from 'common/src/theme/fonts';
import { useI18nContext } from '../i18n/i18n-react';
import { useTheme } from 'common/src/theme';
import useBreakpoints from '../hooks/useBreakpoints';

/**
 * Can't login button
 */
export default function CantLoginButton({ onPress }: { onPress: () => void }) {
  const translate = useI18nContext().LL;
  const theme = useTheme();
  const resize = useBreakpoints().choose({ mobile: 0.7, tablet: 1 });

  return (
    <Button
      mode="text"
      testID="CANT_LOGIN_BTN"
      onPress={onPress}
      labelStyle={{
        ...resolveFont({
          fontFamily: 'Biotif',
          fontWeight: '500'
        }),
        fontSize: 29 * resize,
        lineHeight: 43.5 * resize,
        textDecorationLine: 'underline',
        textTransform: 'uppercase',
        color: theme.colors.primary
      }}
    >
      {translate.errorModals.cantLogin()}
    </Button>
  );
}
