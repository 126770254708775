import { StyleSheet, View } from 'react-native';
import { colors } from 'common/src/theme/colors';

type ProgressBarProps = {
  /**
   * Progress given as a percentage, i.e. between 0 and 100 inclusive.
   * If `undefined`, this indicates the quiz hasn't been started yet. This is displayed differently from 0.
   */
  progress?: number;
};

/**
 * Progress bar to display given progress percentage
 */
export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <View style={styles.wrapper}>
      <View style={styles.totalBar}>
        {progress !== undefined && (
          <>
            <View style={styles.progressBarFixedStart} />
            <View style={styles.progressBarVariableContainer}>
              <View style={[styles.progressBar, { width: `${progress}%` }]} />
            </View>
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    width: '100%'
  },
  totalBar: {
    backgroundColor: colors.greys200,
    width: '100%',
    height: 13,
    borderRadius: 21,
    overflow: 'hidden',
    flexDirection: 'row'
  },
  progressBarFixedStart: {
    width: 13,
    backgroundColor: colors.fieryRose
  },
  progressBarVariableContainer: {
    flex: 1,
    flexDirection: 'row'
  },
  progressBar: {
    backgroundColor: colors.fieryRose
  }
});
