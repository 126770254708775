import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Text from '../../../../components/typography/Text';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { colors } from '../../../../theme/colors';
import { roundToTheNearest } from '../../../../utils/math';
import { orangeJuiceSvg } from '../../../../utils/capacityImages';
import { isInRange } from '../../../../utils/matchers';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

//
// Objects for v1 questions
//

const question2Objects = [
  'Mug',
  'Soap dispenser',
  'Can',
  'Ketchup bottle',
  'Kettle',
  'Jug',
  'Watering can',
  'Cooking pot',
  'Rucksack',
  'Bucket',
  'Microwave',
  'Toolbox',
  'Bin',
  'Bath',
  'Wheelie bin',
  'Fridge'
] as const;

const Question2ObjectsSchema = z.enum(question2Objects);

const question1Objects = [...question2Objects, 'Milk carton', 'Bottle of water'] as const;

const Question1ObjectsSchema = z.enum(question1Objects);

type Question1Object = z.infer<typeof Question1ObjectsSchema>;

//
// Objects for v2 questions
//

const objectsV2 = [
  'Milk carton',
  'Can',
  'Ketchup bottle',
  'Kettle',
  'Watering can',
  'Rucksack',
  'Bucket',
  'Microwave',
  'Bath',
  'Wheelie bin',
  'Fridge'
] as const;

const objectsV2Schema = z.enum(objectsV2);
type ObjectsV2 = z.infer<typeof objectsV2Schema>;

const getObjectCapacity = (object: string) => {
  switch (object) {
    case 'Mug':
    case 'Soap dispenser':
      return 250;
    case 'Can':
    case 'Ketchup bottle':
      return 500;
    case 'Milk carton':
    case 'Bottle of water':
      return 1000;
    case 'Kettle':
    case 'Jug':
      return 2500;
    case 'Watering can':
    case 'Cooking pot':
      return 5000;
    case 'Rucksack':
    case 'Bucket':
      return 10000;
    case 'Microwave':
    case 'Toolbox':
      return 50000;
    case 'Bin':
    case 'Bath':
      return 100000;
    case 'Wheelie bin':
    case 'Fridge':
    default:
      return 250000;
  }
};

const getObjectSvg = (object: string) => {
  switch (object) {
    case 'Mug':
      return 'Mug';
    case 'Soap dispenser':
      return 'Soap_dispenser';
    case 'Can':
      return 'Can_of_fizzy_drink';
    case 'Ketchup bottle':
      return 'Tomato_sauce_ketchup';
    case 'Milk carton':
      return 'Milk_Carton';
    case 'Bottle of water':
      return 'Water_bottle';
    case 'Kettle':
      return 'Kettle';
    case 'Jug':
      return 'Empty_jug';
    case 'Watering can':
      return 'Watering_can';
    case 'Cooking pot':
      return 'Cooking_pot';
    case 'Rucksack':
      return 'Rucksack';
    case 'Bucket':
      return 'Empty_bucket';
    case 'Microwave':
      return 'Microwave';
    case 'Toolbox':
      return 'Toolbox';
    case 'Bin':
      return 'Rubbish_bin';
    case 'Bath':
      return 'Bath';
    case 'Wheelie bin':
      return 'Large_bin';
    case 'Fridge':
      return 'Refrigerator';
  }
};

const fractionToDecimal = (
  fraction: 'eighth' | 'quarter' | 'third' | 'half' | 'twoThirds' | 'threeQuarters'
) => {
  switch (fraction) {
    case 'eighth':
      return number(math.evaluate(`1 / 8`));
    case 'quarter':
      return number(math.evaluate(`1 / 4`));
    case 'third':
      return number(math.evaluate(`1 / 3`));
    case 'half':
      return number(math.evaluate(`1 / 2`));
    case 'twoThirds':
      return number(math.evaluate(`2 / 3`));
    case 'threeQuarters':
      return number(math.evaluate(`3 / 4`));
  }
};

const Question1 = newQuestionContent({
  uid: 'aDS',
  description: 'aDS',
  keywords: ['Capacity', 'Estimate'],
  schema: z
    .object({
      objectA: Question1ObjectsSchema,
      objectB: Question1ObjectsSchema,
      smallerOrGreater: z.enum(['smaller', 'greater'])
    })
    .refine(
      ({ objectA, objectB }) => objectA !== objectB,
      'objectA and objectB cannot be the same.'
    )
    .refine(
      ({ objectA, objectB }) => {
        const objectACategory = getObjectCapacity(objectA);
        const objectBCategory = getObjectCapacity(objectB);
        return objectACategory !== objectBCategory;
      },
      {
        message: 'objectA and objectB cannot have the same capacity.'
      }
    ),
  simpleGenerator: () => {
    const objectA = getRandomFromArray(question1Objects);

    const objectACapacity = getObjectCapacity(objectA);

    const objectB = getRandomFromArray(
      question1Objects.filter(object => getObjectCapacity(object) !== objectACapacity)
    ) as Question1Object;

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { objectA, objectB, smallerOrGreater };
  },
  Component: ({ question, translate }) => {
    const { objectA, objectB, smallerOrGreater } = question;

    const objectACapacity = getObjectCapacity(objectA);

    const objectBCapacity = getObjectCapacity(objectB);

    const objectASvg = getObjectSvg(objectA);
    const objectBSvg = getObjectSvg(objectB);

    const items = shuffle(
      [
        {
          value: objectA,
          svgName: objectASvg,
          isCorrect:
            smallerOrGreater === 'smaller'
              ? objectACapacity < objectBCapacity
              : objectACapacity > objectBCapacity
        },
        {
          value: objectB,
          svgName: objectBSvg,
          isCorrect:
            smallerOrGreater === 'smaller'
              ? objectBCapacity < objectACapacity
              : objectBCapacity > objectACapacity
        }
      ],
      {
        random: seededRandom(question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectObjectWithSmallerCapacity()
            : translate.instructions.selectObjectWithGreaterCapacity()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circleObjectWithSmallerCapacity()
            : translate.instructions.circleObjectWithGreaterCapacity()
        }
        numItems={2}
        renderItems={({ dimens }) =>
          items.map(({ value, svgName }) => ({
            component: (
              <AssetSvg
                name={svgName as SvgName}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value
          }))
        }
        testCorrect={items.filter(item => item.isCorrect).map(item => item.value)}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aDS2',
  description: 'aDS',
  keywords: ['Capacity', 'Estimate'],
  schema: z
    .object({
      objectA: objectsV2Schema,
      objectB: objectsV2Schema,
      smallerOrGreater: z.enum(['smaller', 'greater'])
    })
    .refine(
      ({ objectA, objectB }) => objectA !== objectB,
      'objectA and objectB cannot be the same.'
    )
    .refine(
      ({ objectA, objectB }) => {
        const objectACapacity = getObjectCapacity(objectA);
        const objectBCapacity = getObjectCapacity(objectB);
        return objectACapacity !== objectBCapacity;
      },
      {
        message: 'objectA and objectB cannot have the same capacity.'
      }
    ),
  simpleGenerator: () => {
    const objectA = getRandomFromArray(objectsV2);

    const objectACapacity = getObjectCapacity(objectA);

    const objectB = getRandomFromArray(
      objectsV2.filter(object => getObjectCapacity(object) !== objectACapacity)
    ) as ObjectsV2;

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { objectA, objectB, smallerOrGreater };
  },
  Component: ({ question, translate }) => {
    const { objectA, objectB, smallerOrGreater } = question;

    const objectACapacity = getObjectCapacity(objectA);

    const objectBCapacity = getObjectCapacity(objectB);

    const objectASvg = getObjectSvg(objectA);
    const objectBSvg = getObjectSvg(objectB);

    const items = shuffle(
      [
        {
          value: objectA,
          svgName: objectASvg
        },
        {
          value: objectB,
          svgName: objectBSvg
        }
      ],
      {
        random: seededRandom(question)
      }
    );

    const [smallerObj, greaterObj] =
      objectACapacity < objectBCapacity ? [objectA, objectB] : [objectB, objectA];

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectObjectWithSmallerCapacity()
            : translate.instructions.selectObjectWithGreaterCapacity()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circleObjectWithSmallerCapacity()
            : translate.instructions.circleObjectWithGreaterCapacity()
        }
        numItems={2}
        renderItems={({ dimens }) =>
          items.map(({ value, svgName }) => ({
            component: (
              <AssetSvg
                name={svgName as SvgName}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value
          }))
        }
        testCorrect={[smallerOrGreater === 'smaller' ? smallerObj : greaterObj]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aDT',
  description: 'aDT',
  keywords: ['Capacity', 'Estimate', 'Millilitres', 'Litres'],
  schema: z.object({
    objectA: Question2ObjectsSchema,
    objectB: Question2ObjectsSchema,
    objectC: Question2ObjectsSchema,
    objectD: Question2ObjectsSchema
  }),
  simpleGenerator: () => {
    const { objectA, objectB, objectC, objectD } = rejectionSample(
      () => {
        const [objectA, objectB, objectC, objectD] = getRandomSubArrayFromArray(
          question2Objects,
          4
        );
        return { objectA, objectB, objectC, objectD };
      },
      // Only permit them if they each have different capacities.
      ({ objectA, objectB, objectC, objectD }) => {
        const objectACapacity = getObjectCapacity(objectA);
        const objectBCapacity = getObjectCapacity(objectB);
        const objectCCapacity = getObjectCapacity(objectC);
        const objectDCapacity = getObjectCapacity(objectD);

        return arrayHasNoDuplicates([
          objectACapacity,
          objectBCapacity,
          objectCCapacity,
          objectDCapacity
        ]);
      }
    );

    return { objectA, objectB, objectC, objectD };
  },
  Component: props => {
    const {
      question: { objectA, objectB, objectC, objectD },
      translate
    } = props;

    const objectACapacity = getObjectCapacity(objectA);
    const objectBCapacity = getObjectCapacity(objectB);
    const objectCCapacity = getObjectCapacity(objectC);
    const objectDCapacity = getObjectCapacity(objectD);

    const answerA =
      objectACapacity < 1000
        ? translate.units.numberOfMl(objectACapacity)
        : translate.units.numberOfLitres(objectACapacity / 1000);

    const answerB =
      objectBCapacity < 1000
        ? translate.units.numberOfMl(objectBCapacity)
        : translate.units.numberOfLitres(objectBCapacity / 1000);

    const answerC =
      objectCCapacity < 1000
        ? translate.units.numberOfMl(objectCCapacity)
        : translate.units.numberOfLitres(objectCCapacity / 1000);

    const answerD =
      objectDCapacity < 1000
        ? translate.units.numberOfMl(objectDCapacity)
        : translate.units.numberOfLitres(objectDCapacity / 1000);

    const items = shuffle(
      [
        { svg: getObjectSvg(objectA), answer: answerA },
        { svg: getObjectSvg(objectB), answer: answerB },
        { svg: getObjectSvg(objectC), answer: answerC },
        { svg: getObjectSvg(objectD), answer: answerD }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToMatchApproxCapacityToEachContainer()}
        pdfTitle={translate.instructions.useCardsToMatchApproxCapacityToEachContainer()}
        items={[answerA, answerB, answerC, answerD]}
        itemVariant="shortRectangle"
        pdfItemVariant="rectangle"
        itemMaxLines={1}
        sentence={`<ans/> <ans/> <ans/> <ans/>`}
        sentenceStyle={{ columnGap: 72 }}
        testCorrect={[items[0].answer, items[1].answer, items[2].answer, items[3].answer]}
        pdfLayout="itemsBottom"
        actionPanelVariant="bottom"
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            {items.map(item => (
              <AssetSvg
                key={item.svg}
                name={item.svg as SvgName}
                width={dimens.width / 5}
                height={dimens.height}
              />
            ))}
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aDT2',
  description: 'aDT',
  keywords: ['Capacity', 'Estimate', 'Millilitres', 'Litres'],
  schema: z.object({
    objectA: objectsV2Schema,
    objectB: objectsV2Schema,
    objectC: objectsV2Schema,
    objectD: objectsV2Schema
  }),
  simpleGenerator: () => {
    const { objectA, objectB, objectC, objectD } = rejectionSample(
      () => {
        const [objectA, objectB, objectC, objectD] = getRandomSubArrayFromArray(objectsV2, 4);
        return { objectA, objectB, objectC, objectD };
      },
      // Only permit them if they each have different capacities.
      ({ objectA, objectB, objectC, objectD }) => {
        const objectACapacity = getObjectCapacity(objectA);
        const objectBCapacity = getObjectCapacity(objectB);
        const objectCCapacity = getObjectCapacity(objectC);
        const objectDCapacity = getObjectCapacity(objectD);

        return arrayHasNoDuplicates([
          objectACapacity,
          objectBCapacity,
          objectCCapacity,
          objectDCapacity
        ]);
      }
    );

    return { objectA, objectB, objectC, objectD };
  },
  Component: props => {
    const {
      question: { objectA, objectB, objectC, objectD },
      translate
    } = props;

    const objectACapacity = getObjectCapacity(objectA);
    const objectBCapacity = getObjectCapacity(objectB);
    const objectCCapacity = getObjectCapacity(objectC);
    const objectDCapacity = getObjectCapacity(objectD);

    const answerA =
      objectACapacity < 1000
        ? translate.units.numberOfMl(objectACapacity)
        : translate.units.numberOfLitres(objectACapacity / 1000);

    const answerB =
      objectBCapacity < 1000
        ? translate.units.numberOfMl(objectBCapacity)
        : translate.units.numberOfLitres(objectBCapacity / 1000);

    const answerC =
      objectCCapacity < 1000
        ? translate.units.numberOfMl(objectCCapacity)
        : translate.units.numberOfLitres(objectCCapacity / 1000);

    const answerD =
      objectDCapacity < 1000
        ? translate.units.numberOfMl(objectDCapacity)
        : translate.units.numberOfLitres(objectDCapacity / 1000);

    const items = shuffle(
      [
        { svg: getObjectSvg(objectA), answer: answerA },
        { svg: getObjectSvg(objectB), answer: answerB },
        { svg: getObjectSvg(objectC), answer: answerC },
        { svg: getObjectSvg(objectD), answer: answerD }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToMatchApproxCapacityToEachContainer()}
        pdfTitle={translate.instructions.useCardsToMatchApproxCapacityToEachContainer()}
        items={[answerA, answerB, answerC, answerD]}
        itemVariant="shortRectangle"
        pdfItemVariant="rectangle"
        itemMaxLines={1}
        sentence={`<ans/> <ans/> <ans/> <ans/>`}
        sentenceStyle={{ columnGap: 72 }}
        testCorrect={[items[0].answer, items[1].answer, items[2].answer, items[3].answer]}
        pdfLayout="itemsBottom"
        actionPanelVariant="bottom"
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            {items.map(item => (
              <AssetSvg
                key={item.svg}
                name={item.svg as SvgName}
                width={dimens.width / 5}
                height={dimens.height}
              />
            ))}
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aDU',
  description: 'aDU',
  keywords: ['Capacity', 'Estimate', 'Millilitres', 'Litres'],
  schema: z.object({
    totalJuiceMl: numberEnum([250, 330, 500, 660, 750]),
    glassCapacityFilled: z.enum(['quarter', 'third', 'half', 'twoThirds', 'threeQuarters'])
  }),
  simpleGenerator: () => {
    const totalJuiceMl = getRandomFromArray([250, 330, 500, 660, 750] as const);

    const glassCapacityChoices = (() => {
      switch (totalJuiceMl) {
        case 250:
          return ['quarter', 'third', 'half'] as const;
        case 330:
          return ['third', 'twoThirds'] as const;
        case 500:
          return ['quarter', 'third', 'half', 'twoThirds'] as const;
        case 660:
          return ['third', 'twoThirds'] as const;
        case 750:
          return ['quarter', 'half', 'threeQuarters'] as const;
      }
    })();

    const glassCapacityFilled = getRandomFromArray(glassCapacityChoices);

    return { totalJuiceMl, glassCapacityFilled };
  },
  Component: props => {
    const {
      question: { totalJuiceMl, glassCapacityFilled },
      translate,
      displayMode
    } = props;

    const glassCapacityMl = (() => {
      if (totalJuiceMl === 250) {
        switch (glassCapacityFilled) {
          case 'quarter':
            return 1000;
          case 'third':
            return 750;
          case 'half':
            return 500;
        }
      } else if (totalJuiceMl === 330) {
        switch (glassCapacityFilled) {
          case 'third':
            return 1000;
          case 'twoThirds':
            return 500;
        }
      } else if (totalJuiceMl === 500) {
        switch (glassCapacityFilled) {
          case 'quarter':
            return 2000;
          case 'third':
            return 1500;
          case 'half':
            return 1000;
          case 'twoThirds':
            return 750;
        }
      } else if (totalJuiceMl === 660) {
        switch (glassCapacityFilled) {
          case 'third':
            return 2000;
          case 'twoThirds':
            return 1000;
        }
      } else if (totalJuiceMl === 750) {
        switch (glassCapacityFilled) {
          case 'quarter':
            return 3000;
          case 'half':
            return 1500;
          case 'threeQuarters':
            return 1000;
        }
      }
    })() as number;

    const incorrectOptionChoices = [500, 750, 1000, 1500, 2000, 3000, 4000].filter(
      val => val !== glassCapacityMl
    );

    const random = seededRandom(props.question);

    const incorrectSelectedChoices = getRandomSubArrayFromArray(incorrectOptionChoices, 3, {
      random
    });

    const selectables = shuffle(
      [
        [
          'A',
          glassCapacityMl >= 1000
            ? translate.units.numberOfLitres(glassCapacityMl / 1000)
            : translate.units.numberOfMl(glassCapacityMl)
        ],
        [
          'B',
          incorrectSelectedChoices[0] >= 1000
            ? translate.units.numberOfLitres(incorrectSelectedChoices[0] / 1000)
            : translate.units.numberOfMl(incorrectSelectedChoices[0])
        ],
        [
          'C',
          incorrectSelectedChoices[1] >= 1000
            ? translate.units.numberOfLitres(incorrectSelectedChoices[1] / 1000)
            : translate.units.numberOfMl(incorrectSelectedChoices[1])
        ],
        [
          'D',
          incorrectSelectedChoices[2] >= 1000
            ? translate.units.numberOfLitres(incorrectSelectedChoices[2] / 1000)
            : translate.units.numberOfMl(incorrectSelectedChoices[2])
        ]
      ],
      { random }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.theFullCartonOfJuiceIsPouredIntoAGlass()}
        pdfTitle={translate.instructions.theFullCartonOfJuiceIsPouredIntoAGlassPDF()}
        leftContent={
          <MeasureView>
            {dimens => (
              <View
                style={[
                  dimens,
                  { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }
                ]}
              >
                <View style={{ alignItems: 'center' }}>
                  <AssetSvg name="Orange_juice_carton_blank" width={dimens.width / 3} />
                  <Text
                    style={{
                      color: colors.white,
                      bottom: displayMode === 'digital' ? 262 : 480,
                      right: 10
                    }}
                    variant="WRN700"
                  >
                    {translate.units.numberOfMl(totalJuiceMl)}
                  </Text>
                </View>
                <AssetSvg
                  name={orangeJuiceSvg(glassCapacityFilled, 2).svgName}
                  width={dimens.width / 3}
                />
              </View>
            )}
          </MeasureView>
        }
        selectables={Object.fromEntries(selectables)}
        correctAnswer={['A']}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aDV',
  description: 'aDV',
  keywords: ['Capacity', 'Estimate', 'Millilitres'],
  schema: z
    .object({
      juiceMl: z.number().int().min(100).max(500).multipleOf(100),
      fractionA: z.enum([
        'eighth',
        'quarter',
        'third',
        'half',
        'twoThirds',
        'threeQuarters',
        'sevenEighths',
        'full'
      ]),
      fractionB: z.enum([
        'eighth',
        'quarter',
        'third',
        'half',
        'twoThirds',
        'threeQuarters',
        'sevenEighths',
        'full'
      ]),
      fractionC: z.enum([
        'eighth',
        'quarter',
        'third',
        'half',
        'twoThirds',
        'threeQuarters',
        'sevenEighths',
        'full'
      ]),
      fractionD: z.enum([
        'eighth',
        'quarter',
        'third',
        'half',
        'twoThirds',
        'threeQuarters',
        'sevenEighths',
        'full'
      ])
    })
    .refine(
      val => arrayHasNoDuplicates([val.fractionA, val.fractionB, val.fractionC, val.fractionD]),
      'Fractions must all be different.'
    ),
  simpleGenerator: () => {
    const juiceMl = randomIntegerInclusiveStep(100, 500, 100);

    const [fractionA, fractionB, fractionC, fractionD] = getRandomSubArrayFromArray(
      [
        'eighth',
        'quarter',
        'third',
        'half',
        'twoThirds',
        'threeQuarters',
        'sevenEighths',
        'full'
      ] as const,
      4
    );

    return { juiceMl, fractionA, fractionB, fractionC, fractionD };
  },
  Component: props => {
    const {
      question: { juiceMl, fractionA, fractionB, fractionC, fractionD },
      translate,
      displayMode
    } = props;

    const selectableMaker = (
      fraction:
        | 'eighth'
        | 'quarter'
        | 'third'
        | 'half'
        | 'twoThirds'
        | 'threeQuarters'
        | 'sevenEighths'
        | 'full'
    ): string => {
      switch (fraction) {
        case 'eighth':
          return translate.units.numberOfMl(roundToTheNearest(juiceMl * 8, 5));
        case 'quarter':
          return translate.units.numberOfMl(roundToTheNearest(juiceMl * 4, 5));
        case 'third':
          return translate.units.numberOfMl(roundToTheNearest(juiceMl * 3, 5));
        case 'half':
          return translate.units.numberOfMl(roundToTheNearest(juiceMl * 2, 5));
        case 'twoThirds':
          return translate.units.numberOfMl(roundToTheNearest((juiceMl * 3) / 2, 5));
        case 'threeQuarters':
          return translate.units.numberOfMl(roundToTheNearest((juiceMl * 4) / 3, 5));
        case 'sevenEighths':
          return translate.units.numberOfMl(roundToTheNearest((juiceMl * 8) / 7, 5));
        case 'full':
          return translate.units.numberOfMl(roundToTheNearest(juiceMl, 5));
      }
    };

    const [glassAMl, glassASvgPath] = [
      selectableMaker(fractionA),
      orangeJuiceSvg(fractionA, 2).svgName
    ];
    const [glassBMl, glassBSvgPath] = [
      selectableMaker(fractionB),
      orangeJuiceSvg(fractionB, 2).svgName
    ];
    const [glassCMl, glassCSvgPath] = [
      selectableMaker(fractionC),
      orangeJuiceSvg(fractionC, 2).svgName
    ];
    const [glassDMl, glassDSvgPath] = [
      selectableMaker(fractionD),
      orangeJuiceSvg(fractionD, 2).svgName
    ];

    const random = seededRandom(props.question);

    const selectOptions = shuffle([glassAMl, glassBMl, glassCMl, glassDMl], {
      random
    });

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.eachGlassContainsNumMlOfJuice(juiceMl)}
        pdfTitle={translate.instructions.eachGlassContainsNumMlOfJuicePDF(juiceMl)}
        actionPanelVariant="bottom"
        items={selectOptions}
        itemVariant="shortRectangle"
        pdfItemVariant="rectangle"
        sentence="<ans/> <ans/> <ans/> <ans/>"
        sentenceStyle={{ columnGap: 72 }}
        testCorrect={[glassAMl, glassBMl, glassCMl, glassDMl]}
        Content={({ dimens }) => {
          return (
            <View
              style={[
                dimens,
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  columnGap: displayMode === 'digital' ? 190 : 174
                }
              ]}
            >
              <AssetSvg name={glassASvgPath as SvgName} height={dimens.height} />
              <AssetSvg name={glassBSvgPath as SvgName} height={dimens.height} />
              <AssetSvg name={glassCSvgPath as SvgName} height={dimens.height} />
              <AssetSvg name={glassDSvgPath as SvgName} height={dimens.height} />
            </View>
          );
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aDW',
  description: 'aDW',
  keywords: ['Capacity', 'Estimate', 'Millilitres', 'Litres'],
  schema: z.object({
    fractionOfGlassFilled: z.enum([
      'eighth',
      'quarter',
      'third',
      'half',
      'twoThirds',
      'threeQuarters'
    ]),
    juiceMl: z.number().int().min(100).max(4950).multipleOf(50)
  }),
  simpleGenerator: () => {
    const fractionOfGlassFilled = getRandomFromArray([
      'eighth',
      'quarter',
      'third',
      'half',
      'twoThirds',
      'threeQuarters'
    ] as const);

    const fractionOfGlass = fractionToDecimal(fractionOfGlassFilled);

    const juiceMl = randomIntegerInclusiveStep(100, 4950, 50, {
      constraint: x => x / fractionOfGlass >= 1000
    });

    return {
      fractionOfGlassFilled,
      juiceMl
    };
  },
  Component: props => {
    const {
      question: { fractionOfGlassFilled, juiceMl },
      translate
    } = props;

    const fractionOfGlass = fractionToDecimal(fractionOfGlassFilled);

    const glassCapacityMl = number(math.evaluate(`${juiceMl} / ${fractionOfGlass}`));

    const glassLowerBoundaryMl = number(math.evaluate(`${glassCapacityMl} * 0.875`));

    const glassUpperBoundaryMl = number(math.evaluate(`${glassCapacityMl} * 1.125`));

    const svgPath = orangeJuiceSvg(fractionOfGlassFilled, 2).svgName;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theGlassContainsNumMlOfJuiceEstimateCapacityOfGlass(juiceMl)}
        testCorrect={userAnswer => {
          const userAnswerMl = number(math.evaluate(`${userAnswer[0]} * 1000`));
          return glassLowerBoundaryMl <= userAnswerMl && userAnswerMl <= glassUpperBoundaryMl;
        }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        sentence={translate.answerSentences.ansLitres()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => <AssetSvg name={svgPath} height={dimens.height * 0.9} />}
        pdfDirection="column"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            (glassLowerBoundaryMl / 1000).toLocaleString(undefined, {
              maximumFractionDigits: 2
            }),
            (glassUpperBoundaryMl / 1000).toLocaleString(undefined, {
              maximumFractionDigits: 2
            })
          )
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5v2 = newQuestionContent({
  uid: 'aDW2',
  description: 'aDW',
  keywords: ['Capacity', 'Estimate', 'Millilitres', 'Litres'],
  schema: z.object({
    liquidAmount: z.number().int().min(100).max(19800).multipleOf(50),
    containerCapacityMl: z.number(),
    selectedUnit: z.enum(['ml', 'l'])
  }),
  simpleGenerator: () => {
    const fractionOfGlassFilled = getRandomFromArray([
      'eighth',
      'quarter',
      'third',
      'half',
      'twoThirds',
      'threeQuarters'
    ] as const);

    const fractionOfGlass = fractionToDecimal(fractionOfGlassFilled);

    const liquidAmount = randomIntegerInclusiveStep(100, 4950, 50, {
      constraint: x => x / fractionOfGlass >= 1000
    });

    const containerCapacityMl = number(math.evaluate(`${liquidAmount} / ${fractionOfGlass}`));

    const selectedUnit = getRandomFromArray(['ml', 'l'] as const);

    return {
      liquidAmount,
      containerCapacityMl,
      selectedUnit
    };
  },
  Component: props => {
    const {
      question: { liquidAmount, containerCapacityMl, selectedUnit },
      translate
    } = props;

    const glassLowerBoundaryMl = number(math.evaluate(`${containerCapacityMl} * 0.875`));

    const glassUpperBoundaryMl = number(math.evaluate(`${containerCapacityMl} * 1.125`));

    return (
      <QF1ContentAndSentence
        title={
          selectedUnit === 'ml'
            ? translate.instructions.theContainerContainsNumXCapacityOfWaterEstimateCapacityOfContainer(
                liquidAmount,
                translate.units.ml()
              )
            : translate.instructions.theContainerContainsNumXCapacityOfWaterEstimateCapacityOfContainer(
                liquidAmount / 1000,
                translate.units.l()
              )
        }
        testCorrect={userAnswer => {
          const userAnswerCapacity =
            selectedUnit === 'ml'
              ? number(math.evaluate(`${userAnswer[0]} * 1000`))
              : number(math.evaluate(`${userAnswer[0]}`));

          return isInRange(glassLowerBoundaryMl, glassUpperBoundaryMl)(userAnswerCapacity);
        }}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        sentence={
          selectedUnit === 'ml'
            ? translate.answerSentences.ansL()
            : translate.answerSentences.ansMl()
        }
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={containerCapacityMl}
            liquidAmount={liquidAmount}
            tickValue={containerCapacityMl}
            displayMajorLabels="none"
          />
        )}
        pdfDirection="column"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            selectedUnit === 'ml'
              ? (glassLowerBoundaryMl / 1000).toLocaleString(undefined, {
                  maximumFractionDigits: 2
                })
              : glassLowerBoundaryMl.toLocaleString(undefined, {
                  maximumFractionDigits: 2
                }),
            selectedUnit === 'ml'
              ? (glassUpperBoundaryMl / 1000).toLocaleString(undefined, {
                  maximumFractionDigits: 2
                })
              : glassUpperBoundaryMl.toLocaleString(undefined, {
                  maximumFractionDigits: 2
                })
          )
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'aDX',
  description: 'aDX',
  keywords: ['Capacity', 'Estimate', 'Millilitres', 'Litres'],
  schema: z
    .object({
      minorTickValueMl: numberEnum([100, 200, 250, 500]),
      minorTicksFilled: z.number().int().min(1).max(10),
      numberOfBags: z.number().int().min(2).max(10),
      name: nameSchema
    })
    .refine(
      val => val.minorTickValueMl * val.minorTicksFilled <= 1000,
      'minorTickValueMl x minorTicksFilled must be less than or equal to 1,000'
    )
    .refine(
      val => (val.minorTickValueMl * val.minorTicksFilled) % val.numberOfBags === 0,
      'Total of sand / number of bags must have no remainder.'
    ),
  simpleGenerator: () => {
    const minorTickValueMl = getRandomFromArray([
      100, 100, 100, 100, 200, 200, 200, 250, 250, 500
    ] as const);

    const minorTicksFilled = randomIntegerInclusive(1, 10, {
      constraint: x => minorTickValueMl * x <= 1000
    });

    const numberOfBags = randomIntegerInclusive(2, 10, {
      constraint: x => (minorTickValueMl * minorTicksFilled) % x === 0
    });

    const name = getRandomName();

    return {
      minorTickValueMl,
      minorTicksFilled,
      numberOfBags,
      name
    };
  },
  Component: props => {
    const {
      question: { minorTickValueMl, minorTicksFilled, numberOfBags, name },
      translate
    } = props;

    const sandMl = minorTickValueMl * minorTicksFilled;

    const sandPerBagMl = sandMl / numberOfBags;

    const numberOfMinorTicks = (() => {
      switch (minorTickValueMl) {
        case 100:
          return 9;
        case 200:
          return 4;
        case 250:
          return 3;
        case 500:
          return 1;
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterHasPouredNumFullBagsOfSandIntoContainerEstimateCapacityOfOneBag(
          name,
          numberOfBags
        )}
        testCorrect={[sandPerBagMl.toString()]}
        sentence={translate.answerSentences.ansMl()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row' }]}>
            <View
              style={{
                width: dimens.width / 2,
                height: dimens.height,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AssetSvg name="Sand_bag" height={dimens.height * 0.6} />
            </View>
            <View style={{ width: dimens.width / 2, height: dimens.height, alignItems: 'center' }}>
              <JugWithLiquid
                dimens={{ width: dimens.width / 2, height: dimens.height }}
                jugCapacity={1000}
                tickValue={1000}
                liquidAmount={sandMl}
                liquidType="sand"
                unitsPerMajorTick={numberOfMinorTicks}
              />
            </View>
          </View>
        )}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6v2 = newQuestionContent({
  uid: 'aDX2',
  description: 'aDX2',
  keywords: ['Capacity', 'Estimate', 'Millilitres', 'Litres'],
  schema: z.object({
    liquidAmount: z.number().int().min(50).max(1000).multipleOf(50),
    containerCapacityMl: z.number()
  }),
  simpleGenerator: () => {
    const fractionOfGlassFilled = getRandomFromArray([
      'eighth',
      'quarter',
      'third',
      'half',
      'twoThirds',
      'threeQuarters'
    ] as const);

    const fractionOfGlass = fractionToDecimal(fractionOfGlassFilled);

    const liquidAmount = randomIntegerInclusiveStep(50, 1000, 50, {
      constraint: x => x / fractionOfGlass >= 50
    });

    const containerCapacityMl = number(math.evaluate(`${liquidAmount} / ${fractionOfGlass}`));

    return {
      containerCapacityMl,
      liquidAmount
    };
  },
  Component: props => {
    const {
      question: { containerCapacityMl, liquidAmount },
      translate
    } = props;

    const glassLowerBoundaryMl = number(math.evaluate(`${containerCapacityMl} * 0.875`));

    const glassUpperBoundaryMl = number(math.evaluate(`${containerCapacityMl} * 1.125`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theContainerContainsNumXCapacityOfWaterEstimateCapacityOfContainer(
          liquidAmount / 1000,
          translate.units.l()
        )}
        testCorrect={userAnswer => {
          const userAnswerCapacity = number(math.evaluate(`${userAnswer[0]}`));

          return isInRange(glassLowerBoundaryMl, glassUpperBoundaryMl)(userAnswerCapacity);
        }}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        sentence={translate.answerSentences.ansMl()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={containerCapacityMl}
            liquidAmount={liquidAmount}
            tickValue={containerCapacityMl}
            displayMajorLabels="none"
          />
        )}
        pdfDirection="column"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            glassLowerBoundaryMl.toLocaleString(undefined, {
              maximumFractionDigits: 2
            }),
            glassUpperBoundaryMl.toLocaleString(undefined, {
              maximumFractionDigits: 2
            })
          )
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateCapacity',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5v2, Question6v2],
  unpublishedQuestionTypes: [Question2v2, Question6v2],
  archivedQuestionTypes: [Question1, Question2, Question5, Question6]
});
export default SmallStep;
