import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"
const SvgComponent = (props: SvgProps) => (
  <Svg
    fill="#D1242A"
    stroke="#745E5A"
    viewBox="0 0 100 100"
    {...props}
  >
    <Path strokeWidth={3} d="M1.5 1.5h97v97h-97z" />
  </Svg>
)
const Memo = memo(SvgComponent)
export default Memo
