import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import { filledArray, range, sortNumberArray } from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF15CreateBaseTenNumber from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import { integerToWord, ScientificNotation } from '../../../../utils/math';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bfN',
  description: 'bfN',
  keywords: ['Number track', 'Count', 'Forwards', 'Backwards'],
  schema: z.object({
    numberArray: z.number().int().min(0).max(20).array().length(8),
    missingIndices: z.number().min(0).max(7).array()
  }),
  simpleGenerator: () => {
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const startingNumber = randomIntegerInclusive(0, 13);

    const numberArray = sortNumberArray(range(startingNumber, startingNumber + 7, 1), ordering);

    const missingIndices = rejectionSample(
      () => {
        return sortNumberArray(randomUniqueIntegersInclusive(0, 7, randomIntegerInclusive(3, 5)));
      },
      // Check at least two consecutive numbers
      x => numberArray.some((_, i) => !x.includes(i) && !x.includes(i + 1))
    );

    return { numberArray, missingIndices };
  },
  Component: ({ question: { numberArray, missingIndices }, translate }) => {
    const answerArray = missingIndices.map(x => numberArray[x].toString());
    const boxValues = numberArray.map((x, i) =>
      missingIndices.indexOf(i) === -1 ? x.toLocaleString() : '<ans/>'
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.ks1Instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={boxValues}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bfO',
  description: 'bfO',
  keywords: ['Base 10', 'Ten frame', 'Counters'],
  schema: z.object({
    number: z.number().int().min(2).max(20),
    numOrWord: z.enum(['numeral', 'word']),
    isBase10: z.boolean(),
    counterVariant: z.enum(['red', 'blue', 'green', 'grey', 'yellow'])
  }),
  simpleGenerator: () => {
    const number = getRandomFromArrayWithWeights(
      [randomIntegerInclusive(10, 20), randomIntegerInclusive(2, 9)] as const,
      [3, 1]
    );

    const numOrWord = getRandomFromArray(['numeral', 'word'] as const);

    const isBase10 = getRandomBoolean();

    const counterVariant = getRandomFromArray(['red', 'blue', 'green', 'grey', 'yellow'] as const);

    return { number, numOrWord, isBase10, counterVariant };
  },

  Component: props => {
    const {
      question: { number, numOrWord, isBase10, counterVariant },
      translate,
      displayMode
    } = props;

    const titleNumber = numOrWord === 'numeral' ? number : `${integerToWord(number)}.`;

    const base10 = () => (
      <QF15CreateBaseTenNumber
        title={translate.ks1Instructions.dragTheBase10ToMakeX(titleNumber)}
        pdfTitle={translate.ks1PDFInstructions.drawBase10ToShowX(titleNumber)}
        number={ScientificNotation.fromNumber(number)}
        draggablesToShow={number < 10 ? [0] : [1, 0]}
        questionHeight={900}
      />
    );

    const markSchemeAnswer = [
      filledArray(counterVariant, Math.min(number, 10)),
      filledArray(counterVariant, Math.max(number - 10, 0))
    ];

    const tenFrame = () => (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToMakeX(titleNumber)}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToShowX(titleNumber)}
        items={[counterVariant]}
        numberOfTenFrames={number < 10 ? 1 : 2}
        exampleCorrectAnswer={markSchemeAnswer}
        tenFrameFlexDirection={displayMode === 'digital' ? 'column' : 'row'}
        testCorrect={totalCountersIs(number)}
      />
    );

    return isBase10 ? base10() : tenFrame();
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumbersTo20',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
