import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  markedIsoscelesTriangle,
  markedIsoscelesTriangleNamesSchema
} from '../../../../utils/shapeImages/triangles';
import { countRange } from '../../../../utils/collections';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { CircleShapeAngles } from '../../../../components/question/representations/CircleShapeAngles';
import { CircleShapeNames } from '../../../../utils/anglePositions';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  ALGEBRAIC_A,
  ALGEBRAIC_B,
  ALGEBRAIC_C,
  ALGEBRAIC_D,
  ALGEBRAIC_L,
  ALGEBRAIC_M,
  ALGEBRAIC_N,
  ALGEBRAIC_W,
  ALGEBRAIC_X,
  ALGEBRAIC_Y,
  ALGEBRAIC_Z,
  DEGREES
} from '../../../../constants';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AlgebraicSymbols, algebraicSymbolSchema } from '../../../../utils/algebraicSymbols';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

/** Archived */
const Question1 = newQuestionContent({
  uid: 'aEm',
  description: 'aEm',
  keywords: ['Triangles', 'Angles', 'Isosceles'],
  schema: z.object({
    images: z
      .object({
        svgName: markedIsoscelesTriangleNamesSchema,
        isCorrect: z.boolean(),
        circleIndexes: z.number().min(0).max(2).array().length(2)
      })
      .array()
      .length(4),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const svgNames = getRandomSubArrayFromArray(markedIsoscelesTriangle, 4);
    const isCorrect = shuffle([true, false, ...countRange(2).map(getRandomBoolean)]);

    const images = countRange(4).map(i => ({
      svgName: svgNames[i],
      isCorrect: isCorrect[i],
      circleIndexes: isCorrect[i] ? [0, 1] : [randomIntegerInclusive(0, 1), 2]
    }));

    const name = getRandomName();

    return {
      images,
      name
    };
  },
  Component: props => {
    const {
      question: { images, name },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.xHasCircledAnglesInIsoscelesSelectTrianglesThatAreCorrect(
          name
        )}
        pdfTitle={translate.instructions.xHasCircledAnglesInIsoscelesCircleTrianglesThatAreCorrect(
          name
        )}
        testCorrect={images.filter(val => val.isCorrect).map(val => val.svgName)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          images.map(value => ({
            value: value.svgName,
            component: (
              <CircleShapeAngles
                shapeName={value.svgName as CircleShapeNames}
                dimens={dimens}
                circleIndexes={value.circleIndexes}
              />
            )
          }))
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aEm2',
  description: 'aEm',
  keywords: ['Triangles', 'Angles', 'Isosceles'],
  schema: z.object({
    svgName: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    letters: z.array(algebraicSymbolSchema).length(3)
  }),
  simpleGenerator: () => {
    const svgName = getRandomFromArray([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ] as const);

    const letters = shuffle(
      getRandomFromArray([
        [ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C],
        [ALGEBRAIC_B, ALGEBRAIC_C, ALGEBRAIC_D],
        [ALGEBRAIC_L, ALGEBRAIC_M, ALGEBRAIC_N],
        [ALGEBRAIC_W, ALGEBRAIC_X, ALGEBRAIC_Y],
        [ALGEBRAIC_X, ALGEBRAIC_Y, ALGEBRAIC_Z]
      ]) as AlgebraicSymbols[]
    );

    return {
      svgName,
      letters
    };
  },
  Component: props => {
    const {
      question: { svgName, letters },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4WithContent
        questionHeight={1000}
        title={translate.instructions.selectEqualAngles()}
        pdfTitle={translate.instructions.circleEqualAngles()}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={svgName}
            angleLabels={letters}
            unknownLargerFontIndex={[0, 1, 2]}
          />
        )}
        multiSelect
        numItems={3}
        itemLayout="row"
        renderItems={letters.map(letter => ({
          value: letter,
          component: <TextStructure textVariant="WRN700" sentence={letter} />
        }))}
        testCorrect={letters.filter((_val, i) => i !== 1)}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aEo',
  description: 'aEo',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Calculate'],
  schema: z.object({
    triangle: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    topIsoAngle: z.number().min(34).max(128),
    baseIsoAngle: z.number().min(26).max(73)
  }),
  simpleGenerator: () => {
    const triangle = getRandomFromArray([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ] as const);

    let topIsoAngle;
    let baseIsoAngle;
    if (triangle === 'isoceles_obtuse_triangle1') {
      topIsoAngle = randomIntegerInclusiveStep(92, 128, 2, { constraint: x => x % 10 !== 0 });
      baseIsoAngle = (180 - topIsoAngle) / 2;
    } else {
      topIsoAngle = randomIntegerInclusiveStep(34, 58, 2);
      baseIsoAngle = (180 - topIsoAngle) / 2;
    }

    return {
      triangle,
      topIsoAngle,
      baseIsoAngle
    };
  },
  Component: props => {
    const {
      question: { triangle, topIsoAngle, baseIsoAngle },
      translate
    } = props;

    const labels =
      triangle === 'isoceles_obtuse_triangle1'
        ? ['', `${baseIsoAngle.toLocaleString()}${DEGREES}`, ALGEBRAIC_A]
        : [ALGEBRAIC_A, `${baseIsoAngle.toLocaleString()}${DEGREES}`, ''];

    return (
      <QF1ContentAndSentence
        sentence={`${ALGEBRAIC_A} = ${translate.answerSentences.ansDeg()}`}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[topIsoAngle.toString()]}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={triangle} angleLabels={labels} />
        )}
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aEn',
  description: 'aEn',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Calculate'],
  schema: z.object({
    triangle: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    angles: z.array(z.number().min(26).max(128)).length(3),
    missingIndexes: z.array(z.number().min(0).max(2)).length(2)
  }),
  simpleGenerator: () => {
    const triangle = getRandomFromArray([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ] as const);

    let angles;
    let missingIndexes;
    if (triangle === 'isoceles_obtuse_triangle1') {
      const topIsoAngle = randomIntegerInclusiveStep(92, 128, 2, { constraint: x => x % 10 !== 0 });
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      angles = [baseIsoAngle, baseIsoAngle, topIsoAngle];
      missingIndexes = [0, 1];
    } else {
      const topIsoAngle = randomIntegerInclusiveStep(34, 58, 2);
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      angles = [topIsoAngle, baseIsoAngle, baseIsoAngle];
      missingIndexes = [1, 2];
    }

    return {
      triangle,
      angles,
      missingIndexes
    };
  },
  Component: props => {
    const {
      question: { triangle, angles, missingIndexes },
      translate
    } = props;

    const labels = angles.map((val, i) =>
      missingIndexes.includes(i)
        ? [ALGEBRAIC_A, ALGEBRAIC_B][missingIndexes.findIndex(val => i === val)]
        : `${val.toLocaleString()}${DEGREES}`
    );

    return (
      <QF1ContentAndSentences
        sentences={[
          `${ALGEBRAIC_A} = ${translate.answerSentences.ansDeg()}`,
          `${ALGEBRAIC_B} = ${translate.answerSentences.ansDeg()}`
        ]}
        title={translate.instructions.workOutSizeOfUnknownAngles()}
        testCorrect={missingIndexes.map(val => [angles[val].toString()])}
        pdfDirection="column"
        style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangle}
            angleLabels={labels}
            unknownLargerFontIndex={missingIndexes}
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

/** Archived */
const Question4 = newQuestionContent({
  uid: 'aEp',
  description: 'aEp',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Equilateral', 'Right-angled'],
  schema: z.object({
    selection: z.object({
      sentence: z.enum([
        'everyEquilateralTriangleIsIsos',
        'everyIsosTriangleIsEquilateral',
        'aRATriangleCanBeEquilateral',
        'aRATriangleCanBeIsos',
        'anEquilateralTriangleCanHaveRA',
        'anEquilateralTriangleCanHaveThreeAcuteAngles',
        'anEquilateralTriangleCanHaveThreeObtuseAngles',
        'anIsosTriangleCanHaveTwoAcuteAngles',
        'anIsosTriangleCanHaveTwoObtuseAngles'
      ]),
      isCorrect: z.boolean()
    })
  }),
  simpleGenerator: () => {
    const selection = getRandomFromArray([
      { sentence: 'everyEquilateralTriangleIsIsos', isCorrect: true },
      { sentence: 'everyIsosTriangleIsEquilateral', isCorrect: false },
      { sentence: 'aRATriangleCanBeEquilateral', isCorrect: false },
      { sentence: 'aRATriangleCanBeIsos', isCorrect: true },
      { sentence: 'anEquilateralTriangleCanHaveRA', isCorrect: false },
      { sentence: 'anEquilateralTriangleCanHaveThreeAcuteAngles', isCorrect: true },
      { sentence: 'anEquilateralTriangleCanHaveThreeObtuseAngles', isCorrect: false },
      { sentence: 'anIsosTriangleCanHaveTwoAcuteAngles', isCorrect: true },
      { sentence: 'anIsosTriangleCanHaveTwoObtuseAngles', isCorrect: false }
    ] as const);

    return { selection };
  },
  Component: ({ question: { selection }, translate }) => {
    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.selectIfStatementIsTrueOrFalse()}
        pdfTitle={translate.instructions.circleIfStatementIsTrueOrFalse()}
        correctAnswer={selection.isCorrect}
        content={({ dimens }) => (
          <ContentBox
            containerStyle={{ maxHeight: dimens.height * 0.8, width: dimens.width * 0.8 }}
          >
            <Text variant="WRN400" style={{ textAlign: 'center' }}>
              {translate.answerSentences[selection.sentence]()}
            </Text>
          </ContentBox>
        )}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aEp2',
  description: 'aEn',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Calculate'],
  schema: z.object({
    triangle: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    angles: z.array(z.number().min(26).max(128)).length(3),
    missingIndexes: z.array(z.number().min(0).max(2)).length(2)
  }),
  simpleGenerator: () => {
    const triangle = getRandomFromArray([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ] as const);

    let angles;
    let missingIndexes;
    if (triangle === 'isoceles_obtuse_triangle1') {
      const topIsoAngle = randomIntegerInclusiveStep(92, 128, 2, { constraint: x => x % 10 !== 0 });
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      angles = [baseIsoAngle, baseIsoAngle, topIsoAngle];
      missingIndexes = [randomIntegerInclusive(0, 1), 2];
    } else {
      const topIsoAngle = randomIntegerInclusiveStep(34, 58, 2);
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      angles = [topIsoAngle, baseIsoAngle, baseIsoAngle];
      missingIndexes = [randomIntegerInclusive(1, 2), 0];
    }

    return {
      triangle,
      angles,
      missingIndexes
    };
  },
  Component: props => {
    const {
      question: { triangle, angles, missingIndexes },
      translate
    } = props;

    const labels = angles.map((val, i) =>
      missingIndexes.includes(i)
        ? [ALGEBRAIC_A, ALGEBRAIC_B][missingIndexes.findIndex(val => i === val)]
        : `${val.toLocaleString()}${DEGREES}`
    );

    return (
      <QF1ContentAndSentences
        sentences={[
          `${ALGEBRAIC_A} = ${translate.answerSentences.ansDeg()}`,
          `${ALGEBRAIC_B} = ${translate.answerSentences.ansDeg()}`
        ]}
        title={translate.instructions.workOutSizeOfUnknownAngles()}
        testCorrect={missingIndexes.map(val => [angles[val].toString()])}
        pdfDirection="column"
        style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangle}
            angleLabels={labels}
            unknownLargerFontIndex={missingIndexes}
          />
        )}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aEq',
  description: 'aEq',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Calculate'],
  schema: z.object({
    number1: z.number().int().min(12).max(48).step(2),
    number2: z.number().int().min(31).max(84),
    isCorrect: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(12, 48, 2, { constraint: x => x % 10 !== 0 });
    const isCorrect = getRandomBoolean();
    const number2 = isCorrect
      ? (180 - number1) / 2
      : randomIntegerInclusive(31, 79, {
          constraint: x => x !== (180 - number1) / 2 && x % 10 !== 0
        });

    return {
      number1,
      number2,
      isCorrect
    };
  },
  Component: props => {
    const {
      question: { number1, number2, isCorrect },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.twoAnglesInTriangleArXYIsThisIsosceles(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}<br/>${translate.instructions.selectYourAnswer()}`}
        pdfTitle={translate.instructions.twoAnglesInTriangleArXYIsThisIsoscelesPDF(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={[isCorrect ? 'Yes' : 'No']}
        numItems={2}
        innerContainerStyle={{ alignItems: 'center' }}
        itemStyle={{ height: 200 }}
        renderItems={['Yes', 'No'].map(value => ({
          value,
          component: <Text variant="WRN700">{translate.misc[value as 'Yes' | 'No']()}</Text>
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aEr',
  description: 'aEr',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Calculate'],
  schema: z.object({
    angle1: z.number().int().min(12).max(78).step(2),
    angle2: z.number().int().min(24).max(156).step(2),
    angle3: z.number().int().min(51).max(84),
    angle4: z.number().int().min(102).max(168).step(2)
  }),
  simpleGenerator: () => {
    const angle1 = randomIntegerInclusiveStep(12, 78, 2);
    const angle2 = 180 - angle1 * 2;
    const angle3 = (180 - angle1) / 2;
    const angle4 = 180 - angle1;

    return {
      angle1,
      angle2,
      angle3,
      angle4
    };
  },
  Component: props => {
    const {
      question: { angle1, angle2, angle3, angle4 },
      translate
    } = props;

    const options = shuffle(
      [
        {
          value: 'A',
          string: `${angle1.toLocaleString()}${DEGREES} ${translate.misc.and()} ${angle2.toLocaleString()}${DEGREES}`
        },
        {
          value: 'B',
          string: `${angle3.toLocaleString()}${DEGREES} ${translate.misc.and()} ${angle3.toLocaleString()}${DEGREES}`
        },
        {
          value: 'C',
          string: `${angle4.toLocaleString()}${DEGREES} ${translate.misc.and()} ${angle1.toLocaleString()}${DEGREES}`
        },
        {
          value: 'D',
          string: `${angle2.toLocaleString()}${DEGREES} ${translate.misc.and()} ${angle3.toLocaleString()}${DEGREES}`
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        questionHeight={900}
        title={translate.instructions.oneAngleInIsoscelesTriangleIsXSelectOtherAngles(angle1)}
        pdfTitle={translate.instructions.oneAngleInIsoscelesTriangleIsXCircleOtherAngles(angle1)}
        numItems={4}
        renderItems={options.map(val => ({
          value: val.value,
          component: <Text variant="WRN700">{val.string}</Text>
        }))}
        testCorrect={['A']}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AnglesInATriangleSpecialCases',
  questionTypes: [Question1v2, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [Question1v2],
  archivedQuestionTypes: [Question1, Question4]
});
export default SmallStep;
