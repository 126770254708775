import { StatusBar } from 'expo-status-bar';
import { Camera, type BarCodeScanningResult } from 'expo-camera';
import { useCallback, useRef, useState } from 'react';
import { Platform, StyleSheet, View, useWindowDimensions } from 'react-native';
import { type RootStackProps } from '../navigation/types';
import { parseOfflineQrCode, parseOnlineQrCode } from '../network/qrCodes';
import useQuizSessionStore, { type QuestionInfo } from '../storage/useQuizSessionStore';
import { createNewQuizSession } from '../network/quizSession';
import { useFocusEffect } from '@react-navigation/native';
import { Image } from 'expo-image';
import { getPlayer } from 'common/src/utils/Audio';
import { showErrorToast } from '../components/Toast';
import { useI18nContext } from '../i18n/i18n-react';
import {
  type FQQuestionTypeContentMaybeWithData,
  parseQuestion,
  type QuestionToken
} from 'common/src/SchemeOfLearning';
import useBreakpoints from '../hooks/useBreakpoints';
import { validateSchoolCode } from '../network/schoolCode';
import useLoginStore from '../storage/useLoginStore';
import ENV from '../ENV';

export default function ScanQRScreen({ navigation }: RootStackProps<'ScanQR'>) {
  const translate = useI18nContext().LL;

  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);
  const loggedInLearningGroupShareCode = useLoginStore(state => state.school?.code);

  const { resize } = useBreakpoints();

  // Keep track of whether the current screen is focused (this doesn't cause rerenders though!)
  const isFocused = useRef(false);
  useFocusEffect(
    useCallback(() => {
      isFocused.current = true;
      return () => (isFocused.current = false);
    }, [])
  );

  const [scanned, setScanned] = useState(false);
  const [camera, setCamera] = useState<Camera | null>(null);
  const { height, width } = useWindowDimensions();

  // Set QR bounds
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [qrHeight, setQRHeight] = useState(0);
  const [qrWidth, setQRWidth] = useState(0);

  // Screen Ratio for android
  const [ratio, setRatio] = useState<string | undefined>(undefined);
  const screenRatio = width / height;
  const [isRatioSet, setIsRatioSet] = useState(false);

  const setSchool = useLoginStore(state => state.setSchool);
  const loggedInUser = useLoginStore(state => state.loggedInUser);

  /** Callback for when something has gone wrong */
  const handleError = useCallback(
    (title: string, message: string) => {
      showErrorToast({ title, message, extraDuration: 1000, resize: resize });
      navigation.goBack();
    },
    [navigation, resize]
  );

  /** Callback for successfully scanning an offline QR code */
  const handleOfflineQrCode = useCallback(
    async (data: { tokens: QuestionToken[]; quizName: string }) => {
      // Add tiny delay to show target
      await new Promise(r => setTimeout(r, 500));

      if (!isFocused.current) {
        // User cancelled by navigating away
        return;
      }

      const questions = data.tokens.map((token, index) => {
        const questionInfo: QuestionInfo = {
          id: 'questions/1234',
          uid: typeof token === 'string' ? token : token[0],
          parameters: typeof token === 'string' ? undefined : JSON.stringify(token[1]),
          displayOrder: index + 1
        };

        return questionInfo;
      });

      // Fake up a year string, e.g. "Year 3" or "Year 3/4"
      const years = new Set<string>();
      data.tokens
        .map(parseQuestion)
        // Filter out dodgy questions
        .filter(
          (result): result is FQQuestionTypeContentMaybeWithData => typeof result !== 'string'
        )
        // Add years to the years pool
        .forEach(it => years.add(it.questionType.year));

      // The QR code was an offline QR code. Navigate to the Quiz screen.
      setQuizSession({
        id: 'import',
        name: data.quizName,
        randomiseQuestionParameters: questions.every(
          //If imported quiz does not have parameters it will be randomised
          questionInfo => questionInfo.parameters === undefined
        ),
        questions: questions,
        retryInfo: { type: 'legacy' }
      });
      navigation.replace('Quiz');
    },
    [navigation, setQuizSession]
  );

  /** Callback for successfully scanning an online QR code  */
  const handleOnlineQrCode = useCallback(
    async (data: { learningGroupShareCode: string; quizVersionShareShareCode: string }) => {
      if (
        loggedInLearningGroupShareCode &&
        loggedInLearningGroupShareCode !== data.learningGroupShareCode
      ) {
        handleError(
          translate.errorModals.cantAccessQuiz(),
          translate.errorModals.quizBelongsToDifferentGroup()
        );
        return;
      }

      const schoolCodeResponse = await validateSchoolCode(data.learningGroupShareCode);

      if (typeof schoolCodeResponse === 'string') {
        // API validation failed, show an error message and hide the loading spinner

        switch (schoolCodeResponse) {
          case 'no infinity subscription':
            handleError(
              translate.errorModals.thereIsAnAccountIssue(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          case 'invalid response':
          case 'unknown error':
          case 'http error':
            handleError(
              translate.enterCodeOrPINScreen.somethingWentWrongError(),
              translate.errorModals.pleaseTryAgainLater()
            );
            return;
          case 'network error':
            handleError(
              translate.errorModals.internetConnectionLost(),
              translate.errorModals.thisAppRequiresAnInternetConnection()
            );
            return;
          case 'not found':
            handleError(
              translate.enterCodeOrPINScreen.schoolNotFoundError(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${schoolCodeResponse satisfies never})`);
        }
      }

      // [Plus feature enabled only] This code exposes the new infinity plus screens, so disable it for non-plus
      // builds of the app.
      // If user not logged in check if school has Infinity Plus before creating quiz session to prevent multiple
      // calls to quiz sessions
      if (ENV.PLUS_FEATURE && loggedInUser === undefined) {
        if (schoolCodeResponse.hasInfinityPlus) {
          setSchool({
            code: data.learningGroupShareCode,
            name: schoolCodeResponse.name,
            hasInfinityPlus: schoolCodeResponse.hasInfinityPlus
          });
          navigation.replace('EnterPupilAccessCode', {
            onSuccessLaunchQuizPin: data.quizVersionShareShareCode
          });
        }
      }

      // The QR code was an online QR code. We need to make a network request to get the quiz.
      const quizSessionResult = await createNewQuizSession(data);

      if (!isFocused.current) {
        // User cancelled by navigating away
        return;
      }

      if (typeof quizSessionResult === 'string') {
        // Request failed. Kick them back out to the home screen with an appropriate toast.
        switch (quizSessionResult) {
          case 'invalid response':
          case 'unknown error':
          case 'http error':
            handleError(
              translate.errorModals.somethingWentWrong(),
              translate.errorModals.pleaseTryAgainLater()
            );
            return;
          case 'network error':
            handleError(
              translate.errorModals.internetConnectionLost(),
              translate.errorModals.thisAppRequiresAnInternetConnection()
            );
            return;
          case 'not found':
            handleError(
              translate.errorModals.quizNotFoundForThisQrCode(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          case 'quiz locked':
            handleError(
              translate.errorModals.thisQuizIsLocked(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${quizSessionResult satisfies never})`);
        }
      }

      // Create the audio handler with the correct settings
      getPlayer(quizSessionResult.quizSounds);

      // Go to the Quiz Screen
      setQuizSession(quizSessionResult);

      navigation.replace('Quiz');

      return;
    },
    [
      handleError,
      loggedInLearningGroupShareCode,
      loggedInUser,
      navigation,
      setQuizSession,
      setSchool,
      translate.enterCodeOrPINScreen,
      translate.errorModals
    ]
  );

  /** Callback for when a barcode is scanned. */
  const handleBarCodeScanned = useCallback(
    async ({ data, bounds }: BarCodeScanningResult) => {
      camera?.pausePreview();
      setScanned(true);

      if (bounds && Platform.OS === 'ios') {
        const origin = { y: Number(bounds.origin.y * height), x: Number(bounds.origin.x * width) };
        const size = {
          height: Number(bounds.size.height * height),
          width: Number(bounds.size.width * width)
        };

        const padding = 50;
        setTop(origin.y - padding / 2);
        setLeft(origin.x - padding / 2);
        setQRHeight(size.height + padding);
        setQRWidth(size.height + padding);
      }

      const offlineParams = parseOfflineQrCode(data);
      if (typeof offlineParams !== 'string') {
        await handleOfflineQrCode(offlineParams);
        return;
      }

      const onlineParams = parseOnlineQrCode(data);
      if (typeof onlineParams !== 'string') {
        await handleOnlineQrCode(onlineParams);
        return;
      }

      // Validation failed
      handleError(
        translate.errorModals.invalidQrCode(),
        translate.errorModals.tryAgainWithAValidQrCode()
      );
    },
    [camera, handleError, handleOfflineQrCode, handleOnlineQrCode, height, translate, width]
  );

  /** set the camera ratio for android */
  const prepareRatio = useCallback(async () => {
    let desiredRatio = '4:3';

    // This issue only affects Android and can only be ran once the camera has mounted
    if (Platform.OS === 'android' && camera !== null) {
      if (!ratio) {
        setRatio(desiredRatio);
      }
      const ratios = await camera.getSupportedRatiosAsync();
      // Calculate the width/height of each of the supported camera ratios
      const distances = [];
      const realRatios = [];
      let minDistance = null;
      for (const r of ratios) {
        const parts = r.split(':');
        const realRatio = parseInt(parts[0]) / parseInt(parts[1]);
        realRatios.push([r, realRatio]);
        const distance = Math.abs(screenRatio - realRatio);
        // find the ratio that is closest to the screen ratio without going over
        distances.push([r, distance]);
        // set the first distance as the minDistance
        if (minDistance === null) {
          minDistance = r;
        } else {
          // only update the min distance if the aspect ratio difference is less
          if (distance < Object.fromEntries(distances)[minDistance]) {
            minDistance = r;
          }
        }
      }
      // set the best match
      desiredRatio = minDistance ?? desiredRatio;
      setRatio(desiredRatio);
      // Set a flag so we don't do this calculation each time the screen refreshes
      setIsRatioSet(true);
    }
  }, [camera, ratio, screenRatio]);

  /** Callback for when the camera is ready. */
  const setCameraReady = useCallback(async () => {
    if (!isRatioSet) {
      await prepareRatio();
    }
  }, [isRatioSet, prepareRatio]);

  return (
    <View style={{ position: 'relative' }}>
      <StatusBar translucent style="light" />
      <View
        style={{
          position: 'absolute',
          top: top,
          left: left,
          height: qrHeight,
          width: qrWidth,
          zIndex: 2,
          pointerEvents: 'none'
        }}
      >
        <Image
          source={require('pupil-app/assets/svg/QRGuide.svg')}
          style={{ height: qrHeight, width: qrWidth }}
        />
      </View>
      <View style={{ flex: 1 }}>
        <Camera
          onCameraReady={setCameraReady}
          ratio={ratio}
          ref={ref => {
            setCamera(ref);
          }}
          style={[StyleSheet.absoluteFill, { height: height }]}
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          barCodeScannerSettings={Platform.OS === 'web' ? { barCodeTypes: ['qr'] } : undefined}
        />
      </View>
    </View>
  );
}
