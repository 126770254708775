import './polyfill/Intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AppState, StyleSheet, Image, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Navigation from 'pupil-app/src/navigation';
import { Provider as PaperProvider } from 'react-native-paper';
import { lightTheme } from 'common/src/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { Roboto_400Regular, Roboto_500Medium } from '@expo-google-fonts/roboto';
import {
  LibreBaskerville_400Regular,
  LibreBaskerville_700Bold
} from '@expo-google-fonts/libre-baskerville';
import * as Localization from 'expo-localization';
import type { Locales } from './i18n/i18n-types';
import TypesafeI18n from './i18n/i18n-react';
import { isLocale } from './i18n/i18n-util';
import { loadLocaleAsync } from './i18n/i18n-util.async';
import type { Locales as LocalesCommon } from 'common/src/i18n/i18n-types';
import TypesafeI18nCommon from 'common/src/i18n/i18n-react';
import { isLocale as isLocaleCommon } from 'common/src/i18n/i18n-util';
import { loadLocaleAsync as loadLocaleAsyncCommon } from 'common/src/i18n/i18n-util.async';
import 'setimmediate'; // Is needed for "react-native-gesture-handler"
import { Portal } from 'common/src/components/portal';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { RootSiblingParent } from 'react-native-root-siblings';
import { useNetworkStatus } from './hooks/useNetworkStatus';
import { updateQuizSession } from './network/quizSession';
import { useSubmitAllQueuedQuestions } from './storage/useQuestionQueueStore';
import { getPlayer } from 'common/src/utils/Audio';
import useIsMobileBrowserAndPortrait from './hooks/useIsMobileBrowserAndPortrait';
import showAlert from './utils/showAlert';
import * as Sentry from '@sentry/react-native';
import AppConfig from '../app.json';
import ENV from './ENV';
import useQuizSessionStore from './storage/useQuizSessionStore';

// Initialise Sentry Agent
Sentry.init({
  dsn: 'https://49a766992748c45e05c103da909772ea@o1092821.ingest.us.sentry.io/4507883527077888',
  environment: ENV.SENTRY_ENV,
  debug: ENV.SENTRY_ENV !== 'production',
  release: `infinity@${AppConfig.expo.version}`
});

// Don't hide the splash screen until we say so, as we might be loading fonts or other things.
SplashScreen.preventAutoHideAsync();

export default function App() {
  ////
  // Fonts
  ////

  const [fontsLoaded, fontError] = useFonts({
    'Biotif-Medium': require('common/src/assets/fonts/Biotif-Medium.ttf'),
    'Biotif-Regular': require('common/src/assets/fonts/Biotif-Regular.ttf'),
    'Biotif-Bold': require('common/src/assets/fonts/Biotif-Bold.ttf'),
    'Biotif-ExtraBold': require('common/src/assets/fonts/Biotif-ExtraBold.ttf'),
    'Biotif-Black': require('common/src/assets/fonts/Biotif-Black.ttf'),
    Roboto_400Regular,
    Roboto_500Medium,
    LibreBaskerville_400Regular,
    LibreBaskerville_700Bold,
    'White_Rose_Noto-Bold': require('common/src/assets/fonts/White_Rose_Noto_bold.ttf'),
    'White_Rose_Noto-Regular': require('common/src/assets/fonts/White_Rose_Noto_regular.ttf')
  });

  ////
  // Locales
  ////

  const [locale, setLocale] = useState<(Locales & LocalesCommon) | null>(null);

  const updateLocaleIfNecessary = useCallback(async () => {
    // desiredLocale will fall-back to 'en' if none of the device/browser's locales are found in our supported Locales array
    const desiredLocale =
      Localization.locales.find(
        (x): x is Locales & LocalesCommon => isLocale(x) && isLocaleCommon(x)
      ) ?? 'en';

    if (locale !== desiredLocale) {
      await loadLocaleAsync(desiredLocale);
      await loadLocaleAsyncCommon(desiredLocale);
      setLocale(desiredLocale);
    }
  }, [locale]);

  // On Android, the locale can change without the App being restarted. Listen to AppState changes.
  useEffect(() => {
    const subscription = AppState.addEventListener('change', () => updateLocaleIfNecessary());
    return () => subscription.remove();
  }, [updateLocaleIfNecessary]);

  // Load the locale if needed
  updateLocaleIfNecessary();

  ////
  // Send queued quiz results
  ////

  const submitAllQueuedQuestions = useSubmitAllQueuedQuestions(updateQuizSession);

  const { isConnected } = useNetworkStatus();
  useEffect(() => {
    if (isConnected) {
      submitAllQueuedQuestions();
    }
  }, [isConnected, submitAllQueuedQuestions]);

  ////
  // Clear unneeded data from the useQuizSessionStore, once on startup.
  ////

  const deleteExpiredCacheEntries = useQuizSessionStore(state => state.deleteExpiredCacheEntries);
  useEffect(() => {
    deleteExpiredCacheEntries();
  }, [deleteExpiredCacheEntries]);

  ////
  // Splash screen
  //
  // We show a "fake" splash screen using <Image>. On native, there is also a native splash screen (controlled by
  // expo-splash-screen) which we need to hide.
  //
  // Once the app is ready and the react-splash-screen has loaded.
  // - (Native only) hide the native splash screen with `SplashScreen.hideAsync()`
  // - (Web only) wait for 2 seconds
  // - Fade out the fake splash screen over 0.5 seconds
  ////

  // set splash screen visible initially
  const [showFakeSplash, setShowFakeSplash] = useState<boolean>(true);
  const opacity = useSharedValue(1);

  // Fake splash screen has loaded, fade out the splash screens
  const onFakeSplashLoaded = useCallback(async () => {
    if (Platform.OS !== 'web') {
      // Hide the native splash screen.
      SplashScreen.hideAsync();
    } else if (Platform.OS === 'web') {
      // Wait 2 seconds
      await new Promise(r => setTimeout(r, 2000));
    }

    // Fade out over 0.5s
    opacity.value = withTiming(0, { duration: 500, easing: Easing.linear }, finished => {
      if (finished) runOnJS(setShowFakeSplash)(false);
    });
  }, [opacity]);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value
  }));

  ////
  // Show alert if this is mobile and in portrait. Only show after the splash screen has hidden
  ////
  const isMobileBrowserAndPortrait = useIsMobileBrowserAndPortrait();
  const hasShownMobileBrowserAndPortraitAlert = useRef(false);
  useEffect(() => {
    if (
      !showFakeSplash &&
      isMobileBrowserAndPortrait &&
      !hasShownMobileBrowserAndPortraitAlert.current
    ) {
      hasShownMobileBrowserAndPortraitAlert.current = true;
      showAlert(
        'This app works best in landscape!\nPlease rotate your device - you may need to unlock device rotation.'
      );
    }
  }, [isMobileBrowserAndPortrait, showFakeSplash]);

  ////
  // Main app JSX
  ////

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const player = getPlayer();
      player.setSoundEnabled(true);
      player.playSound('logo');
    }
  }, []);

  const theme = lightTheme;

  if ((!fontsLoaded && !fontError) || locale === null) {
    return null;
  }

  return (
    <GestureHandlerRootView style={styles.rootView}>
      {/* Use i18n strings from common package */}
      <TypesafeI18nCommon locale={locale}>
        {/* Use i18n strings from this package */}
        <TypesafeI18n locale={locale}>
          {/* In future, we might not need this Provider as there is already one in PaperProvider. */}
          <SafeAreaProvider>
            <PaperProvider theme={theme}>
              {/* Needed by react-native-root-toast. Does the same thing as Portal.Host below. */}
              <RootSiblingParent>
                {/* Needed by common */}
                <Portal.Host>
                  {/* In future, it would be better to not control the status bar via expo-status-bar, and
                    instead use react-navigation's screen options. This looks slightly better (it animates
                    more nicely), however it doesn't work with expo go. We would need to switch as a team
                    to the newer expo dev client. See this discussion for more info:
                    https://github.com/react-navigation/react-navigation/discussions/11902 */}
                  <StatusBar translucent style="dark" />
                  {/* Manually show a splash screen for two seconds */}
                  {showFakeSplash && (
                    <Animated.View style={[styles.splashContainer, animatedStyle]}>
                      <Image
                        source={require('pupil-app/assets/AppSplash.png')}
                        style={{
                          flex: 1,
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#002E63'
                        }}
                        resizeMode="contain"
                        fadeDuration={0}
                        onLoadEnd={onFakeSplashLoaded}
                      />
                    </Animated.View>
                  )}
                  {/* The majority of the content is here, in Navigation, via "screens". */}
                  <Navigation
                    theme={{
                      colors: {
                        background: 'white',
                        primary: theme.colors.primary,
                        card: theme.colors.background,
                        border: theme.colors.primary,
                        text: theme.colors.primary,
                        notification: theme.colors.background
                      },
                      dark: false
                    }}
                  />
                </Portal.Host>
              </RootSiblingParent>
            </PaperProvider>
          </SafeAreaProvider>
        </TypesafeI18n>
      </TypesafeI18nCommon>
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  rootView: { flex: 1 },
  splashContainer: {
    backgroundColor: '#002E63',
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
});
