import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { getRandomUniqueWeightObjects, randomObjectsWithWeights } from '../../../../utils/objects';
import { sortNumberArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdQ',
  description: 'bdQ',
  keywords: ['Full', 'Empty', 'Capacity', 'Compare'],
  schema: z.object({
    smallestOrGreatest: z.enum(['Smallest', 'Greatest']),
    randomObjects: z
      .array(z.object({ icon: z.string(), weight: z.number().int(), scale: z.number() }))
      .length(4)
  }),
  simpleGenerator: () => {
    const { smallestOrGreatest, randomObjects } = rejectionSample(
      () => {
        const smallestOrGreatest = getRandomFromArray(['Smallest', 'Greatest'] as const);

        const randomObjects = getRandomUniqueWeightObjects(4);
        return { smallestOrGreatest, randomObjects };
      },

      ({ smallestOrGreatest, randomObjects }) => {
        // Ensure there's at least a gap of 2 in weight between objects
        // Ensures it's not too difficult to distinguish between similar sizes
        if (smallestOrGreatest === 'Smallest') {
          const [smallestObject, secondSmallestObject] = randomObjects.sort(
            (a, b) => a.weight - b.weight
          );

          return Math.abs(smallestObject.weight - secondSmallestObject.weight) > 1;
        } else {
          const [greatestObject, secondGreatestObject] = randomObjects.sort(
            (a, b) => b.weight - a.weight
          );

          return Math.abs(greatestObject.weight - secondGreatestObject.weight) > 1;
        }
      }
    );

    return { smallestOrGreatest, randomObjects };
  },
  Component: props => {
    const {
      question: { smallestOrGreatest, randomObjects },
      translate
    } = props;

    const sortedObjectsByWeight =
      smallestOrGreatest === 'Smallest'
        ? [...randomObjects].sort((a, b) => a.weight - b.weight)
        : [...randomObjects].sort((a, b) => b.weight - a.weight);

    const correctAnswer = sortedObjectsByWeight[0].weight;

    return (
      <QF11SelectImagesUpTo4
        title={
          smallestOrGreatest === 'Greatest'
            ? translate.ks1Instructions.selectTheContainerWithTheGreatestCapacity()
            : translate.ks1Instructions.selectTheContainerWithTheSmallestCapacity()
        }
        pdfTitle={
          smallestOrGreatest === 'Greatest'
            ? translate.ks1PDFInstructions.tickTheContainerWithTheGreatestCapacity()
            : translate.ks1PDFInstructions.tickTheContainerWithTheSmallestCapacity()
        }
        testCorrect={[correctAnswer]}
        numItems={4}
        renderItems={({ dimens }) => {
          return randomObjects.map(randomObject => ({
            value: randomObject.weight,
            component: (
              <AssetSvg
                name={randomObject.icon as SvgName}
                width={dimens.width * randomObject.scale}
                height={dimens.height * randomObject.scale}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdR',
  description: 'bdR',
  keywords: ['Less than', 'Greater than', 'Equal to', 'Capacity', 'Compare'],
  schema: z.object({
    containerSizeA: z.number().min(2).max(6),
    containerSizeB: z.number().min(2).max(6)
  }),
  simpleGenerator: () => {
    const containerSizeA = randomIntegerInclusive(2, 6);
    const containerSizeB = randomIntegerInclusive(2, 6);

    return { containerSizeA, containerSizeB };
  },
  Component: props => {
    const {
      question: { containerSizeA, containerSizeB },
      translate
    } = props;

    const lhsComponent = <AssetSvg name="Containers/container" width={50 * containerSizeA} />;
    const rhsComponent = <AssetSvg name="Containers/container" width={50 * containerSizeB} />;

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheCapacityOfTheContainers()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheCapacityOfTheContainers()}
        items={['<', '>', '=']}
        itemVariant="square"
        statementStyle={{ justifyContent: 'center' }}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(containerSizeA, containerSizeB),
            lhsComponent: lhsComponent,
            rhsComponent: rhsComponent
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdS',
  description: 'bdS',
  keywords: ['Capacity', 'Compare', 'Order'],
  schema: z.object({
    randomObjects: z.array(
      z.object({
        icon: z.string(),
        weight: z.number().int(),
        scale: z.number(),
        pdfPixelScale: z.number()
      })
    ),
    order: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const randomObjects = getRandomSubArrayFromArray(randomObjectsWithWeights, 3);
    const order = getRandomFromArray(['ascending', 'descending'] as const);

    return { randomObjects, order };
  },
  Component: props => {
    const {
      question: { randomObjects, order },
      translate
    } = props;

    const [randomObjectA, randomObjectB, randomObjectC] = randomObjects;

    const items = shuffle(
      [
        {
          value: randomObjectA.weight,
          iconString: randomObjectA.icon,
          scale: randomObjectA.scale
        },
        {
          value: randomObjectB.weight,
          iconString: randomObjectB.icon,
          scale: randomObjectB.scale
        },
        {
          value: randomObjectC.weight,
          iconString: randomObjectC.icon,
          scale: randomObjectC.scale
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={
          order === 'ascending'
            ? translate.ks1Instructions.dragCardsOrderObjectsStartWithSmallestCapacity()
            : translate.ks1Instructions.dragCardsOrderObjectsStartWithGreatestCapacity()
        }
        pdfTitle={
          order === 'ascending'
            ? translate.ks1PDFInstructions.orderObjectsStartWithSmallestCapacity()
            : translate.ks1PDFInstructions.orderObjectsStartWithGreatestCapacity()
        }
        testCorrect={sortNumberArray(
          [randomObjectA.weight, randomObjectB.weight, randomObjectC.weight],
          order
        )}
        items={items.map(({ value, iconString, scale }) => ({
          value,
          component: <AssetSvg name={iconString as SvgName} width={190 * scale} />
        }))}
        draggableVariant="tallRectangle"
        topLabel={
          order === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          order === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareCapacity',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
