import { ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import { InternalNodeContext, noop, SetState, StateLeafNode } from './helpers';

export type StateTreeLeafProps<T extends StateLeafNode = StateLeafNode> = {
  children: (props: { state: T; setState: SetState<T> }) => ReactNode;
  /** The ID for this node. This determines where in the parent node its state is saved. */
  id: string;
  /** The initial value for the state to have, if it hasn't been set yet. */
  defaultState?: T;
  /**
   * A function to evaluate whether the component has been answered _correctly_. Omitting this prop will mean that
   * this node always counts as correct, which might be what you want to do if you test at the group.
   */
  testCorrect?: (value: T) => boolean;
  /**
   * A function to evaluate whether the component has been answered. For example, if the state represents input
   * boxes, then checking that at least one is non-empty. Omitting this prop will mean that this node always counts
   * as complete, which may be what you want e.g. if the node's default value is a valid answer.
   */
  testComplete?: (value: T) => boolean;
};

/**
 * A node accessing a single value in the state tree (i.e. it's a leaf node of the tree).
 *
 * N.B.: Prefer using `withStateHOC` where possible instead of using this component directly, as it produces a cleaner
 * interface.
 *
 * Usage:
 *
 * ```tsx
 * <StateTreeLeaf id='myComponent' defaultState={0} testCorrect={5}>{({state, setState}) =>
 *   <MyComponent foo={state} setFoo={setState}/>
 * }</StateTreeLeaf>
 * ```
 */
export function StateTreeLeaf<T extends StateLeafNode = StateLeafNode>(
  props: StateTreeLeafProps<T>
) {
  const { id, defaultState, children } = props;
  const parent = useContext(InternalNodeContext);

  // Set our default state, if our parent didn't already do it.
  useEffect(() => {
    if (!(id in parent.state)) {
      parent.reportChildState(id)(defaultState);
    }
  }, [defaultState, id, parent]);

  if (!(id in parent.state)) {
    // Return uninteractive version of the UI, while we wait for the default to be set above.
    return (
      <>
        {children({
          state: defaultState as T,
          setState: noop
        })}
      </>
    );
  } else {
    return <StateTreeLeafInner {...props} />;
  }
}

function StateTreeLeafInner<T extends StateLeafNode = StateLeafNode>(props: StateTreeLeafProps<T>) {
  const { children, id, testCorrect = () => true, testComplete = () => true } = props;
  const parent = useContext(InternalNodeContext);

  const state = useMemo(() => parent.getChildState(id) as T, [id, parent]);
  const setState = useMemo(() => parent.reportChildState(id) as SetState<T>, [id, parent]);
  // Work out this leaf node's completeness
  const complete = testComplete(state);

  // If this leaf node's completeness has changed, inform the callback.
  const previousComplete = useRef<boolean | null>(null);
  useEffect(() => {
    if (complete !== previousComplete.current) {
      previousComplete.current = complete;
      parent.reportChildComplete(id)(complete);
    }
  }, [complete, parent, id]);

  // Only check leaf node's correctness if leaf node is complete
  let correct = false;
  if (complete) {
    // Work out this leaf node's correctness
    correct = testCorrect(state);
  }

  // If this leaf node's correctness has changed, inform the callback.
  const previousCorrect = useRef<boolean | null>(null);
  useEffect(() => {
    if (correct !== previousCorrect.current) {
      previousCorrect.current = correct;
      parent.reportChildCorrect(id)(correct);
    }
  }, [correct, parent, id]);

  return <>{children({ state, setState })}</>;
}
