import { StatusBar } from 'expo-status-bar';
import { useCallback, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { RootStackProps } from '../navigation/types';
import { countRange } from 'common/src/utils/collections';
import FilledButton from '../components/FilledButton';
import Spinner from 'common/src/components/molecules/Spinner';
import useLoginStore from '../storage/useLoginStore';
import { useHeaderHeight } from '@react-navigation/elements';
import useBreakpoints from '../hooks/useBreakpoints';
import useKeyboard from '../hooks/useKeyboard';
import useScreenDimensions from '../hooks/useScreenDimensions';
import { resolveFont } from 'common/src/theme/fonts';
import { colors } from 'common/src/theme/colors';
import { validateSchoolCode } from '../network/schoolCode';
import { Image } from 'expo-image';
import { useI18nContext } from '../i18n/i18n-react';
import { type LocalizedString } from 'typesafe-i18n';
import TextInputRow from '../components/TextInputRow';
import ENV from '../ENV';

const CODE_LENGTH = 6;

export default function EnterSchoolCodeScreen({
  navigation,
  route: { params }
}: RootStackProps<'EnterSchoolCode'>) {
  const translate = useI18nContext().LL;

  const [code, setCode] = useState<string[]>(countRange(CODE_LENGTH).map(() => ''));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    displayString: LocalizedString;
    /** Whether the error is because the code is invalid (rather than due to some other error) */
    isInvalid?: boolean;
  } | null>(null);

  const setSchool = useLoginStore(state => state.setSchool);
  const loggedInUser = useLoginStore(state => state.loggedInUser);

  const screenDimensions = useScreenDimensions();
  const { resize, type } = useBreakpoints();
  const keyboard = useKeyboard();
  const headerHeight = useHeaderHeight();

  const onContinueClicked = useCallback(async () => {
    const schoolCode = code.join('').toUpperCase();

    // Local validation - check it's exactly 6 letters
    if (!/^[A-Z]{6}$/.test(schoolCode)) {
      setError({
        displayString: translate.enterCodeOrPINScreen.invalidSchoolCodeError(),
        isInvalid: true
      });
      return;
    }

    // API validation - check the school exists
    setLoading(true);
    const response = await validateSchoolCode(schoolCode);

    if (typeof response === 'string') {
      // API validation failed, show an error message and hide the loading spinner
      setLoading(false);

      switch (response) {
        case 'no infinity subscription':
          setError({
            displayString:
              translate.enterCodeOrPINScreen.accountIssuePleaseSpeakToYourTeacherError()
          });
          return;
        case 'invalid response':
        case 'unknown error':
        case 'http error':
          setError({
            displayString: translate.enterCodeOrPINScreen.somethingWentWrongError()
          });
          return;
        case 'network error':
          setError({ displayString: translate.enterCodeOrPINScreen.internetConnectionLostError() });
          return;
        case 'not found':
          setError({
            displayString: translate.enterCodeOrPINScreen.schoolNotFoundError(),
            isInvalid: true
          });
          return;
        default:
          // Produces TS error and throws runtime error if we missed a case
          throw new Error(`Logic error: unreachable (${response satisfies never})`);
      }
    } else {
      // Success - navigate to next screen
      setSchool({
        code: schoolCode,
        name: response.name,
        hasInfinityPlus: response.hasInfinityPlus
      });

      const hasInfinityPlus = response.hasInfinityPlus;

      // [Plus feature enabled only] This code exposes the new infinity plus screens, so disable it for non-plus
      // builds of the app.
      // If school has infinity plus, and they were trying to enter a Quiz PIN, redirect to pupil login
      if (
        ENV.PLUS_FEATURE &&
        hasInfinityPlus &&
        loggedInUser === undefined &&
        params.nextScreen === 'EnterQuizPIN'
      ) {
        navigation.replace('EnterPupilAccessCode', { onSuccessGoToEnterQuizPIN: true });
      } else {
        navigation.replace(params.nextScreen);
      }
    }
  }, [
    code,
    loggedInUser,
    navigation,
    params.nextScreen,
    setSchool,
    translate.enterCodeOrPINScreen
  ]);

  // Available height, after subtracting the keyboard and header (assumes app is full screen)
  const availableHeight =
    screenDimensions.height - headerHeight - (keyboard.keyboardShown ? keyboard.keyboardHeight : 0);

  // When keyboard is showing, there might not be enough space to show the continue button
  const showShorterLayoutBecauseKeyboard = keyboard.keyboardShown && availableHeight < 300 * resize;

  const loadingSpinner = (
    <View
      style={[
        StyleSheet.absoluteFill,
        { backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', gap: 24 }
      ]}
    >
      <Spinner height={showShorterLayoutBecauseKeyboard ? 100 * resize : 156 * resize} />
      {type !== 'mobile' && (
        <Text
          style={resolveFont({
            fontFamily: 'White_Rose_Noto',
            fontWeight: '400',
            fontSize: 32,
            lineHeight: 48,
            color: colors.prussianBlue
          })}
        >
          {translate.loadingEllipsis()}
        </Text>
      )}
    </View>
  );

  const textInputRow = (
    <TextInputRow
      shape="lll-lll"
      code={code}
      setCode={action => {
        setCode(action);
        // Whenever the user changes the code, hide any error messages
        setError(null);
      }}
      onFinalEnter={onContinueClicked}
      // Highlight the input boxes as errored if it was the code itself being invalid that caused the error
      error={error?.isInvalid}
      autoFocus
    />
  );

  const errorText = (
    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 12 }}>
      {error !== null && (
        <Image
          source={require('pupil-app/assets/svg/InfoIcon.svg')}
          style={{ width: 27 * resize, height: 28 * resize }}
        />
      )}
      <Text
        testID="ERROR_MESSAGE"
        style={resolveFont({
          fontFamily: 'White_Rose_Noto',
          fontWeight: '700',
          fontSize: 21.67 * resize,
          lineHeight: 32.5 * resize,
          color: colors.danger
        })}
      >
        {error?.displayString}
      </Text>
    </View>
  );

  const continueButton = (
    <FilledButton
      icon={() => (
        <Image
          source={require('pupil-app/assets/svg/RightArrow.svg')}
          style={{ width: 48 * resize, height: 48 * resize }}
        />
      )}
      iconOnRight
      onPress={onContinueClicked}
      buttonWidth={320 * resize}
    >
      Continue
    </FilledButton>
  );

  return (
    <>
      <StatusBar style="dark" />
      {showShorterLayoutBecauseKeyboard ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: Platform.OS === 'ios' ? keyboard.keyboardHeight : 0,
            minWidth: 760
          }}
        >
          {textInputRow}
          {errorText}
          {loading && loadingSpinner}
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 32 * resize,
            minWidth: 760
          }}
        >
          {textInputRow}
          {error !== null && errorText}
          {continueButton}
          {loading && loadingSpinner}
        </View>
      )}
    </>
  );
}
