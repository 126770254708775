export default {
  aClockShowsTheTimeNumOClockAnsTimesEachDay:
    "A clock shows the time {0} o'clock <ans/> times a day.",
  aNewDayBeginsAtAns: 'A new day begins at <ans/>',
  ansIsAPart: '<ans/> is a part.',
  aQuarterOfXIsAns: 'A quarter of {0:number|numberFormat} is <ans/>',
  ansGroupsOfAnsEqualsX: '<ans/> groups of <ans/> = {0:number|numberFormat}',
  ansHasNumTensAndNumOnes:
    '<ans/> has {0:number|numberFormat} tens and {1:number|numberFormat} one{{s}}.',
  ansHasNumOnesAndNumTens:
    '<ans/> has {0:number|numberFormat} one{{s}} and {1:number|numberFormat} tens.',
  ansHour: '<ans/> hour',
  ansHours: '<ans/> hours',
  ansIs12OClockAtNight: "<ans/> is 12 o'clock at night.",
  ansIs12OClockInTheMiddleOfTheDay: "<ans/> is 12 o'clock in the middle of the day.",
  ansIsTheBeginningOfANewDay: '<ans/> is the beginning of a new day.',
  ansLotsOfAnsEqualsX: '<ans/> lots of <ans/> = {0:number|numberFormat}',
  ansMinutes: '<ans/> minutes',
  ansMinutesPastAns: '<ans/> minutes past <ans/>',
  ansMinutesToAns: '<ans/> minutes to <ans/>',
  ansOClock: "<ans/> o'clock",
  ansPence: '<ans/> pence',
  anyArrangementOfMoreThanNumCounters:
    'Any arrangement of more than {0:number|numberFormat} counter{{s}}.',
  anyArrangementOfFewerThanNumCounters:
    'Any arrangement of fewer than {0:number|numberFormat} counter{{s}}.',
  doubleX: 'Double {0:number|numberFormat}',
  doubleAnsIsAns: 'Double <ans/> is <ans/>',
  doubleAnsIsNum: 'Double <ans/> is {0:number|numberFormat}',
  doubleNumIsAns: 'Double {0:number|numberFormat} is <ans/>',
  doubleXIsEqualTo: 'Double {0:number|numberFormat} is equal to',
  dragACardToCompleteTheSentence: 'Drag a card to complete the sentence.',
  firstThereWereXBirdsInTree: 'First there were {0:number|numberFormat} birds in a tree.',
  firstThereWereXCarsInCarPark: 'First there were {0:number|numberFormat} cars in a car park.',
  firstThereWereXCookies: 'First there were {0:number|numberFormat} cookies.',
  groupAnsIsTheGreatest: 'Group <ans/> is the greatest.',
  groupAnsIsTheSmallest: 'Group <ans/> is the smallest.',
  halfPastAns: 'half past <ans/>',
  halfOfXIsAns: 'Half of {0:number|numberFormat} is <ans/>',
  halfOfXIsEqualTo: 'Half of {0:number|numberFormat} is equal to',
  howManyGlassesFillTwoJugsAns: 'How many glasses fill 2 jugs? <ans/>',
  name1HasAnsCubesThanName2: '{name1} has <ans/> cubes than {name2}.',
  nowThereAreAnsBirdsLeft: 'Now there are <ans/> birds left.',
  nowThereAreAnsCarsLeft: 'Now there are <ans/> cars left.',
  nowThereAreAnsCookies: 'Now there are <ans/> cookies.',
  nowThereIsAnsBirdLeft: 'Now there is <ans/> bird left.',
  nowThereIsAnsCarLeft: 'Now there is <ans/> car left.',
  nowThereIsAnsCookie: 'Now there is <ans/> cookie.',
  numEqualsAnsTensAnsOnes: '{0:number|numberFormat} = <ans/> tens and <ans/> ones.',
  numHasAnsTensAndAnsOnes: '{0:number|numberFormat} has <ans/> tens and <ans/> one{{s}}.',
  numHasAnsOnesAndAnsTens: '{0:number|numberFormat} has <ans/> one{{s}} and <ans/> tens.',
  num12OClockAtNightIsCalledAns: "12 o'clock at night is called <ans/>",
  num12OClockInTheMiddleOfTheDayIsCalledAns: "12 o'clock in the middle of the day is called <ans/>",
  numberAAnsNumberB: '{numberA:number|numberFormat} <ans/> {numberB:number|numberFormat}',
  numberAIsAnsNumberB: '{numberA:number|numberFormat} is <ans/> {numberB:number|numberFormat}',
  numberHoursAndNumberMinutesEqualsAnsMinutes:
    '{0:number|numberFormat} hour{{s}} and {1:number|numberFormat} minute{{s}} = <ans/> minutes',
  numberMinutesEqualsAnsHourAndAnsMinutes:
    '{0:number|numberFormat} minute{{s}} = <ans/> hour and <ans/> minutes',
  onAnsXNameWasYActivity: 'On <ans/> {0:string} was {1:string|lower}.',
  onAnsXNameWentYActivity: 'On <ans/> {0:string} went {1:string|lower}.',
  onAnsXNameWentToYActivity: 'On <ans/> {0:string} went to {1:string|lower}.',
  oneLessThanXIsAns: '1 less than {0:number|numberFormat} is <ans/>',
  oneLessThanAnsIsX: '1 less than <ans/> is {0:number|numberFormat}',
  oneMoreThanXIsAns: '1 more than {0:number|numberFormat} is <ans/>',
  oneMoreThanAnsIsX: '1 more than <ans/> is {0:number|numberFormat}',
  quarterPast: 'quarter past <ans/>',
  quarterTo: 'quarter to <ans/>',
  stringAIsAnsStringB: '{numberA:string|lower} is <ans/> {numberB:string|lower}',
  theHourHandMovesRoundTheClockAnsTimesInADay:
    'The hour hand moves round the clock <ans/> times in a day.',
  theCapacityOfTheBottleIsAnsXGlasses:
    'The capacity of the bottle is <ans/> {0:number|numberFormat} glasses.',
  theDayBeforeOrAfterXDayIsAns: 'The day {0:string|lower} {1} is <ans />',
  theMassOfTheXShapeIsAnsMassOfTheYShape:
    'The mass of the {0:string|lower} is <ans/><br/> the mass of the {1:string|lower}.',
  theMassOfTheXMassObjectIsAnsCubes: 'The mass of the {0:string|lower} is <ans/> cubes.',
  theMonthBeforeOrAfterXMonthIsAns: 'The month {0:string|lower} {1} is <ans/>',
  theShapeHasTurnedAAnsTurn: 'The shape has turned a <ans/> turn.',
  thenXOfTheBirdsFlewAway: 'Then {0} of the birds flew away.',
  thenXOfTheCarsDriveAway: 'Then {0} of the cars drive away.',
  thenXCookiesWereEaten: 'Then {0} cookies were eaten.',
  thenXCookieWasEaten: 'Then {0} cookie was eaten.',
  theShapeIsToTheAnsOfTheShape: 'The {0:string|lower} is to the <ans/> of the {1:string|lower}.',
  theShapeIsToTheLeftOfAns: 'The {0:string|lower} is to the left of the <ans/>.',
  theShapeIsToTheRightOfAns: 'The {0:string|lower} is to the right of the <ans/>.',
  theTrainHasAnsX: 'The train has <ans/> {0}.',
  theTrainHasNumAns: 'The train has {0:number|numberFormat} <ans/>',
  theXContainerIsAns: 'The {0:string|lower} is <ans/>',
  theXShapeIsAnsTheYShape: 'The {0:string|lower} is <ans/> the {1:string|lower}.',
  thereAreAnsAnimalsAltogether: 'There are <ans/> animals altogether.',
  thereAreAnsApplesAltogether: 'There are <ans/> apples altogether.',
  thereAreAnsBalloonsAltogether: 'There are <ans/> balloons altogether.',
  thereAreAnsBananasAltogether: 'There are <ans/> bananas altogether.',
  thereAreAnsColumns: 'There are <ans/> columns.',
  thereAreAnsColumnsOfAns: 'There are <ans/> columns of <ans/>',
  thereAreAnsCookiesAltogether: 'There are <ans/> cookies altogether.',
  thereAreAnsCountersAltogether: 'There are <ans/> counters altogether.',
  thereAreAnsCountersInEachGroup: 'There are <ans/> counters in each group.',
  thereAreAnsEqualGroupsOfAns: 'There are <ans/> equal groups of <ans/>',
  thereAreAnsFlowersAltogether: 'There are <ans/> flowers altogether.',
  thereAreAnsGroups: 'There are <ans/> groups.',
  thereAreAnsGroupsWithAnsCountersInEachGroup:
    'There are <ans/> groups with <ans/> counters in each group.',
  thereAreAnsGroupsOfTenXAndAnsMoreY: 'There are <ans/> groups of ten {0} and <ans/> more {0}.',
  thereAreAnsHoursInADay: 'There are <ans/> hours in a day.',
  thereAreAnsHoursShownOnAClockFace: 'There are <ans/> hours shown on a clock face.',
  thereAreAnsMarblesAltogether: 'There are <ans/> marbles altogether.',
  thereAreAnsMuffinsAltogether: 'There are <ans/> muffins altogether.',
  thereAreAnsObjectInEachBox: 'There are <ans/> {0:string|lower} in each box.',
  thereAreAnsObjectInTotal: 'There are <ans/> {0:string|lower} in total.',
  thereAreAnsOnesInNum: 'There are <ans/> ones in {0:number|numberFormat}',
  thereAreAnsPearsAltogether: 'There are <ans/> pears altogether.',
  thereAreAnsPencilsAltogether: 'There are <ans/> pencils altogether.',
  thereAreAnsPiecesOfFruitAltogether: 'There are <ans/> pieces of fruit altogether.',
  thereAreAnsRows: 'There are <ans/> rows.',
  thereAreAnsRowsOfAns: 'There are <ans/> rows of <ans/>',
  thereAreAnsSocksAltogether: 'There are <ans/> socks altogether.',
  thereAreAnsSweetsAltogether: 'There are <ans/> sweets altogether.',
  thereAreAnsTensAndAnsOnesTheNumberIsAns:
    'There are <ans/> tens and <ans/> ones.<br/>The number is <ans/>',
  thereAreAnsTensInNum: 'There are <ans/> tens in {0:number|numberFormat}',
  thereAreAnsX: 'There are <ans/> {0:string|lower}.',
  thereAreAnsXCoins: 'There are <ans/> {0:string|lower} coins.',
  thereAreAnsXNotes: 'There are <ans/> {0:string|lower} notes.',
  thereIsAnsObjectInEachBox: 'There is <ans/> {0:string|lower} in each box.',
  thereIsAnsX: 'There is <ans/> {0:string|lower}.',
  useTheCardsToCompleteTheSentence: 'Use the cards to complete the sentence.',
  wholeIsAns: 'The whole is <ans/>',
  xEqualGroupsOfY: '{0:number|numberFormat} equal groups of {1:number|numberFormat}',
  xGroupsOfYEquals: '{0:number|numberFormat} groups of {1:number|numberFormat} = <ans/>',
  xLessThanYisAns: '{0:number|numberFormat} less than {1:number|numberFormat} is <ans/>',
  xLessThanAnsIsY: '{0:number|numberFormat} less than <ans/> is {1:number|numberFormat}',
  xLotsOfYEquals: '{0:number|numberFormat} lots of {1:number|numberFormat} = <ans/>',
  xMoreThanAnsIsY: '{0:number|numberFormat} more than <ans/> is {1:number|numberFormat}',
  xMoreThanYisAns: '{0:number|numberFormat} more than {1:number|numberFormat} is <ans/>',
  xContainerHasAnsJuiceThanContainerY:
    '{0:string} {1:string} has <ans/> juice than {0:string|lower} {2:string}.',
  xObjectsAreSharedEquallyBetweenAnsBoxes:
    '{0:number|numberFormat} {1:string|lower} are shared equally between <g><ans/> boxes</g>.'
};
