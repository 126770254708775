import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { countRange, filledArray, sortNumberArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import {
  getCounterArrangementScale,
  getUniqueArrangements
} from '../../../../components/question/representations/CounterBoxArrangement/counterArrangements';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import { getGroupSvgName } from '../../../../utils/objectsImages';
import QF60DragCountersIntoGroups from '../../../../components/question/questionFormats/QF60DragCountersIntoGroups';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biG',
  description: 'biG',
  keywords: ['Equal', 'Groups'],
  schema: z.object({
    options: z
      .object({
        item: z.enum(['Apple', 'Pear', 'Banana', 'Muffin', 'Cookie', 'Counters']),
        groups: z.number().int().min(2).max(6),
        inEachGroup: z.number().int().min(2).max(5),
        randomSeed: z.number(),
        isDots: z.boolean()
      })
      .array()
      .length(3),
    dotsArrangements: z.array(z.array(z.array(z.boolean()))).length(2),
    dotsArrangementsIndex: z.array(z.number()),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const inEach1 = randomIntegerInclusive(2, 5);
    const groups1 = randomIntegerInclusive(2, 5, { constraint: x => x !== inEach1 });

    const inEach2 = groups1;
    const groups2 = inEach1;

    const groupsSame = getRandomBoolean();
    const inEach3 = groupsSame
      ? randomIntegerInclusive(2, 5, { constraint: x => x !== inEach2 && x !== inEach1 })
      : inEach2;
    const groups3 = groupsSame
      ? groups2
      : randomIntegerInclusive(2, 6, { constraint: x => x !== groups2 && x !== groups1 });

    const dotOption = {
      item: 'Counters' as const,
      groups: groups1,
      inEachGroup: inEach1,
      randomSeed: randomIntegerInclusive(1, 10) * Math.random() * 10000,
      isDots: true
    };

    const dotsArrangements = getUniqueArrangements(inEach1, 2) as boolean[][][];
    const numberOfArrangement1 = randomIntegerInclusive(1, groups1 - 1);
    const dotsArrangementsIndex = randomUniqueIntegersInclusive(
      0,
      groups1 - 1,
      numberOfArrangement1
    );

    const items = getRandomSubArrayFromArray(
      ['Apple', 'Pear', 'Banana', 'Muffin', 'Cookie', 'Counters'] as const,
      2
    );

    const otherOptions = countRange(2).map(i => {
      const groups = [groups2, groups3];
      const eachGroup = [inEach2, inEach3];
      return {
        item: items[i],
        groups: groups[i],
        inEachGroup: eachGroup[i],
        randomSeed: Math.random() * 10000,
        isDots: false
      };
    });

    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;

    return {
      dotsArrangements,
      options: shuffle([dotOption, ...otherOptions]),
      dotsArrangementsIndex,
      seed
    };
  },
  Component: props => {
    const {
      question: { dotsArrangements, options, dotsArrangementsIndex, seed },
      translate,
      displayMode
    } = props;

    const arrays = options.map((arr, i) => ({
      correctAnswer: ['A', 'B', 'C'][i],
      item: arr.item,
      array: filledArray(arr.inEachGroup, arr.groups),
      seed: filledArray(arr.randomSeed, arr.groups),
      isDots: arr.isDots
    }));

    const items = shuffle(
      options.map((val, i) => ({
        value: ['A', 'B', 'C'][i],
        component: (
          <Text variant="WRN700" style={{ fontSize: 32, textAlign: 'center' }}>
            {translate.ks1AnswerSentences.xEqualGroupsOfY(val.groups, val.inEachGroup)}
          </Text>
        )
      })),
      { random: seededRandom(seed) }
    );

    const dimens = {
      width: displayMode === 'digital' ? 400 : 500,
      height: displayMode === 'digital' ? 120 : 300
    };

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchThePicturesToStatements()}
        pdfTitle={translate.ks1PDFInstructions.matchThePicturesToTheStatements()}
        statements={arrays.map((obj, i) => {
          const sort = sortNumberArray(obj.array, 'descending');
          const scale = Math.max(
            getCounterArrangementScale(sort[0], true),
            getCounterArrangementScale(sort[obj.array.length - 1], true)
          );
          const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const, {
            random: seededRandom(obj.seed)
          });

          return {
            lhsComponent: (
              <View
                style={{
                  width: dimens.width,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 10
                }}
              >
                {obj.array.map((val, idx) =>
                  obj.item === 'Counters' ? (
                    <CounterBoxArrangement
                      key={`${idx}`}
                      counters={val}
                      color={color}
                      dimens={{ height: dimens.width / 7, width: dimens.width / 7 }}
                      isCircle
                      isSimpleArrangements
                      seed={obj.seed[idx]}
                      scale={scale}
                      arrangement={
                        obj.isDots
                          ? dotsArrangementsIndex.includes(idx)
                            ? dotsArrangements[0]
                            : dotsArrangements[1]
                          : undefined
                      }
                    />
                  ) : (
                    <AssetSvg
                      key={`${i}_${idx}`}
                      name={getGroupSvgName(obj.item, val)}
                      height={dimens.height}
                      width={dimens.width / 7}
                    />
                  )
                )}
              </View>
            ),
            correctAnswer: obj.correctAnswer
          };
        })}
        questionHeight={1000}
        pdfLayout="itemsRight"
        items={items}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biH',
  description: 'biH',
  keywords: ['Equal', 'Groups', 'Counters'],
  schema: z.object({
    numberOfGroups: numberEnum([2, 3, 4, 5, 6]),
    countersPerGroup: z.array(z.array(z.enum(['red', 'yellow', 'blue', 'green']))),
    correctNumberOfCounters: z.number().int().min(2).max(6),
    color: z.enum(['red', 'yellow', 'blue', 'green'])
  }),
  simpleGenerator: () => {
    const numberOfGroups = getRandomFromArray([2, 3, 4, 5, 6] as const);

    const correctNumberOfCounters = randomIntegerInclusive(2, 6);

    const numberOfCorrectGroups = randomIntegerInclusive(1, numberOfGroups - 1);

    const numberOfIncorrectCountersPerGroup = countRange(
      numberOfGroups - numberOfCorrectGroups
    ).map(() => randomIntegerInclusive(0, correctNumberOfCounters - 1));

    const correctCountGroups = countRange(numberOfCorrectGroups).map(() => correctNumberOfCounters);

    const numberOfCountersPerGroup = shuffle([
      ...numberOfIncorrectCountersPerGroup,
      ...correctCountGroups
    ]);

    const color = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);

    const countersPerGroup = numberOfCountersPerGroup.map(number => {
      return filledArray(color, number);
    });

    return {
      numberOfGroups,
      correctNumberOfCounters,
      countersPerGroup,
      color
    };
  },
  Component: props => {
    const {
      question: { correctNumberOfCounters, numberOfGroups, countersPerGroup, color },
      translate
    } = props;

    const testCorrect = countRange(numberOfGroups).map(() =>
      filledArray(color, correctNumberOfCounters)
    );

    return (
      <QF60DragCountersIntoGroups
        numberOfGroups={numberOfGroups}
        title={translate.ks1Instructions.dragCountersToMakeXEqualGroupsOfY(
          numberOfGroups,
          correctNumberOfCounters
        )}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToMakeXEqualGroupsOfY(
          numberOfGroups,
          correctNumberOfCounters
        )}
        items={[color]}
        countersPerGroup={countersPerGroup}
        testCorrect={testCorrect}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeEqualGroups',
  questionTypes: [Question1, Question2],
  unpublishedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
