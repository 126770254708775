import { Image } from 'expo-image';
import Text from 'common/src/components/typography/Text';
import { colors } from 'common/src/theme/colors';
import { Pressable, View } from 'react-native';
import { ProgressBar } from './ProgressBar';
import { QuizDetails } from './QuizDetails';
import { type StudentQuizApiEntity } from '../network/studentQuizzes';
import { useCallback } from 'react';

/**
 * Quiz Tile to display information about an assigned quiz
 */
export const QuizTile = ({
  item,
  startQuiz,
  showResumeRestartModal
}: {
  item: StudentQuizApiEntity;
  /** Callback for when the user indicated they want to start a new quiz. */
  startQuiz: (quizInstanceAssignmentId: number | undefined) => void;
  /** Callback for when the user indicated they want to start a new quiz or resume an existing one. */
  showResumeRestartModal: (
    quizInstanceAssignmentId: number | undefined,
    quizSessionId: string
  ) => void;
}) => {
  // Some of the test data returns progress > 100% so I have capped it at 100
  // I have raised with back end and they think its just poor data and would not expect this to happen in prod
  /** Current progress, out of 100. */
  const progress = Math.min((item.totalQuestionsAnswered / item.numberOfQuestions) * 100, 100);

  /** Whether the quiz has ever been started. */
  const hasEverBeenStarted = item.quizSessionUuid !== undefined;

  /** Quiz Session ID to use for resuming, or undefined if resuming isn't valid right now. */
  const resumableQuizSessionId =
    // If all questions are answered, this quiz is complete, so no point resuming
    // Note: we don't currently make use of the "isComplete" field, returned from the backend.
    item.quizSessionUuid !== undefined && item.totalQuestionsAnswered < item.numberOfQuestions
      ? item.quizSessionUuid
      : undefined;

  const onPress = useCallback(() => {
    if (resumableQuizSessionId) {
      // Option is available to resume the previous quiz session. Show modal
      showResumeRestartModal(item.quizInstanceAssignmentId, resumableQuizSessionId);
    } else {
      // There is no previous quiz session - just start a new one
      startQuiz(item.quizInstanceAssignmentId);
    }
  }, [item.quizInstanceAssignmentId, resumableQuizSessionId, showResumeRestartModal, startQuiz]);

  return (
    <Pressable
      style={{
        width: 260,
        marginHorizontal: 20
      }}
      onPress={onPress}
      testID="STUDENT_QUIZ"
    >
      <View
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          borderRadius: 6,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <View style={{ padding: 20, paddingBottom: 0 }}>
          <Image
            source={require('pupil-app/assets/svg/AppLogo.svg')}
            style={{ width: 200, height: 200 }}
          />
        </View>
        <QuizDetails
          quizStars={hasEverBeenStarted ? item.totalStars : undefined}
          totalStars={item.numberOfQuestions * 3}
          isInfinite={item.randomiseQuestionParameters}
        />
        <ProgressBar progress={hasEverBeenStarted ? progress : undefined} />
      </View>

      <Text
        variant="WRN400"
        style={{
          color: colors.white,
          fontSize: 22,
          fontWeight: '700',
          lineHeight: 33,
          paddingTop: 12
        }}
      >
        {item.name}
      </Text>
    </Pressable>
  );
};
