import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { rangeAsString } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgd',
  description: 'bgd',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(94),
    answerIndices: z.array(z.number()),
    intervals: numberEnum([8, 9, 10, 11])
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArrayWithWeights([10, 8, 9, 11] as const, [3, 1, 1, 1]);

    const numberOfAnswers = getRandomFromArray([3, 4, 5]);
    const answerIndices = randomUniqueIntegersInclusive(0, intervals - 1, numberOfAnswers);

    const startingNumber =
      intervals === 10
        ? 0
        : randomIntegerInclusive(0, 94, { constraint: x => x + intervals <= 100 });

    return { startingNumber, intervals, answerIndices };
  },
  Component: props => {
    const {
      question: { startingNumber, intervals, answerIndices },
      translate
    } = props;

    const tickValues = rangeAsString(
      startingNumber,
      intervals === 10 ? 100 : startingNumber + intervals,
      intervals === 10 ? 10 : 1,
      true
    );

    const numberArray = tickValues.map((number, index) =>
      answerIndices.includes(index) ? `<ans/>` : number.toString()
    );

    const answerArray = tickValues.flatMap((number, idx) =>
      answerIndices.includes(idx) ? [number.toString()] : []
    );

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.completeTheNumberLine()}
        testCorrect={answerArray}
        tickValues={numberArray}
        answerPositions="bottom"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bge',
  description: 'bge',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(94),
    intervals: numberEnum([6, 7, 8, 9, 10]),
    answerIndex: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArrayWithWeights([10, 6, 7, 8, 9] as const, [4, 1, 1, 1, 1]);

    const startingNumber = intervals === 10 ? 0 : randomIntegerInclusive(0, 100 - intervals);

    const answerIndex =
      intervals === 10
        ? randomIntegerInclusive(1, intervals - 1)
        : randomIntegerInclusive(2, intervals);

    return { startingNumber, answerIndex, intervals };
  },
  Component: props => {
    const {
      question: { startingNumber, answerIndex, intervals },
      translate
    } = props;

    const isTenIntervals = intervals === 10;

    const tickValues = rangeAsString(
      startingNumber,
      isTenIntervals ? startingNumber + intervals * 10 : startingNumber + intervals,
      isTenIntervals ? 10 : 1,
      true
    ).map((el, index) =>
      isTenIntervals ? ([0, intervals].includes(index) ? el : '') : [0, 1].includes(index) ? el : ''
    );

    const answer = isTenIntervals
      ? startingNumber + answerIndex * 10
      : startingNumber + answerIndex;

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[answer.toString()]}
        tickValues={tickValues}
        firstNumber={!isTenIntervals ? startingNumber : undefined}
        lastNumber={!isTenIntervals ? startingNumber + intervals : undefined}
        freeNumberLineAnswer={[answer]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bgf',
  description: 'bgf',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(94),
    number: z.number().int().min(0).max(99),
    intervals: numberEnum([6, 7, 8, 9, 10])
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArrayWithWeights([10, 6, 7, 8, 9] as const, [12, 1, 1, 1, 1]);

    const startingNumber =
      intervals === 10
        ? randomIntegerInclusive(0, 9) * 10
        : randomIntegerInclusive(0, 94, { constraint: x => x + intervals <= 100 });

    const number =
      intervals === 10
        ? randomIntegerInclusive(startingNumber + 1, startingNumber + 9)
        : randomIntegerInclusive(startingNumber + 1, startingNumber + intervals - 1);

    return { startingNumber, number, intervals };
  },
  Component: props => {
    const {
      question: { startingNumber, number, intervals },
      translate
    } = props;

    const tickValues = rangeAsString(startingNumber, startingNumber + intervals, 1, true).map(
      (el, index) => ([0, intervals].includes(index) ? el : '')
    );

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragTheArrowToShowWhereNumBelongsOnTheNumberLine(number)}
        pdfTitle={translate.ks1PDFInstructions.drawAnArrowToShowWhereNumBelongsOnTheNumberLine(
          number
        )}
        testCorrect={[number, number]}
        min={startingNumber}
        max={startingNumber + intervals}
        sliderStep={1}
        tickValues={tickValues}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TensAndOnesOnTheNumberLineTo100',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
