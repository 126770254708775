import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { DraggableVariant } from '../../../../components/draganddrop/utils';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { isEqual } from '../../../../utils/matchers';
import BaseLayoutPDF from '../../../../components/molecules/BaseLayoutPDF';
import { View } from 'react-native';
import Clock from '../../../../components/question/representations/Clock';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bk9',
  description: 'bk9',
  keywords: ['Midnight', 'Noon', 'Hour', 'Time'],
  schema: z.object({
    sentenceForm: z.enum([
      'midnightA',
      'midnightB',
      'midnightC',
      'midnightD',
      'noonA',
      'noonB',
      'hourHandMovesRoundClock',
      'hoursOnAClockFace',
      'hoursInADay',
      'clockShowsTimeNumTimesEachDay'
    ]),
    seed: z.number().int().min(1).max(999)
  }),
  simpleGenerator: () => {
    const sentenceForm = getRandomFromArray([
      'midnightA',
      'midnightB',
      'midnightC',
      'midnightD',
      'noonA',
      'noonB',
      'hourHandMovesRoundClock',
      'hoursOnAClockFace',
      'hoursInADay',
      'clockShowsTimeNumTimesEachDay'
    ] as const);

    const seed = randomIntegerInclusive(1, 999);

    return {
      sentenceForm,
      seed
    };
  },

  Component: props => {
    const {
      question: { sentenceForm, seed },
      translate
    } = props;

    const random = seededRandom({ sentenceForm, seed });

    const randomHourTime = randomIntegerInclusive(1, 12, { random });

    const [sentence, items, testCorrect, itemVariant] = (() => {
      switch (sentenceForm) {
        case 'midnightA':
          return [
            translate.ks1AnswerSentences.num12OClockAtNightIsCalledAns(),
            [translate.time.midnight(), translate.time.noon()],
            [translate.time.midnight()],
            'rectangle'
          ];
        case 'midnightB':
          return [
            translate.ks1AnswerSentences.aNewDayBeginsAtAns(),
            [translate.time.midnight(), translate.time.noon()],
            [translate.time.midnight()],
            'rectangle'
          ];
        case 'midnightC':
          return [
            translate.ks1AnswerSentences.ansIs12OClockAtNight(),
            [translate.time.Midnight(), translate.time.Noon()],
            [translate.time.Midnight()],
            'rectangle'
          ];
        case 'midnightD':
          return [
            translate.ks1AnswerSentences.ansIsTheBeginningOfANewDay(),
            [translate.time.Midnight(), translate.time.Noon()],
            [translate.time.Midnight()],
            'rectangle'
          ];
        case 'noonA':
          return [
            translate.ks1AnswerSentences.num12OClockInTheMiddleOfTheDayIsCalledAns(),
            [translate.time.midnight(), translate.time.noon()],
            [translate.time.noon()],
            'rectangle'
          ];
        case 'noonB':
          return [
            translate.ks1AnswerSentences.ansIs12OClockInTheMiddleOfTheDay(),
            [translate.time.Midnight(), translate.time.Noon()],
            [translate.time.Noon()],
            'rectangle'
          ];
        case 'hourHandMovesRoundClock':
          return [
            translate.ks1AnswerSentences.theHourHandMovesRoundTheClockAnsTimesInADay(),
            [
              (1).toLocaleString(),
              (2).toLocaleString(),
              (12).toLocaleString(),
              (24).toLocaleString()
            ],
            [(2).toLocaleString()],
            'square'
          ];
        case 'hoursOnAClockFace':
          return [
            translate.ks1AnswerSentences.thereAreAnsHoursShownOnAClockFace(),
            [
              (1).toLocaleString(),
              (2).toLocaleString(),
              (12).toLocaleString(),
              (24).toLocaleString()
            ],
            [(12).toLocaleString()],
            'square'
          ];
        case 'hoursInADay':
          return [
            translate.ks1AnswerSentences.thereAreAnsHoursInADay(),
            [
              (1).toLocaleString(),
              (2).toLocaleString(),
              (12).toLocaleString(),
              (24).toLocaleString()
            ],
            [(24).toLocaleString()],
            'square'
          ];
        case 'clockShowsTimeNumTimesEachDay':
          return [
            translate.ks1AnswerSentences.aClockShowsTheTimeNumOClockAnsTimesEachDay(randomHourTime),
            [
              (1).toLocaleString(),
              (2).toLocaleString(),
              (12).toLocaleString(),
              (24).toLocaleString()
            ],
            [(2).toLocaleString()],
            'square'
          ];
      }
    })();

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentence()}
        items={shuffle(items, { random })}
        itemVariant={itemVariant as DraggableVariant}
        pdfItemVariant={itemVariant as DraggableVariant}
        pdfSentencesStyle={{ alignItems: 'flex-start' }}
        sentence={sentence}
        testCorrect={testCorrect}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bla',
  description: 'bla',
  keywords: ['Hours', 'Analogue clock', 'Time'],
  schema: z.object({
    startHour: z.number().int().min(0).max(11),
    hoursAhead: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const startHour = randomIntegerInclusive(0, 11);

    const hoursAhead = randomIntegerInclusive(1, 6);

    return { startHour, hoursAhead };
  },
  Component: props => {
    const {
      question: { startHour, hoursAhead },
      translate,
      displayMode
    } = props;

    const correctHours = (startHour + hoursAhead) % 12;

    return displayMode === 'digital' ? (
      <QF40SetTheClockFace
        title={translate.ks1Instructions.theTimeIsNumOClockDragTheHandsOfTheClockToShowTimeInNumHours(
          startHour === 0 ? 12 : startHour,
          hoursAhead
        )}
        testCorrect={isEqual({ hours: correctHours, minutes: 0 })}
        startTime={{ hours: startHour, minutes: 0 }}
        snapMinutes={5}
      />
    ) : (
      <BaseLayoutPDF
        title={translate.ks1PDFInstructions.theTimeIsNumOClockDrawHandsOnTheClockToShowTimeInNumHours(
          startHour === 0 ? 12 : startHour,
          hoursAhead
        )}
        questionHeight={800}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  width: '100%',
                  justifyContent: 'space-evenly'
                }}
              >
                <Clock
                  width={dimens.width / 3}
                  time={{ hours: startHour, minutes: 0 }}
                  interactive={false}
                />
                <Clock
                  width={dimens.width / 3}
                  time={{ hours: correctHours, minutes: 0 }}
                  interactive={false}
                  showHands={displayMode === 'markscheme'}
                />
              </View>
            )}
          </MeasureView>
        }
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'blb',
  description: 'blb',
  keywords: ['Hours', 'Analogue clock', 'Time'],
  schema: z.object({
    startHour: z.number().int().min(1).max(12),
    hoursAhead: numberEnum([1, 2, 3, 4, 5, 6, 12])
  }),
  simpleGenerator: () => {
    const startHour = randomIntegerInclusive(1, 12);

    const hoursAhead = getRandomFromArray([1, 2, 3, 4, 5, 6, 12] as const);

    return { startHour, hoursAhead };
  },
  Component: props => {
    const {
      question: { startHour, hoursAhead },
      translate
    } = props;
    const endHour = (startHour + hoursAhead) % 12;

    return (
      <QF1ContentAndSentence
        title={
          hoursAhead === 12
            ? translate.ks1Instructions.theTimeIsNumOClockHowManyHoursWillItBeUntilHourIsPointingAtNumAgain(
                startHour
              )
            : translate.ks1Instructions.theTimeIsNumOClockHowManyHoursWillItBeUntilHourIsPointingAtNum(
                startHour,
                endHour
              )
        }
        sentence={
          hoursAhead === 1
            ? translate.ks1AnswerSentences.ansHour()
            : translate.ks1AnswerSentences.ansHours()
        }
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[hoursAhead.toString()]}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: startHour === 12 ? 0 : startHour, minutes: 0 }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HoursInADay',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
