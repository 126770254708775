import { type ReactNode, useContext } from 'react';
import { colors } from '../../../theme/colors';
import { MeasureView } from '../../atoms/MeasureView';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import Grid, { type GridContextType } from '../representations/Coordinates/Grid';
import GridImage, { GridImageWithState } from '../representations/Coordinates/GridImage';
import { DisplayMode } from '../../../contexts/displayMode';
import { isEqual } from '../../../utils/matchers';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import BaseLayout from '../../molecules/BaseLayout';
import { Point2d } from '../../../utils/vectors';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  start: Point2d[];
  end: Point2d[];
  svg: 'dot' | 'square' | 'custom';
  hideContinuationLines?: boolean;
  xMax?: number;
  yMax?: number;
  xMin?: number;
  yMin?: number;
  hideAxis?: boolean;
  gridChildren?: JSX.Element;
  /** Defaults to the middle */
  anchorDX?: number;
  anchorDY?: number;
  customDraggable?: (
    color: string,
    opacity?: number,
    index?: number
  ) => {
    component: JSX.Element;
    width: number;
    height: number;
  };
  /** PDF Question Height */
  questionHeight?: number;
};

export default function QF48TranslateSvg({
  title,
  pdfTitle,
  start,
  end,
  svg,
  hideContinuationLines = true,
  xMax = 10,
  yMax = 5,
  xMin = 0,
  yMin = 0,
  hideAxis = true,
  gridChildren,
  anchorDX,
  anchorDY,
  customDraggable,
  questionHeight,
  ...props
}: Props) {
  if (svg === 'custom' && customDraggable === undefined) {
    throw new Error('customDraggable must be defined when using `custom` svg');
  }
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const renderGrid = (children: (gridContext: GridContextType) => ReactNode) => (
    <MeasureView>
      {dimens => (
        <Grid
          width={dimens.width}
          height={dimens.height}
          xMax={xMax}
          yMax={yMax}
          xMin={xMin}
          yMin={yMin}
          xAxis={hideAxis ? null : undefined}
          yAxis={hideAxis ? null : undefined}
          hideContinuationLines={hideContinuationLines}
          squareGrid
        >
          {children}
        </Grid>
      )}
    </MeasureView>
  );

  const renderSvg = (
    coord: Point2d,
    { mathToSvgX }: GridContextType,
    fill: string,
    index: number,
    ghost = false
  ) => {
    switch (svg) {
      case 'square': {
        const squareWidth = mathToSvgX(1) - mathToSvgX(0);
        return (
          <GridImage
            key={`svg_${index}`}
            mathCoord={coord.toArray()}
            item={{
              component: 'SquareCustomizable',
              svgProps: {
                fill: fill,
                stroke: colors.prussianBlue,
                opacity: ghost ? 0.4 : 1
              },
              width: squareWidth,
              height: squareWidth
            }}
            anchorDX={0}
            anchorDY={squareWidth}
          />
        );
      }
      case 'dot': {
        return (
          <GridImage
            key={`svg_${index}`}
            mathCoord={coord.toArray()}
            item={{
              component: 'Coordinates/CirclePointCustomizable',
              svgProps: { fill: fill, opacity: ghost ? 0.4 : 1 }
            }}
          />
        );
      }
      case 'custom': {
        if (customDraggable)
          return (
            <GridImage
              key={`svg_${index}`}
              mathCoord={coord.toArray()}
              item={customDraggable(fill, ghost ? 0.4 : 1, index)}
              anchorDX={anchorDX}
              anchorDY={anchorDY}
            />
          );
      }
    }
  };

  const renderInteractiveSvg = ({ mathToSvgX }: GridContextType, index: number) => {
    switch (svg) {
      case 'square': {
        const squareWidth = mathToSvgX(1) - mathToSvgX(0);
        return (
          <GridImageWithState
            key={`translate_${index}`}
            id={`translate_${index}`}
            defaultState={start[index].toArray()}
            testCorrect={isEqual(end[index].toArray())}
            item={{
              component: 'SquareCustomizable',
              svgProps: {
                fill: colors.burntSienna,
                stroke: colors.prussianBlue
              },
              width: squareWidth,
              height: squareWidth
            }}
            snapToGrid
            anchorDX={0}
            anchorDY={squareWidth}
          />
        );
      }
      case 'dot': {
        return (
          <GridImageWithState
            key={`translate_${index}`}
            id={`translate_${index}`}
            defaultState={start[index].toArray()}
            testCorrect={isEqual(end[index].toArray())}
            item={{
              component: 'Coordinates/CirclePointCustomizable',
              svgProps: { fill: colors.burntSienna }
            }}
            snapToGrid
          />
        );
      }
      case 'custom': {
        const startPoint = start[index];
        if (customDraggable)
          return (
            <GridImageWithState
              key={`translate_${index}`}
              id={`translate_${index}`}
              defaultState={startPoint.toArray()}
              testCorrect={isEqual(end[index].toArray())}
              item={customDraggable(colors.burntSienna, undefined, index)}
              snapToGrid
              anchorDX={anchorDX}
              anchorDY={anchorDY}
            />
          );
      }
    }
  };

  if (isPdf) {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={renderGrid(gridContext => (
          <>
            {start.map((point, index) => renderSvg(point, gridContext, colors.prussianBlue, index))}
            {displayMode === 'markscheme' &&
              end.map((point, index) => renderSvg(point, gridContext, colors.burntSienna, index))}
            {gridChildren}
          </>
        ))}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={renderGrid(gridContext => (
        <>
          {gridChildren}
          {start.map((point, index) =>
            renderSvg(point, gridContext, colors.prussianBlue, index, true)
          )}
          {start.map((_point, index) => renderInteractiveSvg(gridContext, index))}
        </>
      ))}
      {...props}
    />
  );
}
