import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { isInRange } from '../../../../utils/matchers';
import { randomIntegerInclusive, rejectionSample } from '../../../../utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { SUB } from '../../../../constants';
import { CrossOutBaseTen } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhn',
  description: 'bhn',
  keywords: ['Subtract', 'Base 10', 'Cross out'],
  schema: z
    .object({
      number1: z.number().int().min(22).max(98),
      number2: z.number().int().min(10).max(89)
    })
    .refine(
      ({ number1, number2 }) => isInRange(12, 79)(number1 - number2),
      'subtraction must be between 12 and 79'
    )
    .refine(
      ({ number1, number2 }) => number1 % 10 < number2 % 10,
      'number2 ones are larger than number1 ones'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        // 95 is the max we can fit in the width
        const number1 = randomIntegerInclusive(22, 95, { constraint: x => x % 10 !== 9 });
        const number2 = randomIntegerInclusive(10, 79, {
          constraint: x => number1 % 10 < x % 10
        });
        return { number1, number2 };
      },
      val => isInRange(12, 79)(val.number1 - val.number2)
    ),
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={`${translate.ks1Instructions.completeTheSubtraction()}<br/>${translate.ks1Instructions.youCanTapTheBase10ToCrossThemOut()}`}
        pdfTitle={translate.ks1Instructions.completeTheSubtraction()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => (
          <CrossOutBaseTen
            dimens={dimens}
            tens={Math.floor(number1 / 10 - 1)}
            ones={(number1 % 10) + 10}
            // This will only be used in pdf
            scale={number1 > 50 ? 0.5 : undefined}
          />
        )}
        questionHeight={1000}
        sentence={`${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
        testCorrect={[(number1 - number2).toString()]}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwo2DigitNumbersAcrossA10',
  questionTypes: [Question1],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
