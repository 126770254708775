import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomNumberPairs,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { countRange, filledArray } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { ADD, SUB } from '../../../../constants';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { View } from 'react-native';

////
// Questions
////

const correctAnswers = (a: number, b: number, c: number, numberOfEqs: number) => {
  const possibleEqs = [
    `${a.toLocaleString()} ${ADD} ${b.toLocaleString()} = ${c.toLocaleString()}`,
    `${b.toLocaleString()} ${ADD} ${a.toLocaleString()} = ${c.toLocaleString()}`,
    `${c.toLocaleString()} = ${a.toLocaleString()} ${ADD} ${b.toLocaleString()}`,
    `${c.toLocaleString()} = ${b.toLocaleString()} ${ADD} ${a.toLocaleString()}`,
    `${c.toLocaleString()} ${SUB} ${b.toLocaleString()} = ${a.toLocaleString()}`,
    `${c.toLocaleString()} ${SUB} ${a.toLocaleString()} = ${b.toLocaleString()}`,
    `${a.toLocaleString()} = ${c.toLocaleString()} ${SUB} ${b.toLocaleString()}`,
    `${b.toLocaleString()} = ${c.toLocaleString()} ${SUB} ${a.toLocaleString()}`
  ];

  return getRandomSubArrayFromArray(possibleEqs, numberOfEqs);
};

const incorrectAnswers = (a: number, b: number, c: number, numberOfEqs: number) => {
  const possibleEqs = [
    `${a.toLocaleString()} + ${c.toLocaleString()} = ${b.toLocaleString()}`,
    `${b.toLocaleString()} = ${a.toLocaleString()} + ${c.toLocaleString()}`,
    `${a.toLocaleString()} - ${b.toLocaleString()} = ${c.toLocaleString()}`,
    `${a.toLocaleString()} - ${c.toLocaleString()} = ${b.toLocaleString()}`,

    `${c.toLocaleString()} + ${a.toLocaleString()} = ${b.toLocaleString()}`,
    `${b.toLocaleString()} = ${c.toLocaleString()} + ${a.toLocaleString()}`,
    `${b.toLocaleString()} - ${a.toLocaleString()} = ${c.toLocaleString()}`,
    `${b.toLocaleString()} - ${c.toLocaleString()} = ${a.toLocaleString()}`,

    `${b.toLocaleString()} + ${c.toLocaleString()} = ${a.toLocaleString()}`,
    `${a.toLocaleString()} = ${b.toLocaleString()} + ${c.toLocaleString()}`,
    `${c.toLocaleString()} = ${a.toLocaleString()} - ${b.toLocaleString()}`,
    `${b.toLocaleString()} = ${a.toLocaleString()} - ${c.toLocaleString()}`,

    `${c.toLocaleString()} + ${b.toLocaleString()} = ${a.toLocaleString()}`,
    `${a.toLocaleString()} = ${c.toLocaleString()} + ${b.toLocaleString()}`,
    `${c.toLocaleString()} = ${b.toLocaleString()} - ${a.toLocaleString()}`,
    `${a.toLocaleString()} = ${b.toLocaleString()} - ${c.toLocaleString()}`
  ];

  return getRandomSubArrayFromArray(possibleEqs, numberOfEqs);
};

const Question1 = newQuestionContent({
  uid: 'bgB',
  description: 'bgB',
  keywords: ['Number bonds', 'Add', 'Ten frames', 'Counters'],
  schema: z.object({
    tenFrameCounters: z.number().int().min(1).max(19),
    counterColor: z.enum(['red', 'yellow', 'blue', 'green']),
    rowOrColumn: z.enum(['rowFirst', 'columnFirst'])
  }),
  simpleGenerator: () => {
    const tenFrameCounters = randomIntegerInclusive(1, 19);
    const counterColor = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);
    const rowOrColumn = getRandomFromArray(['rowFirst', 'columnFirst'] as const);

    return { tenFrameCounters, counterColor, rowOrColumn };
  },
  Component: ({
    question: { tenFrameCounters, counterColor, rowOrColumn },
    translate,
    displayMode
  }) => {
    const tenFrames = tenFrameCounters <= 10 ? [tenFrameCounters, 0] : [10, tenFrameCounters - 10];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyMoreToMakeX(20)}
        Content={
          <View style={{ flexDirection: 'row', gap: displayMode === 'digital' ? 12 : 36 }}>
            {tenFrames.map((f, idx) => (
              <TenFrameLayout
                key={idx}
                items={filledArray(counterColor, f)}
                itemOrdering={rowOrColumn}
                size="small"
              />
            ))}
          </View>
        }
        sentence="<ans/>"
        testCorrect={[(20 - tenFrameCounters).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgC',
  description: 'bgC',
  keywords: ['Part-whole model', 'Add', 'Subtract', 'Number sentence'],
  schema: z.object({
    modelVariation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft']),
    total: z.number().int().min(3).max(20),
    numberPair: z.array(z.number().int().min(0).max(20)),
    ansIndices: z.array(numberEnum([0, 1, 2])).length(4)
  }),
  simpleGenerator: () => {
    const modelVariation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      [9, 1, 1, 1]
    );

    const total = randomIntegerInclusive(3, 20);
    const [numberPair] = randomNumberPairs(total, 1);

    const ansIndices = countRange(4).map(() => getRandomFromArray([0, 1, 2] as const));

    return { modelVariation, numberPair, ansIndices, total };
  },
  Component: ({
    question: { modelVariation, numberPair, ansIndices, total },
    translate,
    displayMode
  }) => {
    const equationSentence = (
      parts: number[],
      operator: string,
      ansIndex: number
    ): { sentence: string; answer: number } => {
      const sentenceParts: (string | number)[] = [...parts];

      const answer = parts[ansIndex];
      sentenceParts[ansIndex] = '<ans/>';
      const sentence = `${sentenceParts[0].toLocaleString()} ${operator} ${sentenceParts[1].toLocaleString()} = ${sentenceParts[2].toLocaleString()}`;

      return { sentence, answer };
    };

    const { sentence: sentenceA, answer: answerA } = equationSentence(
      [numberPair[0], numberPair[1], total],
      ADD,
      ansIndices[0]
    );
    const { sentence: sentenceB, answer: answerB } = equationSentence(
      [numberPair[1], numberPair[0], total],
      ADD,
      ansIndices[1]
    );
    const { sentence: sentenceC, answer: answerC } = equationSentence(
      [total, numberPair[0], numberPair[1]],
      SUB,
      ansIndices[2]
    );
    const { sentence: sentenceD, answer: answerD } = equationSentence(
      [total, numberPair[1], numberPair[0]],
      SUB,
      ansIndices[3]
    );

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheNumberSentences()}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="row"
        sentences={[sentenceA, sentenceB, sentenceC, sentenceD]}
        testCorrect={[
          [answerA.toString()],
          [answerB.toString()],
          [answerC.toString()],
          [answerD.toString()]
        ]}
        pdfSentenceStyle={{ width: 700, flexWrap: 'wrap', alignItems: 'flex-start' }}
        Content={({ dimens }) => (
          <PartWholeModel
            top={total}
            partition={[numberPair[0], numberPair[1]]}
            dimens={{
              height: dimens.height * 0.9,
              width: displayMode === 'digital' ? dimens.width * 0.9 : dimens.width * 0.7
            }}
            variation={modelVariation}
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bgD',
  description: 'bgD',
  keywords: ['Fact families', 'Add', 'Subtract'],
  schema: z.object({
    correctEqs: z.array(z.string()),
    incorrectEqs: z.array(z.string())
  }),
  simpleGenerator: () => {
    const numOfCorrect = randomIntegerInclusive(1, 6);
    const total = randomIntegerInclusive(3, 20);
    const [numberPair] = randomNumberPairs(total, 1, { constraint: x => x !== 0 });

    const correctEqs = correctAnswers(numberPair[0], numberPair[1], total, numOfCorrect);
    const incorrectEqs = incorrectAnswers(numberPair[0], numberPair[1], total, 6 - numOfCorrect);

    return { correctEqs, incorrectEqs };
  },
  Component: props => {
    const {
      question: { correctEqs, incorrectEqs },
      translate
    } = props;
    const correctItems = correctEqs.map((eq, idx) => ({
      component: eq,
      value: `correct-${idx}`,
      isCorrect: true
    }));

    const incorrectItems = incorrectEqs.map((eq, idx) => ({
      component: eq,
      value: `incorrect-${idx}`,
      isCorrect: false
    }));

    const items = shuffle([...incorrectItems, ...correctItems], {
      random: seededRandom(props.question)
    });

    return (
      <QF10SelectNumbers
        title={
          correctItems.length === 1
            ? translate.ks1Instructions.selectTheCorrectNumberSentence()
            : translate.ks1Instructions.selectTheCorrectNumberSentences()
        }
        pdfTitle={
          correctItems.length === 1
            ? translate.ks1PDFInstructions.tickTheCorrectNumberSentence()
            : translate.ks1PDFInstructions.tickTheCorrectNumberSentences()
        }
        items={items}
        multiSelect
        testCorrect={items.filter(it => it.isCorrect).map(it => it.value)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FactFamiliesAdditionSubtractionBondsWithin20',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
