import Text from 'common/src/components/typography/Text';
import { View, StyleSheet, Pressable } from 'react-native';
import { Image } from 'expo-image';
import FilledButton from '../components/FilledButton';
import AppConfig from '../../app.json';
import { colors } from 'common/src/theme/colors';
import { checkCameraPermissions } from '../utils/permissions';
import useLoginStore from '../storage/useLoginStore';
import { useI18nContext } from '../i18n/i18n-react';
import { RootStackProps } from '../navigation/types';

export default function PupilMenuScreen({ navigation }: RootStackProps<'PupilMenu'>) {
  const clearLoggedInUser = useLoginStore(state => state.clearLoggedInUser);
  const { school } = useLoginStore();
  const translate = useI18nContext().LL;

  // Permissions for QR code inside menu
  const handlePermissions = async () => {
    // On success, *replace* this screen with the ScanQR screen, so the back button goes to PupilHome
    await checkCameraPermissions(() => navigation.replace('ScanQR'));
  };

  return (
    <View style={{ flex: 1, backgroundColor: colors.prussianBlue }}>
      {/* Menu actions */}
      <View style={styles.menuActionsContainer}>
        <FilledButton
          icon={() => (
            <Image
              source={require('pupil-app/assets/svg/QRCodeIcon.svg')}
              style={{ width: 48, height: 48 }}
            />
          )}
          buttonWidth={377}
          onPress={handlePermissions}
        >
          {translate.home.scanQrCode()}
        </FilledButton>
        <FilledButton
          icon={() => (
            <Image
              source={require('pupil-app/assets/svg/Plus.svg')}
              style={{ width: 48, height: 48 }}
            />
          )}
          buttonWidth={377}
          onPress={
            school !== undefined
              ? () => navigation.replace('EnterQuizPIN')
              : () => navigation.replace('EnterSchoolCode', { nextScreen: 'EnterQuizPIN' })
          }
        >
          {translate.home.enterCode()}
        </FilledButton>
        <Pressable onPress={clearLoggedInUser}>
          <Text style={[styles.text, styles.logoutBtn]}>Log Out</Text>
        </Pressable>
      </View>

      {/* Menu footer text */}
      <View style={{ paddingBottom: 40 }}>
        <Text
          style={{
            fontSize: 22,
            lineHeight: 33,
            color: '#ffffff75', // Opacity: 75%
            textAlign: 'center'
          }}
        >
          {translate.home.appVersion(AppConfig.expo.version)}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    color: colors.white,
    fontSize: 32
  },
  menu: {
    alignItems: 'center',
    position: 'absolute',
    left: 24
  },
  closeButton: { flexDirection: 'row', alignItems: 'center', gap: 10 },
  menuHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: '#304256',
    borderBottomWidth: 1,
    height: 96
  },
  menuActionsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    rowGap: 32
  },
  menuText: {
    color: colors.white
  },
  logoutBtn: {
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
    paddingBottom: 18,
    fontSize: 29
  }
});
