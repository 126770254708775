export const soundFiles = {
  unselect: require('common/src/assets/audio/Modern/SelectUnselect/Unselect1.mp3'),
  select: require('common/src/assets/audio/Modern/SelectUnselect/Select1.mp3'),
  correct1: require('common/src/assets/audio/Modern/Success/Star1.mp3'),
  correct2: require('common/src/assets/audio/Modern/Success/Star2.mp3'),
  correct3: require('common/src/assets/audio/Modern/Success/Star3.mp3'),
  pickup1: require('common/src/assets/audio/Modern/PickUpDropDelete/PickUp1.mp3'),
  pickup2: require('common/src/assets/audio/Modern/PickUpDropDelete/PickUp2.mp3'),
  pickup3: require('common/src/assets/audio/Modern/PickUpDropDelete/PickUp3.mp3'),
  drop1: require('common/src/assets/audio/Modern/PickUpDropDelete/Drop1.mp3'),
  drop2: require('common/src/assets/audio/Modern/PickUpDropDelete/Drop2.mp3'),
  drop3: require('common/src/assets/audio/Modern/PickUpDropDelete/Drop3.mp3'),
  delete1: require('common/src/assets/audio/Modern/PickUpDropDelete/Delete1.mp3'),
  delete2: require('common/src/assets/audio/Modern/PickUpDropDelete/Delete2.mp3'),
  delete3: require('common/src/assets/audio/Modern/PickUpDropDelete/Delete3.mp3'),
  numpad0: require('common/src/assets/audio/Modern/Numpad/Button0.mp3'),
  numpad1: require('common/src/assets/audio/Modern/Numpad/Button1.mp3'),
  numpad2: require('common/src/assets/audio/Modern/Numpad/Button2.mp3'),
  numpad3: require('common/src/assets/audio/Modern/Numpad/Button3.mp3'),
  numpad4: require('common/src/assets/audio/Modern/Numpad/Button4.mp3'),
  numpad5: require('common/src/assets/audio/Modern/Numpad/Button5.mp3'),
  numpad6: require('common/src/assets/audio/Modern/Numpad/Button6.mp3'),
  numpad7: require('common/src/assets/audio/Modern/Numpad/Button7.mp3'),
  numpad8: require('common/src/assets/audio/Modern/Numpad/Button8.mp3'),
  numpad9: require('common/src/assets/audio/Modern/Numpad/Button9.mp3'),
  numpadDelete: require('common/src/assets/audio/Modern/Numpad/Backspace.mp3'),
  continue: require('common/src/assets/audio/Modern/ContinueButton/ContinueButton.mp3'),
  loading: require('common/src/assets/audio/Modern/Loading/Loading.mp3'),
  logo: require('common/src/assets/audio/Modern/Logo/Logo.mp3'),
  quizStart: require('common/src/assets/audio/Modern/QuizStart/QuizStart.mp3'),
  hundredPercent: require('common/src/assets/audio/Modern/Results/100Percent.mp3'),
  wellDone: require('common/src/assets/audio/Modern/Results/WellDone.mp3'),
  resultsScreen: require('common/src/assets/audio/Modern/Results/ResultsScreen.mp3'),
  TableCellDelete1: require('common/src/assets/audio/Modern/TableCellOnOff/Delete/TableCellDelete1.mp3'),
  TableCellDelete2: require('common/src/assets/audio/Modern/TableCellOnOff/Delete/TableCellDelete2.mp3'),
  TableCellDelete3: require('common/src/assets/audio/Modern/TableCellOnOff/Delete/TableCellDelete3.mp3'),
  TableCellDelete4: require('common/src/assets/audio/Modern/TableCellOnOff/Delete/TableCellDelete4.mp3'),
  TableCellDelete5: require('common/src/assets/audio/Modern/TableCellOnOff/Delete/TableCellDelete5.mp3'),
  TableCellDelete6: require('common/src/assets/audio/Modern/TableCellOnOff/Delete/TableCellDelete6.mp3'),
  TableCellRow1: require('common/src/assets/audio/Modern/TableCellOnOff/Notes/Row1.mp3'),
  TableCellRow2: require('common/src/assets/audio/Modern/TableCellOnOff/Notes/Row2.mp3'),
  TableCellRow3: require('common/src/assets/audio/Modern/TableCellOnOff/Notes/Row3.mp3'),
  TableCellRow4: require('common/src/assets/audio/Modern/TableCellOnOff/Notes/Row4.mp3'),
  TableCellRow5: require('common/src/assets/audio/Modern/TableCellOnOff/Notes/Row5.mp3'),
  TableCellRow6: require('common/src/assets/audio/Modern/TableCellOnOff/Notes/Row6.mp3'),
  modal: require('common/src/assets/audio/Modern/Modal/Modal.mp3'),
};
