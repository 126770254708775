import { createContext, type ReactNode, type ComponentProps, forwardRef, useContext } from 'react';
import { type StyleProp, type ViewStyle, View, StyleSheet } from 'react-native';
import UnitGrid, { type SizingProps } from './UnitGrid';
import { format } from 'mathjs';
import { countRange } from '../../../../utils/collections';
import { Svg } from 'react-native-svg';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../../../utils/react';

export type GridProps = {
  /** Distance between two grid lines, in math coordinates. */
  xStepSize?: number;
  /** Value of first point on x axis. */
  xMin?: number;
  /** Value of last point on x axis. */
  xMax: number;
  /**
   * Where the X axis is positioned in Y. Can lie on any grid line, except the last one. Default: yMin.
   * To hide the axis, pass in 'null'.
   */
  xAxis?: number | null;

  /** Distance between two grid lines, in math coordinates. */
  yStepSize?: number;
  /** Value of first point on x axis. */
  yMin?: number;
  /** Value of last point on x axis. */
  yMax: number;
  /**
   * Where the Y axis is positioned in X. Can lie on any grid line, except the last one. Default: xMin.
   * To hide the axis, pass in 'null'.
   */
  yAxis?: number | null;

  /** If provided, x-axis labels are given with this fixed number of decimal places. */
  xDecimalPlaces?: number;
  /** If provided, y-axis labels are given with this fixed number of decimal places. */
  yDecimalPlaces?: number;
  /** Whether to hide continuation grid lines on the sides of the grid without axes. Default: true */
  hideContinuationLines?: boolean;
  /** Whether to hide the grid lines altogether (implies hideContinuationLines). Default: false. */
  hideGridLines?: boolean;
  /** Null for no label. Default: '𝑥'. */
  xAxisArrowLabel?: string | null;
  /** Null for no label. Default: '𝑦'. */
  yAxisArrowLabel?: string | null;
  /** Null for no label. Default: null. */
  xAxisLabel?: string | null;
  /** Null for no label. Default: null. */
  yAxisLabel?: string | null;
  /**
   * Offset for moving the y-axis label. Positive numbers move label right, negative numbers move label left.
   * Optional prop, defaults to 0
   */
  yAxisLabelOffset?: number;
  /** Null for no labels. Default: numbers xMin to xMax. */
  xLabels?: string[] | null;
  /** Null for no labels. Default: numbers yMin to yMax. */
  yLabels?: string[] | null;

  /** Additional style. Note that we default flexShrink to 0, so you need to manually set that to 1 if you want it. */
  style?: StyleProp<ViewStyle>;

  /** Callback for when the outer view has been laid out. */
  onLayout?: ComponentProps<typeof View>['onLayout'];

  /**
   * Components to place within the Grid's bounds.
   *
   * Children can access useful functions (like mathToSvgX) and numbers (like xStepSize) by either:
   *
   * - Provide the children as a function that returns a JSX Element. The argument of this function contains the
   *   functions and numbers.
   *
   * - In the children's components, they have access to the {@link GridContext} context, via {@link useContext}.
   *
   * Note: SVG subcomponents (e.g. Rect, Path) should be placed in the {@link GridSvgChildren} wrapper.
   */
  children?: ElementOrRenderFunction<GridContextType>;
  /**
   * Label to assign to the top-right Cell.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  cellSizeLabel?: string;
  /**
   * Boolean to determine whether to make the grid lines for y-axis labels be darker.
   * Intended to be used for when there are a lot of lines in the y-axis, where only some are labelled.
   * Defaults to false.
   */
  darkGridLinesForYLabels?: boolean;
  /**
   * Whether to add the label in the middle of the cell rather than on the grid line
   */
  xLabelCenteredInMiddle?: boolean;
} & SizingProps;

/**
 * Gridlines, axes and a mapping from mathematical numbers to relative coordinates.
 * This handles any linear x and y axes (i.e. not logarithmic).
 * Labels are written using their simplest non-localized number, unless you provide their decimal places, in which
 * case they are all written with that many decimal places.
 */
export default forwardRef<View, GridProps>(function Grid(
  {
    children,
    style,
    xStepSize = 1,
    xMin = 0,
    xMax,
    xAxis: xAxisProp = 0,
    xDecimalPlaces,
    yStepSize = 1,
    yMin = 0,
    yMax,
    yAxis: yAxisProp = 0,
    yDecimalPlaces,
    xLabels: xLabelsProp,
    yLabels: yLabelsProp,
    hideContinuationLines = false,
    hideGridLines = false,
    yAxisLabelOffset = 0,
    cellSizeLabel,
    darkGridLinesForYLabels = false,
    xLabelCenteredInMiddle,
    ...props
  }: GridProps,
  ref
) {
  const xLength = Math.round((xMax - xMin) / xStepSize);
  const xLabels =
    xLabelsProp === null
      ? null
      : xLabelsProp ??
        countRange(xLength + 1)
          .map(i => xMin + i * xStepSize)
          .map(x =>
            xDecimalPlaces !== undefined
              ? format(x, { notation: 'fixed', precision: xDecimalPlaces })
              : format(x, { precision: 14 })
          );

  const yLength = Math.round((yMax - yMin) / yStepSize);
  const yLabels =
    yLabelsProp === null
      ? null
      : yLabelsProp ??
        countRange(yLength + 1)
          .map(i => yMin + i * yStepSize)
          .map(y =>
            yDecimalPlaces !== undefined
              ? format(y, { notation: 'fixed', precision: yDecimalPlaces })
              : format(y, { precision: 14 })
          );

  const xAxis = xAxisProp === null ? null : Math.round((xAxisProp - yMin) / yStepSize);
  const yAxis = yAxisProp === null ? null : Math.round((yAxisProp - xMin) / xStepSize);

  return (
    <UnitGrid
      ref={ref}
      xLength={xLength}
      yLength={yLength}
      xLabels={xLabels}
      yLabels={yLabels}
      xAxis={xAxis}
      yAxis={yAxis}
      yAxisLabelOffset={yAxisLabelOffset}
      hideContinuationLines={hideContinuationLines}
      hideGridLines={hideGridLines}
      style={style}
      cellSizeLabel={cellSizeLabel}
      darkGridLinesForYLabels={darkGridLinesForYLabels}
      xLabelCenteredInMiddle={xLabelCenteredInMiddle}
      {...props}
    >
      {({ mapX, mapY, unmapX, unmapY, svgWidth, svgHeight }) => {
        const context: GridContextType = {
          mathToSvgX: x => {
            'worklet';
            return mapX((x - xMin) / xStepSize);
          },
          mathToSvgY: y => {
            'worklet';
            return mapY((y - yMin) / yStepSize);
          },
          svgToMathX: xMapped => {
            'worklet';
            return xMin + unmapX(xMapped) * xStepSize;
          },
          svgToMathY: yMapped => {
            'worklet';
            return yMin + unmapY(yMapped) * yStepSize;
          },
          xMin,
          xMax,
          xStepSize,
          yMin,
          yMax,
          yStepSize,
          svgWidth,
          svgHeight
        };
        return (
          <GridContext.Provider value={context}>
            {resolveElementOrRenderFunction(children, context)}
          </GridContext.Provider>
        );
      }}
    </UnitGrid>
  );
});

/**
 * Wrapper component to place SVG sub-components which should be children of the {@link Grid}.
 *
 * Simply wraps the children in an SVG overlay which exactly covers the Grid.
 */
export function GridSvgChildren({ children, zIndex }: { children: ReactNode; zIndex?: number }) {
  const { svgWidth, svgHeight } = useContext(GridContext);
  return (
    <View style={[StyleSheet.absoluteFill, { zIndex }]} pointerEvents="box-none">
      <Svg width={svgWidth} height={svgHeight} pointerEvents="box-none">
        {children}
      </Svg>
    </View>
  );
}

export type GridContextType = {
  mathToSvgX: (x: number) => number;
  mathToSvgY: (y: number) => number;
  svgToMathX: (x: number) => number;
  svgToMathY: (y: number) => number;
  xMin: number;
  xMax: number;
  xStepSize: number;
  yMin: number;
  yMax: number;
  yStepSize: number;
  svgWidth: number;
  svgHeight: number;
};

/**
 * Context to help children transform their math-based coordinates into pixel coordinates relative to the Grid SVG.
 *
 * Note that it is not supported to attempt to use this context without the Grid as an ancestor, so the default value
 * is set up to throw an error as soon as possible.
 */
export const GridContext = createContext<GridContextType>({
  // Default values for when Grid is not an ancestor: throw error straight away
  mathToSvgX: () => {
    throw new Error('Must be placed in GridContext');
  },
  mathToSvgY: () => {
    throw new Error('Must be placed in GridContext');
  },
  svgToMathX: () => {
    throw new Error('Must be placed in GridContext');
  },
  svgToMathY: () => {
    throw new Error('Must be placed in GridContext');
  },
  // Default values for when Grid is not an ancestor: leave these values undefined to cause an error quickly.
  // (Cast to number just to satisfy typescript.)
  xMin: undefined as unknown as number,
  xMax: undefined as unknown as number,
  xStepSize: undefined as unknown as number,
  yMin: undefined as unknown as number,
  yMax: undefined as unknown as number,
  yStepSize: undefined as unknown as number,
  svgWidth: undefined as unknown as number,
  svgHeight: undefined as unknown as number
});
