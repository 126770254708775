import { Locales, TranslationFunctions } from 'common/src/i18n/i18n-types';
import { locales } from 'common/src/i18n/i18n-util';

/** Up-front store of the plural rules of the locales which our app supports. */
const ordinalPluralRules: Record<Locales, Intl.PluralRules> = Object.fromEntries(
  locales.map(locale => [locale, new Intl.PluralRules(locale, { type: 'ordinal' })])
) as Record<Locales, Intl.PluralRules>;

/**
 * Get the Nth ordinal numeral, e.g. 1st, 2nd, 3rd, etc. Supports any integer.
 *
 * typesafe-i18n doesn't support the { type: 'ordinal' } option, so we can't use its plural rules for ordinals.
 * Instead we roll our own solution.
 */
export function getNumeralOrdinal(
  n: number,
  locale: Locales,
  translate: TranslationFunctions
): string {
  const pr = ordinalPluralRules[locale];
  const rule = pr.select(n);

  // The "rule" corresponds to those given in
  // https://www.unicode.org/cldr/charts/43/supplemental/language_plural_rules.html
  // Every locale has a subset of these rules, for ordinal numbers, which are given to us in the translations file.
  switch (rule) {
    case 'zero':
      return translate.ordinals.numeral.zeroOrdinal(n);
    case 'one':
      return translate.ordinals.numeral.oneOrdinal(n);
    case 'two':
      return translate.ordinals.numeral.twoOrdinal(n);
    case 'few':
      return translate.ordinals.numeral.fewOrdinal(n);
    case 'many':
      return translate.ordinals.numeral.manyOrdinal(n);
    case 'other':
      return translate.ordinals.numeral.otherOrdinal(n);
  }
}

/** Get the Nth ordinal word, e.g. first, second, third, fourth, etc. Only supports 1-12 at the moment. */
// TODO: is there a better way?
export function getWordOrdinal(n: number, translate: TranslationFunctions): string {
  switch (n) {
    case 1:
      return translate.ordinals.first();
    case 2:
      return translate.ordinals.second();
    case 3:
      return translate.ordinals.third();
    case 4:
      return translate.ordinals.fourth();
    case 5:
      return translate.ordinals.fifth();
    case 6:
      return translate.ordinals.sixth();
    case 7:
      return translate.ordinals.seventh();
    case 8:
      return translate.ordinals.eighth();
    case 9:
      return translate.ordinals.ninth();
    case 10:
      return translate.ordinals.tenth();
    case 11:
      return translate.ordinals.eleventh();
    case 12:
      return translate.ordinals.twelfth();
    default:
      throw new Error('n must be an integer between 1 and 12');
  }
}
