import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { countRange, filledArray, sumNumberArray } from '../../../../utils/collections';
import { getNonTransparentJugByCapacity } from '../../../../utils/capacityImages';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD } from '../../../../constants';
import QF13DragLiquidInJug from '../../../../components/question/questionFormats/QF13DragLiquidInJug';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJu',
  description: 'aJu',
  keywords: ['Capacity', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z.object({
    beaker1Capacity: numberEnum([200, 500, 1000]),
    beaker2Capacity: numberEnum([200, 500, 1000]),
    isAdd: z.boolean()
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const beaker1Capacity = getRandomFromArray([200, 500, 1000] as const);
    const beaker2Capacity = getRandomFromArray([200, 500, 1000] as const);
    const isAdd =
      beaker2Capacity === 1000 ? true : beaker2Capacity === 200 ? false : getRandomBoolean();

    return { beaker1Capacity, beaker2Capacity, isAdd };
  },
  Component: ({
    question: { beaker1Capacity, beaker2Capacity, isAdd },
    translate,
    displayMode
  }) => {
    const seed = { beaker1Capacity, beaker2Capacity, isAdd };
    const beaker1 = getNonTransparentJugByCapacity(beaker1Capacity, seed);
    const beaker2 = getNonTransparentJugByCapacity(beaker2Capacity, seed);

    const jugDimens =
      displayMode === 'digital' ? { width: 170, height: 190 } : { width: 300, height: 320 };

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectBeakersThatHaveTotalCapacityOfOneLitre()}
        pdfTitle={translate.instructions.tickBeakersThatHaveTotalCapacityOfOneLitre()}
        numItems={2}
        itemLayout="column"
        testCorrect={['A']}
        questionHeight={1200}
        innerContainerStyle={{ rowGap: displayMode === 'digital' ? 24 : 64 }}
        renderItems={({ dimens }) =>
          shuffle(
            [
              {
                value: 'A',
                component: (
                  <View
                    style={[
                      { flexDirection: 'row' },
                      displayMode !== 'digital'
                        ? {
                            borderColor: 'black',
                            borderWidth: 2,
                            width: dimens.width,
                            height: dimens.height,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }
                        : undefined
                    ]}
                  >
                    {countRange(1000 / beaker1.capacity).map(val => (
                      <View style={jugDimens} key={`${val}A`}>
                        <JugWithLiquid
                          dimens={jugDimens}
                          jugCapacity={beaker1Capacity}
                          tickValue={beaker1Capacity / beaker1.intervals}
                          liquidAmount={0}
                          emptyJug
                          displayMajorLabels="top"
                          fontSize={displayMode === 'digital' ? 21 : 30}
                          labelUnits="ml"
                        />
                      </View>
                    ))}
                  </View>
                )
              },
              {
                value: 'B',
                component: (
                  <View
                    style={[
                      { flexDirection: 'row' },
                      displayMode !== 'digital'
                        ? {
                            borderColor: 'black',
                            borderWidth: 2,
                            width: dimens.width,
                            height: dimens.height,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }
                        : undefined
                    ]}
                  >
                    {countRange(
                      isAdd ? 1000 / beaker2.capacity + 1 : 1000 / beaker2.capacity - 1
                    ).map(val => (
                      <View style={jugDimens} key={`${val}B`}>
                        <JugWithLiquid
                          dimens={jugDimens}
                          jugCapacity={beaker2Capacity}
                          displayMajorLabels="top"
                          tickValue={beaker2Capacity / beaker2.intervals}
                          liquidAmount={0}
                          emptyJug
                          fontSize={displayMode === 'digital' ? 21 : 30}
                          labelUnits="ml"
                        />
                      </View>
                    ))}
                  </View>
                )
              }
            ],
            { random: seededRandom(seed) }
          )
        }
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aJu2',
  description: 'aJu',
  keywords: ['Capacity', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z
    .object({
      amountsToMake1l: z
        .array(z.number().int().min(50).max(200).step(50))
        .refine(x => sumNumberArray(x) === 1000, 'Total amount should be 1000'),
      remaining: z.array(z.number().int().min(50).max(200).step(50))
    })
    .refine(({ amountsToMake1l, remaining }) => amountsToMake1l.length + remaining.length === 8),
  simpleGenerator: () => {
    const jugsToMake1l = randomIntegerInclusive(5, 8);
    const amountsToMake1l =
      jugsToMake1l === 5
        ? filledArray(200, 5)
        : rejectionSample(
            () => countRange(jugsToMake1l).map(_ => randomIntegerInclusiveStep(50, 200, 50)),
            x => sumNumberArray(x) === 1000
          );
    const remaining = countRange(8 - jugsToMake1l).map(_ =>
      randomIntegerInclusiveStep(50, 200, 50)
    );
    return { amountsToMake1l, remaining };
  },
  Component: props => {
    const {
      question: { amountsToMake1l, remaining },
      translate,
      displayMode
    } = props;

    const unshuffled = [...amountsToMake1l, ...remaining].map((amount, i) => ({
      key: i,
      value: amount,
      correct: i < amountsToMake1l.length ? true : false
    }));

    const items = shuffle([...unshuffled], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectJugsWithTotalCapacityOfXL(1)}
        pdfTitle={translate.instructions.circleJugsWithTotalCapacityOfXL(1)}
        numItems={8}
        multiSelect
        renderItems={({ dimens }) =>
          items.map(({ key, value }, i) => ({
            value: key,
            component: (
              <JugWithLiquid
                key={i}
                dimens={{ width: dimens.width, height: dimens.height * 0.8 }}
                jugCapacity={200}
                labelUnits="ml"
                displayMajorLabels="top"
                tickValue={200}
                unitsPerMajorTick={3}
                liquidAmount={value}
                fontSize={displayMode === 'digital' ? 20 : 30}
              />
            )
          }))
        }
        testCorrect={userAnswer =>
          items
            .filter(({ key }) => userAnswer.includes(key))
            .reduce((acc, { value }) => acc + value, 0) === 1000
        }
        customMarkSchemeAnswer={{
          answerToDisplay: unshuffled.filter(({ correct }) => correct).map(({ key }) => key),
          answerText: translate.markScheme.acceptAnyCombinationThatAddUpToX(
            translate.units.stringLitres('1')
          )
        }}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aJv',
  description: 'aJv',
  keywords: ['Volume', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z
    .object({
      intervals: numberEnum([100, 200, 250, 500]),
      volume: z.number().int().min(100).max(1000)
    })
    .refine(val => val.volume % val.intervals === 0),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([100, 200, 250, 500] as const);
    const volume = randomIntegerInclusiveStep(intervals, 1000, intervals);

    return { volume, intervals };
  },
  Component: props => {
    const {
      question: { volume, intervals },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansMl()}
        title={translate.instructions.whatIsTheVolumeOfWater()}
        testCorrect={[volume.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={1000}
            displayMajorLabels="top"
            tickValue={intervals}
            labelUnits="l"
            liquidAmount={volume}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aJw',
  description: 'aJw',
  keywords: ['Volume', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z.object({
    tickValueMl: numberEnum([100, 200, 250, 500]),
    juiceMl: z.number().int().min(50).max(950)
  }),
  simpleGenerator: () => {
    const tickValueMl = getRandomFromArray([100, 200, 250, 500] as const);

    const [juiceLowerBound, juiceUpperBound, juiceStep] = (() => {
      switch (tickValueMl) {
        case 100:
          return [50, 950, 50];
        case 200:
          return [100, 900, 100];
        case 250:
          return [250, 750, 250];
        case 500:
          return [500, 500, 500];
      }
    })();

    const juiceMl = randomIntegerInclusiveStep(juiceLowerBound, juiceUpperBound, juiceStep);

    return { tickValueMl, juiceMl };
  },
  Component: props => {
    const {
      question: { tickValueMl, juiceMl },
      translate
    } = props;

    const unitsPerMajorTick = 1000 / tickValueMl - 1;

    return (
      <QF13DragLiquidInJug
        title={translate.instructions.dragArrowToFillJugWithNumMlOfJuice(juiceMl)}
        pdfTitle={translate.instructions.shadeToFillJugWithNumMlOfJuice(juiceMl)}
        testCorrect={juiceMl}
        jugCapacity={1000}
        tickValue={1000}
        liquidType="orange"
        unitsPerMajorTick={unitsPerMajorTick}
        questionHeight={1200}
        snapToNearest={tickValueMl / 2}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aJx',
  description: 'aJx',
  keywords: ['Volume', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z.object({
    intervals: numberEnum([100, 200, 250, 500]),
    volume: z.number().int().min(50).max(950)
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([100, 200, 250, 500] as const);
    const step = [250, 500].includes(intervals) ? intervals : intervals / 2;
    const volume = randomIntegerInclusiveStep(step, 1000 - step, step);

    return { volume, intervals };
  },
  Component: props => {
    const {
      question: { volume, intervals },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansMl()}
        title={translate.instructions.howMuchMoreJuiceToMake1Litre()}
        testCorrect={[(1000 - volume).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={1000}
            tickValue={intervals}
            displayMajorLabels="top"
            labelUnits="l"
            liquidAmount={volume}
            liquidType="orange"
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aJy',
  description: 'aJy',
  keywords: ['Volume', 'Capacity', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z.object({
    ml1: z.number().int().min(100).max(900).step(100),
    ml2: z.number().int().min(100).max(900).step(100),
    ml3: z.number().int().min(100).max(900).step(100)
  }),
  simpleGenerator: () => {
    const [ml1, ml2, ml3] = randomUniqueIntegersInclusiveStep(100, 900, 100, 3);
    return {
      ml1,
      ml2,
      ml3
    };
  },
  Component: props => {
    const {
      question: { ml1, ml2, ml3 },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={[
          `${translate.units.numberOfMl(
            ml1
          )} ${ADD} ${translate.answerSentences.ansMl()} = ${translate.units.numberOfLitres(1)}`,
          `${translate.units.numberOfMl(
            ml2
          )} ${ADD} ${translate.answerSentences.ansMl()} = ${translate.units.numberOfLitres(1)}`,
          `${translate.answerSentences.ansMl()} ${ADD} ${translate.units.numberOfMl(
            ml3
          )} = ${translate.units.numberOfLitres(1)}`
        ]}
        testCorrect={[
          [(1000 - ml1).toString()],
          [(1000 - ml2).toString()],
          [(1000 - ml3).toString()]
        ]}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aJy2',
  description: 'aJy',
  keywords: ['Volume', 'Capacity', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z.object({
    ml: z.number().int().min(100).max(900).step(100),
    isAnswerBoxLHS: z.boolean()
  }),
  simpleGenerator: () => {
    const ml = randomIntegerInclusiveStep(100, 900, 100);
    const isAnswerBoxLHS = getRandomBoolean();
    return {
      ml,
      isAnswerBoxLHS
    };
  },
  Component: props => {
    const {
      question: { ml, isAnswerBoxLHS },
      translate
    } = props;

    const sentence = isAnswerBoxLHS
      ? `${translate.answerSentences.ansMl()} ${ADD} ${translate.units.numberOfMl(
          ml
        )} = ${translate.units.numberOfLitres(1)}`
      : `${translate.units.numberOfMl(
          ml
        )} ${ADD} ${translate.answerSentences.ansMl()} = ${translate.units.numberOfLitres(1)}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        sentence={sentence}
        testCorrect={[(1000 - ml).toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJz',
  description: 'aJz',
  keywords: ['Volume', 'Capacity', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z.object({
    ml1: z.number().int().min(10).max(990).step(10),
    ml2: z.number().int().min(10).max(990).step(10),
    ml3: z.number().int().min(10).max(990).step(10)
  }),
  simpleGenerator: () => {
    const [ml1, ml2, ml3] = randomUniqueIntegersInclusiveStep(10, 990, 10, 3, {
      constraint: x => x % 100 !== 0
    });
    return {
      ml1,
      ml2,
      ml3
    };
  },
  Component: props => {
    const {
      question: { ml1, ml2, ml3 },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={[
          `${translate.units.numberOfMl(
            ml1
          )} ${ADD} ${translate.answerSentences.ansMl()} = ${translate.units.numberOfLitres(1)}`,
          `${translate.units.numberOfMl(
            ml2
          )} ${ADD} ${translate.answerSentences.ansMl()} = ${translate.units.numberOfLitres(1)}`,
          `${translate.answerSentences.ansMl()} ${ADD} ${translate.units.numberOfMl(
            ml3
          )} = ${translate.units.numberOfLitres(1)}`
        ]}
        testCorrect={[
          [(1000 - ml1).toString()],
          [(1000 - ml2).toString()],
          [(1000 - ml3).toString()]
        ]}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aJz2',
  description: 'aJz',
  keywords: ['Volume', 'Capacity', 'Measure', 'Millilitres', 'Litres', 'Equivalent'],
  schema: z.object({
    ml: z.number().int().min(10).max(990).step(10),
    isAnswerBoxLHS: z.boolean()
  }),
  simpleGenerator: () => {
    const ml = randomIntegerInclusiveStep(10, 990, 10, {
      constraint: x => x % 100 !== 0
    });

    const isAnswerBoxLHS = getRandomBoolean();

    return {
      ml,
      isAnswerBoxLHS
    };
  },
  Component: props => {
    const {
      question: { ml, isAnswerBoxLHS },
      translate
    } = props;

    const sentence = isAnswerBoxLHS
      ? `${translate.answerSentences.ansMl()} ${ADD} ${translate.units.numberOfMl(
          ml
        )} = ${translate.units.numberOfLitres(1)}`
      : `${translate.units.numberOfMl(
          ml
        )} ${ADD} ${translate.answerSentences.ansMl()} = ${translate.units.numberOfLitres(1)}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        sentence={sentence}
        testCorrect={[(1000 - ml).toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentCapacitiesAndVolumesLitresAndMillilitres',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question1, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
