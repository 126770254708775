import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question3 = newQuestionContent({
  uid: 'bby',
  description: 'bby',
  keywords: ['Subtract', 'First', 'Then', 'Now', 'Cross out', 'Take away'],
  questionHeight: 900,
  schema: z
    .object({
      initial: z.number().int().min(3).max(10),
      subtract: z.number().int().min(1).max(10),
      item: z.enum(['cookie', 'bird', 'car'])
    })
    .refine(
      ({ initial, subtract }) => initial >= subtract,
      'initial shouldb be greater or equal to subtract'
    ),
  simpleGenerator: () => {
    const initial = randomIntegerInclusive(3, 10);
    const subtract = randomIntegerInclusive(1, initial);

    const item = getRandomFromArray(['cookie', 'bird', 'car'] as const);

    return { initial, subtract, item };
  },
  Component: props => {
    const {
      question: { initial, subtract, item },
      translate
    } = props;

    const remaining = initial - subtract;
    const svgName = {
      cookie: 'Cookie_biscuit/Cookie_biscuit_6',
      car: 'Car',
      bird: 'bird_blue'
    } as const;

    const firstStatement = {
      cookie: 'firstThereWereXCookies',
      car: 'firstThereWereXCarsInCarPark',
      bird: 'firstThereWereXBirdsInTree'
    } as const;

    const secondStatement = {
      cookie: subtract === 1 ? 'thenXCookieWasEaten' : 'thenXCookiesWereEaten',
      car: 'thenXOfTheCarsDriveAway',
      bird: 'thenXOfTheBirdsFlewAway'
    } as const;

    const thirdStatement = {
      cookie: remaining === 1 ? 'nowThereIsAnsCookie' : 'nowThereAreAnsCookies',
      car: remaining === 1 ? 'nowThereIsAnsCarLeft' : 'nowThereAreAnsCarsLeft',
      bird: remaining === 1 ? 'nowThereIsAnsBirdLeft' : 'nowThereAreAnsBirdsLeft'
    } as const;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', gap: 8 }}>
              {countRange(initial).map(i => (
                <AssetSvg
                  key={`${i}_A`}
                  name={svgName[item]}
                  height={dimens.height}
                  width={dimens.width / initial - 8}
                />
              ))}
            </View>
          );
        }}
        sentence={`${translate.ks1AnswerSentences[firstStatement[item]](
          initial
        )}<br/>${translate.ks1AnswerSentences[secondStatement[item]](
          subtract
        )}<br/>${translate.ks1AnswerSentences[thirdStatement[item]]()}`}
        testCorrect={[remaining.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractionTakeAwayCrossOut',
  questionTypes: [Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
