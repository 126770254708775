import { StyleProp, TextStyle } from 'react-native';
import { arraysHaveSameContents, filledArray, sumNumberArray } from 'common/src/utils/collections';
import { useContext } from 'react';

import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';

import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import { isNotEqual } from '../../../utils/matchers';
import { getPictogramIcons } from '../representations/Pictogram/PictogramImages';
import Pictogram from '../representations/Pictogram/Pictogram';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { MeasureView } from '../../atoms/MeasureView';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * An array of numbers representing the total expected for each row.
   * Alternatively, you can provide the correct answer as a function.
   */
  testCorrect: number[] | ((userAnswer: number[][]) => boolean);
  /**
   * Optional custom mark scheme answer (with items). If you provide testCorrect as a function, you _must_ provide
   * this. If you provide testCorrect as an array, you don't need to provide this.
   *
   * An array of numbers to be shown as the total of the pictogram rows
   * The total number is before the key has been taken into account. So if the correct answer is 5 whole circles to be shown
   * and 4 and a half circles shown the answersToDisplay = [5, 4.5]
   */
  customMarkSchemeAnswer?: { answersToDisplay?: number[]; answerText?: string };
  /** The names of each column. */
  columnNames: string[];
  /** The row values including names. This does not include any values for the pictogram column */
  rowData: string[][];
  /** Columns that need to be pre-filled on interactive pictograms */
  filledCols?: number[];
  /** The draggable items to display, Can fit 4 comfotably as with current sizes. */
  draggableItems: ('whole' | 'half' | 'quarter' | 'threeQuarter')[];
  /** String to be displayed as key */
  keyValue: string;
  headerColor?: string;
  headerTextStyle?: StyleProp<TextStyle>;
  /** PDF Question Height */
  questionHeight?: number;
};

/**
 * Question Format 64: CreatePictogram
 *
 * Draggable pictogram table. Will render circle SVGs as the draggables depending on the draggableItems specified
 * which can then be dragged into table
 *
 */
export default function QF64CreatePictogram({
  title,
  pdfTitle,
  testCorrect,
  customMarkSchemeAnswer,
  draggableItems,
  keyValue,
  columnNames,
  rowData,
  filledCols,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  ...props
}: Props) {
  if (typeof testCorrect === 'function' && customMarkSchemeAnswer === undefined) {
    throw new Error(
      'testCorrect is a function, so you must provide the customMarkSchemeAnswer prop'
    );
  }

  const displayMode = useContext(DisplayMode);
  const color = colors.greys700;
  const items = draggableItems.map(i =>
    getPictogramIcons({ fraction: i, height: 90, width: 90, color })
  );

  const getDefaultState = (number: number) => {
    const numOfWholes = filledArray(1, Math.floor(number));
    const numOfHalves = filledArray(0.5, number % 1 === 0.5 ? 1 : 0);
    const numOfThreeQuarters = filledArray(0.75, number % 1 === 0.75 ? 1 : 0);
    const numOfQuarters = filledArray(0.25, number % 1 === 0.25 ? 1 : 0);
    return [...numOfWholes, ...numOfHalves, ...numOfThreeQuarters, ...numOfQuarters];
  };

  const draggableSource = (
    <DragAndDropSection style={{ padding: 0 }}>
      {items.map((item, index) => (
        <EasyDragAndDrop.Source
          key={index}
          id={index}
          widthOverride={item.value === 1 || item.value === 0.75 ? 90 : 45}
        />
      ))}
    </DragAndDropSection>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    let defaultState: number[][];
    if (customMarkSchemeAnswer?.answersToDisplay !== undefined) {
      defaultState = customMarkSchemeAnswer.answersToDisplay.map(x => getDefaultState(x));
    } else {
      const array = testCorrect as number[];
      defaultState = array.map(x => getDefaultState(x));
    }

    return (
      <EasyDragAndDrop.ProviderWithState
        id="draganddrop"
        items={items}
        defaultState={
          displayMode === 'markscheme' ? defaultState : filledCols?.map(x => getDefaultState(x))
        }
        moveOrCopy="copy"
        hideBackground
        draggableStyle={{
          borderWidth: 0,
          shadowOpacity: 0,
          backgroundColor: 'transparent',
          alignItems: 'flex-start'
        }}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              <MeasureView>
                {dimens => (
                  <Pictogram
                    columnNames={columnNames}
                    color={color}
                    keyValue={keyValue}
                    interactive={true}
                    dimens={dimens}
                    rowData={rowData}
                  />
                )}
              </MeasureView>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="copy"
      hideBackground
      defaultState={
        filledCols ? filledCols.map(x => getDefaultState(x)) : filledArray([], rowData.length)
      }
      // Complete if at least one zone has at least one item.
      testComplete={isNotEqual(filledArray([], rowData.length))}
      testCorrect={
        typeof testCorrect === 'function'
          ? testCorrect
          : userAnswer =>
              arraysHaveSameContents(
                userAnswer.map(i => sumNumberArray(i)),
                testCorrect
              )
      }
      draggableStyle={{
        borderWidth: 0,
        shadowOpacity: 0,
        backgroundColor: 'transparent',
        alignItems: 'flex-start'
      }}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="end"
        actionPanelContents={draggableSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <Pictogram
                columnNames={columnNames}
                color={color}
                keyValue={keyValue}
                interactive={true}
                dimens={dimens}
                rowData={rowData}
              />
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}
