import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayout from '../../molecules/BaseLayout';
import Scales, { ScalesSvg, ScalesWithState } from '../representations/Scales';
import { MeasureView } from '../../atoms/MeasureView';
import { isInRange } from '../../../utils/matchers';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import { SvgName } from '../../../assets/svg';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle: string;
  /** Optional:  default is +/-10 grams from answer weight */
  testCorrect?: (userAnswer: number) => boolean;
  answerGrams: number;
  startWeightGrams?: number;
  svgName: ScalesSvg;
  weightImage?: SvgName;
  /** PDF Question Height */
  questionHeight?: number;
};

/**
 * Set the scales face to match the given weight.
 */
export default function QF65SetTheScales({
  title,
  pdfTitle,
  testCorrect: testCorrectProp,
  answerGrams,
  startWeightGrams,
  svgName,
  weightImage,
  questionHeight = MINIMUM_QUESTION_HEIGHT
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle}
        questionHeight={questionHeight}
        mainPanelContents={
          <MeasureView style={{ alignItems: 'center' }}>
            {dimens => (
              <Scales
                weightG={displayMode === 'markscheme' ? answerGrams : undefined}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                interactive={false}
                showHand={displayMode === 'markscheme'}
                svgName={svgName}
                weightImage={weightImage}
              />
            )}
          </MeasureView>
        }
      />
    );
  }

  const testCorrect =
    testCorrectProp === undefined
      ? (answer: number) => isInRange(answerGrams - 10, answerGrams + 10)(answer)
      : testCorrectProp;

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView style={{ alignItems: 'center' }}>
          {dimens => (
            <ScalesWithState
              id="scale"
              scaleWidth={Math.min(dimens.width * 0.6, dimens.height * 0.9)}
              testCorrect={testCorrect}
              defaultState={startWeightGrams}
              svgName={svgName}
              weightImage={weightImage}
              interactive
            />
          )}
        </MeasureView>
      }
    />
  );
}
