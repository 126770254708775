import { SvgName } from '../../assets/svg';
import { getRandomFromArray, getRandomSubArrayFromArray, seededRandom } from '../random';

const irregularShapesSvgs: SvgName[] = [
  'Hexagons/Irregular_hexagon1_white',
  'Hexagons/Irregular_hexagon1_blue',
  'Hexagons/Irregular_hexagon1_green',
  'Hexagons/Irregular_hexagon1_pink',
  'Hexagons/Irregular_hexagon1_purple',
  'Hexagons/Irregular_hexagon1_yellow',
  'Hexagons/Irregular_hexagon2_white',
  'Hexagons/Irregular_hexagon2_blue',
  'Hexagons/Irregular_hexagon2_green',
  'Hexagons/Irregular_hexagon2_pink',
  'Hexagons/Irregular_hexagon2_purple',
  'Hexagons/Irregular_hexagon2_yellow',
  'Hexagons/Irregular_hexagon3_white',
  'Hexagons/Irregular_hexagon3_blue',
  'Hexagons/Irregular_hexagon3_green',
  'Hexagons/Irregular_hexagon3_pink',
  'Hexagons/Irregular_hexagon3_purple',
  'Hexagons/Irregular_hexagon3_yellow',
  'Hexagons/Irregular_hexagon4_white',
  'Hexagons/Irregular_hexagon4_blue',
  'Hexagons/Irregular_hexagon4_green',
  'Hexagons/Irregular_hexagon4_pink',
  'Hexagons/Irregular_hexagon4_purple',
  'Hexagons/Irregular_hexagon4_yellow',
  'Hexagons/Irregular_hexagon5_white',
  'Hexagons/Irregular_hexagon5_blue',
  'Hexagons/Irregular_hexagon5_green',
  'Hexagons/Irregular_hexagon5_pink',
  'Hexagons/Irregular_hexagon5_purple',
  'Hexagons/Irregular_hexagon5_yellow',
  'Hexagons/Irregular_hexagon6_white',
  'Hexagons/Irregular_hexagon6_blue',
  'Hexagons/Irregular_hexagon6_green',
  'Hexagons/Irregular_hexagon6_pink',
  'Hexagons/Irregular_hexagon6_purple',
  'Hexagons/Irregular_hexagon6_yellow',
  'Pentagons/Irregular_pentagon1_white',
  'Pentagons/Irregular_pentagon1_blue',
  'Pentagons/Irregular_pentagon1_green',
  'Pentagons/Irregular_pentagon1_pink',
  'Pentagons/Irregular_pentagon1_purple',
  'Pentagons/Irregular_pentagon1_yellow',
  'Pentagons/Irregular_pentagon2_white',
  'Pentagons/Irregular_pentagon2_blue',
  'Pentagons/Irregular_pentagon2_green',
  'Pentagons/Irregular_pentagon2_pink',
  'Pentagons/Irregular_pentagon2_purple',
  'Pentagons/Irregular_pentagon2_yellow',
  'Pentagons/Irregular_pentagon3_white',
  'Pentagons/Irregular_pentagon3_blue',
  'Pentagons/Irregular_pentagon3_green',
  'Pentagons/Irregular_pentagon3_pink',
  'Pentagons/Irregular_pentagon3_purple',
  'Pentagons/Irregular_pentagon3_yellow',
  'Pentagons/Irregular_pentagon4_white',
  'Pentagons/Irregular_pentagon4_blue',
  'Pentagons/Irregular_pentagon4_green',
  'Pentagons/Irregular_pentagon4_pink',
  'Pentagons/Irregular_pentagon4_purple',
  'Pentagons/Irregular_pentagon4_yellow',
  'Pentagons/Irregular_pentagon5_white',
  'Pentagons/Irregular_pentagon5_blue',
  'Pentagons/Irregular_pentagon5_green',
  'Pentagons/Irregular_pentagon5_pink',
  'Pentagons/Irregular_pentagon5_purple',
  'Pentagons/Irregular_pentagon5_yellow',
  'Pentagons/Irregular_pentagon6_white',
  'Pentagons/Irregular_pentagon6_blue',
  'Pentagons/Irregular_pentagon6_green',
  'Pentagons/Irregular_pentagon6_pink',
  'Pentagons/Irregular_pentagon6_purple',
  'Pentagons/Irregular_pentagon6_yellow',
  'Octagons/Irregular_octagon1_white',
  'Octagons/Irregular_octagon1_blue',
  'Octagons/Irregular_octagon1_green',
  'Octagons/Irregular_octagon1_pink',
  'Octagons/Irregular_octagon1_purple',
  'Octagons/Irregular_octagon1_yellow',
  'Octagons/Irregular_octagon2_white',
  'Octagons/Irregular_octagon2_blue',
  'Octagons/Irregular_octagon2_green',
  'Octagons/Irregular_octagon2_pink',
  'Octagons/Irregular_octagon2_purple',
  'Octagons/Irregular_octagon2_yellow',
  'Octagons/Irregular_octagon3_white',
  'Octagons/Irregular_octagon3_blue',
  'Octagons/Irregular_octagon3_green',
  'Octagons/Irregular_octagon3_pink',
  'Octagons/Irregular_octagon3_purple',
  'Octagons/Irregular_octagon3_yellow',
  'Other_shapes/Irregular_heptagon',
  'Other_shapes/Irregular_heptagon_blue',
  'Other_shapes/Irregular_heptagon_green',
  'Other_shapes/Irregular_heptagon_pink',
  'Other_shapes/Irregular_heptagon_purple',
  'Other_shapes/Irregular_heptagon_yellow',
  'Nonagons/Irregular_nonagon_white',
  'Nonagons/Irregular_nonagon_blue',
  'Nonagons/Irregular_nonagon_green',
  'Nonagons/Irregular_nonagon_pink',
  'Nonagons/Irregular_nonagon_purple',
  'Nonagons/Irregular_nonagon_yellow',
  'Other_shapes/Irregular_decagon1',
  'Other_shapes/Irregular_decagon2',
  'Other_shapes/Irregular_decagon2_blue',
  'Other_shapes/Irregular_decagon2_green',
  'Other_shapes/Irregular_decagon2_pink',
  'Other_shapes/Irregular_decagon2_purple',
  'Other_shapes/Irregular_decagon2_yellow',
  'Dodecagons/Irregular_dodecagon1_white',
  'Dodecagons/Irregular_dodecagon1_blue',
  'Dodecagons/Irregular_dodecagon1_green',
  'Dodecagons/Irregular_dodecagon1_pink',
  'Dodecagons/Irregular_dodecagon1_purple',
  'Dodecagons/Irregular_dodecagon1_yellow',
  'Dodecagons/Irregular_dodecagon2_white',
  'Dodecagons/Irregular_dodecagon2_blue',
  'Dodecagons/Irregular_dodecagon2_green',
  'Dodecagons/Irregular_dodecagon2_pink',
  'Dodecagons/Irregular_dodecagon2_purple',
  'Dodecagons/Irregular_dodecagon2_yellow',
  'Rectangle/rectangle_blue',
  'Rectangle/rectangle_green',
  'Rectangle/rectangle_pink',
  'Rectangle/rectangle_purple',
  'Rectangle/rectangle_white',
  'Rectangle/rectangle_yellow'
];

export const getIrregularShapeSvgName = (
  shape:
    | 'rectangles'
    | 'hexagons'
    | 'pentagons'
    | 'octagons'
    | 'heptagons'
    | 'nonagons'
    | 'decagons'
    | 'dodecagons',
  seed?: string | number | object
): SvgName => {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;

  switch (shape) {
    case 'rectangles':
      return getRandomFromArray(
        [
          'Rectangle/rectangle_blue',
          'Rectangle/rectangle_green',
          'Rectangle/rectangle_pink',
          'Rectangle/rectangle_purple',
          'Rectangle/rectangle_white',
          'Rectangle/rectangle_yellow'
        ] as const,
        seeded
      );
    case 'hexagons':
      return getRandomFromArray(
        [
          'Hexagons/Irregular_hexagon1_white',
          'Hexagons/Irregular_hexagon1_blue',
          'Hexagons/Irregular_hexagon1_green',
          'Hexagons/Irregular_hexagon1_pink',
          'Hexagons/Irregular_hexagon1_purple',
          'Hexagons/Irregular_hexagon1_yellow',
          'Hexagons/Irregular_hexagon2_white',
          'Hexagons/Irregular_hexagon2_blue',
          'Hexagons/Irregular_hexagon2_green',
          'Hexagons/Irregular_hexagon2_pink',
          'Hexagons/Irregular_hexagon2_purple',
          'Hexagons/Irregular_hexagon2_yellow',
          'Hexagons/Irregular_hexagon3_white',
          'Hexagons/Irregular_hexagon3_blue',
          'Hexagons/Irregular_hexagon3_green',
          'Hexagons/Irregular_hexagon3_pink',
          'Hexagons/Irregular_hexagon3_purple',
          'Hexagons/Irregular_hexagon3_yellow',
          'Hexagons/Irregular_hexagon4_white',
          'Hexagons/Irregular_hexagon4_blue',
          'Hexagons/Irregular_hexagon4_green',
          'Hexagons/Irregular_hexagon4_pink',
          'Hexagons/Irregular_hexagon4_purple',
          'Hexagons/Irregular_hexagon4_yellow',
          'Hexagons/Irregular_hexagon5_white',
          'Hexagons/Irregular_hexagon5_blue',
          'Hexagons/Irregular_hexagon5_green',
          'Hexagons/Irregular_hexagon5_pink',
          'Hexagons/Irregular_hexagon5_purple',
          'Hexagons/Irregular_hexagon5_yellow',
          'Hexagons/Irregular_hexagon6_white',
          'Hexagons/Irregular_hexagon6_blue',
          'Hexagons/Irregular_hexagon6_green',
          'Hexagons/Irregular_hexagon6_pink',
          'Hexagons/Irregular_hexagon6_purple',
          'Hexagons/Irregular_hexagon6_yellow'
        ] as const,
        seeded
      );
    case 'pentagons':
      return getRandomFromArray(
        [
          'Pentagons/Irregular_pentagon1_white',
          'Pentagons/Irregular_pentagon1_blue',
          'Pentagons/Irregular_pentagon1_green',
          'Pentagons/Irregular_pentagon1_pink',
          'Pentagons/Irregular_pentagon1_purple',
          'Pentagons/Irregular_pentagon1_yellow',
          'Pentagons/Irregular_pentagon2_white',
          'Pentagons/Irregular_pentagon2_blue',
          'Pentagons/Irregular_pentagon2_green',
          'Pentagons/Irregular_pentagon2_pink',
          'Pentagons/Irregular_pentagon2_purple',
          'Pentagons/Irregular_pentagon2_yellow',
          'Pentagons/Irregular_pentagon3_white',
          'Pentagons/Irregular_pentagon3_blue',
          'Pentagons/Irregular_pentagon3_green',
          'Pentagons/Irregular_pentagon3_pink',
          'Pentagons/Irregular_pentagon3_purple',
          'Pentagons/Irregular_pentagon3_yellow',
          'Pentagons/Irregular_pentagon4_white',
          'Pentagons/Irregular_pentagon4_blue',
          'Pentagons/Irregular_pentagon4_green',
          'Pentagons/Irregular_pentagon4_pink',
          'Pentagons/Irregular_pentagon4_purple',
          'Pentagons/Irregular_pentagon4_yellow',
          'Pentagons/Irregular_pentagon5_white',
          'Pentagons/Irregular_pentagon5_blue',
          'Pentagons/Irregular_pentagon5_green',
          'Pentagons/Irregular_pentagon5_pink',
          'Pentagons/Irregular_pentagon5_purple',
          'Pentagons/Irregular_pentagon5_yellow',
          'Pentagons/Irregular_pentagon6_white',
          'Pentagons/Irregular_pentagon6_blue',
          'Pentagons/Irregular_pentagon6_green',
          'Pentagons/Irregular_pentagon6_pink',
          'Pentagons/Irregular_pentagon6_purple',
          'Pentagons/Irregular_pentagon6_yellow'
        ] as const,
        seeded
      );
    case 'octagons':
      return getRandomFromArray(
        [
          'Octagons/Irregular_octagon1_white',
          'Octagons/Irregular_octagon1_blue',
          'Octagons/Irregular_octagon1_green',
          'Octagons/Irregular_octagon1_pink',
          'Octagons/Irregular_octagon1_purple',
          'Octagons/Irregular_octagon1_yellow',
          'Octagons/Irregular_octagon2_white',
          'Octagons/Irregular_octagon2_blue',
          'Octagons/Irregular_octagon2_green',
          'Octagons/Irregular_octagon2_pink',
          'Octagons/Irregular_octagon2_purple',
          'Octagons/Irregular_octagon2_yellow',
          'Octagons/Irregular_octagon3_white',
          'Octagons/Irregular_octagon3_blue',
          'Octagons/Irregular_octagon3_green',
          'Octagons/Irregular_octagon3_pink',
          'Octagons/Irregular_octagon3_purple',
          'Octagons/Irregular_octagon3_yellow'
        ] as const,
        seeded
      );
    case 'heptagons':
      return getRandomFromArray(
        [
          'Other_shapes/Irregular_heptagon',
          'Other_shapes/Irregular_heptagon_blue',
          'Other_shapes/Irregular_heptagon_green',
          'Other_shapes/Irregular_heptagon_pink',
          'Other_shapes/Irregular_heptagon_purple',
          'Other_shapes/Irregular_heptagon_yellow'
        ] as const,
        seeded
      );
    case 'nonagons':
      return getRandomFromArray(
        [
          'Nonagons/Irregular_nonagon_white',
          'Nonagons/Irregular_nonagon_blue',
          'Nonagons/Irregular_nonagon_green',
          'Nonagons/Irregular_nonagon_pink',
          'Nonagons/Irregular_nonagon_purple',
          'Nonagons/Irregular_nonagon_yellow'
        ] as const,
        seeded
      );
    case 'decagons':
      return getRandomFromArray(
        [
          'Other_shapes/Irregular_decagon1',
          'Other_shapes/Irregular_decagon2',
          'Other_shapes/Irregular_decagon2_blue',
          'Other_shapes/Irregular_decagon2_green',
          'Other_shapes/Irregular_decagon2_pink',
          'Other_shapes/Irregular_decagon2_purple',
          'Other_shapes/Irregular_decagon2_yellow'
        ] as const,
        seeded
      );
    case 'dodecagons':
      return getRandomFromArray(
        [
          'Dodecagons/Irregular_dodecagon1_white',
          'Dodecagons/Irregular_dodecagon1_blue',
          'Dodecagons/Irregular_dodecagon1_green',
          'Dodecagons/Irregular_dodecagon1_pink',
          'Dodecagons/Irregular_dodecagon1_purple',
          'Dodecagons/Irregular_dodecagon1_yellow',
          'Dodecagons/Irregular_dodecagon2_white',
          'Dodecagons/Irregular_dodecagon2_blue',
          'Dodecagons/Irregular_dodecagon2_green',
          'Dodecagons/Irregular_dodecagon2_pink',
          'Dodecagons/Irregular_dodecagon2_purple',
          'Dodecagons/Irregular_dodecagon2_yellow'
        ] as const,
        seeded
      );
  }
};

export const getRandomIrregularShapeSvgNames = (quantity: number) => {
  return getRandomSubArrayFromArray(irregularShapesSvgs, quantity);
};

export const irregularShapesWithArrowsSvgs: Record<
  'triangles' | 'quadrilaterals' | 'pentagons' | 'hexagons' | 'octagons',
  {
    svgName: SvgName;
    sideRatios: number[];
    labelComponent: 'shape' | 'triangle' | 'quadrilateral';
  }[]
> = {
  triangles: [
    {
      svgName: getRandomFromArray([
        'Shapes_with_dimension_arrows/triangle_isos_narrow_purple_arrows',
        'Shapes_with_dimension_arrows/triangle_isos_narrow_blue_arrows',
        'Shapes_with_dimension_arrows/triangle_isos_narrow_green_arrows',
        'Shapes_with_dimension_arrows/triangle_isos_narrow_orange_arrows',
        'Shapes_with_dimension_arrows/triangle_isos_narrow_red_arrows'
      ] as const),
      sideRatios: [2, 1, 2],
      labelComponent: 'triangle'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_dimension_arrows/triangle_scalene_purple_arrows',
        'Shapes_with_dimension_arrows/triangle_scalene_blue_arrows',
        'Shapes_with_dimension_arrows/triangle_scalene_green_arrows',
        'Shapes_with_dimension_arrows/triangle_scalene_orange_arrows',
        'Shapes_with_dimension_arrows/triangle_scalene_red_arrows'
      ] as const),
      sideRatios: [2, 3, 1],
      labelComponent: 'shape'
    }
  ],
  quadrilaterals: [
    {
      svgName: getRandomFromArray([
        'Shapes_with_dimension_arrows/rectangle_blue_arrows_4',
        'Shapes_with_dimension_arrows/rectangle_green_arrows_4',
        'Shapes_with_dimension_arrows/rectangle_orange_arrows_4',
        'Shapes_with_dimension_arrows/rectangle_purple_arrows_4',
        'Shapes_with_dimension_arrows/rectangle_red_arrows_4'
      ] as const),
      sideRatios: [2, 1, 2, 1],
      labelComponent: 'quadrilateral'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows',
        'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows',
        'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows'
      ] as const),
      sideRatios: [1, 2, 3, 2],
      labelComponent: 'shape'
    }
  ],
  pentagons: [
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue',
        'Shapes_with_arrows/Irregular_pentagon1_all_arrows_green',
        'Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink',
        'Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple',
        'Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow'
      ] as const),
      sideRatios: [1, 1, 2, 1, 1],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_pentagon2_all_arrows_blue',
        'Shapes_with_arrows/Irregular_pentagon2_all_arrows_green',
        'Shapes_with_arrows/Irregular_pentagon2_all_arrows_pink',
        'Shapes_with_arrows/Irregular_pentagon2_all_arrows_purple',
        'Shapes_with_arrows/Irregular_pentagon2_all_arrows_yellow'
      ] as const),
      sideRatios: [4, 1, 3, 4, 2],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_pentagon3_all_arrows_blue',
        'Shapes_with_arrows/Irregular_pentagon3_all_arrows_green',
        'Shapes_with_arrows/Irregular_pentagon3_all_arrows_pink',
        'Shapes_with_arrows/Irregular_pentagon3_all_arrows_purple',
        'Shapes_with_arrows/Irregular_pentagon3_all_arrows_yellow'
      ] as const),
      sideRatios: [4, 1, 3, 4, 2],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue',
        'Shapes_with_arrows/Irregular_pentagon4_all_arrows_green',
        'Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink',
        'Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple',
        'Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow'
      ] as const),
      sideRatios: [1, 2, 4, 2, 1],
      labelComponent: 'shape'
    }
  ],
  hexagons: [
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue',
        'Shapes_with_arrows/Irregular_hexagon1_all_arrows_green',
        'Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink',
        'Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple',
        'Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow'
      ] as const),
      sideRatios: [4, 1, 3, 3, 1, 4],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_hexagon2_all_arrows_blue',
        'Shapes_with_arrows/Irregular_hexagon2_all_arrows_green',
        'Shapes_with_arrows/Irregular_hexagon2_all_arrows_pink',
        'Shapes_with_arrows/Irregular_hexagon2_all_arrows_purple',
        'Shapes_with_arrows/Irregular_hexagon2_all_arrows_yellow'
      ] as const),
      sideRatios: [1, 2, 4, 2, 5, 4],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_hexagon3_all_arrows_blue',
        'Shapes_with_arrows/Irregular_hexagon3_all_arrows_green',
        'Shapes_with_arrows/Irregular_hexagon3_all_arrows_pink',
        'Shapes_with_arrows/Irregular_hexagon3_all_arrows_purple',
        'Shapes_with_arrows/Irregular_hexagon3_all_arrows_yellow'
      ] as const),
      sideRatios: [4, 1, 3, 3, 1, 4],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_hexagon4_all_arrows_blue',
        'Shapes_with_arrows/Irregular_hexagon4_all_arrows_green',
        'Shapes_with_arrows/Irregular_hexagon4_all_arrows_pink',
        'Shapes_with_arrows/Irregular_hexagon4_all_arrows_purple',
        'Shapes_with_arrows/Irregular_hexagon4_all_arrows_yellow'
      ] as const),
      sideRatios: [3, 4, 1, 2, 4, 5],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue',
        'Shapes_with_arrows/Irregular_hexagon5_all_arrows_green',
        'Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink',
        'Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple',
        'Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow'
      ] as const),
      sideRatios: [4, 3, 3, 4, 3, 3],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_hexagon6_all_arrows_blue',
        'Shapes_with_arrows/Irregular_hexagon6_all_arrows_green',
        'Shapes_with_arrows/Irregular_hexagon6_all_arrows_pink',
        'Shapes_with_arrows/Irregular_hexagon6_all_arrows_purple',
        'Shapes_with_arrows/Irregular_hexagon6_all_arrows_yellow'
      ] as const),
      sideRatios: [4, 1, 3, 2, 1, 3],
      labelComponent: 'shape'
    },
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_hexagon7_all_arrows_blue',
        'Shapes_with_arrows/Irregular_hexagon7_all_arrows_green',
        'Shapes_with_arrows/Irregular_hexagon7_all_arrows_pink',
        'Shapes_with_arrows/Irregular_hexagon7_all_arrows_purple',
        'Shapes_with_arrows/Irregular_hexagon7_all_arrows_yellow'
      ] as const),
      sideRatios: [3, 4, 2, 1, 1, 2],
      labelComponent: 'shape'
    }
  ],
  octagons: [
    {
      svgName: getRandomFromArray([
        'Shapes_with_arrows/Irregular_octagon1_all_arrows_blue',
        'Shapes_with_arrows/Irregular_octagon1_all_arrows_green',
        'Shapes_with_arrows/Irregular_octagon1_all_arrows_pink',
        'Shapes_with_arrows/Irregular_octagon1_all_arrows_purple',
        'Shapes_with_arrows/Irregular_octagon1_all_arrows_yellow'
      ] as const),
      sideRatios: [2, 1, 1, 3, 2, 2, 2, 3],
      labelComponent: 'shape'
    }
  ]
};

export const irregularWithArrows = [
  'Shapes_with_dimension_arrows/triangle_isos_narrow_purple_arrows',
  'Shapes_with_dimension_arrows/triangle_isos_narrow_blue_arrows',
  'Shapes_with_dimension_arrows/triangle_isos_narrow_green_arrows',
  'Shapes_with_dimension_arrows/triangle_isos_narrow_orange_arrows',
  'Shapes_with_dimension_arrows/triangle_isos_narrow_red_arrows',
  'Shapes_with_dimension_arrows/triangle_scalene_purple_arrows',
  'Shapes_with_dimension_arrows/triangle_scalene_blue_arrows',
  'Shapes_with_dimension_arrows/triangle_scalene_green_arrows',
  'Shapes_with_dimension_arrows/triangle_scalene_orange_arrows',
  'Shapes_with_dimension_arrows/triangle_scalene_red_arrows',
  'Shapes_with_dimension_arrows/rectangle_blue_arrows_4',
  'Shapes_with_dimension_arrows/rectangle_green_arrows_4',
  'Shapes_with_dimension_arrows/rectangle_orange_arrows_4',
  'Shapes_with_dimension_arrows/rectangle_purple_arrows_4',
  'Shapes_with_dimension_arrows/rectangle_red_arrows_4',
  'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows',
  'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows',
  'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows',
  'Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue',
  'Shapes_with_arrows/Irregular_pentagon1_all_arrows_green',
  'Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink',
  'Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple',
  'Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_pentagon2_all_arrows_blue',
  'Shapes_with_arrows/Irregular_pentagon2_all_arrows_green',
  'Shapes_with_arrows/Irregular_pentagon2_all_arrows_pink',
  'Shapes_with_arrows/Irregular_pentagon2_all_arrows_purple',
  'Shapes_with_arrows/Irregular_pentagon2_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_pentagon3_all_arrows_blue',
  'Shapes_with_arrows/Irregular_pentagon3_all_arrows_green',
  'Shapes_with_arrows/Irregular_pentagon3_all_arrows_pink',
  'Shapes_with_arrows/Irregular_pentagon3_all_arrows_purple',
  'Shapes_with_arrows/Irregular_pentagon3_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue',
  'Shapes_with_arrows/Irregular_pentagon4_all_arrows_green',
  'Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink',
  'Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple',
  'Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue',
  'Shapes_with_arrows/Irregular_hexagon1_all_arrows_green',
  'Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink',
  'Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple',
  'Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_hexagon2_all_arrows_blue',
  'Shapes_with_arrows/Irregular_hexagon2_all_arrows_green',
  'Shapes_with_arrows/Irregular_hexagon2_all_arrows_pink',
  'Shapes_with_arrows/Irregular_hexagon2_all_arrows_purple',
  'Shapes_with_arrows/Irregular_hexagon2_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_hexagon3_all_arrows_blue',
  'Shapes_with_arrows/Irregular_hexagon3_all_arrows_green',
  'Shapes_with_arrows/Irregular_hexagon3_all_arrows_pink',
  'Shapes_with_arrows/Irregular_hexagon3_all_arrows_purple',
  'Shapes_with_arrows/Irregular_hexagon3_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_hexagon4_all_arrows_blue',
  'Shapes_with_arrows/Irregular_hexagon4_all_arrows_green',
  'Shapes_with_arrows/Irregular_hexagon4_all_arrows_pink',
  'Shapes_with_arrows/Irregular_hexagon4_all_arrows_purple',
  'Shapes_with_arrows/Irregular_hexagon4_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue',
  'Shapes_with_arrows/Irregular_hexagon5_all_arrows_green',
  'Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink',
  'Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple',
  'Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_hexagon6_all_arrows_blue',
  'Shapes_with_arrows/Irregular_hexagon6_all_arrows_green',
  'Shapes_with_arrows/Irregular_hexagon6_all_arrows_pink',
  'Shapes_with_arrows/Irregular_hexagon6_all_arrows_purple',
  'Shapes_with_arrows/Irregular_hexagon6_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_hexagon7_all_arrows_blue',
  'Shapes_with_arrows/Irregular_hexagon7_all_arrows_green',
  'Shapes_with_arrows/Irregular_hexagon7_all_arrows_pink',
  'Shapes_with_arrows/Irregular_hexagon7_all_arrows_purple',
  'Shapes_with_arrows/Irregular_hexagon7_all_arrows_yellow',
  'Shapes_with_arrows/Irregular_octagon1_all_arrows_blue',
  'Shapes_with_arrows/Irregular_octagon1_all_arrows_green',
  'Shapes_with_arrows/Irregular_octagon1_all_arrows_pink',
  'Shapes_with_arrows/Irregular_octagon1_all_arrows_purple',
  'Shapes_with_arrows/Irregular_octagon1_all_arrows_yellow'
] as const;

export type IrregularWithArrowsNames = (typeof irregularWithArrows)[number];
