import React, { useCallback, useEffect, useState, useRef } from 'react';
import { PanResponder, View } from 'react-native';
import useLoginStore from '../storage/useLoginStore';
import { useNavigation } from '@react-navigation/native';
import { RootStackNavigationProp } from '../navigation/types';
import InactivityModal from './InactivityModal';
import { resetTo } from '../navigation/actions';

const MAX_TIME_INACTIVITY = 570_000; //in ms (9.5 mins)
const WARNING_INACTIVITY = 30_000; //in ms (30 secs)
const INACTIVITY_CHECK_INTERVAL_MS = 1_000; //in ms (1 sec)
type LogoutWrapperProps = {
  children: React.JSX.Element;
};

/**
 * Component that automatically navigates home when the user logs out.
 *
 * Has an auto log out feature; timer gets reset everytime the user makes a tap on the screen
 *
 * Possible future enhancement: show a dialogue here explaining what happened, and
 * navigate to home upon dismissing that dialogue.
 */
export const LogoutWrapper = ({ children }: LogoutWrapperProps) => {
  const loggedInUser = useLoginStore(state => state.loggedInUser);
  const lastActive = useLoginStore(state => state.lastActive);
  const setActivity = useLoginStore(state => state.setActivity);
  const clearLoggedInUser = useLoginStore(state => state.clearLoggedInUser);
  const navigation = useNavigation<RootStackNavigationProp>();

  // State for the auto log out warning countdown
  const [warningCountdown, setWarningCountdown] = useState(0);
  const [activityState, setActivityState] = useState<'ACTIVE' | 'WARNING' | undefined>(undefined);

  // Keep track of whether we were logged in on the previous render. We only want to invoke the code to navigate home
  // if we transition from logged in to logged out.
  const wasLoggedInRef = useRef<boolean | null>(null);

  // Navigate home if we have gone from logged in to logged out.
  useEffect(() => {
    const isLoggedIn = loggedInUser !== undefined;
    if (wasLoggedInRef.current && !isLoggedIn) {
      // We were logged in and now we're not.
      navigation.dispatch(resetTo({ name: 'Home' }));
    }
    wasLoggedInRef.current = isLoggedIn;
  }, [loggedInUser, navigation]);

  const panResponder = useCallback(() => {
    return PanResponder.create({
      onStartShouldSetPanResponderCapture: () => {
        if (loggedInUser) {
          setActivity();
          setActivityState('ACTIVE');
        }
        return false;
      }
    });
  }, [loggedInUser, setActivity]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (loggedInUser && lastActive) {
        if (lastActive + MAX_TIME_INACTIVITY + WARNING_INACTIVITY <= Date.now()) {
          clearLoggedInUser();
          setActivityState(undefined);
        } else if (lastActive + MAX_TIME_INACTIVITY <= Date.now()) {
          setActivityState('WARNING');
          setWarningCountdown(
            Math.floor((lastActive + MAX_TIME_INACTIVITY + WARNING_INACTIVITY - Date.now()) / 1000)
          );
        }
      }
    }, INACTIVITY_CHECK_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [lastActive, loggedInUser, clearLoggedInUser]);

  return (
    <>
      {activityState === 'WARNING' && (
        <InactivityModal
          onLogout={() => {
            clearLoggedInUser();
            setActivityState(undefined);
            return;
          }}
          onStay={() => {
            setActivity();
            setActivityState('ACTIVE');
            return;
          }}
          secondsRemaining={warningCountdown < 10 ? `0${warningCountdown}` : `${warningCountdown}`}
        />
      )}
      <View style={{ flex: 1 }} {...panResponder().panHandlers}>
        {children}
      </View>
    </>
  );
};
