import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { range, rangeAsString } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bga',
  description: 'bga',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(40).step(10),
    answerIndices: z.array(z.number()),
    intervals: numberEnum([6, 7, 8, 9, 10])
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArrayWithWeights([10, 6, 7, 8, 9] as const, [4, 1, 1, 1, 1]);
    const numberOfAnswers = getRandomFromArray([3, 4, 5]);
    const answerIndices = randomUniqueIntegersInclusive(0, intervals - 1, numberOfAnswers);

    const startingNumber =
      intervals === 10 ? 0 : randomIntegerInclusiveStep(0, 100 - intervals * 10, 10);

    return { startingNumber, intervals, answerIndices };
  },
  Component: props => {
    const {
      question: { startingNumber, intervals, answerIndices },
      translate
    } = props;

    const tickValues = rangeAsString(startingNumber, startingNumber + intervals * 10, 10, true);

    const numberArray = tickValues.map((number, index) =>
      answerIndices.includes(index) ? `<ans/>` : number.toString()
    );

    const answerArray = tickValues.flatMap((number, idx) =>
      answerIndices.includes(idx) ? [number.toString()] : []
    );

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.completeTheNumberLine()}
        testCorrect={answerArray}
        tickValues={numberArray}
        answerPositions="bottom"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgb',
  description: 'bgb',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(40).step(10),
    intervals: numberEnum([6, 7, 8, 9, 10]),
    answerIndex: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArrayWithWeights([10, 6, 7, 8, 9] as const, [4, 1, 1, 1, 1]);

    const startingNumber =
      intervals === 10 ? 0 : randomIntegerInclusiveStep(0, 100 - intervals * 10, 10);

    const answerIndex = randomIntegerInclusive(1, intervals - 1);

    return { startingNumber, answerIndex, intervals };
  },
  Component: props => {
    const {
      question: { startingNumber, answerIndex, intervals },
      translate
    } = props;

    const tickValues = rangeAsString(startingNumber, startingNumber + intervals * 10, 10, true).map(
      (el, index) => ([0, intervals].includes(index) ? el : '')
    );

    const answer = startingNumber + answerIndex * 10;

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[answer.toString()]}
        tickValues={tickValues}
        freeNumberLineAnswer={[answer]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bgc',
  description: 'bgc',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(40).step(10),
    number: z.number().int().min(10).max(90),
    intervals: numberEnum([6, 7, 8, 9, 10])
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArrayWithWeights([10, 6, 7, 8, 9] as const, [4, 1, 1, 1, 1]);

    const startingNumber =
      intervals === 10 ? 0 : randomIntegerInclusiveStep(0, 100 - intervals * 10, 10);

    const choices = range(startingNumber + 10, startingNumber + intervals * 10 - 10, 10);

    const number = getRandomFromArray(choices);

    return { startingNumber, number, intervals };
  },
  Component: props => {
    const {
      question: { startingNumber, number, intervals },
      translate
    } = props;

    const tickValues = rangeAsString(startingNumber, startingNumber + intervals * 10, 10, true).map(
      (el, index) => ([0, intervals].includes(index) ? el : '')
    );

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragTheArrowToShowWhereNumBelongsOnTheNumberLine(number)}
        pdfTitle={translate.ks1PDFInstructions.drawAnArrowToShowWhereNumBelongsOnTheNumberLine(
          number
        )}
        testCorrect={[number, number]}
        min={startingNumber}
        max={startingNumber + intervals * 10}
        sliderStep={1}
        tickValues={tickValues}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TensOnTheNumberLineTo100',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
