/** Must be URL-safe (avoid spaces, punctuation, etc.). */
// Generated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts translations-steps
export default {
  ////
  // Year 1
  ////

  // Block 1 - Place value (within 10)
  SortObjects: 'Sort objects',
  CountObjects: 'Count objects',
  CountObjectsFromALargerGroup: 'Count objects from a larger group',
  RepresentObjects: 'Represent objects',
  RecogniseNumbersAsWords: 'Recognise numbers as words',
  CountOnFromAnyNumber: 'Count on from any number',
  OneMore: '1 more',
  CountBackwardsWithin10: 'Count backwards within 10',
  OneLess: '1 less',
  CompareGroupsByMatching: 'Compare groups by matching',
  FewerMoreSame: 'Fewer, more, same',
  LessThanGreaterThanEqualTo: 'Less than, greater than, equal to',
  CompareNumbers: 'Compare numbers',
  OrderObjectsAndNumbers: 'Order objects and numbers',
  TheNumberLine: 'The number line',

  // Block 2 - Addition and subtraction (within 10)
  IntroducePartsAndWholes: 'Introduce parts and wholes',
  PartWholeModel: 'Part-whole model',
  WriteNumberSentences: 'Write number sentences',
  FactFamiliesAdditionFacts: 'Fact families - addition facts',
  NumberBondsWithin10: 'Number bonds within 10',
  SystematicNumberBondsWithin10: 'Systematic number bonds within 10',
  NumberBondsTo10: 'Number bonds to 10',
  AdditionAddTogether: 'Addition - add together',
  AdditionAddMore: 'Addition - add more',
  AdditionProblems: 'Addition problems',
  FindAPart: 'Find a part',
  SubtractionFindAPart: 'Subtraction - find a part',
  FactFamiliesTheEightFacts: 'Fact families - the eight facts',
  SubtractionTakeAwayCrossOut: 'Subtraction - take away/cross out (How many left?)',
  SubtractionTakeAway: 'Subtraction - take away (How many left?)',
  SubtractionOnANumberLine: 'Subtraction on a number line',
  AddOrSubtract1Or2: 'Add or subtract 1 or 2',

  // Block 3 - Shape
  RecogniseAndName3DShapes: 'Recognise and name 3-D shapes',
  Sort3DShapes: 'Sort 3-D shapes',
  RecogniseAndName2DShapes: 'Recognise and name 2-D shapes',
  Sort2DShapes: 'Sort 2-D shapes',
  PatternsWith2DAnd3DShapes: 'Patterns with 2-D and 3-D shapes',

  // Block 4 - Place value (within 20)
  CountWithin20: 'Count within 20',
  Understand10: 'Understand 10',
  Understand1112And13: 'Understand 11, 12 and 13',
  Understand1415And16: 'Understand 14, 15 and 16',
  Understand1718And19: 'Understand 17, 18 and 19',
  Understand20: 'Understand 20',
  OneMoreAndOneLess: '1 more and 1 less',
  TheNumberLineTo20: 'The number line to 20',
  UseANumberLineTo20: 'Use a number line to 20',
  EstimateOnANumberLineTo20: 'Estimate on a number line to 20',
  CompareNumbersTo20: 'Compare numbers to 20',
  OrderNumbersTo20: 'Order numbers to 20',

  // Block 5 - Addition and subtraction (within 20)
  AddByCountingOnWithin20: 'Add by counting on within 20',
  AddOnesUsingNumberBonds: 'Add ones using number bonds',
  FindAndMakeNumberBondsTo20: 'Find and make number bonds to 20',
  Doubles: 'Doubles',
  NearDoubles: 'Near doubles',
  SubtractOnesUsingNumberBonds: 'Subtract ones using number bonds',
  SubtractionCountingBack: 'Subtraction - counting back',
  SubtractionFindingTheDifference: 'Subtraction - finding the difference',
  RelatedFacts: 'Related facts',
  MissingNumberProblems: 'Missing number problems',

  // Block 6 - Place value (within 50)
  CountFrom20To50: 'Count from 20 to 50',
  TwentyThirtyFortyAndFifty: '20, 30, 40 and 50',
  CountByMakingGroupsOfTens: 'Count by making groups of tens',
  GroupsOfTensAndOnes: 'Groups of tens and ones',
  PartitionIntoTensAndOnes: 'Partition into tens and ones',
  TheNumberLineTo50: 'The number line to 50',
  EstimateOnANumberLineTo50: 'Estimate on a number line to 50',
  OneMoreOneLess: '1 more, 1 less',

  // Block 7 - Length and height
  CompareLengthsAndHeights: 'Compare lengths and heights',
  MeasureLengthUsingObjects: 'Measure length using objects',
  MeasureLengthInCentimetres: 'Measure length in centimetres',

  // Block 8 - Mass and volume
  HeavierAndLighter: 'Heavier and lighter',
  MeasureMass: 'Measure mass',
  //CompareMass: 'Compare mass',
  FullAndEmpty: 'Full and empty',
  //CompareVolume: 'Compare volume',
  MeasureCapacity: 'Measure capacity',
  CompareCapacity: 'Compare capacity',

  // Block 9 - Multiplication and division
  CountIn2s: 'Count in 2s',
  CountIn10s: 'Count in 10s',
  CountIn5s: 'Count in 5s',
  RecogniseEqualGroups: 'Recognise equal groups',
  AddEqualGroups: 'Add equal groups',
  MakeArrays: 'Make arrays',
  MakeDoubles: 'Make doubles',
  MakeEqualGroupsGrouping: 'Make equal groups - grouping',
  MakeEqualGroupsSharing: 'Make equal groups - sharing',

  // Block 10 - Fractions
  RecogniseAHalfOfAnObjectOrAShape: 'Recognise a half of an object or a shape',
  FindAHalfOfAnObjectOrAShape: 'Find a half of an object or a shape',
  RecogniseAHalfOfAQuantity: 'Recognise a half of a quantity',
  FindAHalfOfAQuantity: 'Find a half of a quantity',
  RecogniseAQuarterOfAnObjectOrAShape: 'Recognise a quarter of an object or a shape',
  FindAQuarterOfAnObjectOrAShape: 'Find a quarter of an object or a shape',
  RecogniseAQuarterOfAQuantity: 'Recognise a quarter of a quantity',
  FindAQuarterOfAQuantity: 'Find a quarter of a quantity',

  // Block 11 - Position and direction
  DescribeTurns: 'Describe turns',
  DescribePositionLeftAndRight: 'Describe position - left and right',
  DescribePositionForwardsAndBackwards: 'Describe position - forwards and backwards',
  DescribePositionAboveAndBelow: 'Describe position - above and below',
  OrdinalNumbers: 'Ordinal numbers',

  // Block 12 - Place value (within 100)
  CountFrom50To100: 'Count from 50 to 100',
  TensTo100: 'Tens to 100',
  //PartitionIntoTensAndOnes: 'Partition into tens and ones',
  TheNumberLineTo100: 'The number line to 100',
  //OneMoreOneLess: 'OneMoreOneLess',
  CompareNumbersWithTheSameNumberOfTens: 'Compare numbers with the same number of tens',
  CompareAnyTwoNumbers: 'Compare any two numbers',

  // Block 13 - Money
  Unitising: 'Unitising',
  RecogniseCoins: 'Recognise coins',
  RecogniseNotes: 'Recognise notes',
  CountInCoins: 'Count in coins',

  // Block 14 - Time
  BeforeAndAfter: 'Before and after',
  DaysOfTheWeek: 'Days of the week',
  MonthsOfTheYear: 'Months of the year',
  //HoursMinutesAndSeconds: 'Hours, minutes and seconds',
  TellTheTimeToTheHour: 'Tell the time to the hour',
  TellTheTimeToTheHalfHour: 'Tell the time to the half hour',

  ////
  // Year 2
  ////

  // Block 1 - Place Value
  NumbersTo20: 'Numbers to 20',
  CountObjectsTo100ByMaking10s: 'Count objects to 100 by making 10s',
  RecogniseTensAndOnes: 'Recognise tens and ones',
  UsePlaceValueChart: 'Use place value chart',
  WriteNumbersTo100InWords: 'Write numbers to 100 in words',
  FlexiblyPartitionNumbersTo100: 'Flexibly partition numbers to 100',
  WriteNumbersTo100InExpandedForm: 'Write numbers to 100 in expanded form',
  TensOnTheNumberLineTo100: '10s on the number line to 100',
  TensAndOnesOnTheNumberLineTo100: '10s and 1s on the number line to 100',
  EstimateNumbersOnANumberLine: 'Estimate numbers on a number line',
  CompareObjects: 'Compare objects',
  CountIn2s5sAnd10s: 'Count in 2s, 5s and 10s',
  CountIn3s: 'Count in 3s',

  // Block 2 - Addition and Subtraction
  BondsTo10: 'Bonds to 10',
  FactFamiliesAdditionSubtractionBondsWithin20:
    'Fact families - addition and subtraction bonds within 20',
  BondsTo100Tens: 'Bonds to 100 (tens)',
  AddByMaking10: 'Add by making 10',
  AddThree1DigitNumbers: 'Add three 1-digit numbers',
  AddToNext10: 'Add to next 10',
  AddAcrossA10: 'Add across a 10',
  SubtractAcross10: 'Subtract across 10',
  SubtractFromA10: 'Subtract from a 10',
  SubtractA1DigitNumberFromA2DigitNumberAcrossA10:
    'Subtract a 1-digit number from a 2-digit number (across a 10)',
  TenMoreTenLess: '10 more, 10 less',
  AddTwo2DigitNumbersNotAcrossA10: 'Add two 2-digit numbers (not across a 10)',
  AddTwo2DigitNumbersAcrossA10: 'Add two 2-digit numbers (across a 10)',
  SubtractTwo2DigitNumbersNotAcrossA10: 'Subtract two 2-digit numbers (not across a 10)',
  SubtractTwo2DigitNumbersAcrossA10: 'Subtract two 2-digit numbers (across a 10)',
  MixedAdditionAndSubtraction: 'Mixed addition and subtraction',
  CompareNumberSentences: 'Compare number sentences',

  // Block 3 - Shape
  Recognise2DAnd3DShapes: 'Recognise 2-D and 3-D shapes',
  CountSidesOn2DShapes: 'Count sides on 2-D shapes',
  CountVerticesOn2DShapes: 'Count vertices on 2-D shapes',
  Draw2DShapes: 'Draw 2-D shapes',
  LinesOfSymmetryOnShapes: 'Lines of symmetry on shapes',
  UseLinesOfSymmetryToCompleteShapes: 'Use lines of symmetry to complete shapes',
  CountFacesOn3DShapes: 'Count faces on 3-D shapes',
  CountEdgesOn3DShapes: 'Count edges on 3-D shapes',
  CountVerticesOn3DShapes: 'Count vertices on 3-D shapes',
  MakePatternsWith2DAnd3DShapes: 'Make patterns with 2-D and 3-D shapes',

  // Block 4 - Money
  CountMoneyPence: 'Count money - pence',
  CountMoneyPoundsNotesAndCoins: 'Count money - pounds (notes and coins)',
  CountMoneyPoundsAndPence: 'Count money - pounds and pence',
  ChooseNotesAndCoins: 'Choose notes and coins',
  MakeTheSameAmount: 'Make the same amount',
  MakeAPound: 'Make a pound',
  TwoStepProblems: 'Two-step problems',

  // Block 5 - Multiplication and Division
  MakeEqualGroups: 'Make equal groups',
  IntroduceMultiplicationSymbol: 'Introduce the multiplication symbol',
  MultiplicationSentences: 'Multiplication sentences',
  The2TimesTable: 'The 2 times-table',
  DivideBy2: 'Divide by 2',
  DoublingAndHalving: 'Double and halving',
  OddAndEvenNumbers: 'Odd and even numbers',
  The10TimesTable: 'The 10 times-table',
  The5TimesTable: 'The 5 times-table',
  DivideBy5: 'Divide by 5',
  The5And10TimesTables: 'The 5 and 10 times-tables',

  // Block 6 - Length and height
  MeasureInCentimetres: 'Measure in centimetres',
  MeasureInMetres: 'Measure in metres',
  OrderLengthsAndHeights: 'Order lengths and heights',
  FourOperationsWithLengthsAndHeights: 'Four operations with lengths and heights',

  // Block 7 - Mass, capacity and temperature
  MeasureInGrams: 'Measure in grams',
  MeasureInKilograms: 'Measure in kilograms',
  FourOperationsWithMass: 'Four operations with mass',
  CompareVolumeAndCapacity: 'Compare volume and capacity',
  MeasureInMillilitres: 'Measure in millilitres',
  MeasureInLitres: 'Measure in litres',
  FourOperationsWithVolumeAndCapacity: 'Four operations with volume and capacity',
  Temperature: 'Temperature',

  // Block 8 - Fractions
  IntroductionToPartsAndWhole: 'Introduction to parts and whole',
  EqualAndUnequalParts: 'Equal and unequal parts',
  RecogniseAHalf: 'Recognise a half',
  FindAHalf: 'Find a half',
  RecogniseAQuarter: 'Recognise a quarter',
  FindAQuarter: 'Find a quarter',
  RecogniseAThird: 'Recognise a third',
  FindAThird: 'Find a third',
  UnitFractions: 'Unit fractions',
  NonUnitFractions: 'Non-unit fractions',
  RecogniseEquivalenceOfHalfAndTwoQuarters: 'Recognise the equivalence of a half and two quarters',
  RecogniseThreeQuarters: 'Recognise three-quarters',
  FindThreeQuarters: 'Find three-quarters',
  CountInFractionsUpToAWhole: 'Count in fractions up to a whole',

  // Block 9 - Time
  OClockAndHalfPast: "O'clock and half past",
  QuarterPastAndQuarterTo: 'Quarter past and quarter to',
  TellTimePastTheHour: 'Tell time past the hour',
  TellTimeTimeToTheHour: 'Tell time to the hour',
  TellTheTimeTo5MinutesY2: 'Tell the time to 5 minutes',
  MinutesInAnHour: 'Minutes in an hour',
  HoursInADay: 'Hours in a day',

  // Block 10 - Statistics
  MakeTallyCharts: 'Make tally charts',
  Tables: 'Tables',
  BlockDiagrams: 'Block diagrams',
  DrawPictograms1To1: 'Draw pictograms (1-1)',
  InterpretPictograms1To1: 'Interpret pictograms (1-1)',
  DrawPictograms25And10: 'Draw pictograms (2, 5 and 10)',
  InterpretPictograms25And10: 'Interpret pictograms (2, 5 and 10)',

  // Block 11 - Position and direction
  LanguageOfPosition: 'Language of position',
  DescribeMovement: 'Describe movement',
  DescribeMovementAndTurns: 'Describe movement and turns',
  ShapePatternsWithTurns: 'Shape patterns with turns',

  ////
  // Year 3
  ////

  // Block 1 - Place value
  RepresentNumbersTo100: 'Represent numbers to 100',
  PartitionNumbersTo100: 'Partition numbers to 100',
  NumberLineTo100: 'Number line to 100',
  Hundreds: 'Hundreds',
  RepresentNumbersTo1000: 'Represent numbers to 1,000',
  PartitionNumbersTo1000: 'Partition numbers to 1,000',
  FlexiblePartitioningOfNumbersTo1000: 'Flexible partitioning of numbers to 1,000',
  HundredsTensAndOnes: 'Hundreds, tens and ones',
  Find1To100MoreOrLess: 'Find 1, 10 or 100 more or less',
  NumberLineTo1000: 'Number line to 1,000',
  EstimateOnANumberLineTo1000: 'Estimate on a number line to 1,000',
  CompareNumbersTo1000: 'Compare numbers to 1,000',
  OrderNumbersTo1000: 'Order numbers to 1,000',
  CountIn50s: 'Count in 50s',

  // Block 2 - Addition and subtraction
  ApplyNumberBondsWithin10: 'Apply number bonds within 10',
  AddAndSubtract1s: 'Add and subtract 1s',
  AddAndSubtract10s: 'Add and subtract 10s',
  AddAndSubtract100s: 'Add and subtract 100s',
  SpotThePattern: 'Spot the pattern',
  Add1sAcrossA10: 'Add 1s across a 10',
  Add10sAcrossA100: 'Add 10s across a 100',
  Subtract1sAcrossA10: 'Subtract 1s across a 10',
  Subtract10sAcrossA100: 'Subtract 10s across a 100',
  MakeConnections: 'Make connections',
  AddTwoNumbersNoExchange: 'Add two numbers (no exchange)',
  SubtractTwoNumbersNoExchange: 'Subtract two numbers (no exchange)',
  AddTwoNumbersAcrossA10: 'Add two numbers (across a 10)',
  AddTwoNumbersAcrossA100: 'Add two numbers (across a 100)',
  SubtractTwoNumbersAcrossA10: 'Subtract two numbers (across a 10)',
  SubtractTwoNumbersAcrossA100: 'Subtract two numbers (across a 100)',
  Add2DigitAnd3DigitNumbers: 'Add 2-digit and 3-digit numbers',
  SubtractA2DigitNumberFromA3DigitNumber: 'Subtract a 2-digit number from a 3-digit number',
  ComplementsTo100: 'Complements to 100',
  EstimateAnswers: 'Estimate answers',
  InverseOperations: 'Inverse operations',
  MakeDecisions: 'Make decisions',

  // Block 3 - Multiplication and division A
  MultiplicationEqualGroups: 'Multiplication - equal groups',
  UseArrays: 'Use arrays',
  MultiplesOf2: 'Multiples of 2',
  MultiplesOf5And10: 'Multiples of 5 and 10',
  SharingAndGrouping: 'Sharing and grouping',
  MultiplyBy3: 'Multiply by 3',
  DivideBy3: 'Divide by 3',
  The3TimesTable: 'The 3 times-table',
  MultiplyBy4: 'Multiply by 4',
  DivideBy4: 'Divide by 4',
  The4TimesTable: 'The 4 times-table',
  MultiplyBy8: 'Multiply by 8',
  DivideBy8: 'Divide by 8',
  The8TimesTable: 'The 8 times-table',
  The24And8TimesTables: 'The 2, 4 and 8 times-tables',

  // Block 4 - Multiplication and division B
  MultiplesOf10: 'Multiples of 10',
  RelatedCalculations: 'Related calculations',
  ReasoningAboutMultiplication: 'Reasoning about multiplication',
  MultiplyA2DigitNumberByA1DigitNumberNoExchange:
    'Multiply a 2-digit number by a 1-digit number - no exchange',
  MultiplyA2DigitNumberByA1DigitNumberWithExchange:
    'Multiply a 2-digit number by a 1-digit number - with exchange',
  LinkMultiplicationAndDivision: 'Link multiplication and division',
  DivideA2DigitNumberByA1DigitNumberNoExchange:
    'Divide a 2-digit number by a 1-digit number - no exchange',
  DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning:
    'Divide a 2-digit number by a 1-digit number - flexible partitioning',
  DivideA2DigitNumberByA1DigitNumberWithRemainders:
    'Divide a 2-digit number by a 1-digit number - with remainders',
  Scaling: 'Scaling',
  HowManyWays: 'How many ways?',

  // Block 5 - Length and perimeter
  MeasureInMetresAndCentimetres: 'Measure in metres and centimetres',
  MeasureInMillimetres: 'Measure in millimetres',
  MeasureInCentimetresAndMillimetres: 'Measure in centimetres and millimetres',
  MetresCentimetresAndMillimetres: 'Metres, centimetres and millimetres',
  EquivalentLengthsMetresAndCentimetres: 'Equivalent lengths (metres and centimetres)',
  EquivalentLengthsCentimetresAndMillimetres: 'Equivalent lengths (centimetres and millimetres)',
  CompareLengths: 'Compare lengths',
  AddLengths: 'Add lengths',
  SubtractLengths: 'Subtract lengths',
  WhatIsPerimeter: 'What is perimeter?',
  MeasurePerimeter: 'Measure perimeter',
  CalculatePerimeter: 'Calculate perimeter',

  // Block 6 - Fractions A
  UnderstandTheDenominatorsOfUnitFractions: 'Understand the denominators of unit fractions',
  CompareAndOrderUnitFractions: 'Compare and order unit fractions',
  UnderstandTheNumeratorsOfNonUnitFractions: 'Understand the numerators of non-unit fractions',
  UnderstandTheWhole: 'Understand the whole',
  CompareAndOrderNonUnitFractions: 'Compare and order non-unit fractions',
  FractionsAndScales: 'Fractions and scales',
  FractionsOnANumberLine: 'Fractions on a number line',
  CountInFractionsOnANumberLine: 'Count in fractions on a number line',
  EquivalentFractionsOnANumberLine: 'Equivalent fractions on a number line',
  EquivalentFractionsAsBarModels: 'Equivalent fractions as bar models',

  // Block 7 - Mass and capacity
  UseScales: 'Use scales',
  MeasureMassInGrams: 'Measure mass in grams',
  MeasureMassInKilogramsAndGrams: 'Measure mass in kilograms and grams',
  EquivalentMassesKilogramsAndGrams: 'Equivalent masses (kilograms and grams)',
  CompareMass: 'Compare mass',
  AddAndSubtractMass: 'Add and subtract mass',
  MeasureCapacityAndVolumeInMillilitres: 'Measure capacity and volume in millilitres',
  MeasureCapacityAndVolumeInLitresAndMillilitres:
    'Measure capacity and volume in litres and millilitres',
  EquivalentCapacitiesAndVolumesLitresAndMillilitres:
    'Equivalent capacities and volumes (litres and millilitres)',
  CompareCapacityAndVolume: 'Compare capacity and volume',
  AddAndSubtractCapacityAndVolume: 'Add and subtract capacity and volume',

  // Block 8 - Fractions B
  AddFractions: 'Add fractions',
  SubtractFractions: 'Subtract fractions',
  PartitionTheWhole: 'Partition the whole',
  UnitFractionsOfASetOfObjects: 'Unit fractions of a set of objects',
  NonUnitFractionsOfASetOfObjects: 'Non-unit fractions of a set of objects',
  ReasoningWithFractionsOfAnAmount: 'Reasoning with fractions of an amount',

  // Block 9 - Money
  PoundsAndPence: 'Pounds and pence',
  ConvertPoundsAndPence: 'Convert pounds and pence',
  AddMoney: 'Add money',
  SubtractMoney: 'Subtract money',
  FindChange: 'Find change',

  // Block 10 - Time
  RomanNumeralsTo12: 'Roman numerals to 12',
  TellTheTimeTo5Minutes: 'Tell the time to 5 minutes',
  TellTheTimeToTheMinute: 'Tell the time to the minute',
  ReadTimeOnADigitalClock: 'Read time on a digital clock',
  UseAMAndPM: 'Use a.m. and p.m.',
  YearsMonthsAndDays: 'Years, months and days',
  DaysAndHours: 'Days and hours',
  HoursAndMinutesUseStartAndEndTimes: 'Hours and minutes - use start and end times',
  HoursAndMinutesUseDurations: 'Hours and minutes - use durations',
  MinutesAndSeconds: 'Minutes and seconds',
  UnitsOfTime: 'Units of time',
  SolveProblemsWithTime: 'Solve problems with time',

  // Block 11 - Shape
  TurnsAndAngles: 'Turns and angles',
  RightAngles: 'Right angles',
  CompareAngles: 'Compare angles',
  MeasureAndDrawAccurately: 'Measure and draw accurately',
  HorizontalAndVertical: 'Horizontal and vertical',
  ParallelAndPerpendicular: 'Parallel and perpendicular',
  RecogniseAndDescribe2DShapes: 'Recognise and describe 2-D shapes',
  DrawPolygons: 'Draw polygons',
  RecogniseAndDescribe3DShapes: 'Recognise and describe 3-D shapes',
  Make3DShapes: 'Make 3-D shapes',

  // Block 12 - Statistics
  InterpretPictograms: 'Interpret pictograms',
  DrawPictograms: 'Draw pictograms',
  InterpretBarCharts: 'Interpret bar charts',
  DrawBarCharts: 'Draw bar charts',
  CollectAndRepresentData: 'Collect and represent data',
  TwoWayTables: 'Two-way tables',

  ////
  // Year 4
  ////

  // Block 1 - Place value
  Thousands: 'Thousands',
  RepresentNumbersTo10000: 'Represent numbers to 10,000',
  PartitionNumbersTo10000: 'Partition numbers to 10,000',
  FlexiblePartitioningOfNumbersTo10000: 'Flexible partitioning of numbers to 10,000',
  Find1To1000MoreOrLess: 'Find 1, 10, 100, 1,000 more or less',
  NumberLineTo10000: 'Number line to 10,000',
  EstimateOnANumberLineTo10000: 'Estimate on a number line to 10,000',
  CompareNumbersTo10000: 'Compare numbers to 10,000',
  OrderNumbersTo10000: 'Order numbers to 10,000',
  RomanNumerals: 'Roman numerals',
  RoundToTheNearest10: 'Round to the nearest 10',
  RoundToTheNearest100: 'Round to the nearest 100',
  RoundToTheNearest1000: 'Round to the nearest 1,000',
  RoundToTheNearest10100Or1000: 'Round to the nearest 10, 100 or 1,000',

  // Block 2 - Addition and subtraction
  AddAndSubtract1s10s100sAnd1000s: 'Add and subtract 1s, 10s, 100s and 1,000s',
  AddUpToTwo4DigitNumbersNoExchange: 'Add up to two 4-digit numbers - no exchange',
  AddTwo4DigitNumbersOneExchange: 'Add two 4-digit numbers - one exchange',
  AddTwo4DigitNumbersMoreThanOneExchange: 'Add two 4-digit numbers - more than one exchange',
  SubtractTwo4DigitNumbersNoExchange: 'Subtract two 4-digit numbers - no exchange',
  SubtractTwo4DigitNumbersOneExchange: 'Subtract two 4-digit numbers - one exchange',
  SubtractTwo4DigitNumbersMoreThanOneExchange:
    'Subtract two 4-digit numbers - more than one exchange',
  EfficientSubtraction: 'Efficient subtraction',
  CheckingStrategies: 'Checking strategies',

  // Block 3 - Area
  WhatIsArea: 'What is area?',
  CountSquares: 'Count squares',
  MakeShapes: 'Make shapes',
  CompareAreas: 'Compare areas',

  // Block 4 - Multiplication and division A
  MultiplesOf3: 'Multiples of 3',
  MultiplyAndDivideBy6: 'Multiply and divide by 6',
  Num6TimesTableAndDivisionFacts: '6 times-table and division facts',
  MultiplyAndDivideBy9: 'Multiply and divide by 9',
  Num9TimesTableAndDivisionFacts: '9 times-table and division facts',
  The36And9TimesTables: 'The 3, 6 and 9 times-tables',
  MultiplyAndDivideBy7: 'Multiply and divide by 7',
  Num7TimesTableAndDivisionFacts: '7 times-table and division facts',
  Num11TimesTableAndDivisionFacts: '11 times-table and division facts',
  Num12TimesTableAndDivisionFacts: '12 times-table and division facts',
  MultiplyBy1And0: 'Multiply by 1 and 0',
  DivideANumberBy1AndItself: 'Divide a number by 1 and itself',
  MultiplyThreeNumbers: 'Multiply three numbers',

  // Block 5 - Multiplication and division B
  FactorPairs: 'Factor pairs',
  UseFactorPairs: 'Use factor pairs',
  MultiplyBy10: 'Multiply by 10',
  MultiplyBy100: 'Multiply by 100',
  DivideBy10: 'Divide by 10',
  DivideBy100: 'Divide by 100',
  RelatedFactsMultiplicationAndDivision: 'Related facts - multiplication and division',
  InformalWrittenMethodsForMultiplication: 'Informal written methods for multiplication',
  MultiplyA2DigitNumberByA1DigitNumber: 'Multiply a 2-digit number by a 1-digit number',
  MultiplyA3DigitNumberByA1DigitNumber: 'Multiply a 3-digit number by a 1-digit number',
  DivideA2DigitNumberByA1DigitNumber1: 'Divide a 2-digit number by a 1-digit number (1)',
  DivideA2DigitNumberByA1DigitNumber2: 'Divide a 2-digit number by a 1-digit number (2)',
  DivideA3DigitNumberByA1DigitNumber: 'Divide a 3-digit number by a 1-digit number',
  CorrespondenceProblems: 'Correspondence problems',
  EfficientMultiplication: 'Efficient multiplication',

  // Block 6 - Length and perimeter
  MeasureInKilometresAndMetres: 'Measure in kilometres and metres',
  EquivalentLengthsKilometresAndMetres: 'Equivalent lengths (kilometres and metres)',
  PerimeterOnAGrid: 'Perimeter on a grid',
  PerimeterOfARectangle: 'Perimeter of a rectangle',
  PerimeterOfRectilinearShapes: 'Perimeter of rectilinear shapes',
  FindMissingLengthsInRectilinearShapes: 'Find missing lengths in rectilinear shapes',
  CalculateThePerimeterOfRectilinearShapes: 'Calculate the perimeter of rectilinear shapes',
  PerimeterOfRegularPolygons: 'Perimeter of regular polygons',
  PerimeterOfPolygons: 'Perimeter of polygons',

  // Block 7 - Fractions
  CountBeyond1: 'Count beyond 1',
  PartitionAMixedNumber: 'Partition a mixed number',
  NumberLinesWithMixedNumbers: 'Number lines with mixed numbers',
  CompareAndOrderMixedNumbers: 'Compare and order mixed numbers',
  UnderstandImproperFractions: 'Understand improper fractions',
  ConvertMixedNumbersToImproperFractions: 'Convert mixed numbers to improper fractions',
  ConvertImproperFractionsToMixedNumbers: 'Convert improper fractions to mixed numbers',
  EquivalentFractionFamilies: 'Equivalent fraction families',
  AddTwoOrMoreFractions: 'Add two or more fractions',
  AddFractionsAndMixedNumbers: 'Add fractions and mixed numbers',
  SubtractTwoFractions: 'Subtract two fractions',
  SubtractFromWholeAmounts: 'Subtract from whole amounts',
  SubtractFromMixedNumbers: 'Subtract from mixed numbers',

  // Block 8 - Decimals A
  TenthsAsFractions: 'Tenths as fractions',
  TenthsAsDecimals: 'Tenths as decimals',
  TenthsOnAPlaceValueChart: 'Tenths on a place value chart',
  TenthsOnANumberLine: 'Tenths on a number line',
  DivideA1DigitNumberBy10: 'Divide a 1-digit number by 10',
  DivideA2DigitNumberBy10: 'Divide a 2-digit number by 10',
  HundredthsAsFractions: 'Hundredths as fractions',
  HundredthsAsDecimals: 'Hundredths as decimals',
  HundredthsOnAPlaceValueChart: 'Hundredths on a place value chart',
  DivideA1Or2DigitNumberBy100: 'Divide a 1- or 2-digit number by 100',

  // Block 9 - Decimals B
  MakeAWholeWithTenths: 'Make a whole with tenths',
  MakeAWholeWithHundredths: 'Make a whole with hundredths',
  PartitionDecimals: 'Partition decimals',
  FlexiblyPartitionDecimals: 'Flexibly partition decimals',
  CompareDecimals: 'Compare decimals',
  OrderDecimals: 'Order decimals',
  RoundToTheNearestWholeNumber: 'Round to the nearest whole number',
  HalvesAndQuartersAsDecimals: 'Halves and quarters as decimals',

  // Block 10 - Money
  WriteMoneyUsingDecimals: 'Write money using decimals',
  ConvertBetweenPoundsAndPence: 'Convert between pounds and pence',
  CompareAmountsOfMoney: 'Compare amounts of money',
  EstimateWithMoney: 'Estimate with money',
  CalculateWithMoney: 'Calculate with money',
  SolveProblemsWithMoney: 'Solve problems with money',

  // Block 11 - Time
  YearsMonthsWeeksAndDays: 'Years, months, weeks and days',
  HoursMinutesAndSeconds: 'Hours, minutes and seconds',
  ConvertBetweenAnalogueAndDigitalTimes: 'Convert between analogue and digital times',
  ConvertToThe24HourClock: 'Convert to the 24 hour clock',
  ConvertFromThe24HourClock: 'Convert from the 24 hour clock',

  // Block 12 - Shape
  UnderstandAnglesAsTurns: 'Understand angles as turns',
  IdentifyAngles: 'Identify angles',
  CompareAndOrderAngles: 'Compare and order angles',
  Triangles: 'Triangles',
  Quadrilaterals: 'Quadrilaterals',
  Polygons: 'Polygons',
  LinesOfSymmetry: 'Lines of symmetry',
  CompleteASymmetricFigure: 'Complete a symmetric figure',

  // Block 13 - Statistics
  InterpretCharts: 'Interpret charts',
  ComparisonSumAndDifference: 'Comparison, sum and difference',
  InterpretLineGraphs: 'Interpret line graphs',
  DrawLineGraphs: 'Draw line graphs',

  // Block 14 - Position and direction
  DescribePositionUsingCoordinates: 'Describe position using coordinates',
  PlotCoordinates: 'Plot coordinates',
  Draw2DShapesOnAGrid: 'Draw 2-D shapes on a grid',
  TranslateOnAGrid: 'Translate on a grid',
  DescribeTranslationOnAGrid: 'Describe translation on a grid',

  ////
  // Year 5
  ////

  // Block 1 - Place value
  RomanNumeralsTo1000: 'Roman numerals to 1,000',
  NumbersTo10000: 'Numbers to 10,000',
  NumbersTo100000: 'Numbers to 100,000',
  NumbersTo1000000: 'Numbers to 1,000,000',
  ReadAndWriteNumbersTo1000000: 'Read and write numbers to 1,000,000',
  PowersOf10: 'Powers of 10',
  To100000MoreOrLess: '10/100/1,000/10,000/100,000 more or less',
  PartitionNumbersTo1000000: 'Partition numbers to 1,000,000',
  NumberLineTo1000000: 'Number line to 1,000,000',
  CompareAndOrderNumbersTo100000: 'Compare and order numbers to 100,000',
  CompareAndOrderNumbersTo1000000: 'Compare and order numbers to 1,000,000',
  RoundWithin100000: 'Round within 100,000',
  RoundWithin1000000: 'Round within 1,000,000',

  // Block 2 - Addition and subtraction
  MentalStrategies: 'Mental strategies',
  AddWholeNumbersWithMoreThanFourDigits: 'Add whole numbers with more than four digits',
  SubtractWholeNumbersWithMoreThanFourDigits: 'Subtract whole numbers with more than four digits',
  RoundToCheckAnswers: 'Round to check answers',
  InverseOperationsAdditionAndSubtraction: 'Inverse operations (addition and subtraction)',
  MultiStepAdditionAndSubtractionProblems: 'Multi-step addition and subtraction problems',
  CompareCalculations: 'Compare calculations',
  FindMissingNumbers: 'Find missing numbers',

  // Block 3 - Multiplication and division A
  Multiples: 'Multiples',
  CommonMultiples: 'Common multiples',
  Factors: 'Factors',
  CommonFactors: 'Common factors',
  PrimeNumbers: 'Prime numbers',
  SquareNumbers: 'Square numbers',
  CubeNumbers: 'Cube numbers',
  MultiplyBy10100And1000: 'Multiply by 10, 100 and 1,000',
  DivideBy10100And1000: 'Divide by 10, 100 and 1,000',
  MultiplesOf10100And1000: 'Multiples of 10, 100 and 1,000',

  // Block 4 - Fractions A
  FindFractionsEquivalentToAUnitFraction: 'Find fractions equivalent to a unit fraction',
  FindFractionsEquivalentToANonUnitFraction: 'Find fractions equivalent to a non-unit fraction',
  RecogniseEquivalentFractions: 'Recognise equivalent fractions',
  CompareFractionsLessThan1: 'Compare fractions less than 1',
  OrderFractionsLessThan1: 'Order fractions less than 1',
  CompareAndOrderFractionsGreaterThan1: 'Compare and order fractions greater than 1',
  AddAndSubtractFractionsWithTheSameDenominator:
    'Add and subtract fractions with the same denominator',
  AddFractionsWithin1: 'Add fractions within 1',
  AddFractionsWithTotalGreaterThan1: 'Add fractions with total greater than 1',
  AddToAMixedNumber: 'Add to a mixed number',
  AddTwoMixedNumbers: 'Add two mixed numbers',
  // SubtractFractions: 'Subtract fractions',
  SubtractFromAMixedNumber: 'Subtract from a mixed number',
  SubtractFromAMixedNumberBreakingTheWhole: 'Subtract from a mixed number - breaking the whole',
  SubtractTwoMixedNumbers: 'Subtract two mixed numbers',

  // Block 5 - Multiplication and division B
  MultiplyUpToA4DigitNumberByA1DigitNumber: 'Multiply up to a 4-digit number by a 1-digit number',
  MultiplyA2DigitNumberByA2DigitNumberAreaModel:
    'Multiply a 2-digit number by a 2-digit number (area model)',
  MultiplyA2DigitNumberByA2DigitNumber: 'Multiply a 2-digit number by a 2-digit number',
  MultiplyA3DigitNumberByA2DigitNumber: 'Multiply a 3-digit number by a 2-digit number',
  MultiplyA4DigitNumberByA2DigitNumber: 'Multiply a 4-digit number by a 2-digit number',
  SolveProblemsWithMultiplication: 'Solve problems with multiplication',
  ShortDivision: 'Short division',
  DivideA4DigitNumberByA1DigitNumber: 'Divide a 4-digit number by a 1-digit number',
  DivideWithRemainders: 'Divide with remainders',
  EfficientDivision: 'Efficient division',
  SolveProblemsWithMultiplicationAndDivision: 'Solve problems with multiplication and division',

  // Block 6 - Fractions B
  MultiplyAUnitFractionByAnInteger: 'Multiply a unit fraction by an integer',
  MultiplyANonUnitFractionByAnInteger: 'Multiply a non-unit fraction by an integer',
  MultiplyAMixedNumberByAnInteger: 'Multiply a mixed number by an integer',
  CalculateAFractionOfAQuantity: 'Calculate a fraction of a quantity',
  FractionOfAnAmount: 'Fraction of an amount',
  FindTheWhole: 'Find the whole',
  UseFractionsAsOperators: 'Use fractions as operators',

  // Block 7 - Decimals and percentages
  DecimalsUpTo2DecimalPlaces: 'Decimals up to 2 decimal places',
  EquivalentFractionsAndDecimalsTenths: 'Equivalent fractions and decimals (tenths)',
  EquivalentFractionsAndDecimalsHundredths: 'Equivalent fractions and decimals (hundredths)',
  EquivalentFractionsAndDecimals: 'Equivalent fractions and decimals',
  ThousandthsAsFractions: 'Thousandths as fractions',
  ThousandthsAsDecimals: 'Thousandths as decimals',
  ThousandthsOnAPlaceValueChart: 'Thousandths on a place value chart',
  OrderAndCompareDecimalsSameNumberOfDecimalPlaces:
    'Order and compare decimals (same number of decimal places)',
  OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces:
    'Order and compare any decimals with up to 3 decimal places',
  // RoundToTheNearestWholeNumber: 'Round to the nearest whole number',
  RoundTo1DecimalPlace: 'Round to 1 decimal place',
  UnderstandPercentages: 'Understand percentages',
  PercentagesAsFractions: 'Percentages as fractions',
  PercentagesAsDecimals: 'Percentages as decimals',
  EquivalentFractionsDecimalsAndPercentages: 'Equivalent fractions, decimals and percentages',

  // Block 8 - Perimeter and area
  PerimeterOfRectangles: 'Perimeter of rectangles',
  AreaOfRectangles: 'Area of rectangles',
  AreaOfCompoundShapes: 'Area of compound shapes',
  EstimateArea: 'Estimate area',

  // Block 9 - Statistics
  // DrawLineGraphs: 'Draw line graphs',
  ReadAndInterpretLineGraphs: 'Read and interpret line graphs',
  ReadAndInterpretTables: 'Read and interpret tables',
  // TwoWayTables: 'Two-way tables',
  ReadAndInterpretTimetables: 'Read and interpret timetables',

  // Block 10 - Shape
  UnderstandAndUseDegrees: 'Understand and use degrees',
  ClassifyAngles: 'Classify angles',
  EstimateAngles: 'Estimate angles',
  MeasureAnglesUpTo180: 'Measure angles up to 180°',
  DrawLinesAndAnglesAccurately: 'Draw lines and angles accurately',
  CalculateAnglesAroundAPoint: 'Calculate angles around a point',
  CalculateAnglesOnAStraightLine: 'Calculate angles on a straight line',
  LengthsAndAnglesInShapes: 'Lengths and angles in shapes',
  RegularAndIrregularPolygons: 'Regular and irregular polygons',
  Num3DShapes: '3-D shapes',

  // Block 11 - Position and direction
  ReadAndPlotCoordinates: 'Read and plot coordinates',
  ProblemSolvingWithCoordinates: 'Problem solving with coordinates',
  Translation: 'Translation',
  TranslationWithCoordinates: 'Translation with coordinates',
  ReflectionInHorizontalAndVerticalLines: 'Reflection in horizontal and vertical lines',

  // Block 12 - Decimals
  UseKnownFactsToAddAndSubtractDecimalsWithin1:
    'Use known facts to add and subtract decimals within 1',
  ComplementsTo1: 'Complements to 1',
  AddAndSubtractDecimalsAcross1: 'Add and subtract decimals across 1',
  AddDecimalsWithTheSameNumberOfDecimalPlaces:
    'Add decimals with the same number of decimal places',
  SubtractDecimalsWithTheSameNumberOfDecimalPlaces:
    'Subtract decimals with the same number of decimal places',
  AddDecimalsWithDifferentNumbersOfDecimalPlaces:
    'Add decimals with different numbers of decimal places',
  SubtractDecimalsWithDifferentNumbersOfDecimalPlaces:
    'Subtract decimals with different numbers of decimal places',
  EfficientStrategiesForAddingAndSubtractingDecimals:
    'Efficient strategies for adding and subtracting decimals',
  DecimalSequences: 'Decimal sequences',
  MultiplyAndDivideDecimalsMissingValues: 'Multiply and divide decimals - missing values',

  // Block 13 - Negative numbers
  UnderstandNegativeNumbers: 'Understand negative numbers',
  CountThroughZeroIn1s: 'Count through zero in 1s',
  CountThroughZeroInMultiples: 'Count through zero in multiples',
  CompareAndOrderNegativeNumbers: 'Compare and order negative numbers',
  FindTheDifference: 'Find the difference',

  // Block 14 - Converting units
  KilogramsAndKilometres: 'Kilograms and kilometres',
  MillimetresAndMillilitres: 'Millimetres and millilitres',
  ConvertUnitsOfLength: 'Convert units of length',
  ConvertBetweenMetricAndImperialUnits: 'Convert between metric and imperial units',
  ConvertUnitsOfTime: 'Convert units of time',
  CalculateWithTimetables: 'Calculate with timetables',

  // Block 15 - Volume
  CubicCentimetres: 'Cubic centimetres',
  CompareVolume: 'Compare volume',
  EstimateVolume: 'Estimate volume',
  EstimateCapacity: 'Estimate capacity',

  ////
  // Year 6
  ////

  // Block 1 - Place value
  NumbersTo10000000: 'Numbers to 10,000,000',
  ReadAndWriteNumbersTo10000000: 'Read and write numbers to 10,000,000',
  NumberLineTo10000000: 'Number line to 10,000,000',
  CompareAndOrderAnyIntegers: 'Compare and order any integers',
  RoundAnyInteger: 'Round any integer',
  NegativeNumbers: 'Negative numbers',

  // Block 2 - Addition, subtraction, multiplication and division
  AddAndSubtractIntegers: 'Add and subtract integers',
  RulesOfDivisibility: 'Rules of divisibility',
  PrimesTo100: 'Primes to 100',
  SquareAndCubeNumbers: 'Square and cube numbers',
  MultiplyUpToA4DigitNumberByA2DigitNumber: 'Multiply up to a 4-digit number by a 2-digit number',
  DivisionUsingFactors: 'Division using factors',
  IntroductionToLongDivision: 'Introduction to long division',
  LongDivisionWithRemainders: 'Long division with remainders',
  SolveProblemsWithDivision: 'Solve problems with division',
  SolveMultiStepProblems: 'Solve multi-step problems',
  OrderOfOperations: 'Order of operations',
  MentalCalculationsAndEstimation: 'Mental calculations and estimation',
  ReasonFromKnownFacts: 'Reason from known facts',

  // Block 3 - Fractions A
  EquivalentFractionsAndSimplifying: 'Equivalent fractions and simplifying',
  CompareAndOrderDenominator: 'Compare and order (denominator)',
  CompareAndOrderNumerator: 'Compare and order (numerator)',
  AddAndSubtractSimpleFractions: 'Add and subtract simple fractions',
  AddAndSubtractAnyTwoFractions: 'Add and subtract any two fractions',
  AddMixedNumbers: 'Add mixed numbers',
  SubtractMixedNumbers: 'Subtract mixed numbers',
  MultiStepProblems: 'Multi-step problems',

  // Block 4 - Fractions B
  MultiplyFractionsByIntegers: 'Multiply fractions by integers',
  MultiplyFractionsByFractions: 'Multiply fractions by fractions',
  DivideAFractionByAnInteger: 'Divide a fraction by an integer',
  DivideAnyFractionByAnInteger: 'Divide any fraction by an integer',
  MixedQuestionsWithFractions: 'Mixed questions with fractions',
  FractionOfAnAmountFindTheWhole: 'Fraction of an amount - find the whole',

  // Block 5 - Converting units
  MetricMeasures: 'Metric measures',
  ConvertMetricMeasures: 'Convert metric measures',
  CalculateWithMetricMeasures: 'Calculate with metric measures',
  MilesAndKilometres: 'Miles and kilometres',
  ImperialMeasures: 'Imperial measures',

  // Block 6 - Ratio
  AddOrMultiply: 'Add or multiply?',
  UseRatioLanguage: 'Use ratio language',
  IntroductionToTheRatioSymbol: 'Introduction to the ratio symbol',
  RatioAndFractions: 'Ratio and fractions',
  ScaleDrawing: 'Scale drawing',
  UseScaleFactors: 'Use scale factors',
  SimilarShapes: 'Similar shapes',
  RatioProblems: 'Ratio problems',
  ProportionProblems: 'Proportion problems',
  Recipes: 'Recipes',

  // Block 7 - Algebra
  Num1StepFunctionMachines: '1-step function machines',
  Num2StepFunctionMachines: '2-step function machines',
  FormExpressions: 'Form expressions',
  Substitution: 'Substitution',
  Formulae: 'Formulae',
  FormEquations: 'Form equations',
  Solve1StepEquations: 'Solve 1-step equations',
  Solve2StepEquations: 'Solve 2-step equations',
  FindPairsOfValues: 'Find pairs of values',
  SolveProblemsWithTwoUnknowns: 'Solve problems with two unknowns',

  // Block 8 - Decimals
  PlaceValueWithin1: 'Place value within 1',
  PlaceValueIntegersAndDecimals: 'Place value - integers and decimals',
  RoundDecimals: 'Round decimals',
  AddAndSubtractDecimals: 'Add and subtract decimals',
  MultiplyDecimalsByIntegers: 'Multiply decimals by integers',
  DivideDecimalsByIntegers: 'Divide decimals by integers',
  MultiplyAndDivideDecimalsInContext: 'Multiply and divide decimals in context',

  // Block 9 - Fractions, decimals and percentages
  DecimalAndFractionEquivalents: 'Decimal and fraction equivalents',
  FractionsAsDivision: 'Fractions as division',
  FractionsToPercentages: 'Fractions to percentages',
  OrderFractionsDecimalsAndPercentages: 'Order fractions, decimals and percentages',
  PercentageOfAnAmountOneStep: 'Percentage of an amount - one step',
  PercentageOfAnAmountMultiStep: 'Percentage of an amount - multi-step',
  PercentagesMissingValues: 'Percentages - missing values',

  // Block 10 - Area, perimeter and volume
  ShapesSameArea: 'Shapes - same area',
  AreaAndPerimeter: 'Area and perimeter',
  AreaOfATriangleCountingSquares: 'Area of a triangle - counting squares',
  AreaOfARightAngledTriangle: 'Area of a right-angled triangle',
  AreaOfAnyTriangle: 'Area of any triangle',
  AreaOfAParallelogram: 'Area of a parallelogram',
  VolumeCountingCubes: 'Volume - counting cubes',
  VolumeOfACuboid: 'Volume of a cuboid',

  // Block 11 - Statistics
  LineGraphs: 'Line graphs',
  DualBarCharts: 'Dual bar charts',
  ReadAndInterpretPieCharts: 'Read and interpret pie charts',
  PieChartsWithPercentages: 'Pie charts with percentages',
  DrawPieCharts: 'Draw pie charts',
  TheMean: 'The mean',

  // Block 12 - Shape
  MeasureAndClassifyAngles: 'Measure and classify angles',
  CalculateAngles: 'Calculate angles',
  VerticallyOppositeAngles: 'Vertically opposite angles',
  AnglesInATriangle: 'Angles in a triangle',
  AnglesInATriangleSpecialCases: 'Angles in a triangle - special cases',
  AnglesInATriangleMissingAngles: 'Angles in a triangle - missing angles',
  AnglesInQuadrilaterals: 'Angles in quadrilaterals',
  AnglesInPolygons: 'Angles in polygons',
  Circles: 'Circles',
  DrawShapesAccurately: 'Draw shapes accurately',
  NetsOf3DShapes: 'Nets of 3-D shapes',

  // Block 13 - Position and direction
  TheFirstQuadrant: 'The first quadrant',
  ReadAndPlotPointsInFourQuadrants: 'Read and plot points in four quadrants',
  SolveProblemsWithCoordinates: 'Solve problems with coordinates',
  Translations: 'Translations',
  Reflections: 'Reflections'
};
