import { useContext, useMemo } from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import {
  type ElementOrRenderFunction,
  resolveElementOrRenderFunction
} from 'common/src/utils/react';
import MainPanel from './MainPanel';
import ActionPanel from './ActionPanel';
import { type TitleStyleProps } from './TitleRow';
import { MINIMUM_QUESTION_HEIGHT, QUESTION_WIDTH } from '../../theme/scaling';
import ExtraContentModal from '../modals/ExtraContentModal';
import { DisplayMode } from '../../contexts/displayMode';

type Props = TitleStyleProps & {
  title?: string | JSX.Element;
  /** Determines where the action panel is positioned and the dimens of the MainPanel. Default: 'end' */
  actionPanelVariant?: ActionPanelVariant;
  /** Contents of the action panel. */
  actionPanelContents?: ElementOrRenderFunction;
  /** Contents of the main panel, below the subtitle and title. */
  mainPanelContents?: ElementOrRenderFunction;
  /** Props for extra modal content */
  promptButton?: string | JSX.Element;
  promptButtonPosition?: 'left' | 'center' | 'right';
  modalTitle?: string;
  modalContent?: JSX.Element;
  mainPanelContainerStyle?: StyleProp<ViewStyle>;
};

/**
 * The layout that all other layouts are descended from.
 *
 * Manages state so that decendents don't have to. State, such as userAnswer, can be used in a controlled or
 * uncontrolled way.
 *
 * This layout also sets up the MainPanel and ActionPanel using the value of actionPanelVariant.
 *
 * If this is a PDF context, then only show the MainPanel.
 */
export default function BaseLayout({
  title,
  titleStyle,
  titleTextStyle,
  titleFractionContainerStyle,
  titleFractionTextStyle,
  titleFractionDividerStyle,
  actionPanelVariant = 'end',
  actionPanelContents,
  mainPanelContents,
  promptButton,
  promptButtonPosition = 'center',
  mainPanelContainerStyle,
  modalTitle,
  modalContent
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(actionPanelVariant, displayMode);

  // This can be removed once all QF's have been looked at
  if (displayMode === 'pdf') {
    return (
      <MainPanel
        style={styles.mainPanelContainer}
        containerStyle={mainPanelContainerStyle}
        title={title}
        titleStyle={titleStyle}
        titleTextStyle={titleTextStyle}
        titleFractionContainerStyle={titleFractionContainerStyle}
        titleFractionTextStyle={titleFractionTextStyle}
        titleFractionDividerStyle={titleFractionDividerStyle}
        actionPanelVariant={actionPanelVariant}
        displayMode={displayMode}
      >
        {resolveElementOrRenderFunction(mainPanelContents)}
      </MainPanel>
    );
  }

  return (
    <View style={styles.container} testID="BaseLayout">
      <MainPanel
        style={styles.mainPanelContainer}
        title={title}
        titleStyle={titleStyle}
        titleTextStyle={titleTextStyle}
        titleFractionContainerStyle={titleFractionContainerStyle}
        titleFractionTextStyle={titleFractionTextStyle}
        titleFractionDividerStyle={titleFractionDividerStyle}
        actionPanelVariant={actionPanelVariant}
        containerStyle={mainPanelContainerStyle}
      >
        {resolveElementOrRenderFunction(mainPanelContents)}
        <View>
          <ExtraContentModal
            promptButton={promptButton}
            promptButtonPosition={promptButtonPosition}
            modalTitle={modalTitle}
          >
            {modalContent}
          </ExtraContentModal>
        </View>
      </MainPanel>
      <ActionPanel style={styles.actionPanelContainer} variant={actionPanelVariant}>
        {resolveElementOrRenderFunction(actionPanelContents)}
      </ActionPanel>
    </View>
  );
}

export type ActionPanelVariant =
  | 'end'
  | 'endWide'
  | 'bottom'
  | 'bottomTall'
  | 'bottomTallCircleCont'
  | 'endMid';

export const baseLayoutConstants = (
  actionPanelVariant: ActionPanelVariant,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) => {
  switch (actionPanelVariant) {
    case 'end':
      return {
        flexDirection: 'row',
        mainPanelWidth: displayMode === 'digital' ? 1090 : 1980,
        mainPanelHeight: 688,
        actionPanelWidth: 166,
        actionPanelHeight: 688
      } as const;
    case 'endWide':
      return {
        flexDirection: 'row',
        mainPanelWidth: displayMode === 'digital' ? 866 : 1980,
        mainPanelHeight: 688,
        actionPanelWidth: 390,
        actionPanelHeight: 688
      } as const;

    case 'endMid':
      return {
        flexDirection: 'row',
        mainPanelWidth: displayMode === 'digital' ? 978 : 1980,
        mainPanelHeight: 688,
        actionPanelWidth: 278,
        actionPanelHeight: 688
      } as const;
    case 'bottom':
      return {
        flexDirection: 'column',
        mainPanelWidth: displayMode === 'digital' ? 1256 : 1980,
        mainPanelHeight: 522,
        actionPanelWidth: 1256,
        actionPanelHeight: 166
      } as const;
    case 'bottomTall':
      return {
        flexDirection: 'column',
        mainPanelWidth: displayMode === 'digital' ? 1256 : 1980,
        mainPanelHeight: 410,
        actionPanelWidth: 1256,
        actionPanelHeight: 278
      } as const;
    case 'bottomTallCircleCont':
      return {
        flexDirection: 'column',
        mainPanelWidth: displayMode === 'digital' ? 1256 : 1980,
        mainPanelHeight: 410,
        actionPanelWidth: 1256,
        actionPanelHeight: 278
      } as const;
  }
};

function useStyles(
  actionPanelVariant: ActionPanelVariant,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) {
  const { flexDirection, mainPanelWidth, mainPanelHeight, actionPanelWidth, actionPanelHeight } =
    baseLayoutConstants(actionPanelVariant, displayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          //TODO Remove this ternary when all QFs have been updated to use BaseLayoutPDF
          width: displayMode === 'digital' ? QUESTION_WIDTH : 1980,
          height: MINIMUM_QUESTION_HEIGHT,
          flexDirection,
          paddingVertical: 16,
          paddingHorizontal: 12
        },

        mainPanelContainer: {
          width: mainPanelWidth,
          height: mainPanelHeight
        },

        actionPanelContainer: {
          width: actionPanelWidth,
          height: actionPanelHeight
        }
      }),
    [
      actionPanelHeight,
      actionPanelWidth,
      flexDirection,
      displayMode,
      mainPanelHeight,
      mainPanelWidth
    ]
  );
}
