import { useCallback, useContext } from 'react';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import UserInput, { ExtraSymbols } from 'common/src/components/molecules/UserInput';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { arraysHaveSameContents, filledArray } from 'common/src/utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { DisplayMode } from 'common/src/contexts/displayMode';
import { MINIMUM_QUESTION_HEIGHT } from 'common/src/theme/scaling';
import BaseLayoutPDF from 'common/src/components/molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import {
  CustomizableTable,
  CustomizableTableWithState
} from 'common/src/components/question/representations/CustomizableTable';
import { parseMarkup } from 'common/src/markup';
import { parseSymbolsToString } from 'common/src/utils/parse';

type UserAnswer = string[];

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /** Cell headers */
  cellHeaders:
    | string[]
    | { label: string; containerStyle?: StyleProp<ViewStyle>; textStyle?: StyleProp<TextStyle> }[];
  /**
   * Array of an array. The first array covers the number of rows and the second the columns
   * The array accepts a string (text/ans box) or a react component
   */
  tableData: (string | JSX.Element)[][];
  /** By default, this is an array of array of empty strings '' matching the number of <ans/> passed per row. */
  initialState?: UserAnswer;
  inputMaxCharacters?: number;
  /** Defaults to checking that all user answer strings are non-empty. */
  testComplete?: (userAnswer: UserAnswer[]) => boolean;
  /** Either an array of arrays of the correct answers for each sentence, or a function for more complex use cases. */
  testCorrect: UserAnswer | ((userAnswer: UserAnswer) => boolean);
  /** Optional custom mark scheme answer */
  markSchemeAnswer?: string;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
  extraSymbol?: ExtraSymbols;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  columnFlexValues?: number[];
  rowStyle?: StyleProp<ViewStyle>;
};

/**
 * Question Format 7: QF7InteractiveTable.
  /**
   * Array of an array. The first array covers the number of rows and the second the columns
   * The array accepts a string (text/ans box) or a react component
 */
export default function QF7InteractiveTable({
  title,
  pdfTitle,
  cellHeaders,
  tableData,
  initialState,
  inputMaxCharacters,
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  markSchemeAnswer,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  extraSymbol,
  textStyle,
  fractionTextStyle,
  columnFlexValues,
  rowStyle,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  // Default initialState
  const filteredTableData = tableData.flat().filter(item => typeof item === 'string') as string[];
  const numberOfAns = filteredTableData
    .map(parseMarkup)
    .map(it => it.numberOfAns)
    .reduce((acc, num) => acc + num, 0);

  initialState = initialState ?? filledArray('', numberOfAns);

  // Handle testCorrect
  const testCorrect = useCallback(
    (userAnswer: UserAnswer) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContents(userAnswer, testCorrectProp);
      }
    },
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={title}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            <CustomizableTable
              cellHeaders={cellHeaders}
              tableData={tableData}
              textStyle={textStyle}
              fractionTextStyle={fractionTextStyle}
              columnFlexValues={columnFlexValues}
              rowStyle={rowStyle}
              userAnswer={
                displayMode === 'markscheme'
                  ? typeof testCorrectProp === 'function'
                    ? customMarkSchemeAnswer?.answersToDisplay
                    : testCorrectProp.map(ans => {
                        // Temporary variable to convert simple string to localized string
                        const temp = Number(parseSymbolsToString(ans));
                        return temp.toLocaleString();
                      })
                  : undefined
              }
            />
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      title={title}
      mainPanelContents={
        <CustomizableTableWithState
          id="table"
          defaultState={initialState}
          inputMaxCharacters={inputMaxCharacters}
          cellHeaders={cellHeaders}
          tableData={tableData}
          testCorrect={testCorrect}
          textStyle={textStyle}
          rowStyle={rowStyle}
          fractionTextStyle={fractionTextStyle}
          columnFlexValues={columnFlexValues}
        />
      }
      {...props}
    />
  );
}
