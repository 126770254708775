import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { BarModelVerticalBraceWithState } from '../../../../components/question/representations/BarModelVerticalCurlyBrace';
import isEqual from 'react-fast-compare';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biC',
  description: 'biC',
  keywords: ['Multi-step', 'Add', 'Subtract', 'Money'],
  schema: z.object({
    objectA: z.enum(['Coat', 'Jumper', 'Bag', 'Game']),
    objectB: z.enum(['T-shirt', 'Jumper', 'Book', 'Hat']),
    priceA: z.number().int().min(5).max(20),
    priceB: z.number().int().min(1).max(10),
    lessOrMore: z.enum(['less', 'more'])
  }),
  simpleGenerator: () => {
    const { objectA, objectB } = rejectionSample(
      () => {
        const objectA = getRandomFromArray(['Coat', 'Jumper', 'Bag', 'Game'] as const);
        const objectB = getRandomFromArray(['T-shirt', 'Jumper', 'Book', 'Hat'] as const);
        return { objectA, objectB };
      },
      ({ objectA, objectB }) => objectA !== objectB
    );

    const priceA = randomIntegerInclusive(5, 20);
    const priceB = randomIntegerInclusive(1, 10, { constraint: x => x < priceA });

    const lessOrMore = getRandomFromArray(['less', 'more'] as const);

    return { objectA, objectB, priceA, priceB, lessOrMore };
  },
  Component: ({
    question: { objectA, objectB, priceA, priceB, lessOrMore },
    translate,
    displayMode
  }) => {
    const difference = priceA - priceB;

    const instruction =
      lessOrMore === 'less'
        ? translate.ks1Instructions.xObjectCostsLessThanYObjectWhatIsTotalCostCombinedCompleteBarModel(
            {
              objectA,
              objectB,
              difference
            }
          )
        : translate.ks1Instructions.xObjectCostsMoreThanYObjectWhatIsTotalCostCombinedCompleteBarModel(
            {
              objectA,
              objectB,
              difference
            }
          );

    const strings =
      lessOrMore === 'less'
        ? [[`£${priceA.toLocaleString()}`], ['', `£${difference.toLocaleString()}`]]
        : [[''], [`£${priceB.toLocaleString()}`, `£${difference.toLocaleString()}`]];

    return (
      <QF3Content
        title={instruction}
        inputType="numpad"
        Content={({ dimens }) => (
          <BarModelVerticalBraceWithState
            id="barmodel"
            numbers={[[priceA], [priceB, difference]]}
            strings={strings}
            total={Math.max(priceA, priceB)}
            arrowIndices={[[], [1]]}
            dimens={dimens}
            testCorrect={userAnswer => isEqual(userAnswer, [(priceA + priceB).toString()])}
            preBarText={[objectA, objectB]}
            braceText="£ <ans/>"
            inputMaxCharacters={2}
            defaultState={displayMode !== 'digital' ? [(priceA + priceB).toString()] : undefined}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TwoStepProblems',
  questionTypes: [Question1],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
