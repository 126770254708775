import { all, create, number } from 'mathjs';
import { SvgName } from '../../assets/svg';
import { getRandomFromArray, seededRandom } from '../random';
import z from 'zod';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

export const circles = [
  'Circles/circle_blue',
  'Circles/circle_green',
  'Circles/circle_pink',
  'Circles/circle_purple',
  'Circles/circle_red',
  'Circles/circle_white',
  'Circles/circle_yellow'
] as const;

export const getShapeSvgName = (
  shape:
    | 'kites'
    | 'triangles'
    | 'circles'
    | 'squares'
    | 'rectangles'
    | 'hexagons'
    | 'irregularHexagons'
    | 'pentagons'
    | 'irregularPentagons'
    | 'heptagons'
    | 'nonagons'
    | 'octagons'
    | 'decagons'
    | 'equilateralTriangles'
    | 'scaleneTriangles'
    | 'isoscelesTriangles'
    | 'rhombuses'
    | 'parallelograms',
  seed?: string | number | object
): SvgName => {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;

  switch (shape) {
    case 'kites':
      return getRandomFromArray(
        [
          'Kite/kite_green',
          'Kite/kite_blue',
          'Kite/kite_pink',
          'Kite/kite_purple',
          'Kite/kite_yellow'
        ] as const,
        seeded
      );
    case 'triangles':
      return getRandomFromArray(
        [
          'Equilateral_triangles/triangle_equal_blue',
          'Equilateral_triangles/triangle_equal_green',
          'Equilateral_triangles/triangle_equal_pink',
          'Equilateral_triangles/triangle_equal_purple',
          'Equilateral_triangles/triangle_equal_yellow',
          'Equilateral_triangles/triangle_equal_red'
        ] as const,
        seeded
      );
    case 'equilateralTriangles':
      return getRandomFromArray(
        [
          'Equilateral_triangles/triangle_equal_blue',
          'Equilateral_triangles/triangle_equal_green',
          'Equilateral_triangles/triangle_equal_pink',
          'Equilateral_triangles/triangle_equal_purple',
          'Equilateral_triangles/triangle_equal_yellow',
          'Equilateral_triangles/triangle_equal_red'
        ] as const,
        seeded
      );
    case 'isoscelesTriangles':
      return getRandomFromArray(
        [
          'Isosceles_triangles_narrow/triangle_isos_narrow_blue',
          'Isosceles_triangles_narrow/triangle_isos_narrow_green',
          'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
          'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
          'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
        ] as const,
        seeded
      );
    case 'scaleneTriangles':
      return getRandomFromArray(
        [
          'Scalene_triangles/triangle_scalene_blue',
          'Scalene_triangles/triangle_scalene_green',
          'Scalene_triangles/triangle_scalene_purple',
          'Scalene_triangles/triangle_scalene_yellow'
        ] as const,
        seeded
      );
    case 'circles': {
      return getRandomFromArray(
        [
          'Circles/circle_green',
          'Circles/circle_blue',
          'Circles/circle_pink',
          'Circles/circle_purple',
          'Circles/circle_yellow',
          'Circles/circle_red'
        ] as const,
        seeded
      );
    }
    case 'squares': {
      return getRandomFromArray(
        [
          'Square/square_blue',
          'Square/square_green',
          'Square/square_pink',
          'Square/square_purple',
          'Square/square_yellow',
          'Square/square_red'
        ] as const,
        seeded
      );
    }
    case 'rectangles': {
      return getRandomFromArray(
        [
          'Rectangle/rectangle_blue',
          'Rectangle/rectangle_green',
          'Rectangle/rectangle_pink',
          'Rectangle/rectangle_purple',
          'Rectangle/rectangle_white',
          'Rectangle/rectangle_yellow'
        ] as const,
        seeded
      );
    }
    case 'rhombuses':
      return getRandomFromArray(
        [
          'Rhombus/rhombus_green',
          'Rhombus/rhombus_blue',
          'Rhombus/rhombus_pink',
          'Rhombus/rhombus_purple',
          'Rhombus/rhombus_yellow'
        ] as const,
        seeded
      );
    case 'parallelograms':
      return getRandomFromArray(
        [
          'Parallelogram/parallelogram_green',
          'Parallelogram/parallelogram_blue',
          'Parallelogram/parallelogram_pink',
          'Parallelogram/parallelogram_purple',
          'Parallelogram/parallelogram_yellow'
        ] as const,
        seeded
      );
    case 'hexagons':
      return getRandomFromArray(
        [
          'Hexagons/hexagon_blue',
          'Hexagons/hexagon_green',
          'Hexagons/hexagon_pink',
          'Hexagons/hexagon_purple',
          'Hexagons/hexagon_white',
          'Hexagons/hexagon_yellow'
        ] as const,
        seeded
      );
    case 'irregularHexagons':
      return getRandomFromArray(
        [
          'Hexagons/Irregular_hexagon6_blue',
          'Hexagons/Irregular_hexagon6_green',
          'Hexagons/Irregular_hexagon6_pink',
          'Hexagons/Irregular_hexagon6_purple',
          'Hexagons/Irregular_hexagon6_white',
          'Hexagons/Irregular_hexagon6_yellow',
          'Hexagons/Irregular_hexagon5_blue',
          'Hexagons/Irregular_hexagon5_green',
          'Hexagons/Irregular_hexagon5_pink',
          'Hexagons/Irregular_hexagon5_purple',
          'Hexagons/Irregular_hexagon5_white',
          'Hexagons/Irregular_hexagon5_yellow',
          'Hexagons/Irregular_hexagon1_blue',
          'Hexagons/Irregular_hexagon1_green',
          'Hexagons/Irregular_hexagon1_pink',
          'Hexagons/Irregular_hexagon1_purple',
          'Hexagons/Irregular_hexagon1_white',
          'Hexagons/Irregular_hexagon1_yellow',
          'Hexagons/Irregular_hexagon2_blue',
          'Hexagons/Irregular_hexagon2_green',
          'Hexagons/Irregular_hexagon2_pink',
          'Hexagons/Irregular_hexagon2_purple',
          'Hexagons/Irregular_hexagon2_white',
          'Hexagons/Irregular_hexagon2_yellow',
          'Hexagons/Irregular_hexagon3_blue',
          'Hexagons/Irregular_hexagon3_green',
          'Hexagons/Irregular_hexagon3_pink',
          'Hexagons/Irregular_hexagon3_purple',
          'Hexagons/Irregular_hexagon3_white',
          'Hexagons/Irregular_hexagon3_yellow',
          'Hexagons/Irregular_hexagon4_blue',
          'Hexagons/Irregular_hexagon4_green',
          'Hexagons/Irregular_hexagon4_pink',
          'Hexagons/Irregular_hexagon4_purple',
          'Hexagons/Irregular_hexagon4_white',
          'Hexagons/Irregular_hexagon4_yellow'
        ] as const,
        seeded
      );
    case 'pentagons':
      return getRandomFromArray(
        [
          'Pentagons/pentagon_blue',
          'Pentagons/pentagon_green',
          'Pentagons/pentagon_pink',
          'Pentagons/pentagon_purple',
          'Pentagons/pentagon_white',
          'Pentagons/pentagon_yellow'
        ] as const,
        seeded
      );
    case 'irregularPentagons':
      return getRandomFromArray(
        [
          'Pentagons/Irregular_pentagon6_blue',
          'Pentagons/Irregular_pentagon6_green',
          'Pentagons/Irregular_pentagon6_pink',
          'Pentagons/Irregular_pentagon6_purple',
          'Pentagons/Irregular_pentagon6_white',
          'Pentagons/Irregular_pentagon6_yellow',
          'Pentagons/Irregular_pentagon5_blue',
          'Pentagons/Irregular_pentagon5_green',
          'Pentagons/Irregular_pentagon5_pink',
          'Pentagons/Irregular_pentagon5_purple',
          'Pentagons/Irregular_pentagon5_white',
          'Pentagons/Irregular_pentagon5_yellow',
          'Pentagons/Irregular_pentagon1_blue',
          'Pentagons/Irregular_pentagon1_green',
          'Pentagons/Irregular_pentagon1_pink',
          'Pentagons/Irregular_pentagon1_purple',
          'Pentagons/Irregular_pentagon1_white',
          'Pentagons/Irregular_pentagon1_yellow',
          'Pentagons/Irregular_pentagon2_blue',
          'Pentagons/Irregular_pentagon2_green',
          'Pentagons/Irregular_pentagon2_pink',
          'Pentagons/Irregular_pentagon2_purple',
          'Pentagons/Irregular_pentagon2_white',
          'Pentagons/Irregular_pentagon2_yellow',
          'Pentagons/Irregular_pentagon3_blue',
          'Pentagons/Irregular_pentagon3_green',
          'Pentagons/Irregular_pentagon3_pink',
          'Pentagons/Irregular_pentagon3_purple',
          'Pentagons/Irregular_pentagon3_white',
          'Pentagons/Irregular_pentagon3_yellow',
          'Pentagons/Irregular_pentagon4_blue',
          'Pentagons/Irregular_pentagon4_green',
          'Pentagons/Irregular_pentagon4_pink',
          'Pentagons/Irregular_pentagon4_purple',
          'Pentagons/Irregular_pentagon4_white',
          'Pentagons/Irregular_pentagon4_yellow'
        ] as const,
        seeded
      );
    case 'heptagons':
      return getRandomFromArray(
        [
          'Other_shapes/Regular_heptagon',
          'Other_shapes/Regular_heptagon_blue',
          'Other_shapes/Regular_heptagon_green',
          'Other_shapes/Regular_heptagon_pink',
          'Other_shapes/Regular_heptagon_purple',
          'Other_shapes/Regular_heptagon_yellow'
        ] as const,
        seeded
      );
    case 'nonagons':
      return getRandomFromArray(
        [
          'Nonagons/Regular_nonagon_white',
          'Nonagons/Regular_nonagon_blue',
          'Nonagons/Regular_nonagon_green',
          'Nonagons/Regular_nonagon_pink',
          'Nonagons/Regular_nonagon_purple',
          'Nonagons/Regular_nonagon_yellow'
        ] as const,
        seeded
      );
    case 'octagons':
      return getRandomFromArray(
        [
          'Other_shapes/Regular_octagon',
          'Other_shapes/Regular_octagon_blue',
          'Other_shapes/Regular_octagon_green',
          'Other_shapes/Regular_octagon_pink',
          'Other_shapes/Regular_octagon_purple',
          'Other_shapes/Regular_octagon_yellow'
        ] as const,
        seeded
      );
    case 'decagons':
      return getRandomFromArray(
        [
          'Other_shapes/Regular_decagon',
          'Other_shapes/Regular_decagon_blue',
          'Other_shapes/Regular_decagon_green',
          'Other_shapes/Regular_decagon_pink',
          'Other_shapes/Regular_decagon_purple',
          'Other_shapes/Regular_decagon_yellow'
        ] as const,
        seeded
      );
  }
};

export const getShapeSvgNameByColor = (
  shape: 'isoscelesTriangles' | 'scaleneTriangles' | 'rectangles',
  color: 'blue' | 'green' | 'purple' | 'yellow'
): SvgName => {
  switch (shape) {
    case 'isoscelesTriangles':
      return `Isosceles_triangles_narrow/triangle_isos_narrow_${color}`;
    case 'scaleneTriangles':
      return `Scalene_triangles/triangle_scalene_${color}`;
    case 'rectangles':
      return `Rectangle/rectangle_${color}`;
  }
};

export const getDimenShapeSvgName = (
  shape:
    | 'rectangles'
    | 'hexagons'
    | 'pentagons'
    | 'equilateralTriangles'
    | 'scaleneTriangles'
    | 'isoscelesTriangles'
    | 'raTriangles'
    | 'squares',
  seed?: string | number | object
): SvgName => {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;

  switch (shape) {
    case 'rectangles':
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/rectangle_purple_arrows_4',
          'Shapes_with_dimension_arrows/rectangle_blue_arrows_4',
          'Shapes_with_dimension_arrows/rectangle_green_arrows_4',
          'Shapes_with_dimension_arrows/rectangle_orange_arrows_4',
          'Shapes_with_dimension_arrows/rectangle_red_arrows_4'
        ] as const,
        seeded
      );
    case 'hexagons':
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/hexagon_purple_arrows',
          'Shapes_with_dimension_arrows/hexagon_blue_arrows',
          'Shapes_with_dimension_arrows/hexagon_green_arrows',
          'Shapes_with_dimension_arrows/hexagon_orange_arrows',
          'Shapes_with_dimension_arrows/hexagon_red_arrows'
        ] as const,
        seeded
      );
    case 'pentagons':
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/pentagon_purple_arrows',
          'Shapes_with_dimension_arrows/pentagon_blue_arrows',
          'Shapes_with_dimension_arrows/pentagon_green_arrows',
          'Shapes_with_dimension_arrows/pentagon_orange_arrows',
          'Shapes_with_dimension_arrows/pentagon_red_arrows'
        ] as const,
        seeded
      );
    case 'equilateralTriangles':
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/triangle_equal_purple_arrows',
          'Shapes_with_dimension_arrows/triangle_equal_blue_arrows',
          'Shapes_with_dimension_arrows/triangle_equal_green_arrows',
          'Shapes_with_dimension_arrows/triangle_equal_orange_arrows',
          'Shapes_with_dimension_arrows/triangle_equal_red_arrows'
        ] as const,
        seeded
      );
    case 'scaleneTriangles':
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/triangle_scalene_purple_arrows',
          'Shapes_with_dimension_arrows/triangle_scalene_blue_arrows',
          'Shapes_with_dimension_arrows/triangle_scalene_green_arrows',
          'Shapes_with_dimension_arrows/triangle_scalene_orange_arrows',
          'Shapes_with_dimension_arrows/triangle_scalene_red_arrows'
        ] as const,
        seeded
      );
    case 'isoscelesTriangles': {
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/triangle_isos_narrow_purple_arrows',
          'Shapes_with_dimension_arrows/triangle_isos_narrow_blue_arrows',
          'Shapes_with_dimension_arrows/triangle_isos_narrow_green_arrows',
          'Shapes_with_dimension_arrows/triangle_isos_narrow_orange_arrows',
          'Shapes_with_dimension_arrows/triangle_isos_narrow_red_arrows'
        ] as const,
        seeded
      );
    }
    case 'raTriangles': {
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/triangle_RA_purple_arrows',
          'Shapes_with_dimension_arrows/triangle_RA_blue_arrows',
          'Shapes_with_dimension_arrows/triangle_RA_green_arrows',
          'Shapes_with_dimension_arrows/triangle_RA_orange_arrows',
          'Shapes_with_dimension_arrows/triangle_RA_red_arrows'
        ] as const,
        seeded
      );
    }
    case 'squares': {
      return getRandomFromArray(
        [
          'Shapes_with_dimension_arrows/Square_purple_arrows_4',
          'Shapes_with_dimension_arrows/Square_blue_arrows_4',
          'Shapes_with_dimension_arrows/Square_green_arrows_4',
          'Shapes_with_dimension_arrows/Square_orange_arrows_4',
          'Shapes_with_dimension_arrows/Square_red_arrows_4'
        ] as const,
        seeded
      );
    }
  }
};

export const regularShapes = [
  'equilateralTriangles',
  'squares',
  'pentagons',
  'hexagons',
  'heptagons',
  'octagons',
  'nonagons',
  'decagons'
] as const;
type RegularShape = (typeof regularShapes)[number];

/**
 * Calculate width of regular shape given its side length.
 *
 * This follows the geometric properties of regular N-gons and uses the following formulae:
 *
 * Side Length = a
 * Width = w
 *
 * Rc = a / 2 x sin(pi / N)
 *
 * N is even:
 *  - if N / 2 is even:
 *      w = 2 x (a / 2 x tan(pi / N))
 *
 *  - if N / 2 is odd:
 *      w = 2 x Rc
 *
 * N is odd:
 *  Rc =
 *  w = 2 x Rc x sin((N - 1) x pi / 2N)
 */
export const regularShapeWidthFromSideLength = (
  shape: RegularShape,
  sideLength: number
): number => {
  switch (shape) {
    case 'equilateralTriangles':
      return sideLength;
    case 'squares':
      return sideLength;
    case 'pentagons':
      return number(
        math.evaluate(`${sideLength} + 2 * ${sideLength} * ${math.cos(math.unit(72, 'deg'))}`)
      );
    case 'hexagons':
      return number(math.evaluate(`2 * ${sideLength}`));
    case 'heptagons':
      return number(
        math.evaluate(
          `${sideLength} + 2 * ${sideLength} * ${math.cos(
            math.unit(math.bignumber((2 * math.pi) / 7), 'rad')
          )}`
        )
      );
    case 'octagons':
      return number(math.evaluate(`2 * (${sideLength} / (2 * ${math.sqrt(2)} - 2))`));
    case 'nonagons':
      return number(
        math.evaluate(
          `2 * ${number(
            math.evaluate(
              `${sideLength} / (2 * ${math.sin(math.unit(math.bignumber(math.pi / 9), 'rad'))})`
            )
          )} * ${math.sin(math.unit(math.bignumber((8 / 18) * math.pi), 'rad'))}`
        )
      );
    case 'decagons':
      return number(math.evaluate(`${sideLength} / ${math.sin(math.unit(18, 'deg'))}`));
  }
};

export const getRegularShapeWithAllSidesMarkedSvgName = (
  shape: RegularShape,
  seed?: string | number | object
): SvgName => {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;
  switch (shape) {
    case 'equilateralTriangles':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Equilateral_triangle_all__sides_blue',
          'Shapes_with_marked_sides/Equilateral_triangle_all__sides_green',
          'Shapes_with_marked_sides/Equilateral_triangle_all__sides_pink',
          'Shapes_with_marked_sides/Equilateral_triangle_all__sides_purple',
          'Shapes_with_marked_sides/Equilateral_triangle_all__sides_yellow'
        ] as const,
        seeded
      );
    case 'squares':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Square_all__sides_marked_blue',
          'Shapes_with_marked_sides/Square_all__sides_marked_green',
          'Shapes_with_marked_sides/Square_all__sides_marked_pink',
          'Shapes_with_marked_sides/Square_all__sides_marked_purple',
          'Shapes_with_marked_sides/Square_all__sides_marked_yellow'
        ] as const,
        seeded
      );
    case 'hexagons':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked',
          'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_blue',
          'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_green',
          'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_pink',
          'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_purple',
          'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_yellow'
        ] as const,
        seeded
      );
    case 'pentagons':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked',
          'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_blue',
          'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_green',
          'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_pink',
          'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_purple',
          'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_yellow'
        ] as const,
        seeded
      );
    case 'octagons':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Regular_octagon_all__sides_marked',
          'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_blue',
          'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_green',
          'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_pink',
          'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_purple',
          'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_yellow'
        ] as const,
        seeded
      );
    case 'heptagons':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked',
          'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_blue',
          'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_green',
          'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_pink',
          'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_purple',
          'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_yellow'
        ] as const,
        seeded
      );
    case 'nonagons':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked',
          'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_blue',
          'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_green',
          'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_pink',
          'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_purple',
          'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_yellow'
        ] as const,
        seeded
      );
    case 'decagons':
      return getRandomFromArray(
        [
          'Shapes_with_marked_sides/Regular_decagon_all__sides_marked',
          'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_blue',
          'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_green',
          'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_pink',
          'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_purple',
          'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_yellow'
        ] as const,
        seeded
      );
  }
};

export const getRegularShapeWithAllSidesMarkedColouredSvgName = (
  shape: RegularShape,
  color: 'blue' | 'pink' | 'green' | 'purple' | 'yellow'
): SvgName => {
  switch (shape) {
    case 'equilateralTriangles':
      return `Shapes_with_marked_sides/Equilateral_triangle_all__sides_${color}`;
    case 'squares':
      return `Shapes_with_marked_sides/Square_all__sides_marked_${color}`;
    case 'hexagons':
      return `Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_${color}`;
    case 'pentagons':
      return `Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_${color}`;
    case 'octagons':
      return `Shapes_with_marked_sides/Regular_octagon_all__sides_marked_${color}`;
    case 'heptagons':
      return `Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_${color}`;
    case 'nonagons':
      `Shapes_with_marked_sides/Regular_nonagon_all__sides_marked__${color}`;
    case 'decagons':
      return `Shapes_with_marked_sides/Regular_decagon_all__sides_marked_${color}`;
  }
};

export const lollyShapeOptions = [
  'square',
  'pentagon',
  'decagon',
  'heptagon',
  'hexagon',
  'nonagon',
  'octagon',
  'quadrilateral',
  'rectangle',
  'triangle'
] as const;
export type LollyShapeOption = (typeof lollyShapeOptions)[number];

const lollyStickShapeImages: Record<LollyShapeOption, SvgName[]> = {
  square: ['Lolly_stick_shapes/Quadrilateral1_lolly_sticks'],
  pentagon: ['Lolly_sticks/FiveLollyStickPentagon', 'Lolly_stick_shapes/Pentagon_lolly_sticks'],
  decagon: ['Lolly_stick_shapes/Decagon_lolly_sticks'],
  heptagon: ['Lolly_stick_shapes/Heptagon_lolly_sticks'],
  hexagon: [
    'Lolly_stick_shapes/Hexagon1_lolly_sticks',
    'Lolly_stick_shapes/Hexagon2_lolly_sticks',
    'Lolly_sticks/SixLollyStickHexagon'
  ],
  nonagon: ['Lolly_stick_shapes/Nonagon_lolly_sticks', 'Lolly_stick_shapes/Nonagon2_lolly_sticks'],
  octagon: ['Lolly_stick_shapes/Octagon1_lolly_sticks'],
  quadrilateral: ['Lolly_stick_shapes/Quadrilateral2_lolly_sticks'],
  rectangle: [
    'Lolly_stick_shapes/Rectangle1_lolly_sticks',
    'Lolly_stick_shapes/Rectangle2_lolly_sticks',
    'Lolly_stick_shapes/Rectangle3_lolly_sticks',
    'Lolly_stick_shapes/Rectangle4_lolly_sticks',
    'Lolly_stick_shapes/Rectangle5_lolly_sticks',
    'Lolly_stick_shapes/Rectangle6_lolly_sticks',
    'Lolly_stick_shapes/Rectangle7_lolly_sticks',
    'Lolly_stick_shapes/Rectangle8_lolly_sticks',
    'Lolly_stick_shapes/Rectangle9_lolly_sticks',
    'Lolly_stick_shapes/Rectangle10_lolly_sticks',
    'Lolly_stick_shapes/Rectangle11_lolly_sticks',
    'Lolly_stick_shapes/Rectangle12_lolly_sticks',
    'Lolly_stick_shapes/Rectangle13_lolly_sticks',
    'Lolly_stick_shapes/Rectangle14_lolly_sticks',
    'Lolly_stick_shapes/Rectangle15_lolly_sticks',
    'Lolly_stick_shapes/Rectangle16_lolly_sticks',
    'Lolly_stick_shapes/Rectangle17_lolly_sticks',
    'Lolly_stick_shapes/Rectangle18_lolly_sticks',
    'Lolly_stick_shapes/Rectangle19_lolly_sticks',
    'Lolly_stick_shapes/Rectangle20_lolly_sticks'
  ],
  triangle: ['Lolly_stick_shapes/Triangle_lolly_sticks']
};

export const getLollyStickShapeSvg = (
  shape: LollyShapeOption,
  seed?: string | number | object
): SvgName => {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;
  return getRandomFromArray([...lollyStickShapeImages[shape]] as const, seeded) as SvgName;
};

export const labelledCubeColors = ['Blue', 'Green', 'Orange', 'Purple', 'Red', 'Yellow'] as const;
type LabelledCubeColor = (typeof labelledCubeColors)[number];
export const LabelledCubeColorSchema = z.enum(labelledCubeColors);

export const labelledCubeSvg = (color: LabelledCubeColor): SvgName => {
  switch (color) {
    case 'Blue':
      return 'Cubes_labelled/Coloured_cube_labelled_blue';
    case 'Green':
      return 'Cubes_labelled/Coloured_cube_labelled_green';
    case 'Orange':
      return 'Cubes_labelled/Coloured_cube_labelled_orange';
    case 'Purple':
      return 'Cubes_labelled/Coloured_cube_labelled_purple';
    case 'Red':
      return 'Cubes_labelled/Coloured_cube_labelled_red';
    case 'Yellow':
      return 'Cubes_labelled/Coloured_cube_labelled_yellow';
  }
};
