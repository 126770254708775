import { SvgName } from '../assets/svg';
import { TranslationFunctions } from '../i18n/i18n-types';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  deepClone,
  filledArray,
  range,
  sortNumberArray,
  sumArrayBy
} from './collections';
import { getLineGradient } from './lines';
import { getRandomFromArray, getRandomSubArrayFromArray, randomIntegerInclusive } from './random';
import { z } from 'zod';

/** Determine whether a passed in userAnswer shape, in the form of boolean[][], is rectilinear. */
export function isRectilinear(shape: boolean[][]): boolean {
  const numberOfRows: number = shape.length;
  const numberOfColumns: number = shape[0].length;

  const visited: boolean[][] = [];

  // Create an array of array of false values that match the dimensions of shape
  for (let i = 0; i < numberOfRows; i++) {
    visited.push(Array(numberOfColumns).fill(false));
  }

  const isValidSquare = (row: number, col: number): boolean => {
    return row >= 0 && row < shape.length && col >= 0 && col < shape[row].length;
  };

  const depthFirstSearch = (row: number, col: number): void => {
    visited[row][col] = true;

    //
    const directions: [number, number][] = [
      [0, -1],
      [0, 1],
      [-1, 0],
      [1, 0]
    ];

    // Traverse in each direction (up, down, left, right)
    for (const [dx, dy] of directions) {
      const newRow = row + dy;
      const newCol = col + dx;

      // Check if the new square is within bounds, if the square has a true value, and if it has not been visited/checked before.
      if (isValidSquare(newRow, newCol) && shape[newRow][newCol] && !visited[newRow][newCol]) {
        // Recursively perform depth-first search traversal until all connected squares are checked.
        depthFirstSearch(newRow, newCol);
      }
    }
  };

  let startRow = -1;
  let startCol = -1;

  // Find the starting square to begin the traversal
  for (let i = 0; i < numberOfRows; i++) {
    for (let j = 0; j < numberOfColumns; j++) {
      if (shape[i][j]) {
        startRow = i;
        startCol = j;
        break;
      }
    }
    if (startRow !== -1) {
      break;
    }
  }

  if (startRow === -1) {
    // If no starting square is found, return false
    return false;
  }

  // Start the depth-first search traversal
  depthFirstSearch(startRow, startCol);

  // Check if any square with a true value is unvisited.
  // If so, it must be disconnected and therefore the shape cannot be rectilinear.
  for (let i = 0; i < numberOfRows; i++) {
    for (let j = 0; j < numberOfColumns; j++) {
      if (shape[i][j] && !visited[i][j]) {
        return false;
      }
    }
  }

  // All squares are connected (rectilinear)
  return true;
}

/** Determine whether a passed in userAnswer shape, in the form of boolean[][], is a rectangle. */
export function isRectangle(shape: boolean[][]): boolean {
  const firstRowWithTrue = shape.find(row => row.includes(true)) ?? [];
  const allRowsWithTrue = shape.filter(row => row.includes(true));

  // Check that each row with true values matches the shape of the first row with true values.
  const allRowsWithTrueMatchFirstRowWithTrue = () => {
    return allRowsWithTrue.every(row => arraysHaveSameContents(row, firstRowWithTrue));
  };

  // Check each row with true values has true values only consecutively with each other, not separated.
  const allRowsWithTrueHaveConsecutiveTrues = () => {
    for (let i = 0; i < allRowsWithTrue.length; i++) {
      let lastElementWithTrue = -1;
      for (let j = 0; j < allRowsWithTrue[i].length; j++) {
        if (
          allRowsWithTrue[i][j] === true &&
          (lastElementWithTrue === -1 || lastElementWithTrue === j - 1)
        ) {
          lastElementWithTrue = j;
        } else if (
          allRowsWithTrue[i][j] === true &&
          lastElementWithTrue !== -1 &&
          lastElementWithTrue !== j - 1
        ) {
          return false;
        }
      }
    }
    return true;
  };

  // Check all row with true values are consecutive with each other, not separated.
  const allRowsWithTrueAreConsecutive = () => {
    let lastArrayWithTrue = -1;

    for (let i = 0; i < shape.length; i++) {
      if (shape[i].includes(true) && (lastArrayWithTrue === -1 || lastArrayWithTrue === i - 1)) {
        lastArrayWithTrue = i;
      } else if (
        shape[i].includes(true) &&
        lastArrayWithTrue !== -1 &&
        lastArrayWithTrue !== i - 1
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    allRowsWithTrueMatchFirstRowWithTrue() &&
    allRowsWithTrueAreConsecutive() &&
    allRowsWithTrueHaveConsecutiveTrues()
  );
}

/** Returns the dimensions of the passed in rectangle from within a wider grid.
 * If the shape is not a rectangle, it will return width: 0 and height: 0.
 */
export function getRectangleDimensions(shape: boolean[][]): { width: number; height: number } {
  if (!isRectangle(shape)) {
    return { width: 0, height: 0 };
  }

  const height = shape.filter(row => row.includes(true)).length ?? 0;
  const firstRowWithTrue = shape.filter(row => row.includes(true))[0] ?? [];
  const width = firstRowWithTrue.filter(cell => cell).length ?? 0;
  return { width, height };
}

/**
 * Determine whether a passed in userAnswer shape, in the form of boolean[][], is a square.
 */
export function isSquareShape(shape: boolean[][]): boolean {
  const allRowsWithTrue = shape.filter(row => row.includes(true));
  const trueCountFirstRow = allRowsWithTrue.filter(bool => bool).length;
  const trueCountAllRowsMatch = allRowsWithTrue.every(
    row => row.filter(bool => bool).length === trueCountFirstRow
  );
  // Check square against isRectangle function too - squares are rectangles so should always adhere to this.
  return isRectangle(shape) && trueCountAllRowsMatch;
}

/** Determine whether a passed in userAnswer shape, in the form of boolean[][], is rectilinear and has 1 continuous perimeter and is filled. */
export function isRectilinearAndFilled(shape: boolean[][]): boolean {
  const numberOfRows: number = shape.length;
  const numberOfColumns: number = shape[0].length;

  const visited: boolean[][] = [];

  // Create an array of array of false values that match the dimensions of shape
  for (let i = 0; i < numberOfRows; i++) {
    visited.push(Array(numberOfColumns).fill(false));
  }

  const isValidSquare = (row: number, col: number): boolean => {
    return row >= 0 && row < shape.length && col >= 0 && col < shape[row].length;
  };

  const depthFirstSearch = (row: number, col: number): void => {
    visited[row][col] = true;

    const directions: [number, number][] = [
      [0, -1],
      [0, 1],
      [-1, 0],
      [1, 0]
    ];

    // Traverse in each direction (up, down, left, right)
    for (const [dx, dy] of directions) {
      const newRow = row + dy;
      const newCol = col + dx;

      // Check if the new square is within bounds, if the square has a true value, and if it has not been visited/checked before.
      if (isValidSquare(newRow, newCol) && shape[newRow][newCol] && !visited[newRow][newCol]) {
        // Recursively perform depth-first search traversal until all connected squares are checked.
        depthFirstSearch(newRow, newCol);
      }
    }
  };

  let startRow = -1;
  let startCol = -1;

  // Find the starting square to begin the traversal
  for (let i = 0; i < numberOfRows; i++) {
    for (let j = 0; j < numberOfColumns; j++) {
      if (shape[i][j]) {
        startRow = i;
        startCol = j;
        break;
      }
    }
    if (startRow !== -1) {
      break;
    }
  }

  if (startRow === -1) {
    // If no starting square is found, return false
    return false;
  }

  // Start the depth-first search traversal
  depthFirstSearch(startRow, startCol);

  // Check if any square with a true value is unvisited.
  // If so, it must be disconnected and therefore the shape cannot be rectilinear.
  for (let i = 0; i < numberOfRows; i++) {
    for (let j = 0; j < numberOfColumns; j++) {
      if (shape[i][j] && !visited[i][j]) {
        return false;
      }
    }
  }

  // Check the perimeter integrity
  const perimeterVisited: boolean[][] = [];
  for (let i = 0; i < numberOfRows; i++) {
    perimeterVisited.push(Array(numberOfColumns).fill(false));
  }

  const perimeterDfs = (row: number, col: number): void => {
    perimeterVisited[row][col] = true;

    const directions: [number, number][] = [
      [0, -1],
      [0, 1],
      [-1, 0],
      [1, 0]
    ];

    for (const [dx, dy] of directions) {
      const newRow = row + dy;
      const newCol = col + dx;

      if (isValidSquare(newRow, newCol) && !perimeterVisited[newRow][newCol]) {
        if (shape[newRow][newCol] === false) {
          perimeterDfs(newRow, newCol);
        }
      }
    }
  };

  // Start from the outer boundary (first/last rows and columns)
  for (let i = 0; i < numberOfRows; i++) {
    if (!shape[i][0] && !perimeterVisited[i][0]) perimeterDfs(i, 0);
    if (!shape[i][numberOfColumns - 1] && !perimeterVisited[i][numberOfColumns - 1])
      perimeterDfs(i, numberOfColumns - 1);
  }
  for (let j = 0; j < numberOfColumns; j++) {
    if (!shape[0][j] && !perimeterVisited[0][j]) perimeterDfs(0, j);
    if (!shape[numberOfRows - 1][j] && !perimeterVisited[numberOfRows - 1][j])
      perimeterDfs(numberOfRows - 1, j);
  }

  // Check if there's any unvisited false cell inside the shape that is not part of the outer boundary
  for (let i = 0; i < numberOfRows; i++) {
    for (let j = 0; j < numberOfColumns; j++) {
      if (!shape[i][j] && !perimeterVisited[i][j]) {
        return false;
      }
    }
  }

  // The shape is rectilinear and has a continuous perimeter without internal voids
  return true;
}

/**
 * Function to create a random shape from squares, given a number of available rows, available columns,
 * how many squares are expected to be in the shape, whether the shape should be rectilinear or not
 * and whether we care about the shape created.
 */
export function createShapeWithSquares(
  availableRows: number,
  availableColumns: number,
  squaresInShape: number,
  isRectilinearShape: boolean,
  /** Allows seededRandom to be passed to the randomness of cells here. Defaults to Math.random
   * {@link randomIntegerInclusive}
   */
  options?: {
    random?: () => number;
  },
  maxRecursions = 10000,
  shapeMatters = true,
  isFilled = false
): boolean[][] {
  // Check if the number of squares to be placed in the shape exceeds the available area
  const totalArea = availableRows * availableColumns;
  if (squaresInShape > totalArea) {
    throw new Error('Invalid configuration: Number of squares exceeds available area');
  }

  if (maxRecursions <= 0) {
    throw new Error(
      'Maximum recursions reached: No valid shapes generated, please examine props passed in.'
    );
  }

  const shape: boolean[][] = [];

  // Create the initial grid with all false values
  for (let i = 0; i < availableRows; i++) {
    shape.push(Array(availableColumns).fill(false));
  }

  // Helper function to get random coordinates within the shape
  const getRandomCoordinates = (): [number, number] => {
    const row = randomIntegerInclusive(0, availableRows - 1, options);
    const col = randomIntegerInclusive(0, availableColumns - 1, options);
    return [row, col];
  };

  let count = 0;
  while (count < squaresInShape) {
    const [row, col] = getRandomCoordinates();
    if (!shape[row][col]) {
      shape[row][col] = true;
      count++;
    }
  }

  // Check first if the generated shape has any consequence, if so check it matches rectilinearity, else recreate the shape.
  if (shapeMatters) {
    if (isFilled && isRectilinearShape !== isRectilinearAndFilled(shape)) {
      return createShapeWithSquares(
        availableRows,
        availableColumns,
        squaresInShape,
        isRectilinearShape,
        options,
        maxRecursions - 1,
        true,
        true
      );
    } else if (isRectilinearShape !== isRectilinear(shape)) {
      return createShapeWithSquares(
        availableRows,
        availableColumns,
        squaresInShape,
        isRectilinearShape,
        options,
        maxRecursions - 1
      );
    }
  }

  return shape;
}

/**
 * Function to create a random shape in the top diagonal of the given space
 * It generates the shape from squares, given a number of available rows, available columns,
 * how many squares are expected to be in the shape, whether the shape should be rectilinear or not
 * and whether we care about the shape created.
 */
export function createShapeWithSquaresDiagonal(
  availableRows: number,
  availableColumns: number,
  squaresInShape: number,
  isRectilinearShape: boolean,
  /** Allows seededRandom to be passed to the randomness of cells here. Defaults to Math.random
   * {@link randomIntegerInclusive}
   */
  options?: {
    random?: () => number;
  },
  maxRecursions = 10000,
  shapeMatters = true,
  isFilled = false
): boolean[][] {
  // Check if the number of squares to be placed in the shape exceeds the available area
  const totalArea = availableRows * availableColumns;
  if (squaresInShape > totalArea) {
    throw new Error('Invalid configuration: Number of squares exceeds available area');
  }

  if (maxRecursions <= 0) {
    throw new Error(
      'Maximum recursions reached: No valid shapes generated, please examine props passed in.'
    );
  }

  const shape: boolean[][] = [];

  // Create the initial grid with all false values
  for (let i = 0; i < availableRows; i++) {
    shape.push(Array(availableColumns).fill(false));
  }

  // Helper function to get random coordinates within the shape
  const getRandomCoordinates = (): [number, number] => {
    const col = randomIntegerInclusive(0, availableColumns - 2, options);
    const row = randomIntegerInclusive(0, availableRows - 2 - col, options);
    return [row, col];
  };

  let count = 0;
  while (count < squaresInShape) {
    const [row, col] = getRandomCoordinates();
    if (!shape[row][col]) {
      shape[row][col] = true;
      count++;
    }
  }

  // Check first if the generated shape has any consequence, if so check it matches rectilinearity, else recreate the shape.
  if (shapeMatters) {
    if (isFilled && isRectilinearShape !== isRectilinearAndFilled(shape)) {
      return createShapeWithSquares(
        availableRows,
        availableColumns,
        squaresInShape,
        isRectilinearShape,
        options,
        maxRecursions - 1,
        true,
        true
      );
    } else if (isRectilinearShape !== isRectilinear(shape)) {
      return createShapeWithSquares(
        availableRows,
        availableColumns,
        squaresInShape,
        isRectilinearShape,
        options,
        maxRecursions - 1
      );
    }
  }

  return shape;
}

/**
 * Create a rectangle shape of boolean[][] using the given width and height.
 */
export function createRectangleFromSquares(width: number, height: number): boolean[][] {
  const rectangle: boolean[][] = [];

  for (let i = 0; i < height; i++) {
    const row: boolean[] = [];

    for (let j = 0; j < width; j++) {
      row.push(true);
    }

    rectangle.push(row);
  }

  return rectangle;
}

/**
 * Function to create a random shape from squares and right-angle triangles, given a number of available rows, available columns,
 * how many squares are expected to be in the shape, and how many triangles are to be in the shape.
 * Squares are assigned 'true' values, while triangles are assigned 'bottomLeft', 'topLeft', 'topRight or 'bottomRight'
 * based on the position of their right-angle.
 * If no valid shape is created, the function will recursively call into itself
 * and try again up to the maxRecursions amount (defaults to 1,000).
 */
export function createRectilinearShapeWithSquaresAndTriangles(
  availableRows: number,
  availableColumns: number,
  squaresInShape: number,
  trianglesInShape: number,
  /** Allows seededRandom to be passed to the randomness of cells here. Defaults to Math.random
   * {@link randomIntegerInclusive}
   */
  options?: {
    random?: () => number;
  },
  maxRecursions = 1000
): (boolean | string)[][] {
  // Check if the number of squares to be placed in the shape exceeds the available area
  const totalArea = availableRows * availableColumns;
  if (squaresInShape + trianglesInShape > totalArea) {
    throw new Error(
      'Invalid configuration: Number of squares and triangles exceeds available area.'
    );
  }

  if (maxRecursions <= 0) {
    throw new Error(
      'Maximum recursions reached: No valid shapes generated, please examine props passed in.'
    );
  }

  // First, create a rectilinear shape from the squares required.
  const shape: (boolean | string)[][] = createShapeWithSquares(
    availableRows,
    availableColumns,
    squaresInShape,
    true,
    options
  );

  const validTriangleCoordsAndOrientations = [];

  // Find all the viable places a triangle could be placed (spaces which are false with a true value adjacent.)
  for (let i = 0; i < shape.length; i++) {
    for (let j = 0; j < shape[i].length; j++) {
      // Only proceed if current square is false.
      if (!shape[i][j]) {
        const orientations = [];
        // If square is above, triangle can be 'topLeft' or 'topRight' orientation.
        if (i > 0 && shape[i - 1][j]) {
          orientations.push('topLeft', 'topRight');
        }
        // If square is below, triangle can be 'bottomLeft' or 'bottomRight' orientation.
        if (i < shape.length - 1 && shape[i + 1][j]) {
          orientations.push('bottomLeft', 'bottomRight');
        }
        // If square is to the left, triangle can be 'topLeft' or 'bottomLeft' orientation.
        if (j > 0 && shape[i][j - 1]) {
          orientations.push('topLeft', 'bottomLeft');
        }
        if (j < shape[i].length - 1 && shape[i][j + 1]) {
          // If square is to the right, triangle can be 'topLeft' or 'bottomRight' orientation.
          orientations.push('topRight', 'bottomRight');
        }
        if (orientations.length > 1) {
          // Create a set to remove any duplicate orientation values.
          const uniqueOrientations = [...new Set(orientations)];

          // There could be multiple possible orientations for a triangle in this position - randomly select one now.
          const selectedOrientation = getRandomFromArray(uniqueOrientations, options);

          // Push this [i, j] coordinate and orientation into the array of viable triangle locations and orientations.
          validTriangleCoordsAndOrientations.push({ coords: [i, j], selectedOrientation });
        }
      }
    }
  }

  // If there aren't enough valid coordinates for triangles to be placed, the function must start over.
  if (trianglesInShape > validTriangleCoordsAndOrientations.length) {
    return createRectilinearShapeWithSquaresAndTriangles(
      availableRows,
      availableColumns,
      squaresInShape,
      trianglesInShape,
      options,
      maxRecursions - 1
    );
  }

  // Select the final triangles' coordinates and orientations from the array of viable options.
  const triangles = getRandomSubArrayFromArray(
    validTriangleCoordsAndOrientations,
    trianglesInShape,
    options
  );

  // Assign these triangles into the final shape.
  for (let i = 0; i < triangles.length; i++) {
    shape[triangles[i].coords[0]][triangles[i].coords[1]] = triangles[i]
      .selectedOrientation as string;
  }

  return shape;
}

/**
 * Creates a grid of boolean[][] based on the dimension [column, row] around a shape of boolean[][].
 * The location of where the shape is placed is based on the type and it can be "fixed", "random" or "center". Default "fixed".
 * The shaped can also be offset by the offSetCol and offSetRow - which can be used to form an empty frame around the shape.
 *
 * Note: Ideal for only rectangle shapes at the moment.
 */
export function createGridAroundShape(
  dimesion: [number, number], // [col, row]
  shape: boolean[][],
  type: 'fixed' | 'random' | 'center' = 'fixed',
  offSetCol = 0,
  offSetRow = 0
): boolean[][] {
  const gridCol = dimesion[0];
  const gridRow = dimesion[1];

  //Create false filled 2d array based on dimesions
  const grid = Array.from({ length: gridRow }, () => Array.from({ length: gridCol }, () => false));

  //find the starting coordinates where to place the shape, default offSet values and fixed
  let startCol = offSetCol;
  let startRow = offSetRow;

  //the maximum possible coordinates for the shape
  const maxStartRow = gridRow - shape.length;
  const maxStartCol = gridCol - shape[0].length;

  //the starting coordinates based on the type
  switch (type) {
    case 'random': {
      startRow = randomIntegerInclusive(0 + offSetRow, maxStartRow - offSetRow);
      startCol = randomIntegerInclusive(0 + offSetCol, maxStartCol - offSetCol);
      break;
    }
    case 'center':
      startCol = Math.floor(maxStartCol / 2);
      startRow = Math.floor(maxStartRow / 2);
      break;
  }

  //place the shape in the grid
  for (let r = 0; r < shape.length; r++) {
    for (let c = 0; c < shape[0].length; c++) {
      grid[r + startRow][c + startCol] = shape[r][c];
    }
  }

  return grid;
}

export function givenShapeReflectedInY(givenShape: boolean[][]) {
  return givenShape.map(row => [...row].reverse());
}

export function givenShapeReflectedInX(givenShape: boolean[][]) {
  return [...givenShape].reverse();
}

export function givenShapeReflectedInXEqualsY(givenShape: boolean[][]) {
  const array: (true | false)[][] = [];
  const rows = givenShape.length;
  const cols = givenShape[0].length;
  for (let i = 0; i < rows; i++) {
    array[i] = filledArray(false, cols);
  }

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      array[rows - 1 - j][cols - 1 - i] = givenShape[i][j];
    }
  }

  return array;
}

export function trueCount(shape: boolean[][]): number {
  return sumArrayBy(shape, row => sumArrayBy(row, bool => (bool ? 1 : 0)));
}

/**
 * Counts the perimeter of the passed-in shape.
 * It will simply detect the perimeter around every true square it finds, and total all of these.
 * Makes no assumptions about whether the shape is rectilinear, whether there are multiple shapes on the same grid,
 * whether the shape has a hole within it etc., please use other utils to check those.
 */
export function perimeterCount(shape: boolean[][]): number {
  let currentPerimeter = 0;

  // Loop through every row:
  for (let i = 0; i < shape.length; i++) {
    // Loop through every cell of each row:
    for (let j = 0; j < shape[i].length; j++) {
      // Only bother checking perimeter to add if current Cell is true:
      if (shape[i][j]) {
        // Top check:
        if (i === 0) {
          // Must be on top row, so perimeter += 1
          currentPerimeter += 1;
        } else {
          if (!shape[i - 1][j]) {
            // Must be blank above, so perimeter += 1
            currentPerimeter += 1;
          }
        }

        // Bottom check:
        if (i === shape.length - 1) {
          // Must be on bottom row, so perimeter += 1
          currentPerimeter += 1;
        } else {
          if (!shape[i + 1][j]) {
            // Must be blank below, so perimeter += 1
            currentPerimeter += 1;
          }
        }

        // Left check:
        if (j === 0) {
          // Must be left-most column
          currentPerimeter += 1;
        } else {
          if (!shape[i][j - 1]) {
            // Must be blank to the left, so perimeter += 1
            currentPerimeter += 1;
          }
        }

        // Right check:
        if (j === shape[i].length - 1) {
          // Must be right-most column
          currentPerimeter += 1;
        } else {
          if (!shape[i][j + 1]) {
            // Must be blank to the right, so perimeter += 1
            currentPerimeter += 1;
          }
        }
      }
    }
  }

  return currentPerimeter;
}

/**
 * Function to check if a passed-in shape's perimeter matches the expected perimeter. Returns a boolean.
 */
export function isEqualPerimeter(shape: boolean[][], expectedPerimeter: number): boolean {
  return perimeterCount(shape) === expectedPerimeter;
}

export function createHundredSquareShape(filledSquares: number): boolean[][] {
  const availableRows = 10;
  const availableColumns = 10;
  // Check if the number of squares to be placed in the shape exceeds the available area
  const totalArea = availableRows * availableColumns;
  if (filledSquares > totalArea) {
    throw new Error('Invalid configuration: Number of squares exceeds available area');
  }

  const shape: boolean[][] = [];
  let shadedSquares = filledSquares;

  // Fill the hundred square sequentially
  for (let i = 0; i < availableRows; i++) {
    // If shaded squares passed in is >= 10
    if (shadedSquares >= 10) {
      // Push 10 true booleans to empty shape array
      shape.push(Array(availableColumns).fill(true));
      // And decrease amount left to be set to true by 10
      shadedSquares -= 10;
    } else if (shadedSquares > 0) {
      // Fill remaining squares needed to be shaded as true
      const partFilled = Array(shadedSquares).fill(true);
      // Fill the remaining squares to be false
      const partNotFilled = Array(10 - shadedSquares).fill(false);
      // Combined filled/not filled into one row
      const row = [...partFilled, ...partNotFilled];
      // Push row to shape array
      shape.push(row);
      // Set shadedSquares to 0 as shaded already handled above
      shadedSquares = 0;
    } else {
      // Push available columns in to the shape array as false
      // As many times as the loop runs
      shape.push(Array(availableColumns).fill(false));
    }
  }

  return shape;
}

/**
 * Takes in an array of numbers, an array of strings of colors that each set of numbers should be,
 * and optionally a degree of rotation (defaults to 0, building up the square top-down).
 * Returns a square of 10 x 10 rows, filled with either strings of the colors passed, or undefined.
 * E.g. passing in [2, 5], ['red', 'blue'] will return
 * [['red', 'red, 'blue, 'blue', 'blue', undefined, undefined, undefined, undefined, undefined],
 * and 9 more rows of 10 undefined elements].
 * This is used to set cells' individual colors for DisplayShapeOnGrid's cellColorMap prop.
 */

export function numbersToHundredSquareColors(
  numbers: number[],
  colors: (string | undefined)[],
  rotation?: 0 | 90 | 180 | 270
): (string | undefined)[][] {
  if (numbers.reduce((a, b) => a + b) > 100) {
    throw new Error('numbers passed must total to 100 or less');
  }

  const numbersIntoRowsOfTen: (string | undefined)[][] = [];

  const numbersSplitIntoOnes = numbers.map((number, index) => range(1, number).map(() => index));

  let currentRowLength = 0;

  // Construct an array of arrays of colors that mirror the numbers and colors passed.
  // Each inner array should be 10 elements long, until all numbers/colors are used.
  for (let i = 0; i < numbersSplitIntoOnes.length; i++) {
    for (let j = 0; j < numbersSplitIntoOnes[i].length; j++) {
      if (currentRowLength === 0) {
        numbersIntoRowsOfTen.push([colors[i]]);
      } else {
        numbersIntoRowsOfTen[numbersIntoRowsOfTen.length - 1].push(colors[i]);
      }
      currentRowLength += 1;
      if (currentRowLength === 10) {
        currentRowLength = 0;
      }
    }
  }

  // Create the rest of the HundredSquare, filling up the remaining arrays with undefined elements.
  while (numbersIntoRowsOfTen.length <= 10) {
    if (
      numbersIntoRowsOfTen.length === 10 &&
      numbersIntoRowsOfTen[numbersIntoRowsOfTen.length - 1].length === 10
    ) {
      break;
    }
    if (currentRowLength === 0 && numbersIntoRowsOfTen.length !== 10) {
      numbersIntoRowsOfTen.push([undefined]);
    } else {
      numbersIntoRowsOfTen[numbersIntoRowsOfTen.length - 1].push(undefined);
    }
    currentRowLength += 1;
    if (currentRowLength === 10) {
      currentRowLength = 0;
    }
    if (currentRowLength === 0 && numbersIntoRowsOfTen.length === 10) {
      break;
    }
  }

  const rotate90 = (shape: (string | undefined)[][]) => {
    return shape.map((row, i) => row.map((_, j) => shape[shape.length - 1 - j][i]));
  };

  switch (rotation) {
    case 270:
      return rotate90(rotate90(rotate90(numbersIntoRowsOfTen)));
    case 180:
      return rotate90(rotate90(numbersIntoRowsOfTen));
    case 90:
      return rotate90(numbersIntoRowsOfTen);
    case 0:
    default:
      return numbersIntoRowsOfTen;
  }
}

/**
 * Takes in a number and returns an array of numbers based on 100
 * For e.g 345 returns [100, 100, 100, 45]
 */
export function hundredSquareSliced(squaresShaded: number): number[] {
  const shapes = [];

  while (squaresShaded >= 100) {
    // If squaresShaded is >= 100 then push 100 to array
    shapes.push(100);
    // Subtract 100 off squaresShaded
    squaresShaded -= 100;
  }

  // Once squaresShaded has finished pushing all the 100s
  // Push in the remaining amount that is greater than 0 but less than 100
  if (squaresShaded > 0) {
    shapes.push(squaresShaded);
  }

  return shapes;
}

/**
 * Reflects boolean[][] shape on the x-axis, i.e. flips vertically.
 */
export function reflectShapeOnX(shape: boolean[][]): boolean[][] {
  return [...shape].reverse();
}

/**
 * Reflects boolean[][] shape on the y-axis, i.e. flips horizontally.
 */
export function reflectShapeOnY(shape: boolean[][]): boolean[][] {
  return shape.map(row => [...row].reverse());
}

export function rotateShape90(shape: boolean[][]): boolean[][] {
  return shape.map((row, i) => row.map((_, j) => shape[shape.length - 1 - j][i]));
}

export function rotateShape180(shape: boolean[][]): boolean[][] {
  return rotateShape90(rotateShape90(shape));
}

export function rotateShape270(shape: boolean[][]): boolean[][] {
  return rotateShape90(rotateShape90(rotateShape90(shape)));
}

interface CreateLShapeProps {
  rectangleWidth: number;
  rectangleHeight: number;
  whiteSpaceWidth?: number;
  whiteSpaceHeight?: number;
  orientation?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

/**
 * Creates an L-shaped pattern within a rectangle of boolean[][] using the given width, height,
 * optional whiteSpaceWidth, and whiteSpaceHeight props.
 * rectangleWidth and rectangleHeight must both be at least 2.
 * If whiteSpaceWidth and whiteSpaceHeight are passed, they must be positive integers
 * up to one less than rectangleWidth or rectangleHeight.
 * The orientation of the L-shape can be specified - this is based on the direction the 'open' part of the L is pointing towards.
 * By default, this is set to randomly select an orientation.
 */
export function createLShape({
  rectangleWidth,
  rectangleHeight,
  whiteSpaceWidth,
  whiteSpaceHeight,
  orientation = 'topRight'
}: CreateLShapeProps): boolean[][] {
  if (rectangleWidth < 2 || rectangleHeight < 2) {
    throw new Error('Rectangle dimens not large enough to carve L-shape out of.');
  }
  if ((whiteSpaceWidth && whiteSpaceWidth < 1) || (whiteSpaceHeight && whiteSpaceHeight < 1)) {
    throw new Error('whiteSpaceWidth and whiteSpaceHeight must be at least 1');
  }

  if (
    (whiteSpaceWidth && whiteSpaceWidth >= rectangleWidth) ||
    (whiteSpaceHeight && whiteSpaceHeight >= rectangleHeight)
  ) {
    throw new Error(
      'whiteSpaceWidth must be smaller than rectangleWidth, and whiteSpaceHeight must be smaller than rectangleHeight.'
    );
  }

  // Create the outer rectangle of this shape as an array of an array of true values.
  const rectangle: boolean[][] = createRectangleFromSquares(rectangleWidth, rectangleHeight);

  // Get the dimensions of the blank part of this rectangle to transform it into an L-shape.
  const blankHeight = whiteSpaceHeight ?? randomIntegerInclusive(1, rectangleHeight - 1);
  const blankWidth = whiteSpaceWidth ?? randomIntegerInclusive(1, rectangleWidth - 1);

  // Carve out the whitespace from the top-left of the rectangle to create an L-shape by setting these values to false.
  for (let i = 0; i < blankHeight; i++) {
    for (let j = 0; j < blankWidth; j++) {
      rectangle[i][j] = false;
    }
  }

  // Select the correct orientation of this L-shape. If no orientation prop is passed, select one randomly.
  switch (orientation ?? getRandomFromArray(['topLeft', 'topRight', 'bottomLeft', 'bottomRight'])) {
    case 'topLeft':
      return rectangle;
    case 'topRight':
      return reflectShapeOnY(rectangle);
    case 'bottomLeft':
      return reflectShapeOnX(rectangle);
    case 'bottomRight':
      return reflectShapeOnX(reflectShapeOnY(rectangle));
  }
}

interface scaleShapeProps {
  shapeHeight: number;
  shapeWidth: number;
  scale: number;
  shape: 'lShape' | 'rectangle' | 'square';
}

export function scaleShape({
  shapeHeight,
  shapeWidth,
  scale,
  shape
}: scaleShapeProps): boolean[][] {
  return shape === 'lShape'
    ? createLShape({
        rectangleWidth: shapeWidth * scale,
        rectangleHeight: shapeHeight * scale,
        whiteSpaceWidth: scale,
        whiteSpaceHeight: (shapeHeight - 1) * scale
      })
    : createRectangleFromSquares(shapeWidth * scale, shapeHeight * scale);
}

export function getTShape(scale: number, rotation?: 90 | 180 | 270): boolean[][] {
  if (scale < 1 || scale > 4) {
    throw new Error('scale must be between 1 and 4');
  }
  let shape;
  if (scale === 2) {
    shape = [
      [true, true, false, false],
      [true, true, false, false],
      [true, true, true, true],
      [true, true, true, true],
      [true, true, false, false],
      [true, true, false, false]
    ];
  } else if (scale === 3) {
    shape = [
      [true, true, true, false, false, false],
      [true, true, true, false, false, false],
      [true, true, true, false, false, false],
      [true, true, true, true, true, true],
      [true, true, true, true, true, true],
      [true, true, true, true, true, true],
      [true, true, true, false, false, false],
      [true, true, true, false, false, false],
      [true, true, true, false, false, false]
    ];
  } else if (scale === 4) {
    shape = [
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true, true],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false]
    ];
  } else {
    shape = [
      [true, false],
      [true, true],
      [true, false]
    ];
  }
  return rotation === 90
    ? rotateShape90(shape)
    : rotation === 180
    ? rotateShape180(shape)
    : rotation === 270
    ? rotateShape270(shape)
    : shape;
}
export const incorrectTScale2 = [
  [true, true, false, false, false, false],
  [true, true, false, false, false, false],
  [true, true, true, true, true, true],
  [true, true, true, true, true, true],
  [true, true, false, false, false, false],
  [true, true, false, false, false, false]
];

export function getSShape(scale: number, rotation?: 90 | 180 | 270): boolean[][] {
  if (scale < 1 || scale > 4) {
    throw new Error('scale must be between 1 and 4');
  }
  let shape;
  if (scale === 2) {
    shape = [
      [true, true, false, false],
      [true, true, false, false],
      [true, true, true, true],
      [true, true, true, true],
      [false, false, true, true],
      [false, false, true, true]
    ];
  } else if (scale === 3) {
    shape = [
      [true, true, true, false, false, false],
      [true, true, true, false, false, false],
      [true, true, true, false, false, false],
      [true, true, true, true, true, true],
      [true, true, true, true, true, true],
      [true, true, true, true, true, true],
      [false, false, false, true, true, true],
      [false, false, false, true, true, true],
      [false, false, false, true, true, true]
    ];
  } else if (scale === 4) {
    shape = [
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, false, false, false, false],
      [true, true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true, true],
      [false, false, false, false, true, true, true, true],
      [false, false, false, false, true, true, true, true],
      [false, false, false, false, true, true, true, true],
      [false, false, false, false, true, true, true, true]
    ];
  } else {
    shape = [
      [true, false],
      [true, true],
      [false, true]
    ];
  }
  return rotation === 90
    ? rotateShape90(shape)
    : rotation === 180
    ? rotateShape180(shape)
    : rotation === 270
    ? rotateShape270(shape)
    : shape;
}

export const incorrectSScale2 = [
  [true, true, false, false, false, false],
  [true, true, false, false, false, false],
  [true, true, true, true, true, true],
  [true, true, true, true, true, true],
  [false, false, false, false, true, true],
  [false, false, false, false, true, true]
];

// Shape translations
export const shapesNames = [
  'Equilateral triangle',
  'Square',
  'Regular pentagon',
  'Regular hexagon',
  'Regular heptagon',
  'Regular octagon',
  'Regular nonagon',
  'Regular decagon'
] as const;

export type shapesName = (typeof shapesNames)[number];

export const shapesSchema = z.enum(shapesNames);

export const shapesAsWord = (
  object: shapesName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'Equilateral triangle':
      return translate.shapes.equilateralTriangles(amount);
    case 'Square':
      return translate.shapes.squares(amount);
    case 'Regular pentagon':
      return translate.shapes.regularPentagons(amount);
    case 'Regular hexagon':
      return translate.shapes.regularHexagons(amount);
    case 'Regular heptagon':
      return translate.shapes.regularHeptagons(amount);
    case 'Regular octagon':
      return translate.shapes.regularOctagons(amount);
    case 'Regular nonagon':
      return translate.shapes.regularNonagons(amount);
    case 'Regular decagon':
      return translate.shapes.regularDecagons(amount);
  }
};

export function getRandomShape() {
  return getRandomFromArray(shapesNames);
}

export function getRandomUniqueShapeNames(numberOfShapesNames: number) {
  return getRandomSubArrayFromArray([...shapesNames] as const, numberOfShapesNames);
}

export const shapeData = (translate: TranslationFunctions, amount: number) => {
  return [
    {
      name: translate.shapes.equilateralTriangles(amount),
      sides: 3
    },
    {
      name: translate.shapes.squares(amount),
      sides: 4
    },
    {
      name: translate.shapes.regularPentagons(amount),
      sides: 5
    },
    {
      name: translate.shapes.regularHexagons(amount),
      sides: 6
    },
    {
      name: translate.shapes.regularHeptagons(amount),
      sides: 7
    },
    {
      name: translate.shapes.regularOctagons(amount),
      sides: 8
    },
    {
      name: translate.shapes.regularNonagons(amount),
      sides: 9
    },
    {
      name: translate.shapes.regularDecagons(amount),
      sides: 10
    }
  ];
};

function angleBetweenPoints(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number]
) {
  const v1 = [point1[0] - point2[0], point1[1] - point2[1]];
  const v2 = [point3[0] - point2[0], point3[1] - point2[1]];
  const dotProduct = v1[0] * v2[0] + v1[1] * v2[1];
  const mag1 = Math.sqrt(v1[0] ** 2 + v1[1] ** 2);
  const mag2 = Math.sqrt(v2[0] ** 2 + v2[1] ** 2);
  return Math.acos(dotProduct / (mag1 * mag2)) * (180 / Math.PI);
}

function squareDistance(point1: [number, number], point2: [number, number]) {
  const x = 0;
  const y = 1;
  return (
    (point1[x] - point2[x]) * (point1[x] - point2[x]) +
    (point1[y] - point2[y]) * (point1[y] - point2[y])
  );
}

/**
 * Helper function to calculate the distance between two points.
 */
export function calculateDistanceBetweenTwoPoints(
  point1: { x: number; y: number },
  point2: { x: number; y: number }
): number {
  return Math.sqrt(squareDistance([point1.x, point1.y], [point2.x, point2.y]));
}

export function triangleArea(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number]
) {
  return (
    Math.abs(
      (point2[0] - point1[0]) * (point3[1] - point1[1]) -
        (point2[1] - point1[1]) * (point3[0] - point1[0])
    ) / 2
  );
}

export function isValidTriangle(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number],
  areaGreaterThan = 0
) {
  return triangleArea(point1, point2, point3) > areaGreaterThan;
}

export function isValidRightAngledTriangle(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number]
) {
  const distance1 = squareDistance(point1, point2);
  const distance2 = squareDistance(point2, point3);
  const distance3 = squareDistance(point3, point1);

  const orderedLengths = sortNumberArray([distance1, distance2, distance3], 'descending');
  return (
    orderedLengths[0] === orderedLengths[1] + orderedLengths[2] &&
    isValidTriangle(point1, point2, point3)
  );
}

export function isValidIsoscelesTriangle(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number]
) {
  const distance1 = squareDistance(point1, point2);
  const distance2 = squareDistance(point2, point3);
  const distance3 = squareDistance(point3, point1);

  return (
    (distance1 === distance2 && distance2 !== distance3) ||
    (distance1 === distance3 && distance3 !== distance2) ||
    (distance3 === distance2 && distance2 !== distance1 && isValidTriangle(point1, point2, point3))
  );
}

export function isValidScaleneTriangle(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number]
) {
  const distance1 = squareDistance(point1, point2);
  const distance2 = squareDistance(point2, point3);
  const distance3 = squareDistance(point3, point1);

  return (
    arrayHasNoDuplicates([distance1, distance2, distance3]) &&
    isValidTriangle(point1, point2, point3)
  );
}

export function isValidRectangle(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number],
  point4: [number, number],
  excludeSquare: boolean = false,
  isOrdered: boolean = false
) {
  const excludeSquareCondition = excludeSquare
    ? !isValidSquare(point1, point2, point3, point4)
    : true;

  const isOrderedCondition = isOrdered ? isOrderedRectangle(point1, point2, point3, point4) : true;
  return (
    isValidRightAngledTriangle(point1, point2, point3) &&
    isValidRightAngledTriangle(point1, point2, point4) &&
    isValidRightAngledTriangle(point1, point3, point4) &&
    isValidRightAngledTriangle(point2, point3, point4) &&
    excludeSquareCondition &&
    isOrderedCondition
  );
}

function isOrderedRectangle(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number],
  point4: [number, number]
) {
  // get all side lengths
  const dist1 = squareDistance(point1, point2);
  const dist2 = squareDistance(point2, point3);
  const dist3 = squareDistance(point3, point4);
  const dist4 = squareDistance(point4, point1);

  // get diagonals
  const diag1 = squareDistance(point1, point3);
  const diag2 = squareDistance(point2, point4);

  // Check if the opposite sides are equal and diagonals are equal and that the diagonals are greater than all of the distances
  return (
    dist1 === dist3 &&
    dist2 === dist4 &&
    diag1 === diag2 &&
    diag1 > dist1 &&
    diag1 > dist2 &&
    diag1 > dist3 &&
    diag1 > dist4
  );
}

export function isValidSquare(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number],
  point4: [number, number],
  isOrdered: boolean = false
) {
  const isOrderedCondition = isOrdered ? isOrderedSquare(point1, point2, point3, point4) : true;
  const dist2 = squareDistance(point1, point2);
  const dist3 = squareDistance(point1, point3);
  const dist4 = squareDistance(point1, point4);

  //when length of point1-point2 and point1-point3 are same, and square of (point1-point4) = 2*(point1-point2)
  if (dist2 === dist3 && 2 * dist2 === dist4) {
    const dist = squareDistance(point2, point4);
    return dist === squareDistance(point3, point4) && dist === dist2 && isOrderedCondition;
  }
  //same condition for all other combinations
  if (dist3 === dist4 && 2 * dist3 === dist2) {
    const dist = squareDistance(point2, point3);
    return dist === squareDistance(point2, point4) && dist === dist3 && isOrderedCondition;
  }
  if (dist2 === dist4 && 2 * dist2 === dist3) {
    const dist = squareDistance(point2, point3);
    return dist === squareDistance(point3, point4) && dist === dist2 && isOrderedCondition;
  }
  return false;
}

function isOrderedSquare(
  point1: [number, number],
  point2: [number, number],
  point3: [number, number],
  point4: [number, number]
) {
  // get all side lengths
  const dist1 = squareDistance(point1, point2);
  const dist2 = squareDistance(point2, point3);
  const dist3 = squareDistance(point3, point4);
  const dist4 = squareDistance(point4, point1);

  // get diagonals
  const diag1 = squareDistance(point1, point3);
  const diag2 = squareDistance(point2, point4);

  // Check if all of the sides are equal and diagonals are equal and that the diagonals are greater than all of the distances
  return (
    dist1 === dist2 &&
    dist1 === dist3 &&
    dist1 === dist4 &&
    diag1 === diag2 &&
    diag1 > dist1 &&
    diag1 > dist2 &&
    diag1 > dist3 &&
    diag1 > dist4
  );
}

export function areCollinear(p1: [number, number], p2: [number, number], p3: [number, number]) {
  // Points are collinear if the cross product of the two vectors is 0
  // i.e (y2 - y1) * (x3 - x2) - (x2 - x1) * (y3 - y2) === 0
  return (p2[1] - p1[1]) * (p3[0] - p2[0]) === (p2[0] - p1[0]) * (p3[1] - p2[1]);
}

const direction = (p1: Point, p2: Point, p3: Point): number =>
  (p3.x - p1.x) * (p2.y - p1.y) - (p2.x - p1.x) * (p3.y - p1.y);

const onSegment = (p1: Point, p2: Point, p: Point): boolean =>
  Math.min(p1.x, p2.x) <= p.x &&
  p.x <= Math.max(p1.x, p2.x) &&
  Math.min(p1.y, p2.y) <= p.y &&
  p.y <= Math.max(p1.y, p2.y);

export const isIntersecting = (p1: Point, p2: Point, p3: Point, p4: Point): boolean => {
  const d1 = direction(p3, p4, p1);
  const d2 = direction(p3, p4, p2);
  const d3 = direction(p1, p2, p3);
  const d4 = direction(p1, p2, p4);

  if (((d1 > 0 && d2 < 0) || (d1 < 0 && d2 > 0)) && ((d3 > 0 && d4 < 0) || (d3 < 0 && d4 > 0)))
    return true;

  return (
    (d1 === 0 && onSegment(p3, p4, p1)) ||
    (d2 === 0 && onSegment(p3, p4, p2)) ||
    (d3 === 0 && onSegment(p1, p2, p3)) ||
    (d4 === 0 && onSegment(p1, p2, p4))
  );
};

type Point = { x: number; y: number };

export function isValidIrregularShape(coordinates: Point[]): boolean {
  const sideLengths = [];
  const angles = [];

  const filteredPoints = [];
  const numPoints = coordinates.length;
  for (let i = 0; i < numPoints; i++) {
    const p1 = coordinates[i];
    const p2 = coordinates[(i + 1) % numPoints];
    const p3 = coordinates[(i + 2) % numPoints];

    if (!areCollinear([p1.x, p1.y], [p2.x, p2.y], [p3.x, p3.y])) {
      filteredPoints.push(p2);
    }
  }

  const numFilteredPoints = filteredPoints.length;
  // Not a polygon
  if (numFilteredPoints < 3) return false;

  // Check for self-intersections
  for (let i = 0; i < numPoints; i++) {
    for (let j = i + 2; j < numPoints; j++) {
      if (i === 0 && j === numPoints - 1) continue; // Skip adjacent sides
      if (
        isIntersecting(
          coordinates[i],
          coordinates[(i + 1) % numPoints],
          coordinates[j],
          coordinates[(j + 1) % numPoints]
        )
      ) {
        return false; // The polygon is self-intersecting
      }
    }
  }

  for (let i = 0; i < numFilteredPoints; i++) {
    const p1 = filteredPoints[i];
    const p2 = filteredPoints[(i + 1) % numFilteredPoints];
    const p3 = filteredPoints[(i + 2) % numFilteredPoints];
    // Calculate side lengths
    const length = squareDistance([p1.x, p1.y], [p2.x, p2.y]);
    sideLengths.push(length);

    // Calculate angles
    const ang = angleBetweenPoints([p1.x, p1.y], [p2.x, p2.y], [p3.x, p3.y]);
    angles.push(ang);
  }

  // Check if all side lengths are equal
  const allSidesEqual = sideLengths.every((length, _i, arr) => length === arr[0]);

  // Check if all angles are equal
  const allAnglesEqual = angles.every((ang, _i, arr) => ang === arr[0]);

  return !allSidesEqual || !allAnglesEqual;
}

/**
 * When given the points of a trapezium with one missing index,
 * return true if the newPoint creates any trapezium
 * Points should be given in order bottom left, bottom right, top right, top left
 */
export const isValidTrapezium = (
  givenPoints: [number, number][],
  missingIndex: 0 | 1 | 2 | 3,
  newPoint: [number, number],
  allowParallelogram = true
) => {
  if (givenPoints.length !== 4) {
    throw new Error('Given points must have length 4');
  }
  const y = 1;
  const onHorizontalLine =
    missingIndex <= 1 ? newPoint[1] === givenPoints[0][y] : newPoint[1] === givenPoints[2][y];
  const coordPairs = [
    [0, 3],
    [1, 2]
  ];
  const givenCoords = coordPairs.filter(pair => !pair.includes(missingIndex))[0];
  const point1Index = coordPairs
    .filter(pair => pair.includes(missingIndex))
    .flat()
    .filter(val => val !== missingIndex);
  const point1 = givenPoints[point1Index[0]];
  const otherGradient = getLineGradient(givenPoints[givenCoords[0]], givenPoints[givenCoords[1]]);
  const ansGradient = getLineGradient(point1, newPoint);
  return (
    // is not a given point
    !givenPoints.filter((_val, i) => i !== missingIndex).includes(newPoint) &&
    // parallel to horizontal or diagonal line
    (onHorizontalLine || otherGradient === ansGradient) &&
    // whether to allow parallelograms
    ((!allowParallelogram && !(onHorizontalLine && otherGradient === ansGradient)) ||
      allowParallelogram)
  );
};

/**
 * For a given shape (boolean[][]) insert/overlap a new shape.
 * Shape to insert *must* be  smaller than the initialShape provided.
 */
export function insertShapeIntoArray(initialShape: boolean[][], shape: boolean[][]): boolean[][] {
  const clonedArray = deepClone(initialShape);

  for (let i = 0; i < shape.length; i++) {
    for (let j = 0; j < shape[i].length; j++) {
      clonedArray[i][j] = shape[i][j];
    }
  }
  return clonedArray;
}

export const shapePartSVGPaths = {
  equal: [
    'Equal_shapes_2_parts/Kite_equal_2-2',
    'Equal_shapes_2_parts/Square_equal_2-2',
    'Equal_shapes_2_parts/Triangle_equal_2-2',
    'Equal_shapes_3_parts/Circle_equal_3-3',
    'Equal_shapes_3_parts/Square_equal_3-3',
    'Equal_shapes_3_parts/Triangle_equal_3-3',
    'Equal_shapes_4_parts/Circle_equal_4-4',
    'Equal_shapes_4_parts/Cross_equal_4-4',
    'Equal_shapes_4_parts/Square_equal_4-4',
    'Equal_shapes_5_parts/Circle_equal_5-5',
    'Equal_shapes_5_parts/Pentagon_5-5',
    'Equal_shapes_5_parts/Square_equal_5-5',
    'Equal_shapes_6_parts/Circle_equal_6-6',
    'Equal_shapes_6_parts/Hexagon_6-6',
    'Equal_shapes_6_parts/Square_equal_6-6',
    'Equal_shapes_7_parts/Circle_equal_7-7',
    'Equal_shapes_7_parts/Rectangle_equal_7-7',
    'Equal_shapes_7_parts/Square_equal_7-7',
    'Equal_shapes_8_parts/Circle_equal_8-8',
    'Equal_shapes_8_parts/Cross_equal_8-8',
    'Equal_shapes_8_parts/Square_equal_8-8',
    'Equal_shapes_9_parts/Circle_equal_9-9',
    'Equal_shapes_9_parts/Rectangle_equal_9-9',
    'Equal_shapes_9_parts/Square_equal_9-9',
    'Equal_shapes_10_parts/Circle_equal_10-10',
    'Equal_shapes_10_parts/Pentagon_10-10',
    'Equal_shapes_10_parts/Square_equal_10-10',
    'Equal_shapes_11_parts/Circle_equal_11-11',
    'Equal_shapes_11_parts/Rectangle_equal_11-11',
    'Equal_shapes_11_parts/Square_equal_11-11',
    'Equal_shapes_12_parts/Hexagon_12-12',
    'Equal_shapes_12_parts/Rectangle_equal_12-12',
    'Equal_shapes_12_parts/Square_equal_12-12'
  ],
  unequal: [
    'Unequal_shapes_2_parts/Kite_unequal_2-2',
    'Unequal_shapes_2_parts/Square_unequal_2-2',
    'Unequal_shapes_2_parts/Triangle_unequal_2-2',
    'Unequal_shapes_3_parts/Circle_unequal_3-3',
    'Unequal_shapes_3_parts/Square_unequal_3-3',
    'Unequal_shapes_3_parts/Triangle_unequal_3-3',
    'Unequal_shapes_4_parts/Circle_unequal_4-4',
    'Unequal_shapes_4_parts/Cross_unequal_4-4',
    'Unequal_shapes_4_parts/Square_unequal_4-4',
    'Unequal_shapes_5_parts/Circle_unequal_5-5',
    'Unequal_shapes_5_parts/Pentagon_unequal_5-5',
    'Unequal_shapes_5_parts/Square_unequal_5-5',
    'Unequal_shapes_6_parts/Circle_unequal_6-6',
    'Unequal_shapes_6_parts/Hexagon_unequal6-6',
    'Unequal_shapes_6_parts/Square_unequal_6-6',
    'Unequal_shapes_7_parts/Circle_unequal_7-7',
    'Unequal_shapes_7_parts/Rectangle_unequal_7-7',
    'Unequal_shapes_7_parts/Square_unequal_7-7',
    'Unequal_shapes_8_parts/Circle_unequal_8-8',
    'Unequal_shapes_8_parts/Cross_unequal_8-8',
    'Unequal_shapes_8_parts/Square_unequal_8-8',
    'Unequal_shapes_9_parts/Circle_unequal_9-9',
    'Unequal_shapes_9_parts/Rectangle_unequal_9-9',
    'Unequal_shapes_9_parts/Square_unequal_9-9',
    'Unequal_shapes_10_parts/Circle_unequal_10-10',
    'Unequal_shapes_10_parts/Pentagon_unequal_10-10',
    'Unequal_shapes_10_parts/Square_unequal_10-10',
    'Unequal_shapes_11_parts/Circle_unequal_11-11',
    'Unequal_shapes_11_parts/Rectangle_unequal_11-11',
    'Unequal_shapes_11_parts/Square_unequal_11-11',
    'Unequal_shapes_12_parts/Circle_unequal_12-12',
    'Unequal_shapes_12_parts/Rectangle_unequal_12-12',
    'Unequal_shapes_12_parts/Square_unequal_12-12'
  ]
} as const satisfies Record<'equal' | 'unequal', readonly SvgName[]>;

export const equalShapeByPartSVGPaths = {
  2: [
    'Equal_shapes_2_parts/Kite_equal_2-2',
    'Equal_shapes_2_parts/Square_equal_2-2',
    'Equal_shapes_2_parts/Triangle_equal_2-2'
  ],
  3: [
    'Equal_shapes_3_parts/Circle_equal_3-3',
    'Equal_shapes_3_parts/Square_equal_3-3',
    'Equal_shapes_3_parts/Triangle_equal_3-3'
  ],
  4: [
    'Equal_shapes_4_parts/Circle_equal_4-4',
    'Equal_shapes_4_parts/Cross_equal_4-4',
    'Equal_shapes_4_parts/Square_equal_4-4'
  ],
  5: [
    'Equal_shapes_5_parts/Circle_equal_5-5',
    'Equal_shapes_5_parts/Pentagon_5-5',
    'Equal_shapes_5_parts/Square_equal_5-5'
  ],
  6: [
    'Equal_shapes_6_parts/Circle_equal_6-6',
    'Equal_shapes_6_parts/Hexagon_6-6',
    'Equal_shapes_6_parts/Square_equal_6-6'
  ],
  7: [
    'Equal_shapes_7_parts/Circle_equal_7-7',
    'Equal_shapes_7_parts/Rectangle_equal_7-7',
    'Equal_shapes_7_parts/Square_equal_7-7'
  ],
  8: [
    'Equal_shapes_8_parts/Circle_equal_8-8',
    'Equal_shapes_8_parts/Cross_equal_8-8',
    'Equal_shapes_8_parts/Square_equal_8-8'
  ],
  9: [
    'Equal_shapes_9_parts/Circle_equal_9-9',
    'Equal_shapes_9_parts/Rectangle_equal_9-9',
    'Equal_shapes_9_parts/Square_equal_9-9'
  ],
  10: [
    'Equal_shapes_10_parts/Circle_equal_10-10',
    'Equal_shapes_10_parts/Pentagon_10-10',
    'Equal_shapes_10_parts/Square_equal_10-10'
  ],
  11: [
    'Equal_shapes_11_parts/Circle_equal_11-11',
    'Equal_shapes_11_parts/Rectangle_equal_11-11',
    'Equal_shapes_11_parts/Square_equal_11-11'
  ],
  12: [
    'Equal_shapes_12_parts/Hexagon_12-12',
    'Equal_shapes_12_parts/Rectangle_equal_12-12',
    'Equal_shapes_12_parts/Square_equal_12-12'
  ]
} as const satisfies Record<number, readonly SvgName[]>;

export const fractionPartSVGPaths = {
  equal: {
    2: [
      'Equal_shapes_2_parts/Kite_equal_1-2',
      'Equal_shapes_2_parts/Square_equal_1-2',
      'Equal_shapes_2_parts/Triangle_equal_1-2'
    ],
    3: ['Equal_shapes_3_parts/Square_equal_1-3', 'Equal_shapes_3_parts/Triangle_equal_1-3'],
    4: ['Equal_shapes_4_parts/Cross_equal_1-4', 'Equal_shapes_4_parts/Square_equal_1-4'],
    5: ['Equal_shapes_5_parts/Pentagon_1-5', 'Equal_shapes_5_parts/Square_equal_1-5']
  },
  unequal: {
    2: [
      'Unequal_shapes_2_parts/Kite_unequal_1-2',
      'Unequal_shapes_2_parts/Square_unequal_1-2',
      'Unequal_shapes_2_parts/Triangle_unequal_1-2'
    ],
    3: ['Unequal_shapes_3_parts/Square_unequal_1-3', 'Unequal_shapes_3_parts/Triangle_unequal_1-3'],
    4: ['Unequal_shapes_4_parts/Cross_unequal_4-4', 'Unequal_shapes_4_parts/Square_unequal_4-4'],
    5: ['Unequal_shapes_5_parts/Pentagon_unequal_5-5', 'Unequal_shapes_5_parts/Square_unequal_5-5']
  }
} as const satisfies Record<'equal' | 'unequal', Record<number, readonly SvgName[]>>;

export const shapePartsSchema = z.enum([...shapePartSVGPaths.equal, ...shapePartSVGPaths.unequal]);
export const equalShapePartsSchema = z.enum(shapePartSVGPaths.equal);
export const unequalShapePartsSchema = z.enum(shapePartSVGPaths.unequal);

export const fractionPartsSchema = z.enum([
  ...fractionPartSVGPaths.equal[2],
  ...fractionPartSVGPaths.equal[3],
  ...fractionPartSVGPaths.equal[4],
  ...fractionPartSVGPaths.equal[5],
  ...fractionPartSVGPaths.unequal[2],
  ...fractionPartSVGPaths.unequal[3],
  ...fractionPartSVGPaths.unequal[4],
  ...fractionPartSVGPaths.unequal[5]
]);
export const equalFractionShapePartsSchema = z.enum([
  ...fractionPartSVGPaths.equal[2],
  ...fractionPartSVGPaths.equal[3],
  ...fractionPartSVGPaths.equal[4],
  ...fractionPartSVGPaths.equal[5]
]);
export const unequalFractionPartsSchema = z.enum([
  ...fractionPartSVGPaths.unequal[2],
  ...fractionPartSVGPaths.unequal[3],
  ...fractionPartSVGPaths.unequal[4],
  ...fractionPartSVGPaths.unequal[5]
]);

export const allPartsShapesSchema = z.enum([
  ...fractionPartSVGPaths.equal[2],
  ...fractionPartSVGPaths.equal[3],
  ...fractionPartSVGPaths.equal[4],
  ...fractionPartSVGPaths.equal[5],
  ...fractionPartSVGPaths.unequal[2],
  ...fractionPartSVGPaths.unequal[3],
  ...fractionPartSVGPaths.unequal[4],
  ...fractionPartSVGPaths.unequal[5],
  ...shapePartSVGPaths.equal,
  ...shapePartSVGPaths.unequal
]);

export type EqualShapePart = (typeof shapePartSVGPaths.equal)[number];
export type UnequalShapePart = (typeof shapePartSVGPaths.unequal)[number];

export const shapePartsArray = [...shapePartSVGPaths.equal, ...shapePartSVGPaths.unequal];

export function getRandomShapePart() {
  return getRandomFromArray([...shapePartSVGPaths.equal, ...shapePartSVGPaths.unequal] as const);
}

export function getRandomEqualShapePart() {
  return getRandomFromArray([...shapePartSVGPaths.equal] as const);
}

export function getEqualShapeByParts(denominator: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) {
  return getRandomFromArray([...equalShapeByPartSVGPaths[denominator]] as const);
}

export function getRandomUniqueEqualShapeParts(numberOfShapes: number) {
  return getRandomSubArrayFromArray([...shapePartSVGPaths.equal] as const, numberOfShapes);
}

export function getRandomUnequalShapePart() {
  return getRandomFromArray([...shapePartSVGPaths.unequal] as const);
}

export function getRandomUniqueUnequalShapeParts(numberOfShapes: number) {
  return getRandomSubArrayFromArray([...shapePartSVGPaths.unequal] as const, numberOfShapes);
}

export function getRandomEqualFractionPart(denominator: 2 | 3 | 4 | 5) {
  return getRandomFromArray([...fractionPartSVGPaths.equal[denominator]] as const);
}

export function getRandomUniqueEqualFractionParts(
  numberOfShapes: number,
  denominator: 2 | 3 | 4 | 5
) {
  return getRandomSubArrayFromArray(
    [...fractionPartSVGPaths.equal[denominator]] as const,
    numberOfShapes
  );
}

export function getRandomUnequalFractionPart(denominator: 2 | 3 | 4 | 5) {
  return getRandomFromArray([...fractionPartSVGPaths.unequal[denominator]] as const);
}

export function getRandomUniqueUnequalFractionParts(
  numberOfShapes: number,
  denominator: 2 | 3 | 4 | 5
) {
  return getRandomSubArrayFromArray(
    [...fractionPartSVGPaths.unequal[denominator]] as const,
    numberOfShapes
  );
}

/**
 * Returns all the SVG paths for equal shapes split into partsPerShape parts.
 */
export function getEqualShapesSVGPaths(partsPerShape: number): EqualShapePart[] {
  if (partsPerShape < 2 || partsPerShape > 12) {
    throw new Error('Only shapes with 2 to 12 equal parts available.');
  }
  switch (partsPerShape) {
    case 2:
      return [
        'Equal_shapes_2_parts/Kite_equal_2-2',
        'Equal_shapes_2_parts/Square_equal_2-2',
        'Equal_shapes_2_parts/Triangle_equal_2-2'
      ];
    case 3:
      return [
        'Equal_shapes_3_parts/Circle_equal_3-3',
        'Equal_shapes_3_parts/Square_equal_3-3',
        'Equal_shapes_3_parts/Triangle_equal_3-3'
      ];
    case 4:
      return [
        'Equal_shapes_4_parts/Circle_equal_4-4',
        'Equal_shapes_4_parts/Cross_equal_4-4',
        'Equal_shapes_4_parts/Square_equal_4-4'
      ];
    case 5:
      return [
        'Equal_shapes_5_parts/Circle_equal_5-5',
        'Equal_shapes_5_parts/Pentagon_5-5',
        'Equal_shapes_5_parts/Square_equal_5-5'
      ];
    case 6:
      return [
        'Equal_shapes_6_parts/Circle_equal_6-6',
        'Equal_shapes_6_parts/Hexagon_6-6',
        'Equal_shapes_6_parts/Square_equal_6-6'
      ];
    case 7:
      return [
        'Equal_shapes_7_parts/Circle_equal_7-7',
        'Equal_shapes_7_parts/Rectangle_equal_7-7',
        'Equal_shapes_7_parts/Square_equal_7-7'
      ];
    case 8:
      return [
        'Equal_shapes_8_parts/Circle_equal_8-8',
        'Equal_shapes_8_parts/Cross_equal_8-8',
        'Equal_shapes_8_parts/Square_equal_8-8'
      ];
    case 9:
      return [
        'Equal_shapes_9_parts/Circle_equal_9-9',
        'Equal_shapes_9_parts/Rectangle_equal_9-9',
        'Equal_shapes_9_parts/Square_equal_9-9'
      ];
    case 10:
      return [
        'Equal_shapes_10_parts/Circle_equal_10-10',
        'Equal_shapes_10_parts/Pentagon_10-10',
        'Equal_shapes_10_parts/Square_equal_10-10'
      ];
    case 11:
      return [
        'Equal_shapes_11_parts/Circle_equal_11-11',
        'Equal_shapes_11_parts/Rectangle_equal_11-11',
        'Equal_shapes_11_parts/Square_equal_11-11'
      ];
    case 12:
      return [
        'Equal_shapes_12_parts/Hexagon_12-12',
        'Equal_shapes_12_parts/Rectangle_equal_12-12',
        'Equal_shapes_12_parts/Square_equal_12-12'
      ];
    default:
      return [];
  }
}

/**
 * Returns all the SVG paths for unequal shapes split into partsPerShape parts.
 */
export function getUnequalShapesSVGPaths(partsPerShape: number): UnequalShapePart[] {
  if (partsPerShape < 2 || partsPerShape > 12) {
    throw new Error('Only shapes with 2 to 12 unequal parts available.');
  }
  switch (partsPerShape) {
    case 2:
      return [
        'Unequal_shapes_2_parts/Kite_unequal_2-2',
        'Unequal_shapes_2_parts/Square_unequal_2-2',
        'Unequal_shapes_2_parts/Triangle_unequal_2-2'
      ];
    case 3:
      return [
        'Unequal_shapes_3_parts/Circle_unequal_3-3',
        'Unequal_shapes_3_parts/Square_unequal_3-3',
        'Unequal_shapes_3_parts/Triangle_unequal_3-3'
      ];
    case 4:
      return [
        'Unequal_shapes_4_parts/Circle_unequal_4-4',
        'Unequal_shapes_4_parts/Cross_unequal_4-4',
        'Unequal_shapes_4_parts/Square_unequal_4-4'
      ];
    case 5:
      return [
        'Unequal_shapes_5_parts/Circle_unequal_5-5',
        'Unequal_shapes_5_parts/Pentagon_unequal_5-5',
        'Unequal_shapes_5_parts/Square_unequal_5-5'
      ];
    case 6:
      return [
        'Unequal_shapes_6_parts/Circle_unequal_6-6',
        'Unequal_shapes_6_parts/Hexagon_unequal6-6',
        'Unequal_shapes_6_parts/Square_unequal_6-6'
      ];
    case 7:
      return [
        'Unequal_shapes_7_parts/Circle_unequal_7-7',
        'Unequal_shapes_7_parts/Rectangle_unequal_7-7',
        'Unequal_shapes_7_parts/Square_unequal_7-7'
      ];
    case 8:
      return [
        'Unequal_shapes_8_parts/Circle_unequal_8-8',
        'Unequal_shapes_8_parts/Cross_unequal_8-8',
        'Unequal_shapes_8_parts/Square_unequal_8-8'
      ];
    case 9:
      return [
        'Unequal_shapes_9_parts/Circle_unequal_9-9',
        'Unequal_shapes_9_parts/Rectangle_unequal_9-9',
        'Unequal_shapes_9_parts/Square_unequal_9-9'
      ];
    case 10:
      return [
        'Unequal_shapes_10_parts/Circle_unequal_10-10',
        'Unequal_shapes_10_parts/Pentagon_unequal_10-10',
        'Unequal_shapes_10_parts/Square_unequal_10-10'
      ];
    case 11:
      return [
        'Unequal_shapes_11_parts/Circle_unequal_11-11',
        'Unequal_shapes_11_parts/Rectangle_unequal_11-11',
        'Unequal_shapes_11_parts/Square_unequal_11-11'
      ];
    case 12:
      return [
        'Unequal_shapes_12_parts/Circle_unequal_12-12',
        'Unequal_shapes_12_parts/Rectangle_unequal_12-12',
        'Unequal_shapes_12_parts/Square_unequal_12-12'
      ];
    default:
      return [];
  }
}

// More shape translations
export const moreShapesNames = [
  'squares',
  'rectangles',
  'triangles',
  'circles',
  'hexagons',
  'pentagons',
  'heptagons',
  'nonagons',
  'octagons',
  'decagons',
  'quadrilaterals'
] as const;

export type moreShapesName = (typeof moreShapesNames)[number];

export const moreShapesSchema = z.enum(moreShapesNames);

export const moreShapesAsWord = (
  object: moreShapesName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'rectangles':
      return translate.shapes.rectangles(amount);
    case 'squares':
      return translate.shapes.squares(amount);
    case 'triangles':
      return translate.shapes.triangles(amount);
    case 'circles':
      return translate.shapes.circles(amount);
    case 'hexagons':
      return translate.shapes.hexagons(amount);
    case 'pentagons':
      return translate.shapes.pentagons(amount);
    case 'heptagons':
      return translate.shapes.heptagons(amount);
    case 'nonagons':
      return translate.shapes.nonagons(amount);
    case 'octagons':
      return translate.shapes.octagons(amount);
    case 'decagons':
      return translate.shapes.decagons(amount);
    case 'quadrilaterals':
      return translate.shapes.quadrilaterals(amount);
  }
};

export function getRandomUniqueMoreShapeNames(numberOfShapesNames: number) {
  return getRandomSubArrayFromArray([...moreShapesNames] as const, numberOfShapesNames);
}

const realLifeObjectOf2DShapesSvgNames: SvgName[] = [
  '3D_shapes_in_real_life_objects/Cone_objects/Christmas_tree',
  '3D_shapes_in_real_life_objects/Cone_objects/Cooking_funnel',
  '3D_shapes_in_real_life_objects/Cone_objects/Party_hat',
  '3D_shapes_in_real_life_objects/Cone_objects/Pine_cone',
  '3D_shapes_in_real_life_objects/Sphere_objects/Bubble',
  '3D_shapes_in_real_life_objects/Sphere_objects/Basketball',
  '3D_shapes_in_real_life_objects/Sphere_objects/Coloured_marble',
  '3D_shapes_in_real_life_objects/Sphere_objects/Ball_of_yarn'
];

export function getRandomUniqueRealLifeObjectOf2DShapesSvgNames(numberOf3DShapesNames: number) {
  return getRandomSubArrayFromArray(
    [...realLifeObjectOf2DShapesSvgNames] as const,
    numberOf3DShapesNames
  );
}

export const missingChunkAndWholeShapePairs: [SvgName, SvgName][] = [
  ['Whole_shapes/Whole_shape_1', 'Shapes_with_missing_parts/Missing_part_shape1'],
  ['Whole_shapes/Whole_shape_2', 'Shapes_with_missing_parts/Missing_part_shape2'],
  ['Whole_shapes/Whole_shape_3', 'Shapes_with_missing_parts/Missing_part_shape3'],
  ['Whole_shapes/Whole_shape_5', 'Shapes_with_missing_parts/Missing_part_shape5']
];

/**
 * @deprecated. These do not have the right proportions - see rectilinearShapes.ts
 */
export const archivedRectilinearShapesProperties = [
  {
    name: 'Rectilinear_shape1_all_arrows',
    sideLengths: [
      [10, 6, 8, 3, 2, 9],
      [12, 8, 10, 3, 2, 11],
      [11, 7, 9, 3, 2, 10]
    ],
    addedSides: [
      [10, 9],
      [12, 11],
      [11, 10]
    ]
  },
  {
    name: 'Rectilinear_shape2_all_arrows',
    sideLengths: [
      [9, 7, 2, 1, 2, 7, 9, 7, 2, 1, 2, 7],
      [10, 5, 2, 1, 2, 5, 10, 5, 2, 1, 2, 5],
      [12, 7, 3, 1, 3, 7, 12, 7, 3, 1, 3, 7]
    ],
    addedSides: [[], [], []]
  },
  {
    name: 'Rectilinear_shape3_all_arrows',
    sideLengths: [
      [6, 8, 2, 6, 2, 7, 10, 9],
      [4, 7, 3, 5, 3, 6, 10, 8],
      [5, 9, 3, 6, 3, 7, 11, 10]
    ],
    addedSides: [[10], [10], [11]]
  },
  {
    name: 'Rectilinear_shape4_all_arrows',
    sideLengths: [
      [10, 3, 1, 2, 2, 8, 7, 9],
      [12, 5, 2, 3, 3, 9, 7, 11],
      [12, 4, 2, 3, 3, 8, 7, 10]
    ],
    addedSides: [[10], [12], [12]]
  },
  {
    name: 'Rectilinear_shape5_all_arrows',
    sideLengths: [
      [9, 2, 3, 7, 12, 5],
      [7, 2, 3, 6, 10, 4],
      [10, 1, 2, 6, 12, 5]
    ],
    addedSides: [
      [7, 12],
      [6, 10],
      [6, 12]
    ]
  },
  {
    name: 'Rectilinear_shape6_all_arrows',
    sideLengths: [
      [9, 2, 8, 5, 1, 7],
      [10, 3, 8, 6, 2, 9],
      [12, 2, 11, 8, 1, 10]
    ],
    addedSides: [
      [9, 7],
      [10, 9],
      [12, 10]
    ]
  },
  {
    name: 'Rectilinear_shape7_all_arrows',
    sideLengths: [
      [8, 1, 2, 12, 10, 11],
      [6, 1, 4, 12, 10, 11],
      [7, 1, 2, 11, 9, 10]
    ],
    addedSides: [
      [12, 10],
      [12, 10],
      [11, 9]
    ]
  },
  {
    name: 'Rectilinear_shape8_all_arrows',
    sideLengths: [
      [10, 8, 2, 9, 12, 1],
      [8, 7, 3, 9, 11, 2],
      [9, 6, 3, 7, 12, 1]
    ],
    addedSides: [
      [9, 12],
      [9, 11],
      [7, 12]
    ]
  },
  {
    name: 'Rectilinear_shape9_all_arrows',
    sideLengths: [
      [2, 8, 3, 9, 5, 1],
      [3, 9, 4, 11, 7, 2],
      [3, 8, 3, 10, 6, 2]
    ],
    addedSides: [
      [9, 5],
      [11, 7],
      [10, 6]
    ]
  },
  {
    name: 'Rectilinear_shape10_all_arrows',
    sideLengths: [
      [9, 3, 8, 3, 1, 6],
      [11, 4, 9, 4, 2, 8],
      [12, 5, 9, 5, 3, 10]
    ],
    addedSides: [
      [9, 6],
      [11, 8],
      [12, 10]
    ]
  },
  {
    name: 'Rectilinear_shape11_all_arrows',
    sideLengths: [
      [3, 9, 7, 2, 10, 11],
      [4, 8, 5, 2, 9, 10],
      [4, 10, 7, 2, 11, 12]
    ],
    addedSides: [
      [10, 11],
      [9, 10],
      [11, 12]
    ]
  },
  {
    name: 'Rectilinear_shape12_all_arrows',
    sideLengths: [
      [11, 9, 7, 8, 3, 8, 1, 9],
      [12, 10, 6, 9, 4, 9, 2, 10],
      [10, 8, 5, 7, 4, 7, 1, 8]
    ],
    addedSides: [[11], [12], [10]]
  },
  {
    name: 'Rectilinear_shape13_all_arrows',
    sideLengths: [
      [10, 3, 8, 9, 2, 12],
      [9, 5, 8, 7, 1, 12],
      [10, 4, 8, 7, 2, 11]
    ],
    addedSides: [
      [10, 12],
      [9, 12],
      [10, 11]
    ]
  },
  {
    name: 'Rectilinear_shape14_all_arrows',
    sideLengths: [
      [3, 7, 6, 4, 9, 11],
      [2, 7, 8, 5, 10, 12],
      [1, 7, 8, 4, 9, 11]
    ],
    addedSides: [
      [9, 11],
      [10, 12],
      [9, 11]
    ]
  },
  {
    name: 'Rectilinear_shape15_all_arrows',
    sideLengths: [
      [8, 7, 1, 3, 6, 3, 1, 7],
      [10, 8, 2, 3, 6, 3, 2, 8],
      [12, 10, 2, 5, 8, 5, 2, 10]
    ],
    addedSides: [[8], [10], [12]]
  },
  {
    name: 'Rectilinear_shape16_all_arrows',
    sideLengths: [
      [11, 1, 7, 2, 4, 3],
      [10, 2, 6, 3, 4, 5],
      [11, 1, 6, 3, 5, 4]
    ],
    addedSides: [
      [11, 3],
      [10, 5],
      [11, 4]
    ]
  },
  {
    name: 'Rectilinear_shape17_all_arrows',
    sideLengths: [
      [8, 10, 3, 9, 5, 1],
      [8, 9, 3, 7, 5, 2],
      [10, 12, 4, 9, 6, 3]
    ],
    addedSides: [
      [8, 10],
      [8, 9],
      [10, 12]
    ]
  },
  {
    name: 'Rectilinear_shape18_all_arrows',
    sideLengths: [
      [8, 1, 5, 6, 3, 7],
      [10, 3, 6, 5, 4, 8],
      [11, 3, 7, 6, 4, 9]
    ],
    addedSides: [
      [8, 7],
      [10, 8],
      [11, 9]
    ]
  },
  {
    name: 'Rectilinear_shape19_all_arrows',
    sideLengths: [
      [2, 6, 8, 3, 10, 9],
      [2, 7, 9, 3, 11, 10],
      [4, 6, 8, 5, 12, 11]
    ],
    addedSides: [
      [10, 9],
      [11, 10],
      [12, 11]
    ]
  },
  {
    name: 'Rectilinear_shape20_all_arrows',
    sideLengths: [
      [6, 3, 5, 7, 1, 10],
      [7, 4, 6, 8, 1, 12],
      [10, 3, 8, 9, 2, 12]
    ],
    addedSides: [
      [6, 10],
      [7, 12],
      [10, 12]
    ]
  },
  {
    name: 'Rectilinear_shape21_all_arrows',
    sideLengths: [
      [3, 11, 10, 5, 7, 6],
      [1, 12, 11, 4, 10, 8],
      [2, 12, 9, 4, 7, 8]
    ],
    addedSides: [
      [11, 10],
      [12, 11],
      [12, 9]
    ]
  },
  {
    name: 'Rectilinear_shape22_all_arrows',
    sideLengths: [
      [10, 1, 7, 8, 3, 9],
      [12, 2, 9, 8, 3, 10],
      [12, 3, 8, 6, 4, 9]
    ],
    addedSides: [
      [10, 9],
      [12, 10],
      [12, 9]
    ]
  },
  {
    name: 'Rectilinear_shape23_all_arrows',
    sideLengths: [
      [4, 7, 8, 4, 12, 11],
      [3, 5, 6, 3, 9, 8],
      [2, 8, 9, 2, 11, 10]
    ],
    addedSides: [
      [12, 11],
      [9, 8],
      [11, 10]
    ]
  },
  {
    name: 'Rectilinear_shape24_all_arrows',
    sideLengths: [
      [5, 12, 9, 5, 4, 7],
      [2, 11, 3, 2, 1, 9],
      [3, 12, 5, 3, 2, 9]
    ],
    addedSides: [
      [12, 9],
      [11, 3],
      [12, 5]
    ]
  },
  {
    name: 'Rectilinear_shape25_all_arrows',
    sideLengths: [
      [10, 9, 5, 3, 5, 12],
      [10, 8, 5, 3, 5, 11],
      [8, 7, 4, 3, 4, 10]
    ],
    addedSides: [
      [10, 12],
      [10, 11],
      [8, 10]
    ]
  },
  {
    name: 'Rectilinear_shape26_all_arrows',
    sideLengths: [
      [9, 11, 7, 3, 2, 8],
      [7, 10, 5, 4, 2, 6],
      [10, 12, 7, 4, 3, 8]
    ],
    addedSides: [
      [9, 11],
      [7, 10],
      [10, 12]
    ]
  },
  {
    name: 'Rectilinear_shape27_all_arrows',
    sideLengths: [
      [4, 3, 6, 9, 10, 12],
      [3, 2, 8, 10, 11, 12],
      [2, 1, 6, 9, 8, 10]
    ],
    addedSides: [
      [10, 12],
      [11, 12],
      [8, 10]
    ]
  },
  {
    name: 'Rectilinear_shape28_all_arrows',
    sideLengths: [
      [4, 3, 6, 8, 10, 5],
      [4, 3, 8, 10, 12, 7],
      [3, 1, 8, 10, 11, 9]
    ],
    addedSides: [
      [8, 10],
      [10, 12],
      [10, 11]
    ]
  },
  {
    name: 'Rectilinear_shape29_all_arrows',
    sideLengths: [
      [3, 4, 8, 6, 11, 10],
      [4, 5, 8, 6, 12, 11],
      [2, 3, 8, 6, 10, 9]
    ],
    addedSides: [
      [11, 10],
      [12, 11],
      [10, 9]
    ]
  },
  {
    name: 'Rectilinear_shape30_all_arrows',
    sideLengths: [
      [9, 12, 8, 2, 1, 10],
      [4, 7, 3, 2, 1, 5],
      [7, 12, 5, 4, 2, 8]
    ],
    addedSides: [
      [9, 12],
      [4, 7],
      [7, 12]
    ]
  },
  {
    name: 'Rectilinear_shape31_all_arrows',
    sideLengths: [
      [9, 8, 4, 2, 5, 6],
      [12, 10, 5, 2, 7, 8],
      [12, 11, 5, 3, 7, 8]
    ],
    addedSides: [
      [9, 8],
      [12, 10],
      [12, 11]
    ]
  },
  {
    name: 'Rectilinear_shape32_all_arrows',
    sideLengths: [
      [10, 7, 2, 4, 6, 4, 2, 7],
      [12, 10, 3, 4, 6, 4, 3, 10],
      [11, 9, 3, 4, 5, 4, 3, 9]
    ],
    addedSides: [[10], [12], [11]]
  },
  {
    name: 'Rectilinear_shape33_all_arrows',
    sideLengths: [
      [9, 1, 2, 11, 7, 12],
      [8, 2, 3, 9, 5, 11],
      [7, 2, 3, 8, 4, 10]
    ],
    addedSides: [
      [9, 12],
      [8, 11],
      [7, 10]
    ]
  },
  {
    name: 'Rectilinear_shape34_all_arrows',
    sideLengths: [
      [6, 8, 2, 3, 4, 11],
      [8, 7, 3, 4, 5, 11],
      [10, 8, 3, 4, 7, 12]
    ],
    addedSides: [
      [6, 11],
      [8, 11],
      [10, 12]
    ]
  },
  {
    name: 'Rectilinear_shape35_all_arrows',
    sideLengths: [
      [10, 8, 6, 3, 4, 11],
      [11, 9, 6, 3, 5, 12],
      [11, 9, 7, 3, 4, 12]
    ],
    addedSides: [
      [10, 11],
      [11, 12],
      [11, 12]
    ]
  },
  {
    name: 'Rectilinear_shape36_all_arrows',
    sideLengths: [
      [7, 2, 3, 9, 4, 11],
      [7, 1, 3, 9, 4, 10],
      [9, 2, 4, 10, 5, 12]
    ],
    addedSides: [
      [7, 11],
      [7, 10],
      [9, 12]
    ]
  },
  {
    name: 'Rectilinear_shape37_all_arrows',
    sideLengths: [
      [4, 11, 6, 2, 9, 2],
      [4, 8, 5, 1, 7, 1],
      [4, 12, 7, 3, 9, 3]
    ],
    addedSides: [
      [11, 6],
      [8, 5],
      [12, 7]
    ]
  },
  {
    name: 'Rectilinear_shape38_all_arrows',
    sideLengths: [
      [10, 12, 2, 3, 8, 9],
      [9, 12, 3, 5, 6, 7],
      [8, 10, 3, 4, 5, 6]
    ],
    addedSides: [
      [10, 12],
      [9, 12],
      [8, 10]
    ]
  }
];

export const aOrAnShapeAsWords = (object: moreShapesName, translate: TranslationFunctions) => {
  switch (object) {
    case 'rectangles':
      return translate.shapes.aRectangle();
    case 'squares':
      return translate.shapes.aSquare();
    case 'triangles':
      return translate.shapes.aTriangle();
    case 'circles':
      return translate.shapes.aCircle();
    case 'hexagons':
      return translate.shapes.aHexagon();
    case 'pentagons':
      return translate.shapes.aPentagon();
    case 'heptagons':
      return translate.shapes.aHeptagon();
    case 'nonagons':
      return translate.shapes.aNonagon();
    case 'octagons':
      return translate.shapes.anOctagon();
    case 'decagons':
      return translate.shapes.aDecagon();
    case 'quadrilaterals':
      return translate.shapes.aQuadrilateral();
  }
};

export const getRegularSvg = (
  polygonSides: number
):
  | 'Pentagon_1_arrow'
  | 'Hexagon_1_arrow'
  | 'Heptagon_1_arrow'
  | 'Octagon_1_arrow'
  | 'Nonagon_1_arrow'
  | 'Decagon_1_arrow'
  | 'Square_1_arrow' => {
  switch (polygonSides) {
    case 4: {
      return 'Square_1_arrow';
    }
    case 5: {
      return 'Pentagon_1_arrow';
    }
    case 6: {
      return 'Hexagon_1_arrow';
    }
    case 7: {
      return 'Heptagon_1_arrow';
    }
    case 8: {
      return 'Octagon_1_arrow';
    }
    case 9: {
      return 'Nonagon_1_arrow';
    }
    default:
    case 10: {
      return 'Decagon_1_arrow';
    }
  }
};

export type shapeAnglesType = {
  name: string;
  rightAngles: number;
  acuteAngles: number;
  obtuseAngles: number;
  shapeImages: SvgName[];
}[];

export function getRandomUniqueShapeAngles(numberOfShapeAngles: number) {
  return getRandomSubArrayFromArray([...shapeAngles] as const, numberOfShapeAngles);
}

export const shapeAnglesSchema = z.array(
  z.object({
    rightAngles: z.number().int().min(0).max(5),
    acuteAngles: z.number().int().min(0).max(3),
    obtuseAngles: z.number().int().min(0).max(6),
    name: z.string(),
    shapeImages: z.array(z.string())
  })
);

export const shapeAngles: shapeAnglesType = [
  {
    name: 'Circle',
    rightAngles: 0,
    acuteAngles: 0,
    obtuseAngles: 0,
    shapeImages: [
      'Circles/circle_purple',
      'Circles/circle_green',
      'Circles/circle_white',
      'Circles/circle_yellow',
      'Circles/circle_red',
      'Circles/circle_'
    ]
  },
  {
    name: 'Equilateral Triangle',
    rightAngles: 0,
    acuteAngles: 3,
    obtuseAngles: 0,
    shapeImages: [
      'Equilateral_triangles/triangle_equal_purple',
      'Equilateral_triangles/triangle_equal_',
      'Equilateral_triangles/triangle_equal_blue',
      'Equilateral_triangles/triangle_equal_pink',
      'Equilateral_triangles/triangle_equal_white',
      'Equilateral_triangles/triangle_equal_yellow',
      'Equilateral_triangles/triangle_equal_red',
      'Equilateral_triangles/triangle_equal_green'
    ]
  },
  {
    name: 'Right Angle Triangle',
    rightAngles: 1,
    acuteAngles: 2,
    obtuseAngles: 0,
    shapeImages: [
      'Right_angled_triangles/triangle_RA_green',
      'Right_angled_triangles/triangle_RA_purple',
      'Right_angled_triangles/triangle_RA_pink',
      'Right_angled_triangles/triangle_RA_white',
      'Right_angled_triangles/triangle_RA_yellow',
      'Right_angled_triangles/triangle_RA'
    ]
  },
  {
    name: 'Isosceles Triangle Narrow',
    rightAngles: 0,
    acuteAngles: 3,
    obtuseAngles: 0,
    shapeImages: [
      'Isosceles_triangles_narrow/triangle_isos_narrow_blue',
      'Isosceles_triangles_narrow/triangle_isos_narrow_green',
      'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
      'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
      'Isosceles_triangles_narrow/triangle_isos_narrow_white',
      'Isosceles_triangles_narrow/triangle_isos_narrow_yellow',
      'Isosceles_triangles_narrow/triangle_isos_narrow'
    ]
  },
  {
    name: 'Isosceles Triangle Wide',
    rightAngles: 0,
    acuteAngles: 3,
    obtuseAngles: 0,
    shapeImages: [
      'Isosceles_triangles_wide/triangle_isos_wide_blue',
      'Isosceles_triangles_wide/triangle_isos_wide_pink',
      'Isosceles_triangles_wide/triangle_isos_wide_green',
      'Isosceles_triangles_wide/triangle_isos_wide_purple',
      'Isosceles_triangles_wide/triangle_isos_wide_white',
      'Isosceles_triangles_wide/triangle_isos_wide_yellow',
      'Isosceles_triangles_wide/triangle_isos_wide'
    ]
  },
  {
    name: 'Scalene Triangle',
    rightAngles: 0,
    acuteAngles: 2,
    obtuseAngles: 1,
    shapeImages: [
      'Scalene_triangles/triangle_scalene_blue',
      'Scalene_triangles/triangle_scalene',
      'Scalene_triangles/triangle_scalene_yellow',
      'Scalene_triangles/triangle_scalene_white',
      'Scalene_triangles/triangle_scalene_purple',
      'Scalene_triangles/triangle_scalene_green'
    ]
  },
  {
    name: 'Square',
    rightAngles: 4,
    acuteAngles: 0,
    obtuseAngles: 0,
    shapeImages: [
      'Square/square_green',
      'Square/square_blue',
      'Square/square_pink',
      'Square/square_purple',
      'Square/square_white',
      'Square/square_yellow',
      'Square/square'
    ]
  },
  {
    name: 'Isosceles Trapezium',
    rightAngles: 0,
    acuteAngles: 2,
    obtuseAngles: 2,
    shapeImages: [
      'Trapezium/trapezium_isosceles_green',
      'Trapezium/trapezium_isosceles_blue',
      'Trapezium/trapezium_isosceles_pink',
      'Trapezium/trapezium_isosceles_white',
      'Trapezium/trapezium_isosceles_purple',
      'Trapezium/trapezium_isosceles_yellow',
      'Trapezium/trapezium_isosceles'
    ]
  },
  {
    name: 'Right Angle Trapezium',
    rightAngles: 2,
    acuteAngles: 1,
    obtuseAngles: 1,
    shapeImages: [
      'Right_angled_trapeziums/trapezium_RA_pink',
      'Right_angled_trapeziums/trapezium_RA_purple',
      'Right_angled_trapeziums/trapezium_RA_white',
      'Right_angled_trapeziums/trapezium_RA_yellow',
      'Right_angled_trapeziums/trapezium_RA',
      'Right_angled_trapeziums/trapezium_RA_green',
      'Right_angled_trapeziums/trapezium_RA_blue'
    ]
  },
  {
    name: 'Rhombus',
    rightAngles: 0,
    acuteAngles: 2,
    obtuseAngles: 2,
    shapeImages: [
      'Rhombus/rhombus_purple',
      'Rhombus/rhombus_blue',
      'Rhombus/rhombus_green',
      'Rhombus/rhombus_pink',
      'Rhombus/rhombus_white',
      'Rhombus/rhombus_yellow',
      'Rhombus/rhombus'
    ]
  },
  {
    name: 'Rectangle',
    rightAngles: 4,
    acuteAngles: 0,
    obtuseAngles: 0,
    shapeImages: [
      'Rectangle/rectangle_pink',
      'Rectangle/rectangle_blue',
      'Rectangle/rectangle_green',
      'Rectangle/rectangle_purple',
      'Rectangle/rectangle_yellow',
      'Rectangle/rectangle_white',
      'Rectangle/rectangle'
    ]
  },
  {
    name: 'Parallelogram',
    rightAngles: 0,
    acuteAngles: 2,
    obtuseAngles: 2,
    shapeImages: [
      'Parallelogram/parallelogram_green',
      'Parallelogram/parallelogram_pink',
      'Parallelogram/parallelogram_blue',
      'Parallelogram/parallelogram_purple',
      'Parallelogram/parallelogram_white',
      'Parallelogram/parallelogram_yellow',
      'Parallelogram/parallelogram'
    ]
  },
  {
    name: 'Kite',
    rightAngles: 0,
    acuteAngles: 2,
    obtuseAngles: 2,
    shapeImages: [
      'Kite/kite_purple',
      'Kite/kite_pink',
      'Kite/kite_green',
      'Kite/kite_white',
      'Kite/kite_yellow',
      'Kite/kite'
    ]
  },
  {
    name: 'Pentagon',
    rightAngles: 0,
    acuteAngles: 0,
    obtuseAngles: 5,
    shapeImages: [
      'Pentagons/pentagon_purple',
      'Pentagons/pentagon_green',
      'Pentagons/pentagon_blue',
      'Pentagons/pentagon_pink',
      'Pentagons/pentagon_white',
      'Pentagons/pentagon_yellow',
      'Pentagons/pentagon'
    ]
  },
  {
    name: 'Irregular Pentagon 1',
    rightAngles: 0,
    acuteAngles: 1,
    obtuseAngles: 4,
    shapeImages: [
      'Pentagons/Irregular_pentagon1_blue',
      'Pentagons/Irregular_pentagon1_green',
      'Pentagons/Irregular_pentagon1_pink',
      'Pentagons/Irregular_pentagon1_purple',
      'Pentagons/Irregular_pentagon1_white',
      'Pentagons/Irregular_pentagon1_yellow'
    ]
  },
  {
    name: 'Irregular Pentagon 2',
    rightAngles: 2,
    acuteAngles: 0,
    obtuseAngles: 3,
    shapeImages: [
      'Pentagons/Irregular_pentagon2_blue',
      'Pentagons/Irregular_pentagon2_green',
      'Pentagons/Irregular_pentagon2_pink',
      'Pentagons/Irregular_pentagon2_purple',
      'Pentagons/Irregular_pentagon2_white',
      'Pentagons/Irregular_pentagon2_yellow'
    ]
  },
  {
    name: 'Irregular Pentagon 3',
    rightAngles: 0,
    acuteAngles: 0,
    obtuseAngles: 5,
    shapeImages: [
      'Pentagons/Irregular_pentagon3_blue',
      'Pentagons/Irregular_pentagon3_green',
      'Pentagons/Irregular_pentagon3_pink',
      'Pentagons/Irregular_pentagon3_purple',
      'Pentagons/Irregular_pentagon3_white',
      'Pentagons/Irregular_pentagon3_yellow'
    ]
  },
  {
    name: 'Irregular Pentagon 4',
    rightAngles: 2,
    acuteAngles: 1,
    obtuseAngles: 2,
    shapeImages: [
      'Pentagons/Irregular_pentagon4_blue',
      'Pentagons/Irregular_pentagon4_green',
      'Pentagons/Irregular_pentagon4_pink',
      'Pentagons/Irregular_pentagon4_purple',
      'Pentagons/Irregular_pentagon4_white',
      'Pentagons/Irregular_pentagon4_yellow'
    ]
  },
  {
    name: 'Irregular Pentagon 5',
    rightAngles: 0,
    acuteAngles: 0,
    obtuseAngles: 5,
    shapeImages: [
      'Pentagons/Irregular_pentagon5_blue',
      'Pentagons/Irregular_pentagon5_green',
      'Pentagons/Irregular_pentagon5_pink',
      'Pentagons/Irregular_pentagon5_purple',
      'Pentagons/Irregular_pentagon5_white',
      'Pentagons/Irregular_pentagon5_yellow'
    ]
  },
  {
    name: 'Irregular Pentagon 6',
    rightAngles: 2,
    acuteAngles: 0,
    obtuseAngles: 3,
    shapeImages: [
      'Pentagons/Irregular_pentagon6_blue',
      'Pentagons/Irregular_pentagon6_green',
      'Pentagons/Irregular_pentagon6_pink',
      'Pentagons/Irregular_pentagon6_purple',
      'Pentagons/Irregular_pentagon6_white',
      'Pentagons/Irregular_pentagon6_yellow'
    ]
  },
  {
    name: 'Pentagon House',
    rightAngles: 2,
    acuteAngles: 0,
    obtuseAngles: 3,
    shapeImages: [
      'Pentagon_houses/pentagon_house_blue',
      'Pentagon_houses/pentagon_house_green',
      'Pentagon_houses/pentagon_house_pink',
      'Pentagon_houses/pentagon_house_purple',
      'Pentagon_houses/pentagon_house_yellow',
      'Pentagon_houses/pentagon_house_white',
      'Pentagon_houses/pentagon_house'
    ]
  },
  {
    name: 'Hexagon',
    rightAngles: 0,
    acuteAngles: 0,
    obtuseAngles: 6,
    shapeImages: [
      'Hexagons/hexagon_white',
      'Hexagons/hexagon_blue',
      'Hexagons/hexagon_green',
      'Hexagons/hexagon_pink',
      'Hexagons/hexagon_yellow',
      'Hexagons/hexagon_purple',
      'Hexagons/hexagon'
    ]
  },
  {
    name: 'Irregular Hexagon 1',
    rightAngles: 5,
    acuteAngles: 0,
    obtuseAngles: 0,
    shapeImages: [
      'Hexagons/Irregular_hexagon1_blue',
      'Hexagons/Irregular_hexagon1_green',
      'Hexagons/Irregular_hexagon1_pink',
      'Hexagons/Irregular_hexagon1_purple',
      'Hexagons/Irregular_hexagon1_white',
      'Hexagons/Irregular_hexagon1_yellow'
    ]
  },
  {
    name: 'Irregular Hexagon 2',
    rightAngles: 0,
    acuteAngles: 2,
    obtuseAngles: 4,
    shapeImages: [
      'Hexagons/Irregular_hexagon2_blue',
      'Hexagons/Irregular_hexagon2_green',
      'Hexagons/Irregular_hexagon2_pink',
      'Hexagons/Irregular_hexagon2_purple',
      'Hexagons/Irregular_hexagon2_white',
      'Hexagons/Irregular_hexagon2_yellow'
    ]
  },
  {
    name: 'Irregular Hexagon 3',
    rightAngles: 5,
    acuteAngles: 0,
    obtuseAngles: 0,
    shapeImages: [
      'Hexagons/Irregular_hexagon3_blue',
      'Hexagons/Irregular_hexagon3_green',
      'Hexagons/Irregular_hexagon3_pink',
      'Hexagons/Irregular_hexagon3_purple',
      'Hexagons/Irregular_hexagon3_white',
      'Hexagons/Irregular_hexagon3_yellow'
    ]
  },
  {
    name: 'Arrow',
    rightAngles: 2,
    acuteAngles: 3,
    obtuseAngles: 0,
    shapeImages: [
      'Arrows/arrow_green',
      'Arrows/arrow_blue',
      'Arrows/arrow_pink',
      'Arrows/arrow_purple',
      'Arrows/arrow_white',
      'Arrows/arrow_yellow',
      'Arrows/arrow'
    ]
  }
];

export type shapeAnglesWithVerticesType = {
  lhs: {
    svgName: SvgName;
    vertex: {
      x: number;
      y: number;
    };
    answer: number;
  };
  rhs: {
    svgName: SvgName;
    vertex: {
      x: number;
      y: number;
    };
    answer: number;
  };
}[];

export const shapeAnglesWithVerticesSchema = z.object({
  lhs: z.object({
    svgName: z.string(),
    vertex: z.object({
      x: z.number().int(),
      y: z.number().int()
    }),
    answer: z.number().int()
  }),
  rhs: z.object({
    svgName: z.string(),
    vertex: z.object({
      x: z.number().int(),
      y: z.number().int()
    }),
    answer: z.number().int()
  })
});

export function getRandomUniqueShapeAnglesWithVertices() {
  return getRandomSubArrayFromArray([...shapeAnglesWithVertices], 1)[0];
}

export const shapeAnglesWithVertices: shapeAnglesWithVerticesType = [
  {
    lhs: {
      svgName: getRandomFromArray([
        'Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_lhs',
        'Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_lhs',
        'Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_lhs',
        'Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_lhs',
        'Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 0,
        y: 328
      },
      answer: 55
    },
    rhs: {
      svgName: getRandomFromArray([
        'Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_rhs',
        'Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_rhs',
        'Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_rhs',
        'Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_rhs',
        'Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 440,
        y: 328
      },
      answer: 55
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_lhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_lhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_lhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_lhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_lhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 0,
        y: 328
      },
      answer: 67
    },
    rhs: {
      svgName: getRandomFromArray([
        'Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_rhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_rhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_rhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_rhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_rhs',
        'Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 273,
        y: 330
      },
      answer: 67
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Right_angled_triangles/triangle_RA_green_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_pink_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_purple_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_white_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 0,
        y: 328
      },
      answer: 90
    },
    rhs: {
      svgName: getRandomFromArray([
        'Right_angled_triangles/triangle_RA_green_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_pink_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_purple_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_white_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 330,
        y: 330
      },
      answer: 45
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Right_angled_triangles/triangle_RA_long_green_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_long_white_one_interior_angle_lhs',
        'Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 0,
        y: 225
      },
      answer: 90
    },
    rhs: {
      svgName: getRandomFromArray([
        'Right_angled_triangles/triangle_RA_long_green_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_long_white_one_interior_angle_rhs',
        'Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 432,
        y: 225
      },
      answer: 27
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Scalene_triangles/triangle_scalene_blue_one_interior_angle_lhs',
        'Scalene_triangles/triangle_scalene_green_one_interior_angle_lhs',
        'Scalene_triangles/triangle_scalene_purple_one_interior_angle_lhs',
        'Scalene_triangles/triangle_scalene_white_one_interior_angle_lhs',
        'Scalene_triangles/triangle_scalene_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 65,
        y: 330
      },
      answer: 100
    },
    rhs: {
      svgName: getRandomFromArray([
        'Scalene_triangles/triangle_scalene_blue_one_interior_angle_rhs',
        'Scalene_triangles/triangle_scalene_green_one_interior_angle_rhs',
        'Scalene_triangles/triangle_scalene_purple_one_interior_angle_rhs',
        'Scalene_triangles/triangle_scalene_white_one_interior_angle_rhs',
        'Scalene_triangles/triangle_scalene_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 444,
        y: 328
      },
      answer: 36
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_lhs',
        'Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_lhs',
        'Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_lhs',
        'Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_lhs',
        'Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_lhs',
        'Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 0,
        y: 270
      },
      answer: 90
    },
    rhs: {
      svgName: getRandomFromArray([
        'Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_rhs',
        'Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_rhs',
        'Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_rhs',
        'Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_rhs',
        'Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_rhs',
        'Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 398,
        y: 270
      },
      answer: 65
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Trapezium/trapezium_isosceles_blue_one_interior_angle_lhs',
        'Trapezium/trapezium_isosceles_green_one_interior_angle_lhs',
        'Trapezium/trapezium_isosceles_pink_one_interior_angle_lhs',
        'Trapezium/trapezium_isosceles_purple_one_interior_angle_lhs',
        'Trapezium/trapezium_isosceles_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 0,
        y: 264
      },
      answer: 73
    },
    rhs: {
      svgName: getRandomFromArray([
        'Trapezium/trapezium_isosceles_blue_one_interior_angle_rhs',
        'Trapezium/trapezium_isosceles_green_one_interior_angle_rhs',
        'Trapezium/trapezium_isosceles_pink_one_interior_angle_rhs',
        'Trapezium/trapezium_isosceles_purple_one_interior_angle_rhs',
        'Trapezium/trapezium_isosceles_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 355,
        y: 264
      },
      answer: 73
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Pentagons/Irregular_pentagon1_blue_one_interior_angle_lhs',
        'Pentagons/Irregular_pentagon1_green_one_interior_angle_lhs',
        'Pentagons/Irregular_pentagon1_pink_one_interior_angle_lhs',
        'Pentagons/Irregular_pentagon1_purple_one_interior_angle_lhs',
        'Pentagons/Irregular_pentagon1_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 90,
        y: 295
      },
      answer: 120
    },
    rhs: {
      svgName: getRandomFromArray([
        'Pentagons/Irregular_pentagon1_blue_one_interior_angle_rhs',
        'Pentagons/Irregular_pentagon1_green_one_interior_angle_rhs',
        'Pentagons/Irregular_pentagon1_pink_one_interior_angle_rhs',
        'Pentagons/Irregular_pentagon1_purple_one_interior_angle_rhs',
        'Pentagons/Irregular_pentagon1_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 230,
        y: 295
      },
      answer: 120
    }
  },
  {
    lhs: {
      svgName: getRandomFromArray([
        'Hexagons/Irregular_hexagon1_blue_one_interior_angle_lhs',
        'Hexagons/Irregular_hexagon1_green_one_interior_angle_lhs',
        'Hexagons/Irregular_hexagon1_pink_one_interior_angle_lhs',
        'Hexagons/Irregular_hexagon1_purple_one_interior_angle_lhs',
        'Hexagons/Irregular_hexagon1_yellow_one_interior_angle_lhs'
      ] as const),
      vertex: {
        x: 0,
        y: 350
      },
      answer: 90
    },
    rhs: {
      svgName: getRandomFromArray([
        'Hexagons/Irregular_hexagon1_blue_one_interior_angle_rhs',
        'Hexagons/Irregular_hexagon1_green_one_interior_angle_rhs',
        'Hexagons/Irregular_hexagon1_pink_one_interior_angle_rhs',
        'Hexagons/Irregular_hexagon1_purple_one_interior_angle_rhs',
        'Hexagons/Irregular_hexagon1_yellow_one_interior_angle_rhs'
      ] as const),
      vertex: {
        x: 305,
        y: 350
      },
      answer: 90
    }
  }
];

////
// Unequal quarter shaded shapes
////

/** Unequal quarter shaded shapes: List of shape names. */
export const unequalQuarterShadedShapes = [
  'Arrow',
  'Circle',
  'Heart',
  'Hexagon',
  'Octagon',
  'Rectangle',
  'Square',
  'Star',
  'Triangle'
] as const;
type UnequalQuarterShadedShape = (typeof unequalQuarterShadedShapes)[number];

const isUnequalQuarterShadedShape = (x: string): x is UnequalQuarterShadedShape => {
  const shapes: readonly string[] = unequalQuarterShadedShapes; // Up-cast, to stop TS complaining
  return shapes.includes(x);
};

function getUnequalQuarterShadedShapesSvgNames(shapeName: UnequalQuarterShadedShape) {
  switch (shapeName) {
    case 'Arrow':
      return [
        'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_yellow'
      ] as const;
    case 'Circle':
      return [
        'Unequal_shapes_4_parts/Circle_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Circle_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Circle_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Circle_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Circle_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Circle_unequal_1_4_2_yellow'
      ] as const;
    case 'Heart':
      return [
        'Unequal_shapes_4_parts/Heart_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_2_yellow',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_3_blue',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_3_green',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_3_yellow',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_4_blue',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_4_green',
        'Unequal_shapes_4_parts/Heart_unequal_1_4_4_yellow'
      ] as const;
    case 'Hexagon':
      return [
        'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_yellow'
      ] as const;
    case 'Octagon':
      return [
        'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_yellow'
      ] as const;
    case 'Rectangle':
      return [
        'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_yellow'
      ] as const;
    case 'Square':
      return [
        'Unequal_shapes_4_parts/Square_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Square_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Square_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Square_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Square_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Square_unequal_1_4_2_yellow'
      ] as const;
    case 'Star':
      return [
        'Unequal_shapes_4_parts/Star_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Star_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Star_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Star_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Star_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Star_unequal_1_4_2_yellow'
      ] as const;
    case 'Triangle':
      return [
        'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_blue',
        'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_green',
        'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_yellow',
        'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_blue',
        'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_green',
        'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_yellow'
      ] as const;
  }
}

/** Unequal quarter shaded shapes: Get random SvgName. */
export function getRandomUnequalQuarterShadedShapesSvgName(shapeName: UnequalQuarterShadedShape) {
  return getRandomFromArray(getUnequalQuarterShadedShapesSvgNames(shapeName)) satisfies SvgName;
}

/** Unequal quarter shaded shapes: Schema to validate an SvgName. */
export const unequalQuarterShadedShapesSvgNameSchema = z.string().refine(val => {
  const match = val.match(/^Unequal_shapes_4_parts\/(\w*)_unequal_1_4_/);
  if (!match) return false;
  const shape = match[1];
  if (!isUnequalQuarterShadedShape(shape)) return false;
  const svgNames: readonly string[] = getUnequalQuarterShadedShapesSvgNames(shape); // Up-cast
  return svgNames.includes(val);
}, 'Unrecognized quarter shaded shape SVG name') as z.Schema<
  ReturnType<typeof getRandomUnequalQuarterShadedShapesSvgName>
>;

/** Equal quarter shaded shapes: List of shape names. */
export const equalQuarterShadedShapes = [
  'Circle',
  'Hexagon',
  'Octagon',
  'Rectangle',
  'Square'
] as const;
type EqualQuarterShadedShape = (typeof equalQuarterShadedShapes)[number];
const isEqualQuarterShadedShape = (x: string): x is EqualQuarterShadedShape => {
  const shapes: readonly string[] = equalQuarterShadedShapes; // Up-cast, to stop TS complaining
  return shapes.includes(x);
};

function getEqualQuarterShadedShapesSvgNames(shapeName: EqualQuarterShadedShape) {
  switch (shapeName) {
    case 'Circle':
      return [
        'Equal_shapes_4_parts/Circle_equal_4-1_1_blue',
        'Equal_shapes_4_parts/Circle_equal_4-1_1_green',
        'Equal_shapes_4_parts/Circle_equal_4-1_1_yellow'
      ] as const;
    case 'Hexagon':
      return [
        'Equal_shapes_4_parts/Hexagon_equal_4-1_1_blue',
        'Equal_shapes_4_parts/Hexagon_equal_4-1_1_green',
        'Equal_shapes_4_parts/Hexagon_equal_4-1_1_yellow'
      ] as const;
    case 'Octagon':
      return [
        'Equal_shapes_4_parts/Octagon_equal_4-1_1_blue',
        'Equal_shapes_4_parts/Octagon_equal_4-1_1_green',
        'Equal_shapes_4_parts/Octagon_equal_4-1_1_yellow'
      ] as const;
    case 'Rectangle':
      return [
        'Equal_shapes_4_parts/Rectangle_equal_4-1_1_blue',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_1_green',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_1_yellow',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_2_blue',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_2_green',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_2_yellow',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_3_blue',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_3_green',
        'Equal_shapes_4_parts/Rectangle_equal_4-1_3_yellow'
      ] as const;
    case 'Square':
      return [
        'Equal_shapes_4_parts/Square_equal_4-1_1_blue',
        'Equal_shapes_4_parts/Square_equal_4-1_1_green',
        'Equal_shapes_4_parts/Square_equal_4-1_1_yellow'
      ] as const;
  }
}

/** Equal quarter shaded shapes: Get random SvgName. */
export function getRandomEqualQuarterShadedShapesSvgName(shapeName: UnequalQuarterShadedShape) {
  return getRandomFromArray(getUnequalQuarterShadedShapesSvgNames(shapeName)) satisfies SvgName;
}

/** Equal quarter shaded shapes: Schema to validate an SvgName. */
export const equalQuarterShadedShapesSvgNameSchema = z.string().refine(val => {
  const match = val.match(/^Equal_shapes_4_parts\/(\w*)_equal_1_4_/);
  if (!match) return false;
  const shape = match[1];
  if (!isEqualQuarterShadedShape(shape)) return false;
  const svgNames: readonly string[] = getEqualQuarterShadedShapesSvgNames(shape); // Up-cast
  return svgNames.includes(val);
}, 'Unrecognized quarter shaded shape SVG name') as z.Schema<
  ReturnType<typeof getRandomEqualQuarterShadedShapesSvgName>
>;

////
// Unequal half shaded shapes
////

/** Unequal half shaded shapes: List of shape names. */
export const unequalHalfShadedShapes = [
  'Arrow',
  'Circle',
  'Heart',
  'Hexagon',
  'Octagon',
  'Rectangle',
  'Square',
  'Star',
  'Triangle'
] as const;
type UnequalHalfShadedShape = (typeof unequalHalfShadedShapes)[number];

const isUnequalHalfShadedShape = (x: string): x is UnequalHalfShadedShape => {
  const shapes: readonly string[] = unequalHalfShadedShapes; // Up-cast, to stop TS complaining
  return shapes.includes(x);
};

function getUnequalHalfShadedShapesSvgNames(shapeName: UnequalHalfShadedShape) {
  switch (shapeName) {
    case 'Arrow':
      return [
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_yellow',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_blue',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_green',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_yellow',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_blue',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_green',
        'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_yellow'
      ] as const;
    case 'Circle':
      return [
        'Unequal_shapes_2_parts/Circle_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_2_yellow',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_3_blue',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_3_green',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_3_yellow',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_4_blue',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_4_green',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_4_yellow',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_5_blue',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_5_green',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_5_yellow',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_6_blue',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_6_green',
        'Unequal_shapes_2_parts/Circle_unequal_1_2_6_yellow'
      ] as const;
    case 'Heart':
      return [
        'Unequal_shapes_2_parts/Heart_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Heart_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Heart_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Heart_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Heart_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Heart_unequal_1_2_2_yellow'
      ] as const;
    case 'Hexagon':
      return [
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_yellow',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_blue',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_green',
        'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_yellow'
      ] as const;
    case 'Octagon':
      return [
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_yellow',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_blue',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_green',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_yellow',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_blue',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_green',
        'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_yellow'
      ] as const;
    case 'Rectangle':
      return [
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_yellow',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_blue',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_green',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_yellow',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_blue',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_green',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_yellow',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_blue',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_green',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_yellow',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_blue',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_green',
        'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_yellow'
      ] as const;
    case 'Square':
      return [
        'Unequal_shapes_2_parts/Square_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Square_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Square_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Square_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Square_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Square_unequal_1_2_2_yellow',
        'Unequal_shapes_2_parts/Square_unequal_1_2_3_blue',
        'Unequal_shapes_2_parts/Square_unequal_1_2_3_green',
        'Unequal_shapes_2_parts/Square_unequal_1_2_3_yellow',
        'Unequal_shapes_2_parts/Square_unequal_1_2_4_blue',
        'Unequal_shapes_2_parts/Square_unequal_1_2_4_green',
        'Unequal_shapes_2_parts/Square_unequal_1_2_4_yellow',
        'Unequal_shapes_2_parts/Square_unequal_1_2_5_blue',
        'Unequal_shapes_2_parts/Square_unequal_1_2_5_green',
        'Unequal_shapes_2_parts/Square_unequal_1_2_5_yellow',
        'Unequal_shapes_2_parts/Square_unequal_1_2_6_blue',
        'Unequal_shapes_2_parts/Square_unequal_1_2_6_green',
        'Unequal_shapes_2_parts/Square_unequal_1_2_6_yellow'
      ] as const;
    case 'Star':
      return [
        'Unequal_shapes_2_parts/Star_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Star_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Star_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Star_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Star_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Star_unequal_1_2_2_yellow'
      ] as const;
    default:
      return [
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_blue',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_green',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_yellow',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_blue',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_green',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_yellow',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_blue',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_green',
        'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_yellow'
      ] as const;
  }
}

/** Unequal Half shaded shapes: Get random SvgName. */
export function getRandomUnequalHalfShadedShapesSvgName(shapeName: UnequalHalfShadedShape) {
  return getRandomFromArray(getUnequalHalfShadedShapesSvgNames(shapeName)) satisfies SvgName;
}

/** Unequal Half shaded shapes: Schema to validate an SvgName. */
export const unequalHalfShadedShapesSvgNameSchema = z.string().refine(val => {
  const match = val.match(/^Unequal_shapes_2_parts\/(\w*)_unequal_1_2_/);
  if (!match) return false;
  const shape = match[1];
  if (!isUnequalHalfShadedShape(shape)) return false;
  const svgNames: readonly string[] = getUnequalHalfShadedShapesSvgNames(shape); // Up-cast
  return svgNames.includes(val);
}, 'Unrecognized unequal half shaded shape SVG name') as z.Schema<
  ReturnType<typeof getRandomUnequalHalfShadedShapesSvgName>
>;

/** Equal half shaded shapes: List of shape names. */
export const equalHalfShadedShapes = [
  'Circle',
  'Hexagon',
  'Octagon',
  'Rectangle',
  'Square',
  'Triangle'
] as const;
type EqualHalfShadedShape = (typeof equalHalfShadedShapes)[number];
const isEqualHalfShadedShape = (x: string): x is EqualHalfShadedShape => {
  const shapes: readonly string[] = equalHalfShadedShapes; // Up-cast, to stop TS complaining
  return shapes.includes(x);
};

function getEqualHalfShadedShapesSvgNames(shapeName: EqualHalfShadedShape) {
  switch (shapeName) {
    case 'Circle':
      return [
        'Equal_shapes_2_parts/Circle_equal_2-1_1_blue',
        'Equal_shapes_2_parts/Circle_equal_2-1_1_green',
        'Equal_shapes_2_parts/Circle_equal_2-1_1_yellow'
      ] as const;
    case 'Hexagon':
      return [
        'Equal_shapes_2_parts/Hexagon_equal_2-1_1_blue',
        'Equal_shapes_2_parts/Hexagon_equal_2-1_1_green',
        'Equal_shapes_2_parts/Hexagon_equal_2-1_1_yellow'
      ] as const;
    case 'Octagon':
      return [
        'Equal_shapes_2_parts/Octagon_equal_2-1_1_blue',
        'Equal_shapes_2_parts/Octagon_equal_2-1_1_green',
        'Equal_shapes_2_parts/Octagon_equal_2-1_1_yellow'
      ] as const;
    case 'Rectangle':
      return [
        'Equal_shapes_2_parts/Rectangle_equal_2-1_1_blue',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_1_green',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_1_yellow',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_2_blue',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_2_green',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_2_yellow',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_3_blue',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_3_green',
        'Equal_shapes_2_parts/Rectangle_equal_2-1_3_yellow'
      ] as const;
    case 'Square':
      return [
        'Equal_shapes_2_parts/Square_equal_2-1_1_blue',
        'Equal_shapes_2_parts/Square_equal_2-1_1_green',
        'Equal_shapes_2_parts/Square_equal_2-1_1_yellow'
      ] as const;
    case 'Triangle':
      return [
        'Equal_shapes_2_parts/Triangle_equal_2-1_1_blue',
        'Equal_shapes_2_parts/Triangle_equal_2-1_1_green',
        'Equal_shapes_2_parts/Triangle_equal_2-1_1_yellow'
      ] as const;
  }
}

/** Equal Half shaded shapes: Get random SvgName. */
export function getRandomEqualHalfShadedShapesSvgName(shapeName: EqualHalfShadedShape) {
  return getRandomFromArray(getEqualHalfShadedShapesSvgNames(shapeName)) satisfies SvgName;
}

/** Equal Half shaded shapes: Schema to validate an SvgName. */
export const equalHalfShadedShapesSvgNameSchema = z.string().refine(val => {
  const match = val.match(/^Equal_shapes_2_parts\/(\w*)_equal_2-1_/);
  if (!match) return false;
  const shape = match[1];
  if (!isEqualHalfShadedShape(shape)) return false;
  const svgNames: readonly string[] = getEqualHalfShadedShapesSvgNames(shape); // Up-cast
  return svgNames.includes(val);
}, 'Unrecognized equally half shaded shape SVG name') as z.Schema<
  ReturnType<typeof getRandomEqualHalfShadedShapesSvgName>
>;
