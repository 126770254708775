import type { FormattersInitializer } from 'typesafe-i18n';
import type { Locales, Formatters } from './i18n-types';
import { lowercase, number } from 'typesafe-i18n/formatters';
import { i18n } from './i18n-util';

const L = i18n();

export const initFormatters: FormattersInitializer<Locales, Formatters> = (locale: Locales) => {
  const formatters: Formatters = {
    Year: value => L[locale].years[value](),
    Term: value => L[locale].terms[value](),
    Block: value => L[locale].blocks[value](),
    SmallStep: value => L[locale].smallSteps[value](),
    Field: value => L[locale].fields[value](),
    Description: value => L[locale].descriptions[value](),
    Keyword: value => L[locale].keywords[value](),
    Base10RepresentationVariant: value => L[locale].enums.Base10RepresentationVariant[value](),
    lower: lowercase,
    /** Adds separators (e.g. commas) and translates to local number system. */
    numberFormat: number(locale)
  };

  return formatters;
};
