import { View, Platform } from 'react-native';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { type TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import Grid, {
  GridContext,
  type GridProps,
  type GridContextType
} from '../representations/Coordinates/Grid';
import { withStateHOC } from '../../../stateTree';
import deepEqual from 'react-fast-compare';
import { filledArray } from '../../../utils/collections';
import { MeasureView } from '../../atoms/MeasureView';
import { SetState, projectSetState } from '../../../utils/react';
import { Portal } from '../../portal';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { MINIMUM_QUESTION_HEIGHT, ScaleFactorContext } from '../../../theme/scaling';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import {
  AssetSvg,
  getSvgInfo,
  type SvgNameCustomizable,
  type SvgNameFixed
} from '../../../assets/svg';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import GridImage, { MIN_TOUCHABLE_AREA } from '../representations/Coordinates/GridImage';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { type SvgProps } from 'react-native-svg';

type Props = TitleStyleProps & {
  title: string;

  testCorrect: (ans: [number, number][]) => boolean;
  customMarkSchemeAnswer?: { answersToDisplay?: ([number, number] | null)[]; answerText?: string };
  /** Any additional props for the grid. */
  gridProps: GridProps;

  /** Whether items should snap to a grid point. Default: false */
  snapToGrid?: boolean;

  /**
   * The items to show as draggables.
   *
   * For asset SVGs, you can use a string "component".
   * Note that you can only provide the svgProps field if the SVG name corresponds to a customizable SVG asset.
   *
   * As a fallback, you can just pass an arbitrary JSX Element, however in that case you need to also provide its
   * width and height.
   */
  items: (
    | {
        component: SvgNameCustomizable;
        /** Defaults to the natural width */
        width?: number;
        /** Defaults to the natural height */
        height?: number;
        /** Defaults to the middle */
        anchor?: [number, number];
        svgProps?: SvgProps;
      }
    | {
        component: SvgNameFixed;
        /** Defaults to the natural width */
        width?: number;
        /** Defaults to the natural height */
        height?: number;
        /** Defaults to the middle */
        anchor?: [number, number];
      }
    | {
        component: JSX.Element;
        width: number;
        height: number;
        /** Defaults to the middle */
        anchor?: [number, number];
      }
  )[];
  gridChildren?: JSX.Element[];
  questionHeight?: number;
};

/**
 * Question Format 46: Plot coordinate (drag and drop)
 *
 * Title at the top, draggables on the right, and positions to drag them into on the left.
 */
export default function QF46PlotCoordinate({
  title,
  testCorrect,
  customMarkSchemeAnswer,
  items: itemsProp,
  snapToGrid = false,
  gridProps,
  gridChildren,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  ...props
}: Props) {
  // Implementation notes:
  // The dragging behaviour is powered by a portal over the top of the whole question, in which the draggables exist.
  // The draggables never move in the DOM, they are always in this portal - they just pretend to be in the action panel
  // or the grid by setting their top and left style props to the correct values. Of course, this requires measuring
  // the various views carefully.

  const items: {
    component: JSX.Element;
    width: number;
    height: number;
    anchor: [number, number];
  }[] = itemsProp.map(item => {
    if (typeof item.component === 'string') {
      // SVG name
      let { width, height } = getSvgInfo(item.component);
      width = item.width ?? width;
      height = item.height ?? height;
      return {
        component:
          'svgProps' in item ? (
            <AssetSvg
              name={item.component}
              width={width}
              height={height}
              svgProps={'svgProps' in item ? item.svgProps : undefined}
            />
          ) : (
            <AssetSvg name={item.component} width={width} height={height} />
          ),
        width,
        height,
        anchor: item.anchor ?? [width / 2, height / 2]
      };
    } else {
      // Any other react component
      item = item as {
        component: JSX.Element;
        width: number;
        height: number;
        anchor?: [number, number];
      };
      return { ...item, anchor: item.anchor ?? [item.width / 2, item.height / 2] };
    }
  });

  const displayMode = useContext(DisplayMode);
  const scaleFactor = useContext(ScaleFactorContext);

  const gridRef = useRef<View>(null);
  const draggableSourceRefs = useRef<(View | null)[]>(filledArray(null, items.length));
  const portalHostRef = useRef<View>(null);

  ////
  // Get measurements
  ////

  const [draggableSourceTopLefts, setDraggableSourceTopLefts] = useState<
    ([number, number] | null)[]
  >(filledArray(null, items.length));
  const [gridTopLeft, setGridTopLeft] = useState<[number, number] | null>(null);
  const [portalHostTopLeft, setPortalHostTopLeft] = useState<[number, number] | null>(null);

  const measure = useCallback(() => {
    if (
      !(draggableSourceTopLefts.every(it => it !== null) && gridTopLeft && portalHostTopLeft) &&
      draggableSourceRefs.current.every(it => it !== null) &&
      gridRef.current &&
      portalHostRef.current
    ) {
      // We have all the refs and some measurements are missing.
      draggableSourceRefs.current.forEach((it, index) =>
        it!.measure((_x, _y, _width, _height, pageX = 0, pageY = 0) => {
          pageX = Platform.OS === 'web' ? pageX : pageX / scaleFactor;
          pageY = Platform.OS === 'web' ? pageY : pageY / scaleFactor;
          setDraggableSourceTopLefts(old => {
            if (deepEqual(old[index], [pageX, pageY])) {
              return old;
            }
            const newState = [...old];
            newState[index] = [pageX, pageY];
            return newState;
          });
        })
      );
      gridRef.current.measure((_x, _y, _width, _height, pageX = 0, pageY = 0) => {
        pageX = Platform.OS === 'web' ? pageX : pageX / scaleFactor;
        pageY = Platform.OS === 'web' ? pageY : pageY / scaleFactor;
        setGridTopLeft(old => (deepEqual(old, [pageX, pageY]) ? old : [pageX, pageY]));
      });
      portalHostRef.current.measure((_x, _y, _width, _height, pageX = 0, pageY = 0) => {
        pageX = Platform.OS === 'web' ? pageX : pageX / scaleFactor;
        pageY = Platform.OS === 'web' ? pageY : pageY / scaleFactor;
        setPortalHostTopLeft(old => (deepEqual(old, [pageX, pageY]) ? old : [pageX, pageY]));
      });
    }
  }, [draggableSourceTopLefts, gridTopLeft, portalHostTopLeft, scaleFactor]);

  // Measure again each render
  useEffect(measure);

  ////
  // JSX
  ////

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <MeasureView>
            {({ width, height }) => (
              <>
                <Grid width={width} height={height} {...gridProps} ref={gridRef}>
                  {displayMode === 'markscheme' &&
                    customMarkSchemeAnswer?.answersToDisplay &&
                    customMarkSchemeAnswer.answersToDisplay.map(
                      (coord, index) =>
                        coord && <GridImage key={index} item={items[index]} mathCoord={coord} />
                    )}
                  {gridChildren}
                </Grid>
                {displayMode === 'markscheme' &&
                  customMarkSchemeAnswer?.answerText &&
                  renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
              </>
            )}
          </MeasureView>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <Portal.Host ref={portalHostRef}>
      <BaseLayout
        title={title}
        actionPanelContents={
          <DragAndDropSection style={{ alignItems: 'center' }}>
            {items.map(({ component }, index) => (
              // For each item, render an invisible version of the item in the action panel.
              // This is used to get a screen coordinate to position the item in sometimes.
              <View
                ref={ref => (draggableSourceRefs.current[index] = ref)}
                key={index}
                style={{ opacity: 0 }}
              >
                {component}
              </View>
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <MeasureView>
            {({ width, height }) => (
              <Grid width={width} height={height} {...gridProps} ref={gridRef} onLayout={measure}>
                {draggableSourceTopLefts.every((it): it is [number, number] => it !== null) &&
                  gridTopLeft !== null &&
                  portalHostTopLeft !== null && (
                    <DraggablesOverlayWithState
                      draggableSourceTopLefts={draggableSourceTopLefts}
                      gridTopLeft={gridTopLeft}
                      portalHostTopLeft={portalHostTopLeft}
                      items={items}
                      snapToGrid={snapToGrid}
                      id="QF46"
                      testCorrect={ans => testCorrect(ans as [number, number][])}
                      testComplete={ans => ans.every(it => it !== null)}
                      defaultState={filledArray(null, items.length)}
                    />
                  )}
                {gridChildren && gridChildren.map(i => i)}
              </Grid>
            )}
          </MeasureView>
        }
        {...props}
      />
    </Portal.Host>
  );
}

/**
 * Portal containing all the draggables. This is a controlled component.
 */
function DraggablesOverlay({
  draggableSourceTopLefts,
  gridTopLeft,
  portalHostTopLeft,
  items,
  snapToGrid,
  state,
  setState
}: {
  draggableSourceTopLefts: [number, number][];
  gridTopLeft: [number, number];
  portalHostTopLeft: [number, number];
  items: {
    component: JSX.Element;
    width: number;
    height: number;
    anchor: [number, number];
  }[];
  snapToGrid: boolean;
  // null means the draggable is still in the action panel right
  // [number, number] gives the math-based coordinates of the point when it's in the grid
  state: ([number, number] | null)[];
  setState: SetState<([number, number] | null)[]>;
}) {
  const gridContext = useContext(GridContext);

  return (
    <Portal>
      {items.map((item, index) => (
        <Draggable
          key={index}
          state={state[index]}
          setState={projectSetState(setState, index)}
          draggableSourceTopLeft={[
            draggableSourceTopLefts[index]![0] - portalHostTopLeft[0],
            draggableSourceTopLefts[index]![1] - portalHostTopLeft[1]
          ]}
          gridTopLeft={[
            gridTopLeft[0] - portalHostTopLeft[0],
            gridTopLeft[1] - portalHostTopLeft[1]
          ]}
          item={item}
          snapToGrid={snapToGrid}
          {...gridContext}
        />
      ))}
    </Portal>
  );
}

const DraggablesOverlayWithState = withStateHOC(DraggablesOverlay);

/**
 * A single draggable. Consists of a complicated gesture and a gesture detector.
 * This is a controlled component.
 */
function Draggable({
  state,
  setState,
  draggableSourceTopLeft,
  gridTopLeft,
  item,
  snapToGrid,
  mathToSvgX,
  mathToSvgY,
  svgToMathX,
  svgToMathY,
  xMin,
  xMax,
  xStepSize,
  yMin,
  yMax,
  yStepSize
}: GridContextType & {
  state: [number, number] | null;
  setState: SetState<[number, number] | null>;
  draggableSourceTopLeft: [number, number];
  gridTopLeft: [number, number];
  item: {
    component: JSX.Element;
    width: number;
    height: number;
    anchor: [number, number];
  };
  snapToGrid: boolean;
}) {
  const scaleFactor = useContext(ScaleFactorContext);
  const [draggableSourceLeft, draggableSourceTop] = draggableSourceTopLeft;
  const [gridLeft, gridTop] = gridTopLeft;
  const [anchorLeft, anchorTop] = item.anchor;

  const mathToPixel = useCallback(
    (coord: [number, number] | null): [number, number] => {
      'worklet';
      return coord === null
        ? [draggableSourceLeft, draggableSourceTop]
        : [
            mathToSvgX(coord[0]) + gridLeft - anchorLeft,
            mathToSvgY(coord[1]) + gridTop - anchorTop
          ];
    },
    [
      anchorLeft,
      anchorTop,
      draggableSourceLeft,
      draggableSourceTop,
      gridLeft,
      gridTop,
      mathToSvgX,
      mathToSvgY
    ]
  );
  const pixelToMath = useCallback(
    (coord: [number, number]): [number, number] | null => {
      'worklet';
      return coord[0] === draggableSourceLeft && coord[1] === draggableSourceTop
        ? null
        : [
            svgToMathX(coord[0] - gridLeft + anchorLeft),
            svgToMathY(coord[1] - gridTop + anchorTop)
          ];
    },
    [
      anchorLeft,
      anchorTop,
      draggableSourceLeft,
      draggableSourceTop,
      gridLeft,
      gridTop,
      svgToMathX,
      svgToMathY
    ]
  );

  const animatedPixelCoord = useSharedValue(mathToPixel(state));

  const beginPagePosition = useSharedValue<[number, number] | null>(null);
  const beginPixelCoord = useSharedValue<[number, number] | null>(null);

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginPagePosition.value = [event.absoluteX, event.absoluteY];
          beginPixelCoord.value = animatedPixelCoord.value;
        })
        .onUpdate(event => {
          const translation = [
            (event.absoluteX - beginPagePosition.value![0]) / scaleFactor,
            (event.absoluteY - beginPagePosition.value![1]) / scaleFactor
          ];

          animatedPixelCoord.value = [
            beginPixelCoord.value![0] + translation[0],
            beginPixelCoord.value![1] + translation[1]
          ];
        })
        .onFinalize(() => {
          const mathCoord = pixelToMath(animatedPixelCoord.value);
          if (mathCoord === null) {
            // Dragged somehow exactly back to the source position!
            animatedPixelCoord.value = withTiming([draggableSourceLeft, draggableSourceTop]);
            runOnJS(setState)(null);
            return;
          }

          // First, calculate snapping behaviour, even if not configured to do so
          const snappedMathCoord: [number, number] = [
            Math.round((mathCoord[0] - xMin) / xStepSize) * xStepSize + xMin,
            Math.round((mathCoord[1] - yMin) / yStepSize) * yStepSize + yMin
          ];

          // Check if we were dropped outside the grid (after taking into account snapping)
          if (
            snappedMathCoord[0] < xMin ||
            snappedMathCoord[0] > xMax ||
            snappedMathCoord[1] < yMin ||
            snappedMathCoord[1] > yMax
          ) {
            // Dropped outside grid - animate back to draggable source position
            animatedPixelCoord.value = withTiming([draggableSourceLeft, draggableSourceTop]);
            runOnJS(setState)(null);
          } else {
            // Dropped inside grid
            if (snapToGrid) {
              // Animate quickly to snapped position
              animatedPixelCoord.value = withTiming(mathToPixel(snappedMathCoord), {
                duration: 100
              });
              runOnJS(setState)(snappedMathCoord);
            } else {
              // Already at the final position
              runOnJS(setState)(mathCoord);
            }
          }
        }),
    [
      animatedPixelCoord,
      beginPagePosition,
      beginPixelCoord,
      draggableSourceLeft,
      draggableSourceTop,
      mathToPixel,
      pixelToMath,
      scaleFactor,
      setState,
      snapToGrid,
      xMax,
      xMin,
      xStepSize,
      yMax,
      yMin,
      yStepSize
    ]
  );

  const animatedStyle = useAnimatedStyle(
    () => ({
      position: 'absolute',
      left: animatedPixelCoord.value[0],
      top: animatedPixelCoord.value[1]
    }),
    [animatedPixelCoord]
  );

  const horizontalHitSlop = Math.max(0, MIN_TOUCHABLE_AREA / scaleFactor - item.width);
  const verticalHitSlop = Math.max(0, MIN_TOUCHABLE_AREA / scaleFactor - item.height);
  const hitSlop = {
    left: horizontalHitSlop / 2,
    right: horizontalHitSlop / 2,
    top: verticalHitSlop / 2,
    bottom: verticalHitSlop / 2
  };

  return (
    <GestureDetector
      gesture={
        Platform.OS === 'android'
          ? // On android, hit slop can be passed in to the gesture. This is documented to only work on Android.
            panGesture.hitSlop(hitSlop)
          : panGesture
      }
    >
      <Animated.View
        style={animatedStyle}
        // On iOS, hit slop can be passed in as a prop. This doesn't seem to work for Android or web.
        {...(Platform.OS === 'ios' && {
          hitSlop: hitSlop
        })}
      >
        {item.component}
      </Animated.View>
    </GestureDetector>
  );
}
