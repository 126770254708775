import { MULT } from '../../constants';

export default {
  afterSchool: 'After school',
  beforeSchool: 'Before school',
  canEachChildHave1AppleSelectYourAnswer: 'Can each child have 1 apple?<br/>Select your answer.',
  canEachChildHave1CakeSelectYourAnswer: 'Can each child have 1 cake?<br/>Select your answer.',
  canEachChildHave1CookieSelectYourAnswer: 'Can each child have 1 cookie?<br/>Select your answer.',
  canEachChildHave1PieceOfFruitSelectYourAnswer:
    'Can each child have 1 piece of fruit?<br/>Select your answer.',
  canEachHorseHave1CarrotSelectYourAnswer: 'Can each horse have 1 carrot?<br/>Select your answer.',
  canEachHorseHave1BaleOfHaySelectYourAnswer:
    'Can each horse have 1 bale of hay?<br/>Select your answer.',
  canEachSheepHave1BaleOfHaySelectYourAnswer:
    'Can each sheep have 1 bale of hay?<br/>Select your answer.',
  canEachDogHave1BallSelectYourAnswer: 'Can each dog have 1 ball?<br/>Select your answer.',
  canEachDogHave1BoneSelectYourAnswer: 'Can each dog have 1 bone?<br/>Select your answer.',
  charAHasXFewerYThanCharB: '{0} has {1:number|numberFormat} fewer {2:string|lower} than {3}.',
  charAHasXMoreYThanCharB: '{0} has {1:number|numberFormat} more {2:string|lower} than {3}.',
  charAHasXSweetsCharBHasYSweetsMoreThanHowManySweetsDoTheyHaveTogether:
    '{nameA} has {sweetsA} sweet{{s}}.<br/>{nameB} has {diffSweets} more than {nameA}.<br/>How many sweets do they have altogether?',
  charAHasXY: '{0} has {1} {2:string|lower}.',
  charAGivesCharBXMoreY: '{0} gives {1} {2} more {3:string|lower}.',
  charHasTheseItems: '{0} has these {1:string|lower}.',
  characterIsCountingBackwardsFromXToYSelectTheNumbersCharacterWillSay:
    '{character} is counting backwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Select the numbers {character} will say.',
  characterIsCountingForwardsFromXToYSelectTheNumbersCharacterWillSay:
    '{character} is counting forwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Select the numbers {character} will say.',
  characterIsCountingForwardsInXFromYSelectNumbers:
    '{0} is counting forwards in {1}s from {2}<br/>Select the numbers that {0} will say.',
  characterIsCountingBackwardsInXFromYSelectNumbers:
    '{0} is counting backwards in {1}s from {2}<br/>Select the numbers that {0} will say.',
  completeTheCalculations: 'Complete the calculations.',
  completeTheCalculationsToMatchArray: 'Complete the calculations to match the array.',
  completeTheNumberLine: 'Complete the number line.',
  completeNumberTrack: 'Complete the number track.',
  completeTheAddition: 'Complete the addition.',
  completeTheAdditions: 'Complete the additions.',
  completeTheBarModel: 'Complete the bar model.',
  completeTheDivision: 'Complete the division.',
  completeTheDivisionToMatchArray: 'Complete the division to match the array.',
  completeTheFactFamily: 'Complete the fact family.',
  completeTheMultiplication: 'Complete the multiplication.',
  completeTheNumberBonds: 'Complete the number bonds.',
  completeNumberLine: 'Complete the number line.',
  completeTheNumberBondsToXFindTwoDifferentWays:
    'Complete the number bonds to {0:number|numberFormat}<br/>Find two different ways.',
  completeTheNumberSentence: 'Complete the number sentence.',
  completeTheNumberSentences: 'Complete the number sentences.',
  completeTheNumberSentencesToMatchTheBarModel:
    'Complete the number sentences to match the bar model.',
  completeTheNumberSentencesToMatchThePicture:
    'Complete the number sentences to match the picture.',
  completeThePartWholeModel: 'Complete the part-whole model.',
  completeTheSentence: 'Complete the sentence.',
  completeTheSentences: 'Complete the sentences.',
  completeTheSentenceUseTheArrayToHelpYou: 'Complete the sentence.<br/>Use the array to help you.',
  completeTheStatement: 'Complete the statement.',
  completeTheSubtraction: 'Complete the subtraction.',
  completeTheSubtractions: 'Complete the subtractions.',
  createTwoDifferentMultiplicationsToMatchArray:
    'Create two different multiplications to match the array.',
  drag1AppleForEachChildIntoTheBox: 'Drag 1 apple for each child into the box.',
  drag1MoreCounterOnToTheTenFrame: 'Drag 1 more counter on to the ten frame.',
  dragACardToCompare: 'Drag a card to compare.',
  dragACardToCompareTheAmounts: 'Drag a card to compare the amounts.',
  dragACardToCompareTheCapacityOfTheContainers:
    'Drag a card to compare the capacity of the containers.',
  dragACardToCompareTheNumbers: 'Drag a card to compare the numbers.',
  dragACardToCompleteTheAddition: 'Drag a cards to complete the addition.',
  dragACardToCompleteTheMultiplication: 'Drag a card to complete the multiplication.',
  dragACardToCompleteTheNumberSentence: 'Drag a card to complete the number sentence.',
  dragACardToCompleteTheSentence: 'Drag a card to complete the sentence.',
  dragACardToMatchTheShapesToTheTurn: 'Drag a card to match the shapes to the turn.',
  dragArrowEstimateNumberIs: 'Drag the arrow to estimate where {0} is.',
  dragArrowToEstimateWhereXIsOnNumberLine:
    'Drag the arrow to estimate where {0:number|numberFormat} is on the number line.',
  dragCardCompleteSentence: 'Drag a card to complete the sentence.',
  dragCardsOrderGlassesStartWithTheGreatestAmountVolume:
    'Drag the cards to order the glasses.<br/>Start with the greatest volume.',
  dragCardsOrderGlassesStartWithTheSmallestAmountVolume:
    'Drag the cards to order the glasses.<br/>Start with the smallest volume.',
  dragCardsOrderObjectsStartWithGreatestCapacity:
    'Drag the cards to order the objects.<br/>Start with the greatest capacity.',
  dragCardsOrderObjectsStartWithSmallestCapacity:
    'Drag the cards to order the objects.<br/>Start with the smallest capacity.',
  dragCardsToCompleteTheCalculations: 'Drag the cards to complete the calculations.',
  dragCardsToCompleteTheMultiplication: 'Drag cards to complete the multiplication.',
  dragCardsToFillInTheMissingNumbers: 'Drag cards to fill in the missing number{{s}}.',
  dragCountersToMakeXEqualGroupsOfY:
    'Drag counters to make {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  dragCountersToShow1LessThanNum: 'Drag counters to show 1 less than {0:number|numberFormat}',
  dragNumCountersOnToTheTenFrame: 'Drag {0: string|lower} counter{{1:s}} on to the ten frame.',
  dragNumCountersToShareThemEquallyIntoNumBoxes:
    'Drag {0:number|numberFormat} counters to share them equally into the {1:number|numberFormat} boxes.',
  dragCardsToSortTheActivities: 'Drag the cards to sort the activities.',
  dragCountersToMakeX: 'Drag counters to make {0}',
  dragTheCardsToMakeA2DigitEvenNumber: 'Drag the cards to make a 2-digit <b>even</b> number.',
  dragTheCardsToMakeA2DigitOddNumber: 'Drag the cards to make a 2-digit <b>odd</b> number.',
  dragTheArrowTo1LessThanNum: 'Drag the arrow to 1 less than {numberA:number|numberFormat}',
  dragTheArrowTo1MoreThanNum: 'Drag the arrow to 1 more than {numberA:number|numberFormat}',
  dragTheArrowToNum: 'Drag the arrow to {numberA:number|numberFormat}',
  dragBase10ToMakeMore:
    'Drag the base 10 to make {0:number|numberFormat} more than {1:number|numberFormat}',
  dragBase10ToMakeLess:
    'Drag the base 10 to make the {0:number|numberFormat} less than {1:number|numberFormat}',
  dragCountersToAddXMore: 'Drag counters to add {0:number|numberFormat} more.',
  dragCountersToShowX: 'Drag counters to show {0}',
  dragTheArrowToNumberAsWord: 'Drag the arrow to {0: string|lower}.',
  dragTheArrowToOneLessThanNumberAsWord: 'Drag the arrow to one less than {0: string|lower}.',
  dragTheArrowTo1LessThanNumberAsNumber: 'Drag the arrow to 1 less than {0: number}',
  dragTheArrowToOneMoreThanNumberAsWord: 'Drag the arrow to one more than {0: string|lower}.',
  dragTheArrowTo1MoreThanNumberAsNumber: 'Drag the arrow to 1 more than {0: number}',
  dragTheArrowToEstimateWhereNumBelongsOnTheNumberLine:
    'Drag the arrow to estimate where {0:number|numberFormat} belongs on the number line.',
  dragTheArrowToShowWhereNumBelongsOnTheNumberLine:
    'Drag the arrow to show where {0:number|numberFormat} belongs on the number line.',
  dragTheArrowLessThanNumberAsWord:
    'Drag the arrow to a number that is less than {0: string|lower}.',
  dragTheArrowLessThanNumberAsNumber: 'Drag the arrow to a number that is less than {0: number}',
  dragTheArrowGreaterThanNumberAsWord:
    'Drag the arrow to a number that is greater than {0: string|lower}.',
  dragTheArrowGreaterThanNumberAsNumber:
    'Drag the arrow to a number that is greater than {0: number}',
  dragTheBase10ToMakeX: 'Drag the base 10 to make {0}',
  dragTheCardsToCompleteTheMultiplication: 'Drag the cards to complete the multiplication.',
  dragTheCardsToCompleteTheNumberSentence: 'Drag the cards to complete the number sentence.',
  dragTheCardsToCompleteTheSentences: 'Drag the cards to complete the sentences.',
  dragTheCardsToMatchTheAdditionsToMultiplications:
    'Drag the cards to match the additions to the multiplications.',
  dragTheCardsToMatchTheNumeralsToTheWords: 'Drag the cards to match the numerals to the words.',
  dragTheCardsToMatchTheBaseTenToTheWords: 'Drag the cards to match the base 10 to the numbers.',
  dragTheCardsToMatchTheCorrectValueToTheCoins:
    'Drag the cards to match the correct value to the coins.',
  dragTheCardsToMatchTheCorrectValueToTheNotes:
    'Drag the cards to match the correct value to the notes.',
  dragTheCardsToMatchTheDoublesToAdditions: 'Drag the cards to match the doubles to the additions.',
  dragTheCardsToMatchTheNameToTheShape: 'Drag the cards to match the name to the shape.',
  dragTheCardsToMatchThePicturesToStatements: 'Drag the cards to match the pictures to statements.',
  dragTheCardsToShowIfGroupsAreEqualOrUnequal:
    'Drag the cards to show if the groups are equal or unequal.',
  dragTheCardsToMatchTheWordsToThePictures: 'Drag the cards to match the words to the pictures.',
  dragTheCardsToPutThemInOrder: 'Drag the cards to put them in order.',
  dragTheCardsToSortTheFruitIntoGroups: 'Drag the cards to sort the fruit into groups.',
  dragTheCardsToSortTheNumbers: 'Drag the cards to sort the numbers.',
  dragTheCardsToSortTheObjectsIntoGroups: 'Drag the cards to sort the objects into groups.',
  dragTheCardsToOrderTheNumbers: 'Drag the cards to order the numbers.',
  dragTheCardsToSortTheObjectsIntoTwoGroups: 'Drag the cards to sort the objects into two groups.',
  dragTheHandsOfClockToShowX: 'Drag the hands of the clock to show {0}',
  dragTheHourHandOfClockToShowX: 'Drag the hour hand of the clock to show {0}.',
  dragToMatchTheNumberBondsToTheTenFrames: 'Drag to match the number bonds to the ten frames.',
  dragToMatchTheSubtractionsToTheTenFrames: 'Drag to match the subtractions to the ten frames.',
  dragTwoCardsToMakeAdditionCorrect: 'Drag two cards to make the addition correct.',
  estimateTheNumberArrowPointingToSelectAnswer:
    'Estimate the number the arrow is pointing to.<br/>Select your answer.',
  groupN: 'Group {numberA:number|numberFormat}',
  howManyMinutesAreThereInX: 'How many minutes are there in {0}?',
  howManyCirclesAreThere: 'How many circles are there?',
  howManyColumns: 'How many <b>columns</b> are there?',
  howManyCounters: 'How many counters?',
  howManyMoreToMakeX: 'How many more to make {0:number|numberFormat}?',
  howManyObjectsAreThere: 'How many {0:string|lower} are there?',
  howManyObjectsAreInTheCup: 'How many {0:string|lower} are there in the cup?',
  howManyXAltogether: 'How many {0:string|lower} altogether?',
  howManyXAreThereSelectYourAnswer: 'How many {0} are there?<br/>Select your answer.',
  howManyXCoinsAreThere: 'How many {0:string|lower} coins are there?',
  howManyXDoesCharAHave: 'How many {0:string|lower} does {1} have?',
  howManyXDoesCharAHaveNow: 'How many {0:string|lower} does {1} have now?',
  howManyXNotesAreThere: 'How many {0:string|lower} notes are there?',
  howManyXAreThere: 'How many {0:string|lower} are there?',
  howMuchMoneyIsThere: 'How much money is there?',
  howManyRows: 'How many <b>rows</b> are there?',
  nameAHasNCountersNameBHasFewerCountersThanNameADragTheCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has fewer counters than {nameA}.<br/>Drag the counters to show how many counters {nameB} <b>could</b> have.',
  nameAHasNCountersNameBHasMoreCountersThanNameADragTheCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has more counters than {nameA}.<br/>Drag the counters to show how many counters {nameB} <b>could</b> have.',
  nameHasSomeTenPCoinsPronounXPenceInTotalHowManyCoinsDoesNameHave:
    '{name} has some 10p coins.<br/>{pronoun} has {pence:number|numberFormat}p in total.<br/>How many coins does {name} have?',
  nameHasSomeTenPCoinsPronounXPoundInTotalHowManyCoinsDoesNameHave:
    '{name} has some 10p coins.<br/>{pronoun} has £{pound:number|numberFormat} in total.<br/>How many coins does {name} have?',
  nameHasXObjectsPronounSharesBetweenYBagsHowManyInEachBag:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally into {containerCount:number|numberFormat} bags.<br/>How many {object: string|lower} are there in each bag?',
  nameHasXObjectsPronounSharesBetweenYBoxesHowManyInEachBoxes:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally into {containerCount:number|numberFormat} boxes.<br/>How many {object: string|lower} are there in each box?',
  nameHasXObjectsPronounSharesBetweenYPotsHowManyInEachPot:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally into {containerCount:number|numberFormat} pots.<br/>How many {object: string|lower} are there in each pot?',
  nameHasXObjectsPronounSharesBetweenYPlatesHowManyOnEachPlate:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally onto {containerCount:number|numberFormat} plates.<br/>How many {object: string|lower} are there on each plate?',
  objectComeInBagsOfYNameHasObjectsHowMany:
    '{object} come in bags of {perContainer:number|numberFormat}<br/>{name} has {total:number|numberFormat} {object: string|lower}.<br/>How many bags does {name} have?',
  objectComeInPotsOfYNameHasObjectsHowMany:
    '{object} come in pots of {perContainer:number|numberFormat}<br/>{name} has {total:number|numberFormat} {object: string|lower}.<br/>How many pots does {name} have?',
  objectComeOnPlatesOfYNameHasObjectsHowMany:
    '{object} come on plates of {perContainer:number|numberFormat}<br/>{name} has {total:number|numberFormat} {object: string|lower}.<br/>How many plates does {name} have?',
  selectAllTheEvenNumbers: 'Select all the even numbers.',
  selectAllTheOddNumbers: 'Select all the odd numbers.',
  selectAllTheNumberBondsToNum: 'Select <b>all</b> the number bonds to {0:number|numberFormat}',
  selectTheAdditionsThatAreEqualToNum:
    'Select the additions that are equal to {0:number|numberFormat}',
  selectTheAdditionsThatMatchTheBarModel: 'Select the additions that match the bar model.',
  selectTheAdditionsThatMatchTheCounters: 'Select the additions that match the counters.',
  selectTheAdditionThatMatchTheBarModel: 'Select the addition that match the bar model.',
  selectTheAdditionThatMatchTheCounters: 'Select the addition that match the counters.',
  selectTheBase10ThatShowsX: 'Select the base 10 that shows {0}',
  selectTheContainerWithTheGreatestCapacity: 'Select the container with the greatest capacity.',
  selectTheContainerWithTheSmallestCapacity: 'Select the container with the smallest capacity.',
  selectTheCorrectNumberSentence: 'Select the correct number sentence.',
  selectTheCorrectNumberSentences: 'Select the correct number sentences.',
  selectTheDiagramsThatShowAHalf: 'Select the diagram{{s}} that show a half.',
  selectTheDiagramsThatShowAQuarter: 'Select the diagram{{s}} that show a quarter.',
  selectTheGroupWithFewerObject: 'Select the group with fewer {0:string|lower}.',
  selectTheGroupWithMoreObject: 'Select the group with more {0:string|lower}.',
  selectTheGroupThatHasAXShaded: 'Select the group that has a {0:string|lower} shaded.',
  selectTheGroupThatMatchesTheNumberOfChildren:
    'Select the group that matches the number of children.',
  selectTheGroupThatShowsX: 'Select the group that shows {0:string|lower}.',
  selectTheGroupsThatShowsX: 'Select the groups that shows {0:string|lower}.',
  selectTheGroupsThatHaveAXShaded: 'Select the groups that have a {0:string|lower} shaded.',
  selectTheMissingNumberBondToX: 'Select the <b>missing</b> number bond to {0:number|numberFormat}',
  selectTheMonthsThatHaveExactlyXDays:
    'Select the months that have exactly {0:number|numberFormat} days.',
  selectTheNumberBondsToNum: 'Select the number bonds to {0:number|numberFormat}',
  selectThePictureThatShowsEqualGroups: 'Select the picture that shows <b>equal</b> groups.',
  selectThePictureThatShowsUnequalGroups: 'Select the picture that shows <b>unequal</b> groups.',
  selectTheNumberSentenceThatMatchesPicture: 'Select the number sentence that matches the picture.',
  selectThePictureThatShowsXEqualGroupsOfY:
    'Select the picture that shows {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  selectTheTenFrameThatMatchesThePicture: 'Select the ten frame that matches the picture.',
  selectTheTimeShown: 'Select the time shown.',
  selectTheXWeightObject: 'Select the {0:string|lower} object.',
  selectTheXThatAreCutInHalf: 'Select the {0:string|lower} that are cut in half.',
  selectTheXThatIsCutInHalf: 'Select the {0:string|lower} that is cut in half.',
  selectTheXThatAreCutIntoYEqualParts:
    'Select the {0:string|lower} that are cut into {1:number|numberFormat} equal parts.',
  selectTheXThatIsCutIntoYEqualParts:
    'Select the {0:string|lower} that is cut into {1:number|numberFormat} equal parts.',
  selectWhatTheShapeWillLookLikeAfterAFullTurn:
    'Select what the shape will look like after a <b>full</b> turn.',
  selectWhatTheShapeWillLookLikeAfterAHalfTurn:
    'Select what the shape will look like after a <b>half</b> turn.',
  selectWhatTheShapeWillLookLikeAfterAQuarterTurn:
    'Select what the shape will look like after a <b>quarter</b> turn.',
  selectWhatTheShapeWillLookLikeAfterAThreeQuarterTurn:
    'Select what the shape will look like after a <b>three-quarter</b> turn.',
  selectYourAnswer: 'Select your answer.',
  tapFourDotsToSplitTheShapeInQuarters: 'Tap 4 dots to split the shape into quarters.',
  tapTheCirclesToMakeArrayOfXRowsAndYColumns:
    'Tap the circles to make an array with {0:number|numberFormat} rows and {1:number|numberFormat} columns.',
  tapTheCirclesToMakeArrayThatShowsXMultY: `Tap the circles to make an array that shows {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  tapTwoDotsToSplitTheShapeInHalf: 'Tap 2 dots to split the shape in half.',
  tapXLessThanY: 'Tap {0:number|numberFormat} less than {1:number|numberFormat}',
  tapXMoreThanY: 'Tap {0:number|numberFormat} more than {1:number|numberFormat}',
  theCapacityOfOneJugIsXCupsOfSandSelectTheCapacityOfTwoJugs:
    'The capacity of 1 jug is {0:number|numberFormat} cups of sand. Select the capacity of 2 jugs.',
  theDaysAreInOrderSelectTheMissingDayOfTheWeek:
    'The days are in order.<br/>Select the missing day of the week.',
  theMassOfTheXObjectIsYAmountThanZCubesSelectThePictureThatShowsThis:
    'The mass of the {0:string|lower} is {1:string|lower} {2:number|numberFormat} cube{{s}}.<br/>Select the picture that shows this.',
  theNumbersAreAllInXTimesTableSelectNumbersAlsoInYTimesTable:
    'The numbers are all in the {0:number|numberFormat} times-table.<br/>Select the numbers that are also in the {1:number|numberFormat} times-table.',
  thereAreXObjectsAltogether: 'There are {0:number|numberFormat} {1:string|lower} altogether.',
  theToyCarIsHeavierThenXCubesButLighterThanYCubesSelectAllTheMassesThatTheCarCouldBe:
    'The toy car is heavier than {0:number|numberFormat} cubes, but lighter than {1:number|numberFormat} cubes.<br/>Select all the masses that the car could be.',
  theTimeIsNumOClockDragTheHandsOfTheClockToShowTimeInNumHours:
    "The time is {0} o'clock.<br/>Drag the hands of the clock to show what time it will be in {1:number|numberFormat} hour{{s}}.",
  theTimeIsNumOClockHowManyHoursWillItBeUntilHourIsPointingAtNum:
    "The time is {0} o'clock.<br/>How many hours will it be until the hour hand is pointing at the {1}?",
  theTimeIsNumOClockHowManyHoursWillItBeUntilHourIsPointingAtNumAgain:
    "The time is {0} o'clock.<br/>How many hours will it be until the hour hand is pointing at the {0} again?",
  theWaterInTheGlassesFillsTheBottle: 'The water in the glasses fills the bottle.',
  theXContainerIsYAmountDragTheArrowToShowHowMuchJuiceCouldBeInTheXContainer:
    'The {0:string|lower} is {1:string|lower}.<br/>Drag the arrow to show how much juice could be in the {0:string|lower}.',
  theXObjectIsYWeightThanTheZObjectSelectThePicture:
    'The {0:string|lower} is {1:string|lower} than the {2:string|lower}.<br/>Select the picture that shows this.',
  threeChildrenRunARaceSelectWhichChildComesFirst:
    'Three children run a race.<br/>Select which child comes first.',
  usePartWholeModelToCompleteSentence: 'Use the part-whole model to complete the sentence.',
  whatIsTheNumberAsANumeralSelectYourAnswer:
    'What is the word as a numeral?<br/>Select your answer.',
  whatIsTheNumeralAsANumberSelectYourAnswer:
    'What is the numeral as a word?<br/>Select your answer.',
  whatAdditionIsShown: 'What addition is shown?',
  whatAreTheParts: 'What are the parts?',
  whatDayOfTheWeekIsTheXDate: 'What day of the week is the {0:string|lower}?',
  whatDoubleIsShown: 'What double is shown?',
  whatIsDoubleX: 'What is double {0:number|numberFormat}?',
  whatNumberIsShown: 'What number is shown?',
  whatNumberIsTheArrowPointingTo: 'What number is the arrow pointing to?',
  whatSubtractionIsShownOnTheNumberLineDragTheCardsToCompleteTheNumberSentence:
    'What subtraction is shown on the number line?<br/>Drag the cards to complete the number sentence.',
  whatTimeDoesTheClockShow: 'What time does the clock show?',
  whatIsTheNumber: 'What is the number?',
  whatIsTheWholeFillInTheNumber: 'What is the whole?<br/>Fill in the number.',
  wholeIsX: 'The whole is {0:number|numberFormat}',
  xContainerHasYMoreOrLessJuiceThanZContainerDragTheArrowToShowHowMuchJuice:
    '{0:string} {1:string} has {2:string|lower} juice than {0:string|lower} {3:string}.<br/>Drag the arrow to show how much juice could be in {0:string|lower} {3:string}.',
  xGlassesOfWaterFillOneJug: '{0:number|numberFormat} glasses of water fill 1 jug.',
  xObjectsAreSharedEquallyBetweenYBags:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} bags.',
  xObjectsAreSharedEquallyBetweenYBoxes:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} boxes.',
  xObjectsAreSharedEquallyBetweenYTanks:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} tanks.',
  xObjectsAreSharedEquallyBetweenYTrays:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} trays.',
  xObjectsAreSharedEquallyBetweenYPlates:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} plates.',
  xObjectsAreSharedEquallyBetweenYPots:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} pots.',
  xObjectsAreSharedEquallyBetweenYVases:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} vases.',
  xObjectCostsLessThanYObjectWhatIsTotalCostCombinedCompleteBarModel:
    'A {objectB:string|lower} costs £{difference} <b>less</b> than a {objectA:string|lower}.<br/>What is the total cost of a {objectA:string|lower} and a {objectB:string|lower}?<br/>Complete the bar model.',
  xObjectCostsMoreThanYObjectWhatIsTotalCostCombinedCompleteBarModel:
    'A {objectA:string|lower} costs £{difference} <b>more</b> than a {objectB:string|lower}.<br/>What is the total cost of a {objectA:string|lower} and a {objectB:string|lower}?<br/>Complete the bar model.',
  youCanTapTheBase10ToCrossThemOut: 'You can tap the base 10 to cross them out.'
};
