import { View } from 'react-native';
import { useTheme } from 'common/src/theme';
import Button from 'common/src/components/atoms/Button';
import Text from 'common/src/components/typography/Text';
import ModalCard from 'common/src/components/modals/ModalCard';
import { useI18nContext } from '../i18n/i18n-react';
import BaseScreen from 'common/src/components/screens/BaseScreen';

/**
 * Simple modal which simply shows a translate.errorModals.cantLogin(), some text, a confirm button and a cancel button.
 */
export default function CantLoginModal({
  onDismiss,
  onConfirm
}: {
  onDismiss: () => void;
  onConfirm: () => void;
}) {
  const theme = useTheme();
  const translate = useI18nContext().LL;

  return (
    <BaseScreen>
      <ModalCard
        onDismiss={onDismiss}
        containerStyle={{ alignItems: 'center', rowGap: 8, width: 708 }}
      >
        <Text variant="WRN700">{translate.errorModals.cantLogin()}</Text>

        <Text variant="WRN400" style={{ fontSize: 32, lineHeight: 48, textAlign: 'center' }}>
          {translate.errorModals.cantLoginPromptText()}
        </Text>

        <View style={{ flexDirection: 'column', rowGap: 40, marginTop: 32 }}>
          <Button
            baseColor={theme.colors.secondaryContainer}
            onPress={onConfirm}
            style={{ width: 580, height: 96 }}
            testId="CANT_LOGIN_CONTINUE_BTN"
          >
            {translate.errorModals.cantLoginButtonText()}
          </Button>
          <Button
            baseColor={theme.colors.background}
            onPress={onDismiss}
            style={{ width: 580, height: 96 }}
            testId="CANT_LOGIN_CANCEL_BTN"
          >
            {translate.misc.cancel()}
          </Button>
        </View>
      </ModalCard>
    </BaseScreen>
  );
}
