import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { ADD, DIV, MULT } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bi8',
  description: 'bi8',
  keywords: ['Double', 'Halve'],
  schema: z
    .object({
      number: z.number().int().min(0).max(20),
      isDouble: z.boolean(),
      answerIndex: z.number().int().min(0).max(1)
    })
    .refine(
      val =>
        (val.isDouble && isInRange(0, 10)(val.number)) ||
        (!val.isDouble && isInRange(2, 20)(val.number) && val.number % 2 === 0)
    ),
  simpleGenerator: () => {
    const isDouble = getRandomBoolean();
    const number = isDouble ? randomIntegerInclusive(0, 10) : randomIntegerInclusiveStep(2, 20, 2);
    const answerIndex = randomIntegerInclusive(0, 1);

    return {
      isDouble,
      number,
      answerIndex
    };
  },
  Component: ({ question, translate }) => {
    const { isDouble, number, answerIndex } = question;

    const [sentences, answers] = (() => {
      if (isDouble) {
        return [
          [
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} <ans/> ${ADD} <ans/>`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${MULT} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} <ans/>`
          ],
          [
            [number.toString(), number.toString()],
            [(answerIndex === 0 ? number : 2).toString()],
            [(number * 2).toString()]
          ]
        ];
      } else {
        return [
          [
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${DIV} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} <ans/>`
          ],
          [[(answerIndex === 0 ? number : 2).toString()], [(number / 2).toString()]]
        ];
      }
    })();

    return (
      <QF2AnswerBoxManySentences
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={sentences}
        testCorrect={answers}
      />
    );
  }
});

const SmallStep = newSmallStepContent({
  smallStep: 'DoublingAndHalving',
  questionTypes: [Question2],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
